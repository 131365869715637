import { useCompany } from "@inspecto/common";
import { Popconfirm, Tag, Tooltip } from "antd";
import { useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Icon, IconType, IconButton } from "src/components";

import { getIconName, Page, Question } from "../../../models";
import { useTemplateContext } from "../TemplateEditorView";
import { QuestionLevelElement } from "./QuestionLevelElement";

const QuestionContent = styled.div`
  flex: 1;
  font-size: 20px;
`;

const QuestionLabel = styled.div<{ isEmpty: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.isEmpty
      ? props.theme.colors.text.gray
      : props.theme.colors.text.dark};
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

export function QuestionComponent(props: {
  question: Question;
  index: number;
  page: Page;
  allPages: Page[];
  removeQuestion: (pageFrontendId: string, questionFrontendId: string) => void;
  insertQuestion: (
    pageFrontendId: string,
    question: Question,
    index: number
  ) => void;
  canDuplicateQuestion: boolean;
  onEditQuestionClick: () => void;
  onCloneQuestionClick: () => void;
}) {
  const company = useCompany();
  const { customVehicleFields } = useTemplateContext();
  const questionType = props.question.questionType;
  let isDragProhibited = useMemo(() => {
    return ["choice", "damages"].includes(questionType);
  }, [questionType]);

  const { t } = useTranslation("backoffice");

  const customVehicleFieldId =
    props.question.questionType === "custom-field-update"
      ? props.question.customVehicleFieldId
      : null;
  const assignedCustomVehicleField = customVehicleFieldId
    ? customVehicleFields.filter((field) => field.id === customVehicleFieldId)
    : null;

  const customVehicleMainIcon: IconType =
    assignedCustomVehicleField && assignedCustomVehicleField[0]
      ? getIconName(assignedCustomVehicleField[0].type)
      : "questionCircle";

  return (
    <>
      <Draggable
        draggableId={props.question.frontendId}
        index={props.index}
        isDragDisabled={isDragProhibited}
      >
        {(provided) => (
          <QuestionLevelElement
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            data-test="single-question"
          >
            <QuestionContent>
              <QuestionLabel isEmpty={!props.question.label}>
                <IconWrapper>
                  <Icon
                    icon={
                      questionType === "custom-field-update"
                        ? customVehicleMainIcon
                        : getIconName(questionType)
                    }
                  />
                </IconWrapper>
                {questionType === "custom-field-update" ? (
                  <Tooltip
                    title={`${t("builder.questionFields.type")}: ${t(
                      "builder.fieldType.customFieldUpdate"
                    )}`}
                  >
                    <Tag color="default">
                      <Icon icon={getIconName("custom-field-update")} />
                    </Tag>
                  </Tooltip>
                ) : null}
                {props.question.label[company.protocolFillerLanguage] ||
                  t("builder.questionFields.noLabel")}
              </QuestionLabel>
            </QuestionContent>
            <Popconfirm
              title={t("builder.confirmations.question.delete")}
              onConfirm={() =>
                props.removeQuestion(
                  props.page.frontendId,
                  props.question.frontendId
                )
              }
            >
              <IconButton icon="trashAlt" />
            </Popconfirm>
            <IconButton
              icon="pen"
              onClick={props.onEditQuestionClick}
              ariaLabel={t("builder.editQuestionLabel")}
            />
            {props.canDuplicateQuestion && (
              <IconButton
                icon="copy"
                onClick={props.onCloneQuestionClick}
                ariaLabel={t("builder.cloneQuestionLabel")}
              />
            )}
          </QuestionLevelElement>
        )}
      </Draggable>
    </>
  );
}
