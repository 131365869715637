import { Button } from "antd";
import { throttle } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 500px;
  text-align: center;
`;

const SignatureWrapper = styled.div`
  border: 1px solid black;
  box-sizing: content-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface Props {
  onSignatureDataChange(signatureData: File | null): void;
  value: File | null;
  "aria-label"?: string;
}

export function SignaturePad(props: Props) {
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const signatureWrapperRef = useRef<HTMLDivElement | null>(null);
  const [signaturePadWidth, setSignaturePadWidth] = useState<number | null>(
    null
  );
  const { t } = useTranslation("protocolFiller");

  const getSignatureWrapperWidth = useCallback(() => {
    if (!signatureWrapperRef.current) {
      return;
    }

    const width = signatureWrapperRef.current?.offsetWidth;
    setSignaturePadWidth(width);
  }, []);

  const getSignatureWrapperWidthThrottled = useCallback(
    () => throttle(getSignatureWrapperWidth, 500)(),
    [getSignatureWrapperWidth]
  );

  const onSignatureChange = async () => {
    if (!signatureRef.current) {
      return;
    }
    const signatureCanvas = signatureRef.current?.getCanvas();
    const signatureBlob = await new Promise<Blob | null>((resolve, reject) => {
      signatureCanvas?.toBlob((blob) => {
        resolve(blob);
      });
    });

    if (signatureBlob && !signatureRef.current?.isEmpty()) {
      const signatureFile = new File([signatureBlob], "signature.png");
      props.onSignatureDataChange(signatureFile);
    } else {
      props.onSignatureDataChange(null);
    }
  };

  useEffect(() => {
    getSignatureWrapperWidth();
  }, [getSignatureWrapperWidth]);

  useEffect(() => {
    window.addEventListener("resize", getSignatureWrapperWidthThrottled);

    return () => {
      window.removeEventListener("resize", getSignatureWrapperWidthThrottled);
    };
  }, [getSignatureWrapperWidthThrottled]);

  useEffect(() => {
    if (!signatureRef.current) {
      return;
    }

    const signatureCanvas = signatureRef.current.getCanvas();
    const context = signatureCanvas.getContext("2d");

    if (context && props.value) {
      const image = new Image();
      image.src = URL.createObjectURL(props.value);
      image.onload = () => {
        context?.drawImage(
          image,
          0,
          0,
          signatureCanvas.width,
          signatureCanvas.height
        );
      };
    }
  }, [signatureRef, props.value, signaturePadWidth]);

  const clear = async () => {
    signatureRef.current?.clear();
    await onSignatureChange();
  };

  return (
    <Wrapper>
      <SignatureWrapper ref={signatureWrapperRef}>
        {!!signaturePadWidth && (
          <SignatureCanvas
            ref={signatureRef}
            canvasProps={{
              width: signaturePadWidth,
              height: signaturePadWidth / 2,
              "aria-label": props["aria-label"],
            }}
            onEnd={onSignatureChange}
          />
        )}
      </SignatureWrapper>
      <Button type="link" onClick={clear}>
        {t("clearSignaturePad")}
      </Button>
    </Wrapper>
  );
}
