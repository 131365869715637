import { Select, SelectProps } from "antd";
import { useTranslation } from "react-i18next";

import { DividerCheckboxFilterItem } from "./DividerCheckboxFilterItem";
import { FilterItem } from "./ListView";

export function CompanyDepartmentSelectFilterItem(props: {
  options: SelectProps["options"];
}) {
  const { t } = useTranslation("backoffice");
  return (
    <FilterItem name="companyDepartment" $inputWidth="medium">
      <Select
        allowClear
        style={{ width: 250 }}
        options={props.options}
        placeholder={t("settings.companyDepartments.singleItemLabel")}
        dropdownRender={(menu) => (
          <DividerCheckboxFilterItem
            checkboxLabel={t(
              "assignVehiclesList.excludeVehiclesWithSelectedCompanyDepartment"
            )}
            filterItemName="excludeCompanyDepartment"
          >
            {menu}
          </DividerCheckboxFilterItem>
        )}
      />
    </FilterItem>
  );
}
