import { ApiError } from "@inspecto/common";
import { Alert, Button, Form, Modal, Select, Spin } from "antd";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SignatureInput } from "src/components/SignatureInput";
import { convertFileToBase64 } from "src/utils";

import { backOfficeApi } from "../api";
import {
  isSignatureWithSignature,
  isSignatureWithSigner,
  ListEmployee,
  RetrieveProtocolSignature,
} from "../models";

interface Props {
  signature: RetrieveProtocolSignature;
  closeModal: () => void;
  saveSignature: (payload: {
    signature?: string | null;
    signer?: string | null;
  }) => Promise<void>;
}

export function FillOutSignatureModal(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [newSignature, setNewSignature] = useState<File | null>(null);

  const [isSearching, setIsSearching] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<
    string | undefined
  >(undefined);
  const [employees, setEmployees] = useState<ListEmployee[]>([]);

  const handleSearch = useCallback(
    async (value: string): Promise<void> => {
      setIsSearching(true);
      try {
        const allEmployees = await backOfficeApi.getAllEmployees({
          tableParams: {
            pageNumber: 1,
          },
          searchTerm: value,
          searchIn: "name",
          status: "all",
          includeInactive: false,
          userRoles: props.signature.signerRoles,
          emailFilter: "all",
        });
        setEmployees(allEmployees.results);
      } catch (e) {
        setError(t("protocolView.somethingWentWrongWhenSearchingEmployees"));
      }
      setIsSearching(false);
    },
    [props.signature.signerRoles, t]
  );

  useEffect(() => {
    handleSearch("");
  }, [handleSearch]);

  return (
    <Modal
      onCancel={props.closeModal}
      open
      title={
        props.signature.label.length
          ? props.signature.label
          : t("protocolView.fillOutSignatureModalTitle")
      }
      footer={[
        <Button key="back" onClick={props.closeModal}>
          {t("protocolView.fillOutSignatureModalCancelButton")}
        </Button>,
        <Button type="primary" disabled={isLoading} onClick={handleSave}>
          {t("protocolView.fillOutSignatureModalSaveButton")}
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        {!!error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}
        {isSignatureWithSigner(props.signature.type) &&
          !props.signature.signer && (
            <Form layout="vertical">
              <Form.Item
                name="employee"
                label={t("protocolView.fillOutSignatureSelectEmployeeLabel")}
              >
                <Select
                  showSearch
                  loading={isSearching}
                  value={selectedEmployeeId}
                  defaultValue={selectedEmployeeId}
                  placeholder={t("protocolView.startTypingToSearch")}
                  style={{ width: 300 }}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={debounce(handleSearch, 400)}
                  onChange={setSelectedEmployeeId}
                  notFoundContent={t("protocolView.noMatchingEmployeeFound")}
                >
                  {employees.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.firstName} {d.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          )}
        {isSignatureWithSignature(props.signature.type) &&
          !props.signature.signature && (
            <SignatureInput
              onChange={(file: File | null) => {
                setNewSignature(file);
              }}
              label={t("builder.fieldType.signature")}
              value={newSignature}
            />
          )}
      </Spin>
    </Modal>
  );

  async function handleSave() {
    setIsLoading(true);
    setError("");
    try {
      let base64newSignature: string | null = "";
      if (newSignature) {
        base64newSignature = (await convertFileToBase64(
          newSignature
        )) as string;
      }

      await props.saveSignature({
        ...(!!selectedEmployeeId ? { signer: selectedEmployeeId } : {}),
        ...(!!base64newSignature ? { signature: base64newSignature } : {}),
      });
    } catch (e) {
      if (e instanceof ApiError) {
        setError(Object.values(e.body).join(" "));
      } else {
        setError(t("protocolView.somethingWentWrongWhenSavingSignature"));
      }
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    props.closeModal();
  }
}
