import { LockOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { Icon } from "src/components";

interface Props {
  pin: "__HIDDEN__" | string;
}

const PinWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Pin = styled.code`
  width: 60px;
`;

export function PinDisplay({ pin }: Props) {
  const isDisabled = useMemo(() => pin === "__HIDDEN__", [pin]);
  const isEmptyPin = useMemo(() => pin.length === 0, [pin]);
  const [isVisible, setIsVisible] = useState(isEmptyPin);

  return (
    <PinWrapper>
      <Pin>
        <Typography.Text
          type={isDisabled || isEmptyPin ? "secondary" : undefined}
        >
          {isVisible ? pin || "------" : "******"}
        </Typography.Text>
      </Pin>
      {!isEmptyPin && (
        <Button
          size="small"
          type="text"
          icon={
            isDisabled ? (
              <LockOutlined />
            ) : (
              <Icon icon={isVisible ? "eyeSlashRegular" : "eyeRegular"} />
            )
          }
          onClick={() => setIsVisible(!isVisible)}
          disabled={isDisabled}
        />
      )}
    </PinWrapper>
  );
}
