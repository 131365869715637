import { useCompany } from "@inspecto/common";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import inspectoLogoBlue from "src/static/inspectoLogoBlue.svg";
import { urls } from "src/urls";

export interface LogoImageProps {
  maxHeight: number;
  maxWidth: number;
}

export interface CompanyLogoProps {
  size: LogoImageProps;
  onClick?: (navigateToLandingPage: () => void) => void;
}

const LogoImage = styled.img<LogoImageProps>`
  max-height: ${(props) => props.maxHeight}px;
  max-width: ${(props) => props.maxWidth}px;
  cursor: pointer;
  display: block;
`;

export function CompanyLogo({
  onClick = (a) => a(),
  ...props
}: CompanyLogoProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const company = useCompany();

  return (
    <LogoImage
      role="link"
      maxHeight={props.size.maxHeight}
      maxWidth={props.size.maxWidth}
      src={company.logo || inspectoLogoBlue}
      alt={company.name}
      title={t("mainPage")}
      onClick={() => {
        onClick(() => history.push(urls.landingPage()));
      }}
    />
  );
}
