import { useCallback } from "react";

import { backOfficeApi } from "../api";
import { SimpleVehicleGroup } from "../models";
import { useFetchOptions } from "./useFetchOptions";

export interface UseVehicleGroups {
  vehicleGroups: SimpleVehicleGroup[];
  isLoadingVehicleGroups: boolean;
  vehicleGroupOptions: { label: string; value: SimpleVehicleGroup["id"] }[];
  refresh: () => void;
}

export function useVehicleGroups(): UseVehicleGroups {
  const mapper = useCallback(
    (vehicleGroup) => ({
      value: vehicleGroup.id,
      label: vehicleGroup.label,
    }),
    []
  );
  const fetcher = useCallback(async () => {
    const vehicleGroups = await backOfficeApi.getVehicleGroups();
    return vehicleGroups.results;
  }, []);

  const [vehicleGroups, isLoadingVehicleGroups, vehicleGroupOptions, refresh] =
    useFetchOptions<SimpleVehicleGroup>(fetcher, mapper);

  return {
    isLoadingVehicleGroups,
    vehicleGroupOptions,
    vehicleGroups,
    refresh,
  };
}
