import { Checkbox, Divider, List } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { isSingleElementDefined } from "src/utils";

import { useScrollToElement } from "../hooks";
import { ListCustomVehicleField } from "../models";
import { CustomAvatarFieldType } from "./CustomAvatarFieldType";

const Wrapper = styled.div`
  padding-top: 10px;
`;

const StyledCheckbox = styled(Checkbox)`
  display: flex;

  & > span {
    flex: 1 1 auto;

    &.ant-checkbox {
      flex: 0;
    }
  }
`;

interface Props {
  value?: string[];
  onChange?: (value: string[]) => void;
  customVehicleFields: ListCustomVehicleField[];
  onEditCustomVehicleFieldClick?(id: string): void;
  onCloneCustomVehicleFieldClick?(id: string): void;
}

export function CustomVehicleFieldCheckboxSelector(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  const [containerRef, customVehicleFieldRefs] = useScrollToElement({
    value: isSingleElementDefined(props.value) ? props.value[0] : undefined,
    items: props.customVehicleFields,
  });

  const handleSelectAllChange = useCallback(
    (e) => {
      props.onChange?.(
        e.target.checked
          ? props.customVehicleFields.map((field) => field.id)
          : []
      );
    },
    [props]
  );

  const handleCheckboxChange = useCallback(
    (id: string, checked: boolean) => {
      if (checked) {
        props.onChange?.([...(props.value || []), id]);
      } else {
        props.onChange?.((props.value || []).filter((value) => value !== id));
      }
    },
    [props]
  );

  const isIndeterminate =
    props.value &&
    props.value.length > 0 &&
    props.value.length < props.customVehicleFields.length;
  const isChecked = props.value?.length === props.customVehicleFields.length;

  return (
    <Wrapper>
      {!!props.customVehicleFields.length && (
        <>
          <Checkbox
            indeterminate={isIndeterminate}
            checked={isChecked}
            onChange={handleSelectAllChange}
          >
            {t("reportsView.fieldNames.selectAll")}
          </Checkbox>
          <Divider style={{ margin: "15px 0" }} />
        </>
      )}

      <div ref={containerRef}>
        <List
          grid={{
            gutter: 0,
            column: 1,
          }}
          style={{
            height: 456,
            overflowY: "scroll",
          }}
          split={false}
          dataSource={props.customVehicleFields}
          renderItem={(customVehicleField) => (
            <List.Item style={{ marginBottom: 10 }}>
              <div ref={customVehicleFieldRefs[customVehicleField.id]}>
                <StyledCheckbox
                  value={customVehicleField.id}
                  checked={props.value?.includes(customVehicleField.id)}
                  onChange={(e) =>
                    handleCheckboxChange(
                      customVehicleField.id,
                      e.target.checked
                    )
                  }
                >
                  <CustomAvatarFieldType
                    customVehicleField={customVehicleField}
                    onEditCustomVehicleFieldClick={
                      props.onEditCustomVehicleFieldClick
                    }
                    onCloneCustomVehicleFieldClick={
                      props.onCloneCustomVehicleFieldClick
                    }
                  />
                </StyledCheckbox>
                <Divider style={{ margin: "10px 0" }} />
              </div>
            </List.Item>
          )}
        />
      </div>
    </Wrapper>
  );
}
