import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CommonForm, FormProps } from "./CommonForm";

export function WithSaveButtonForm<
  RecordType extends Record<string, any>,
  TSaveOutput = void
>({ children, ...restProps }: FormProps<RecordType, TSaveOutput>) {
  const { t } = useTranslation("backoffice");
  const [isSaving, setIsSaving] = useState(false);

  return (
    <CommonForm<RecordType, TSaveOutput>
      onIsSavingChange={setIsSaving}
      {...restProps}
    >
      {children}
      <Button loading={isSaving} type="primary" htmlType="submit">
        {t("save")}
      </Button>
    </CommonForm>
  );
}
