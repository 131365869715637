import { Divider } from "antd";
import { PropsWithChildren } from "react";

interface Props {
  topContent: JSX.Element;
}

export function DropdownMenuDivider(props: PropsWithChildren<Props>) {
  return (
    <>
      <div style={{ padding: "5px 18px" }}>{props.topContent}</div>
      <Divider style={{ margin: "4px 0 8px 0" }} />
      {props.children}
    </>
  );
}
