import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Space, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { urls } from "src/urls";

import { RetrieveCompanyDepartment } from "../../../models";
import {
  BackOfficeLayout,
  BreadcrumbInformation,
} from "../../BackOfficeLayout";
import { contentMinWidth } from "./common";
import { CompanyDepartmentForm } from "./CompanyDepartmentForm";

function ButtonWithCount(props: { label: string; url: string; count: number }) {
  const history = useHistory();
  return (
    <Button onClick={() => history.push(props.url)}>
      <Space>
        <ArrowRightOutlined />
        <span>{props.label}</span>
        <Tag color={props.count > 0 ? "default" : "error"}>
          <Typography.Text strong>{props.count}</Typography.Text>
        </Tag>
      </Space>
    </Button>
  );
}

export function EditCompanyDepartmentView(props: {
  companyDepartment: RetrieveCompanyDepartment;
  breadcrumbs: BreadcrumbInformation[];
  onSuccessfulSave: () => void;
}) {
  const { t } = useTranslation("backoffice");

  return (
    <BackOfficeLayout
      contentMinWidth={contentMinWidth}
      breadcrumbs={props.breadcrumbs}
      pageTitle={t("settings.companyDepartments.singleItemLabel")}
    >
      <BackOfficeLayout.Content>
        <CompanyDepartmentForm
          existingCompanyDepartment={props.companyDepartment}
          onSuccessfulSave={props.onSuccessfulSave}
        >
          <Button.Group
            style={{ marginRight: 20, display: "block", marginBottom: 20 }}
          >
            <ButtonWithCount
              label={t("assignVehiclesList.title")}
              url={urls.backOffice.settings.assignCompanyDepartmentToVehicles(
                props.companyDepartment.id
              )}
              count={props.companyDepartment.assignedVehiclesCount}
            />
            <ButtonWithCount
              label={t("assignUsersList.title")}
              url={urls.backOffice.settings.assignCompanyDepartmentToUsers(
                props.companyDepartment.id
              )}
              count={props.companyDepartment.assignedUsersCount}
            />
          </Button.Group>
        </CompanyDepartmentForm>
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
