import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { wrapCallback } from "src/utils/antdFormUtils";

import { CommonForm, FormProps } from "./CommonForm";
import { GlobalFormErrors } from "./GlobalFormErrors";

export interface ModalFormProps {
  darkBackground?: boolean;
  disableMotion?: boolean;
  width?: number;
  open: boolean;
  closeModal(): void;
  formTitle: string;
  destroy?: {
    removeCallback: () => Promise<void>;
    buttonText: string;
    buttonIcon?: JSX.Element;
    isDangerButton: boolean;
    confirmationText?: string;
    unexpectedErrorMessageOnDestroy: string;
  };
  cancelButtonText: string;
  confirmButtonText: string;
}

export function ModalForm<
  RecordType extends Record<string, any>,
  TSaveOutput = void
>(props: FormProps<RecordType, TSaveOutput> & ModalFormProps) {
  const { t } = useTranslation();
  const [modal, modalContextHolder] = Modal.useModal();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { open, closeModal, formTitle, width, children, ...commonProps } =
    props;
  const [form] = Form.useForm(props.form);
  const [removeErrors, setRemoveErrors] = useState<string[]>([]);

  const destroy = useCallback(async () => {
    setIsDeleting(true);
    if (!props.destroy) {
      return;
    }

    try {
      await wrapCallback(
        props.destroy.removeCallback,
        form,
        setRemoveErrors,
        () => {},
        props.destroy.unexpectedErrorMessageOnDestroy,
        t("formValidationErrors", { returnObjects: true }),
        false
      );
      props.closeModal();
    } catch {}

    setIsDeleting(false);
  }, [t, form, props]);

  return (
    <Modal
      transitionName={props.disableMotion ? "" : undefined} // AntD: this prop is internal usage which we don't promise exist in next major version.
      maskTransitionName={props.disableMotion ? "" : undefined} // AntD: this prop is internal usage which we don't promise exist in next major version.
      width={width}
      open={open}
      onCancel={props.closeModal}
      title={formTitle}
      bodyStyle={
        props.darkBackground
          ? {
              background: "#ececec",
            }
          : undefined
      }
      footer={[
        ...(props.destroy
          ? [
              <Button
                danger={props.destroy.isDangerButton}
                disabled={isSaving}
                loading={isDeleting}
                icon={props.destroy.buttonIcon}
                key="destroy"
                onClick={() => {
                  if (props.destroy?.confirmationText) {
                    return modal.confirm({
                      centered: true,
                      title: props.destroy?.confirmationText,
                      icon: <ExclamationCircleOutlined />,
                      okText: t("yes"),
                      okType: "danger",
                      cancelText: t("no"),
                      onOk() {
                        destroy();
                      },
                    });
                  } else {
                    destroy();
                  }
                }}
              >
                {props.destroy.buttonText}
              </Button>,
            ]
          : []),
        <Button key="back" onClick={props.closeModal}>
          {props.cancelButtonText}
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={isSaving}
          disabled={isDeleting}
          onClick={() => form.submit()}
        >
          {props.confirmButtonText}
        </Button>,
      ]}
    >
      <CommonForm<RecordType, TSaveOutput>
        {...commonProps}
        onIsSavingChange={setIsSaving}
        onSuccessfulSave={(formValues, savedRecord) => {
          props.closeModal();
          props.onSuccessfulSave?.(formValues, savedRecord);
        }}
        form={form}
      >
        {children}
      </CommonForm>
      <GlobalFormErrors errors={removeErrors} />
      {modalContextHolder}
    </Modal>
  );
}
