import {
  ChoiceResponse,
  PhotosAndNoteRequirementsWithId,
} from "src/components";

import { ValidationError } from "../models";
import { getChoicesWithPhotosAndNoteResponseValidationErrors } from "./getChoicesWithPhotosAndNoteResponseValidationErrors";

export function getChoiceResponseValidationErrors(
  choices: PhotosAndNoteRequirementsWithId[],
  isRequired: boolean,
  response: ChoiceResponse | undefined = {
    photos: [],
    note: "",
    selectedChoiceIds: [],
  }
): ValidationError[] {
  if (!response.selectedChoiceIds.length && isRequired) {
    return [ValidationError.REQUIRED_QUESTION];
  }

  return getChoicesWithPhotosAndNoteResponseValidationErrors(choices, response);
}
