import { PaginationResults } from "./types";

export async function fetchAllPaginatedElements<T>(
  dataGetter: (pageNumber: number) => Promise<PaginationResults<T[]>>
): Promise<T[]> {
  let shouldContinue = true;
  let pageNumber = 1;
  const builtElements: T[] = [];
  while (shouldContinue) {
    const fetchedPage = await dataGetter(pageNumber);

    shouldContinue = !!fetchedPage.next;
    builtElements.push(...fetchedPage.results);
    pageNumber = pageNumber + 1;
  }

  return builtElements;
}
