import { useCallback } from "react";

import { backOfficeApi } from "../api";
import { VehicleType } from "../models";
import { useFetchOptions } from "./useFetchOptions";

interface UseVehicleTypes {
  vehicleTypes: VehicleType[];
  isLoadingVehicleTypes: boolean;
  vehicleTypeOptions: { label: string; value: string }[];
}

export function useVehicleTypes(): UseVehicleTypes {
  const mapper = useCallback(
    (vehicleType) => ({
      value: vehicleType.id,
      label: vehicleType.label,
    }),
    []
  );
  const fetcher = useCallback(() => backOfficeApi.getVehicleTypes(), []);

  const [vehicleTypes, isLoadingVehicleTypes, vehicleTypeOptions] =
    useFetchOptions<VehicleType>(fetcher, mapper);

  return {
    vehicleTypes,
    isLoadingVehicleTypes,
    vehicleTypeOptions,
  };
}
