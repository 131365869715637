import { InputNumber } from "antd";
import { useCallback } from "react";

import { numberFieldValueConfig } from "./config";

export type NumberFieldValue = number | null;

interface Props {
  value?: NumberFieldValue;
  onChange?: (value: NumberFieldValue) => void;
  autoFocus?: boolean;
  disabled?: boolean;
}

export function NumberFieldComponent(props: Props) {
  const handleOnChange = useCallback(
    (inputNumberValue: number | null) => {
      props.onChange?.(inputNumberValue);
    },
    [props]
  );

  return (
    <InputNumber<number>
      autoFocus={props.autoFocus}
      inputMode="decimal"
      decimalSeparator=","
      style={{ width: 160 }}
      controls={false}
      disabled={props.disabled}
      value={props.value == null ? undefined : props.value}
      onChange={handleOnChange}
      step="" // Prevent up/down arrow keys from modifying input value
      onChangeCapture={(event) => {
        const { maxDigitsBeforeDecimal, decimalPlaces } =
          numberFieldValueConfig;
        const pattern = `^(-?)(\\d{0,${maxDigitsBeforeDecimal}})(([.,])(\\d{0,${decimalPlaces}}))?`;
        const matchedGroups = event.currentTarget.value.match(
          new RegExp(pattern, "g")
        );
        event.currentTarget.value =
          Array.isArray(matchedGroups) &&
          typeof matchedGroups[0] !== "undefined"
            ? matchedGroups[0]
            : "";
      }}
    />
  );
}
