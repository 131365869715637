import { ArrowDownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SelectorWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const SelectorHeightLimit = styled.div`
  max-height: 200px;
  overflow: hidden;
  opacity: 0.8;
`;

const SelectorOverlayWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const SelectorOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  display: flex;
  align-items: end;
  padding-bottom: 20px;
  justify-content: center;
`;

interface Props {
  children: (isExpanded: boolean) => JSX.Element;
}

export function ExpandableSection({ children }: Props) {
  const { t } = useTranslation("backoffice");
  const [isExpanded, setIsExpanded] = useState(false);

  const selector = React.useMemo(
    () => children(isExpanded),
    [children, isExpanded]
  );

  return isExpanded ? (
    selector
  ) : (
    <SelectorWrapper onClick={() => setIsExpanded(true)}>
      <SelectorHeightLimit>{selector}</SelectorHeightLimit>
      <SelectorOverlayWrapper>
        <SelectorOverlay>
          <Button onClick={() => setIsExpanded(true)}>
            <ArrowDownOutlined />
            {t("expand")}
          </Button>
        </SelectorOverlay>
      </SelectorOverlayWrapper>
    </SelectorWrapper>
  );
}
