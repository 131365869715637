import { Space, Typography } from "antd";
import { forwardRef, ReactNode } from "react";
import styled from "styled-components";

import { Asterisk, Icon } from "src/components";

const Wrapper = styled.div<{ hasRedBorder?: boolean }>`
  background-color: #fff;
  border: 1px solid
    ${(props) =>
      props.hasRedBorder
        ? props.theme.colors.status.notOk
        : props.theme.colors.whitePanelBorderColor};
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 12px 12px 0 12px;
`;

const Label = styled.div`
  margin-bottom: 12px;
`;

const Children = styled.div`
  margin-bottom: 12px;
`;

const ErrorMessage = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

interface Props {
  children: ReactNode;
  hasAsterisk?: boolean;
  errors?: string[];
  title?: string;
  innerKey?: string;
}

export const WhiteCard = forwardRef<HTMLDivElement, Props>(
  ({ errors = [], ...props }, ref) => {
    return (
      <Wrapper
        key={props.innerKey}
        ref={ref}
        hasRedBorder={!!errors.length}
        data-testid={`WhiteCard for ${props.title}`}
      >
        {props.title && (
          <Label>
            {props.hasAsterisk && <Asterisk />}
            <strong>{props.title}</strong>
          </Label>
        )}
        <Children>{props.children}</Children>
        {errors.map((error) => (
          <ErrorMessage>
            <Space align="start">
              <Icon icon="exclamationCircle" color="red" />
              <Typography.Text type="danger">{error}</Typography.Text>
            </Space>
          </ErrorMessage>
        ))}
      </Wrapper>
    );
  }
);
