import { Form, Input, message } from "antd";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { MaxWidth700WithSaveButtonForm } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import {
  CreateCompanyDepartment,
  RetrieveCompanyDepartment,
} from "../../../models";

interface Props {
  existingCompanyDepartment: RetrieveCompanyDepartment | null;
  onSuccessfulSave?: () => void;
}

const formDefaultValues: CreateCompanyDepartment = {
  name: "",
};

export function CompanyDepartmentForm(props: PropsWithChildren<Props>) {
  const { t } = useTranslation("backoffice");
  const history = useHistory();

  return (
    <MaxWidth700WithSaveButtonForm<CreateCompanyDepartment, void>
      saveCallback={async (values) => {
        if (props.existingCompanyDepartment) {
          await backOfficeApi.updateCompanyDepartment({
            ...values,
            id: props.existingCompanyDepartment.id,
          });
        } else {
          const newCompanyDepartment =
            await backOfficeApi.createCompanyDepartment(values);

          history.replace(
            urls.backOffice.settings.editCompanyDepartment(
              newCompanyDepartment.id
            )
          );
        }
        props.onSuccessfulSave?.();
        message.success(t("settings.companyDepartments.savedSuccessfully"));
      }}
      initialValues={props.existingCompanyDepartment || formDefaultValues}
    >
      <Form.Item
        name="name"
        label={t("settings.companyDepartments.columns.name")}
      >
        <Input style={{ maxWidth: 350 }} />
      </Form.Item>
      {props.children}
    </MaxWidth700WithSaveButtonForm>
  );
}
