import { Space, Timeline, TimelineItemProps, Typography } from "antd";
import dayjs from "dayjs";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { UserAvatar } from "src/components";
import { urls } from "src/urls";
import { fullName } from "src/utils";

const TimelineItemContent = styled.div`
  display: flex;
  margin-top: 10px;
`;

interface Props {
  key: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  datetime: string;
  dot?: TimelineItemProps["dot"];
  datePrefix?: string;
  description?: JSX.Element;
  marginBottom: 30 | 10;
}

export function UserDateTimelineItem(props: PropsWithChildren<Props>) {
  return (
    <Timeline.Item key={props.key} dot={props.dot}>
      <div>
        <Space>
          {props.user ? (
            <Typography.Text strong>
              <Link
                to={urls.backOffice.editEmployee(props.user.id)}
                target="_blank"
              >
                <Space>
                  <UserAvatar user={props.user} size={22} />
                  {fullName(props.user)}
                </Space>
              </Link>
            </Typography.Text>
          ) : null}
          <Typography.Text>
            {props.datePrefix ? `${props.datePrefix} ` : null}
            <Typography.Text strong>
              {dayjs(props.datetime).format("DD.MM.YYYY, HH:mm")}
            </Typography.Text>
          </Typography.Text>
        </Space>
      </div>
      {props.description}

      <TimelineItemContent style={{ marginBottom: props.marginBottom }}>
        {props.children}
      </TimelineItemContent>
    </Timeline.Item>
  );
}
