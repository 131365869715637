import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { protocolFillerApi } from "../api";
import { Vehicle } from "../models";

type VehiclesContext = {
  vehicles: Vehicle[];
  loadingState: "loading" | "error" | "loaded";
  reloadVehicles: () => void;
  getVehicleById: (vehicleId: string) => Vehicle | null;
};

const vehiclesContext = createContext<VehiclesContext>({
  vehicles: [],
  loadingState: "loading",
  reloadVehicles: () => {},
  getVehicleById: () => null,
});

const { Provider } = vehiclesContext;

export function VehiclesProvider(props: PropsWithChildren<{}>) {
  const [vehicles, setVehicles] = useState<VehiclesContext["vehicles"]>([]);
  const [loadingState, setLoadingState] =
    useState<VehiclesContext["loadingState"]>("loading");

  const reloadVehicles = useCallback(() => {
    setLoadingState("loading");
    protocolFillerApi
      .getVehicles()
      .then((vehicles) => {
        setVehicles(vehicles);
        setLoadingState("loaded");
      })
      .catch(() => setLoadingState("error"));
  }, []);

  const getVehicleById = useCallback(
    (vehicleId: string) => {
      if (loadingState !== "loaded") {
        return null;
      }
      const vehicle = vehicles.find((vehicle) => vehicle.id === vehicleId);

      return vehicle || null;
    },
    [vehicles, loadingState]
  );

  useEffect(() => {
    reloadVehicles();
  }, [reloadVehicles]);

  return (
    <Provider
      value={{
        vehicles,
        loadingState,
        reloadVehicles,
        getVehicleById,
      }}
    >
      {props.children}
    </Provider>
  );
}

export function useVehicles() {
  return useContext(vehiclesContext);
}
