import { StorageUploadedImage } from "src/storage";

import {
  CommonImmediateFileUploader,
  ImmediateImageUploaderProps,
} from "./CommonImmediateFileUploader";

export type FileValue = StorageUploadedImage | null;

export interface SingleFileImmediateUploaderProps
  extends Pick<ImmediateImageUploaderProps, "uploaderId"> {
  value?: FileValue;
  onChange?(image: FileValue): void;
}

export function SingleFileImmediateUploader({
  value,
  onChange,
  ...props
}: SingleFileImmediateUploaderProps) {
  return (
    <CommonImmediateFileUploader
      singleImageMode={true}
      uploadType="files"
      onChange={(uploadedImages) =>
        onChange
          ? onChange(uploadedImages.length ? uploadedImages[0]! : null)
          : null
      }
      value={value ? [value] : undefined}
      {...props}
    />
  );
}
