import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Icon } from "./Icon";

const Warning = styled.div`
  color: ${(props) => props.theme.colors.status.notOk};
  font-size: 9px;
  vertical-align: middle;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  min-width: 70px;
`;

const IconWrapper = styled.span`
  margin-right: 2px;
  vertical-align: middle;
`;

export function WarningBadge(props: { text?: string }) {
  const { t } = useTranslation();
  return (
    <Warning>
      <IconWrapper>
        <Icon icon="exclamationCircle" color="red" size={15} />
      </IconWrapper>{" "}
      {props.text || t("thisFieldIsRequired")}
    </Warning>
  );
}
