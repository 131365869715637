import { Avatar, Button, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { DamagesCustomVehicleFieldType } from "src/company";
import { Icon } from "src/components";
import { myTheme } from "src/theme";

import { getIconName, Question } from "../models";

export function QuestionTypeWithAvatar(props: {
  questionType: Question["questionType"] | DamagesCustomVehicleFieldType;
  label: string;
  onChangeButtonClick?: () => void;
}): JSX.Element {
  const { t } = useTranslation("backoffice");

  return (
    <Space size="large">
      <div>
        <Avatar
          icon={<Icon icon={getIconName(props.questionType)} />}
          style={{
            color: myTheme.colors.text.dark,
            backgroundColor: myTheme.colors.text.light,
            marginRight: 10,
          }}
        />
        <Typography.Text strong>{props.label}</Typography.Text>
      </div>
      {!!props.onChangeButtonClick && (
        <Button shape="round" size="small" onClick={props.onChangeButtonClick}>
          <Space>
            <Icon icon="edit" />
            {t("builder.questionFields.change")}
          </Space>
        </Button>
      )}
    </Space>
  );
}
