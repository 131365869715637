import { myTheme } from "src/theme";

export function scrollToElement(element: HTMLElement): void {
  // TODO: This should be fixed to take deployment info into account
  window.scrollTo({
    top:
      element.getBoundingClientRect().top +
      window.pageYOffset -
      (myTheme.commonVariables.protocolFillerHeaderHeight + 10),
    behavior: "smooth",
  });
}
