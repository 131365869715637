import { ProtocolFillerLanguage, useCompany } from "@inspecto/common";
import { Modal, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { getCompanyAvailableProtocolFillerLanguages } from "src/company";
import { FlagImage, RadioGroupOfTypeButton, Spinner } from "src/components";
import { getLanguageConfig, UserProtocolFillerLanguage } from "src/locales";
import { useReporting } from "src/reporting/useReporting";

import { protocolFillerApi } from "../api";

function LabelWithFlag(props: {
  language: ProtocolFillerLanguage;
  text: string;
}): JSX.Element {
  return (
    <Space size="middle">
      <FlagImage
        alt={props.language}
        src={getLanguageConfig(props.language).countryFlag}
      />
      {props.text}
    </Space>
  );
}

type LanguageOption<TLanguage> = {
  label: JSX.Element;
  value: TLanguage;
};

interface Props {
  userId: string;
  currentUserProtocolFillerLanguage: UserProtocolFillerLanguage;
  closeModal(): void;
  reloadUser(): Promise<void>;
}

export function SelectLanguageModal(props: Props): JSX.Element {
  const { logAnalyticsEvent } = useReporting();
  const { t } = useTranslation("protocolFiller");
  const { t: tTranslation } = useTranslation("translation");
  const company = useCompany();
  const protocolFillerLanguageOptions: LanguageOption<UserProtocolFillerLanguage>[] =
    languagesToSortedOptions(
      getCompanyAvailableProtocolFillerLanguages(company)
    );
  const [isSaving, setIsSaving] = useState(false);

  return isSaving ? (
    <Spinner />
  ) : (
    <Modal
      open
      footer={[]}
      title={t("language")}
      onCancel={props.closeModal}
      style={{ top: 25 }}
    >
      <RadioGroupOfTypeButton
        items={protocolFillerLanguageOptions}
        onChange={handleChange}
        value={
          props.currentUserProtocolFillerLanguage ||
          company.protocolFillerLanguage
        }
      />
    </Modal>
  );

  function languagesToSortedOptions<TLanguage extends ProtocolFillerLanguage>(
    languages: readonly TLanguage[]
  ): LanguageOption<TLanguage>[] {
    return languages
      .map((language) => ({
        language,
        translatedLanguage: tTranslation(`languages.${language}`),
      }))
      .sort((a, b) => (a.translatedLanguage < b.translatedLanguage ? -1 : 1))
      .map(({ language, translatedLanguage }) => ({
        label: <LabelWithFlag language={language} text={translatedLanguage} />,
        value: language,
      }));
  }

  async function handleChange(
    selectedLanguage: UserProtocolFillerLanguage | null
  ) {
    setIsSaving(true);
    logAnalyticsEvent({
      eventType: "changedUserLanguageFromProtocolFiller",
      payload: {
        fromLanguage: props.currentUserProtocolFillerLanguage,
        toLanguage: selectedLanguage || "",
      },
    });
    try {
      await protocolFillerApi.updateEmployee(
        props.userId,
        selectedLanguage || ""
      );
      await props.reloadUser();
      props.closeModal();
    } catch (e) {
      toast.error(tTranslation("somethingWentWrong"));
    } finally {
      setIsSaving(false);
    }
  }
}
