import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, TableColumnType, Typography } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { BasicTable } from "src/components";
import { urls } from "src/urls";

import { BackOfficeLayout } from "../../BackOfficeLayout";

interface NotificationSettingType {
  label: string;
  url: string;
}

export function NotificationsSettingsListView() {
  const { t } = useTranslation("backoffice");

  const history = useHistory();

  const columns = useMemo<TableColumnType<NotificationSettingType>[]>(
    () => [
      {
        title: t("settings.notifications.notificationType"),
        render: (item) => <Typography.Text>{item.label}</Typography.Text>,
      },
      {
        width: 140,
        align: "right",
        render: (item: NotificationSettingType) => (
          <Button
            size="small"
            icon={<ArrowRightOutlined />}
            onClick={() => {
              history.push(item.url);
            }}
          >
            {t("tableActions.open")}
          </Button>
        ),
      },
    ],
    [history, t]
  );
  return (
    <BackOfficeLayout
      pageTitle={t("settings.notifications.label")}
      breadcrumbs={[{ label: t("settings.label") }]}
    >
      <BackOfficeLayout.Content>
        <BasicTable
          columns={columns}
          pagination={false}
          dataSource={[
            {
              label: t("settings.notifications.notificationTypes.newDamages"),
              url: urls.backOffice.settings.notifications.newDamages(),
            },
            {
              label: t("settings.notifications.notificationTypes.newProtocols"),
              url: urls.backOffice.settings.notifications.newProtocols(),
            },
          ]}
        />
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
