import { useMemo } from "react";

import { PAGE_SIZE } from "./config";

interface UseLocalPagination<T> {
  currentPage: T[];
}

export function useLocalPagination<T>(
  allItems: T[],
  pageNumber: number
): UseLocalPagination<T> {
  const currentPage = useMemo(() => {
    const startIndex = (pageNumber - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    return allItems.slice(startIndex, endIndex);
  }, [allItems, pageNumber]);

  return {
    currentPage,
  };
}
