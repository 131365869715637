import { TableColumnType } from "antd";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { MultiSelectProps } from "src/components";
import { fullName } from "src/utils";

import { backOfficeApi } from "../../../api";
import {
  AssignUsersListView,
  CompanyDepartmentsMultiSelectFilterItem,
} from "../../../components";
import { getAssignedItemsTagsRenderer } from "../../../components/AssignedItemsTagsRenderer";
import {
  CompanyDepartmentListEmployee,
  ListCompanyDepartment,
  ListEmployee,
  RetrieveCompanyDepartment,
} from "../../../models";
import {
  BackOfficeLayout,
  BreadcrumbInformation,
} from "../../BackOfficeLayout";
import { contentMinWidth } from "./common";

export function AssignCompanyDepartmentToUsersView(props: {
  companyDepartment: RetrieveCompanyDepartment;
  breadcrumbs: BreadcrumbInformation[];
  allCompanyDepartments: ListCompanyDepartment[];
  onSuccessfulSave: () => void;
}) {
  const { t } = useTranslation("backoffice");
  const { companyDepartmentId } = useParams<{ companyDepartmentId: string }>();
  const allCompanyDepartmentsOptions: MultiSelectProps<string>["options"] =
    useMemo(
      () =>
        props.allCompanyDepartments.map((department) => ({
          label: department.name,
          value: department.id,
        })),
      [props.allCompanyDepartments]
    );

  const isUserAssigned: (user: ListEmployee) => boolean = useCallback(
    (user) =>
      user.companyDepartments
        .map((item) => item.id)
        .includes(companyDepartmentId),
    [companyDepartmentId]
  );

  const extraColumns: (
    selectedRows: ListEmployee[]
  ) => TableColumnType<ListEmployee>[] = useCallback(
    (selectedRows: ListEmployee[]) => [
      {
        dataIndex: "companyDepartments",
        title: t("settings.companyDepartments.listLabel"),
        width: 400,
        render: getAssignedItemsTagsRenderer<
          ListEmployee,
          CompanyDepartmentListEmployee
        >(
          selectedRows,
          {
            id: companyDepartmentId,
            label: props.companyDepartment.name,
          },
          (companyDepartment) => companyDepartment.name
        ),
      },
    ],
    [companyDepartmentId, props.companyDepartment.name, t]
  );

  return (
    <div>
      <BackOfficeLayout
        contentMinWidth={contentMinWidth}
        breadcrumbs={props.breadcrumbs}
        pageTitle={t(
          "settings.companyDepartments.usersAssignedToCompanyDepartment",
          {
            name: props.companyDepartment.name,
          }
        )}
      >
        <BackOfficeLayout.Content>
          <AssignUsersListView
            header=""
            selectableRowRequirements={{
              deselectionDisallowedMessages: {
                title: t(
                  "settings.companyDepartments.userUnassignmentDisallowed.title"
                ),
                info: t(
                  "settings.companyDepartments.userUnassignmentDisallowed.info"
                ),
              },
              renderDisallowedRow: (row) => fullName(row),
              isRowSelectionRequired: (row) =>
                row.companyDepartments.length === 1 &&
                row.companyDepartments[0]!.id === companyDepartmentId,
            }}
            isUserAssigned={isUserAssigned}
            assignUsers={async (payload) => {
              await backOfficeApi.assignCompanyDepartmentToUsers(
                companyDepartmentId,
                payload
              );
              props.onSuccessfulSave();
            }}
            extraColumns={extraColumns}
            extraFilters={
              <CompanyDepartmentsMultiSelectFilterItem
                options={allCompanyDepartmentsOptions}
              />
            }
          />
        </BackOfficeLayout.Content>
      </BackOfficeLayout>
    </div>
  );
}
