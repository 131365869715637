import { Divider, Form, Grid, Input, message } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { CommonForm, SwitchInput } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import {
  CustomFieldValuesFormState,
  CustomVehicleFieldsFormItems,
  FilterItem,
  FiltersRow,
} from "../../../components";
import {
  RetrieveVehicleCustomVehicleField,
  ListCustomVehicleField,
} from "../../../models";
import { BackOfficeLayout } from "../../BackOfficeLayout";
import { StickySaveButtons } from "./StickySaveButtons";

type CustomFieldValue = Pick<
  RetrieveVehicleCustomVehicleField["valueObject"],
  "value" | "note" | "photos"
>;

type CustomFieldValueById = Record<string, CustomFieldValue>;

interface Props {
  vehicleId: string;
  customFieldValues: Record<string, RetrieveVehicleCustomVehicleField>;
  customVehicleFieldsToDisplay: ListCustomVehicleField[];
  reloadVehicleFieldValues: () => Promise<void>;
}

export function DisplayUpdateVehicleCustomFieldsForm(props: Props) {
  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  const [showOnlyNotOkVehicleFields, setShowOnlyNotOkVehicleFields] =
    useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");

  const initialFormValues = useMemo(
    () =>
      Object.values(props.customFieldValues).reduce<CustomFieldValueById>(
        (acc, curr) => {
          return {
            ...acc,
            [curr.fieldObject.id]: {
              value: curr.valueObject.value,
              note: "",
              photos: [],
            },
          };
        },
        {}
      ),
    [props.customFieldValues]
  );

  const searchInputFilterItem = (
    <FilterItem>
      <Input.Search
        placeholder={t("search")}
        onChange={(e) => setSearchPhrase(e.target.value)}
        value={searchPhrase}
      />
    </FilterItem>
  );
  const showNotOkOrWarningFilterItem = (
    <SwitchInput
      label={t("vehicleView.showNotOkOrWarning")}
      isChecked={showOnlyNotOkVehicleFields}
      onToggle={setShowOnlyNotOkVehicleFields}
    />
  );

  return (
    <>
      {!!props.customVehicleFieldsToDisplay.length && (
        <Form layout="horizontal">
          <BackOfficeLayout.Filters>
            {breakpoint.md ? (
              <FiltersRow>
                {searchInputFilterItem}
                {showNotOkOrWarningFilterItem}
              </FiltersRow>
            ) : (
              <>
                <FiltersRow>{showNotOkOrWarningFilterItem}</FiltersRow>
                <FiltersRow>{searchInputFilterItem}</FiltersRow>
              </>
            )}
          </BackOfficeLayout.Filters>
          <Divider style={{ marginTop: 0 }} />
        </Form>
      )}

      <CommonForm<CustomFieldValuesFormState, void>
        form={form}
        onIsSavingChange={setIsSaving}
        saveCallback={async (values) => {
          const customFieldValues = values["customFieldValues"];

          if (customFieldValues) {
            await backOfficeApi.updateVehicleCustomFields(
              props.vehicleId,
              customFieldValues
            );
          }
        }}
        onSuccessfulSave={(
          formValues,
          savedRecord: undefined,
          shouldRedirect: boolean | undefined
        ) => {
          if (shouldRedirect) {
            history.replace(urls.backOffice.vehicles());
          } else {
            props.reloadVehicleFieldValues();
          }

          message.success(t("vehicleSuccessfullySaved"));
        }}
        initialValues={{
          customFieldValues: initialFormValues,
        }}
      >
        <CustomVehicleFieldsFormItems
          displayMode="editingVehicle"
          vehicleId={props.vehicleId}
          customVehicleFieldValues={props.customFieldValues}
          customVehicleFields={props.customVehicleFieldsToDisplay}
          searchPhrase={searchPhrase}
          displayOnlyNotOk={showOnlyNotOkVehicleFields}
        />

        <StickySaveButtons isSaving={isSaving} form={form} />
      </CommonForm>
    </>
  );
}
