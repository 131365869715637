import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ImagesGallery } from "src/components/ImagesGallery";

import { ChoiceResponse } from "../models";
import { FormInputWrapper } from "./Form";

const ResponseValueWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.protocolResponseBorder};
  background-color: ${(props) => props.theme.colors.protocolResponseBackground};
  border-radius: 4px;
  flex: 1;
`;

const CommentHeader = styled.div``;
const Comment = styled.div`
  padding: 17px 17px 0;
`;

const ResponsesBox = styled.div<{ withBorder: boolean }>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.protocolResponseBorder};
  ${(props) => (props.withBorder ? "" : "border: none;")}
  padding: 10px 17px;
  min-height: 44px;
`;

const ImagesWrapper = styled.div`
  padding: 10px;
`;

const Response = styled.div<{ selected: boolean; isMultichoice: boolean }>`
  width: 50%;
  line-height: 23px;
  vertical-align: middle;
  color: ${(props) =>
    props.selected ? props.theme.colors.text.dark : props.theme.colors.gray600};
  font-weight: ${(props) =>
    props.selected && props.isMultichoice ? "bold" : "normal"};
`;

const CommentContent = styled.div`
  margin-bottom: 10px;
`;

export interface ChoiceResponseComponentProps
  extends Pick<
    ChoiceResponse,
    "note" | "photos" | "choices" | "isMultichoice"
  > {}

export function ChoiceResponseComponent(props: ChoiceResponseComponentProps) {
  const { t } = useTranslation("backoffice");
  return (
    <ResponseValueWrapper>
      <FormInputWrapper>
        <ResponsesBox withBorder={!!props.note || props.photos.length > 0}>
          {props.choices.map((choice) => (
            <Response
              key={choice.id}
              selected={choice.wasSelected}
              isMultichoice={props.isMultichoice}
            >
              {choice.label}
            </Response>
          ))}
        </ResponsesBox>
      </FormInputWrapper>
      {props.note && (
        <Comment>
          <CommentHeader>{t("comment")}:</CommentHeader>
          <CommentContent>{props.note}</CommentContent>
        </Comment>
      )}
      {props.photos.length > 0 && (
        <ImagesWrapper>
          <ImagesGallery
            images={props.photos.map((photo) => ({
              src: photo.photo,
              alt: "",
            }))}
            width={120}
          />
        </ImagesWrapper>
      )}
    </ResponseValueWrapper>
  );
}
