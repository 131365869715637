import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { ChoiceResponse, Spinner } from "src/components";
import { useDevToolbar } from "src/devToolbar";
import { Base64, convertBase64ToFile, convertFileToBase64 } from "src/utils";

import { Damage, Responses, Signature, Signatures } from "../models";
import { fromLocalStorage, getLocalStorageSize } from "../utils";
import { emptyResponses } from "./ProtocolFillerContext";

async function resolveToObject<TValue>(
  input: Promise<{ key: string; value: TValue }>[]
): Promise<Record<string, TValue>> {
  const resolvedInput = await Promise.all(input);
  return resolvedInput.reduce<Record<string, TValue>>((acc, curr) => {
    return {
      ...acc,
      [curr.key]: curr.value,
    };
  }, {});
}

export function getLocalStorageKey(item: keyof Responses | "signatures") {
  const LOCAL_STORAGE_PREFIX = "proto_filler__";
  return LOCAL_STORAGE_PREFIX + item;
}

export interface ProtocolFillerLocalStorageContextState {
  responses: Responses;
  signatures: Signatures;
  persistResponses: (responses: Responses) => void;
  persistSignatures: (signatures: Signatures) => void;
  clearStorage: () => void;
}

export const protocolFillerLocalStorageContext =
  createContext<ProtocolFillerLocalStorageContextState>({
    responses: {
      textResponses: {},
      choiceResponses: {},
      numberResponses: {},
      dateResponses: {},
      dateMonthResponses: {},
      photoActionResponses: {},
      damagesResponses: {},
      customFieldVerificationResponses: {},
    },
    signatures: {},
    persistResponses: () => null,
    persistSignatures: () => null,
    clearStorage: () => null,
  });

export function ProtocolFillerLocalStorageProvider(props: {
  children: ReactNode;
}): JSX.Element {
  const [photoActionResponses, setPhotoActionResponses] = useState<
    Responses["photoActionResponses"] | "pending"
  >("pending");
  const [choiceResponses, setChoiceResponses] = useState<
    Responses["choiceResponses"] | "pending"
  >("pending");
  const [damagesResponses, setDamagesResponses] = useState<
    Responses["damagesResponses"] | "pending"
  >("pending");
  const [signatures, setSignatures] = useState<Signatures | "pending">(
    "pending"
  );
  const { setDevToolbarLocalStorageSize, isDevToolbarEnabled } =
    useDevToolbar();

  useEffect(() => {
    parsePhotoActionResponses(
      fromLocalStorage(getLocalStorageKey("photoActionResponses"))
    );
    parseChoiceResponses(
      fromLocalStorage(getLocalStorageKey("choiceResponses"))
    );
    parseDamagesResponses(
      fromLocalStorage(getLocalStorageKey("damagesResponses"))
    );
    parseSignatures(fromLocalStorage(getLocalStorageKey("signatures")));
  }, []);

  const textResponses: Responses["textResponses"] = fromLocalStorage(
    getLocalStorageKey("textResponses")
  );

  const numberResponses: Responses["numberResponses"] = fromLocalStorage(
    getLocalStorageKey("numberResponses")
  );

  const dateResponses: Responses["dateResponses"] = fromLocalStorage(
    getLocalStorageKey("dateResponses")
  );

  const dateMonthResponses: Responses["dateMonthResponses"] = fromLocalStorage(
    getLocalStorageKey("dateMonthResponses")
  );

  const customFieldVerificationResponses: Responses["customFieldVerificationResponses"] =
    fromLocalStorage(getLocalStorageKey("customFieldVerificationResponses"));

  const persistResponses = useCallback(
    async (newResponses: Responses) => {
      // text
      localStorage.setItem(
        getLocalStorageKey("textResponses"),
        JSON.stringify(newResponses.textResponses)
      );

      // number
      localStorage.setItem(
        getLocalStorageKey("numberResponses"),
        JSON.stringify(newResponses.numberResponses)
      );

      // date
      localStorage.setItem(
        getLocalStorageKey("dateResponses"),
        JSON.stringify(newResponses.dateResponses)
      );

      // date-month
      localStorage.setItem(
        getLocalStorageKey("dateMonthResponses"),
        JSON.stringify(newResponses.dateMonthResponses)
      );

      // photoAction

      localStorage.setItem(
        getLocalStorageKey("photoActionResponses"),
        JSON.stringify(newResponses.photoActionResponses)
      );

      // choice

      localStorage.setItem(
        getLocalStorageKey("choiceResponses"),
        JSON.stringify(newResponses.choiceResponses)
      );

      // damages

      localStorage.setItem(
        getLocalStorageKey("damagesResponses"),
        JSON.stringify(newResponses.damagesResponses)
      );

      // customFieldVerification

      localStorage.setItem(
        getLocalStorageKey("customFieldVerificationResponses"),
        JSON.stringify(newResponses.customFieldVerificationResponses)
      );

      if (isDevToolbarEnabled) {
        setDevToolbarLocalStorageSize(getLocalStorageSize());
      }
    },
    [isDevToolbarEnabled, setDevToolbarLocalStorageSize]
  );

  const persistSignatures = useCallback(
    async (newSignatures: Signatures) => {
      const stringifiedSignaturesPhotos = await stringifySignaturesPhotos(
        newSignatures
      );
      localStorage.setItem(
        getLocalStorageKey("signatures"),
        JSON.stringify(stringifiedSignaturesPhotos)
      );

      if (isDevToolbarEnabled) {
        setDevToolbarLocalStorageSize(getLocalStorageSize());
      }
    },
    [isDevToolbarEnabled, setDevToolbarLocalStorageSize]
  );

  const clearStorage = useCallback(() => {
    persistSignatures({});
    persistResponses(emptyResponses);
    setPhotoActionResponses({});
    setChoiceResponses({});
    setDamagesResponses({});
    setSignatures({});
  }, [persistResponses, persistSignatures]);

  return (
    <div>
      {photoActionResponses === "pending" ||
      choiceResponses === "pending" ||
      damagesResponses === "pending" ||
      signatures === "pending" ? (
        <Spinner />
      ) : (
        <protocolFillerLocalStorageContext.Provider
          value={{
            responses: {
              textResponses,
              numberResponses,
              dateResponses,
              dateMonthResponses,
              choiceResponses,
              photoActionResponses,
              damagesResponses,
              customFieldVerificationResponses,
            },
            signatures,
            persistResponses,
            persistSignatures,
            clearStorage,
          }}
        >
          {props.children}
        </protocolFillerLocalStorageContext.Provider>
      )}
    </div>
  );

  async function parsePhotoActionResponses(
    photoActionResponses: Responses["photoActionResponses"]
  ): Promise<void> {
    try {
      setPhotoActionResponses(photoActionResponses);
    } catch (e) {
      setPhotoActionResponses({});
    }
  }

  async function parseChoiceResponses(
    choiceResponses: Record<string, ChoiceResponse>
  ): Promise<void> {
    try {
      setChoiceResponses(choiceResponses);
    } catch (e) {
      setChoiceResponses({});
    }
  }

  async function parseDamagesResponses(
    damagesResponses: Record<string, Damage[]>
  ): Promise<void> {
    try {
      setDamagesResponses(damagesResponses);
    } catch (e) {
      setDamagesResponses({});
    }
  }

  async function parseSignatures(
    signatures: Record<string, Signature<Base64>>
  ): Promise<void> {
    try {
      const signaturesOperations = Object.entries(signatures).map<
        Promise<{ key: string; value: Signature }>
      >(async ([key, signature]) => {
        const signatureAsFile = await convertBase64ToFile(
          signature.signature,
          "image/jpg"
        );
        return {
          key,
          value: {
            ...signature,
            signature: signatureAsFile,
          },
        };
      });
      const parsedSignatures = await resolveToObject(signaturesOperations);
      setSignatures(parsedSignatures);
    } catch (e) {
      setSignatures({});
    }
  }

  async function stringifySignaturesPhotos(
    signatures: Signatures
  ): Promise<Record<string, Signature<Base64>>> {
    const signaturesOperations = Object.entries(signatures).map<
      Promise<{ key: string; value: Signature<Base64> }>
    >(async ([key, signature]) => {
      const signatureSignature = signature.signature;
      if (!signatureSignature) {
        return {
          key,
          value: {
            ...signature,
            signature: signatureSignature,
          },
        };
      }
      const signatureAsBase64 = await convertFileToBase64(signatureSignature);
      return {
        key,
        value: { ...signature, signature: signatureAsBase64 },
      };
    });
    return resolveToObject(signaturesOperations);
  }
}
