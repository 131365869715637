import {
  DamagesQuestion,
  isChoiceResponse,
  Responses,
  responsesKeyByCustomFieldType,
  TemplateQuestion,
  ValidationError,
} from "../models";
import { getChoiceResponseValidationErrors } from "./getChoiceResponseValidationErrors";
import { getChoicesWithPhotosAndNoteResponseValidationErrors } from "./getChoicesWithPhotosAndNoteResponseValidationErrors";
import {
  getResponseValidationErrorsForCustomFieldVerification,
  isDamagesResponseNotEmpty,
  isDateMonthResponseNotEmpty,
  isDateResponseNotEmpty,
  isNumberResponseNotEmpty,
  isTextResponseNotEmpty,
} from "./selectors";

export function getResponseValidationErrors(
  question: TemplateQuestion | DamagesQuestion,
  responses: Responses,
  isRequiredQuestion: boolean
): ValidationError[] {
  let isValid = false;
  switch (question.questionType) {
    case "text":
      isValid =
        !isRequiredQuestion ||
        isTextResponseNotEmpty(question.id, responses.textResponses);
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "number":
      isValid =
        !isRequiredQuestion ||
        isNumberResponseNotEmpty(question.id, responses.numberResponses);
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "date":
      isValid =
        !isRequiredQuestion ||
        isDateResponseNotEmpty(question.id, responses.dateResponses);
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "date-month":
      isValid =
        !isRequiredQuestion ||
        isDateMonthResponseNotEmpty(question.id, responses.dateMonthResponses);
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "photo-action":
      isValid =
        !isRequiredQuestion || !!responses.photoActionResponses[question.id];
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "choice":
      return getChoiceResponseValidationErrors(
        question.choices,
        isRequiredQuestion,
        responses.choiceResponses[question.id]
      );
    case "multi-choice":
      return getChoicesWithPhotosAndNoteResponseValidationErrors(
        question.choices,
        responses.choiceResponses[question.id]
      );
    case "damages":
      isValid =
        !isRequiredQuestion ||
        isDamagesResponseNotEmpty(question.id, responses.damagesResponses);
      return isValid ? [] : [ValidationError.REQUIRED_QUESTION];
    case "custom-field-update":
      if (question.isCustomVehicleFieldVerification) {
        if (question.customVehicleField.type === "damages") {
          return [];
        }
        const fieldVerification =
          responses.customFieldVerificationResponses[question.id];
        const response =
          responses[
            responsesKeyByCustomFieldType[question.customVehicleField.type]
          ][question.id];

        if (
          typeof fieldVerification === "undefined" ||
          fieldVerification == null
        ) {
          return isRequiredQuestion ? [ValidationError.REQUIRED_QUESTION] : [];
        }
        if (fieldVerification === "incorrect") {
          const responseToCompare =
            response && isChoiceResponse(response)
              ? response.selectedChoiceIds.length
                ? response.selectedChoiceIds[0]
                : null
              : response;
          return responseToCompare === question.customVehicleField.value
            ? [ValidationError.NEW_VALUE_MUST_BE_DIFFERENT_THAN_CURRENT]
            : getResponseValidationErrorsForCustomFieldVerification(
                question,
                responses
              );
        }

        return question.customVehicleField.type === "choice"
          ? getChoiceResponseValidationErrors(
              question.customVehicleField.choices,
              question.isRequired,
              responses.choiceResponses[question.id]
            )
          : [];
      } else {
        if (question.customVehicleField.type === "choice") {
          return getResponseValidationErrors(
            {
              ...question,
              choices: question.customVehicleField.choices,
              questionType: question.customVehicleField.type,
            },
            responses,
            question.isRequired
          );
        }
        return getResponseValidationErrors(
          {
            ...question,
            questionType: question.customVehicleField.type,
          },
          responses,
          question.isRequired
        );
      }
  }
}
