import { useCompany } from "@inspecto/common";
import { Col, Form, message, Radio, Row, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { MultiLanguageProvider } from "src/company";
import { CommonForm } from "src/components";
import aImage from "src/static/relations/a.png";
import bImage from "src/static/relations/b.png";
import manyToManyImage from "src/static/relations/many-to-many.png";
import oneToManyImage from "src/static/relations/one-to-many.png";
import oneToOneImage from "src/static/relations/one-to-one.png";
import { urls } from "src/urls";

import { RetrieveVehicleFieldsPreset } from "../../../models";
import { UpdateLinkType } from "../../../models/LinkType";
import { StickySaveButtons } from "../../vehicles/components/StickySaveButtons";
import { LinkField } from "./LinkField";

interface Props {
  initialValue: Partial<UpdateLinkType>;
  onSave: (values: UpdateLinkType) => Promise<{ id: string }>;
  vehicleTypeOptions: { label: string; value: string }[];
}

const RelationImg = styled.img`
  display: block;
  width: 90%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
`;

const RelationImgWrapper = styled.div`
  padding-bottom: 62%;
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.whitePanelBorderColor};
  border-radius: 4px;
  width: 180px;
`;

function RelationTypeRadio(props: {
  value: string;
  label: string;
  image: string;
  offset?: number;
}) {
  return (
    <Col xs={8} offset={props.offset}>
      <Radio value={props.value}>
        <Space direction="vertical">
          {props.label}
          <RelationImgWrapper>
            <RelationImg src={props.image} />
          </RelationImgWrapper>
        </Space>
      </Radio>
    </Col>
  );
}

export function LinkTypeForm(props: Props) {
  const [form] = Form.useForm();
  const { t } = useTranslation("backoffice");
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();
  const company = useCompany();

  return (
    <MultiLanguageProvider initialLanguage={company.language}>
      <CommonForm
        saveCallback={props.onSave}
        initialValues={props.initialValue}
        form={form}
        onIsSavingChange={setIsSaving}
        onSuccessfulSave={(
          formValues,
          savedRecord: RetrieveVehicleFieldsPreset,
          shouldRedirect: boolean | undefined
        ) => {
          if (shouldRedirect) {
            history.replace(urls.backOffice.settings.linkTypes.baseView());
          }
          message.success(t("settings.linkTypes.savedSuccessfully"));
        }}
      >
        <Form.Item hidden name="id" />
        <LinkField
          linkSide="A"
          linkSideImage={aImage}
          linkSideLabelName="leftName"
          linkSideVehicleTypesName="leftVehicleTypes"
          vehicleTypeOptions={props.vehicleTypeOptions}
        />

        <LinkField
          linkSide="B"
          linkSideImage={bImage}
          linkSideLabelName="rightName"
          linkSideVehicleTypesName="rightVehicleTypes"
          vehicleTypeOptions={props.vehicleTypeOptions}
        />

        <Form.Item
          label={t("settings.linkTypes.fields.relationType")}
          name="type"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <div style={{ width: 700 }}>
              <Row>
                <RelationTypeRadio
                  value="ONE_TO_ONE"
                  label={t("settings.linkTypes.types.oneToOne")}
                  image={oneToOneImage}
                />
                <RelationTypeRadio
                  value="ONE_TO_MANY"
                  label={t("settings.linkTypes.types.oneToMany")}
                  image={oneToManyImage}
                />
                <RelationTypeRadio
                  value="MANY_TO_MANY"
                  label={t("settings.linkTypes.types.manyToMany")}
                  image={manyToManyImage}
                />
              </Row>
            </div>
          </Radio.Group>
        </Form.Item>

        <StickySaveButtons isSaving={isSaving} form={form} />
      </CommonForm>
    </MultiLanguageProvider>
  );
}
