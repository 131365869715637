import { useTranslation } from "react-i18next";

import { backOfficeApi } from "../../api";
import { GenericDateVehicleTypeCustomVehicleFieldsReportView } from "./GenericDateVehicleTypeCustomVehicleFieldsReportView";

export function ListOfReportedDeficienciesReportView() {
  const { t } = useTranslation("backoffice");

  return (
    <GenericDateVehicleTypeCustomVehicleFieldsReportView
      reportTitle={t("reportsView.reports.listOfReportedDeficiencies")}
      startDownloading={async (data) =>
        backOfficeApi.reports.startDownloadListOfReportedDeficiencies(data)
      }
    />
  );
}
