import { useCallback, useState } from "react";

import { PAGE_SIZE } from "./config";
import { PaginationResults } from "./types";

interface Props<TParams, TResultItem> {
  dataGetter: (
    params: TParams
  ) => Promise<PaginationResults<TResultItem[]> | null>;
  onSuccessfulFetch?: (results: TResultItem[]) => void;
}

interface Result<TResultItem> {
  totalPages: number;
  totalItems: number;
  currentPage: TResultItem[];
  isLoading: boolean;
}

export function usePaginatedResults<TParams, TResultItem>(
  props: Props<TParams, TResultItem>
) {
  const initialResultState: Result<TResultItem> = {
    totalPages: 1,
    totalItems: 0,
    isLoading: true,
    currentPage: [],
  };
  const [result, setResult] = useState<Result<TResultItem>>(initialResultState);
  const { dataGetter } = props;

  const getData = useCallback(async (params: TParams): Promise<void> => {
    setResult((result) => ({
      ...result,
      isLoading: true,
    }));

    try {
      const response = await dataGetter(params);
      if (response === null) {
        setResult((result) => ({
          ...result,
          isLoading: false,
        }));
        return;
      }

      setResult({
        totalItems: response.count,
        totalPages: Math.ceil(response.count / PAGE_SIZE),
        isLoading: false,
        currentPage: response.results,
      });
      props.onSuccessfulFetch?.(response.results);
    } catch (e) {
      setResult({
        ...initialResultState,
        isLoading: false,
      });
      throw e;
    }
  }, []);

  return {
    ...result,
    getData,
  };
}
