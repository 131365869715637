import { Grid, Result, Space } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";

import { useAuthentication } from "src/authentication";
import { BorderedCard } from "src/backOffice/components";
import { LandingPageTopBarLayout } from "src/components";
import landingCheckboxList from "src/static/landing-checkbox-list.svg";
import ladingLayout from "src/static/landing-layout.svg";
import { urls } from "src/urls";

const CoverWrapper = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding-top: 20px;
`;

const MiniCoverWrapper = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileWrapper = styled.div`
  width: 100%;
  max-width: 370px;
  padding: 0 24px;
  margin: auto;
`;

const DesktopWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ApplicationModule {
  url: string;
  image: string;
  title: string;
  description: string;
}

export function LandingPage() {
  const { user, logoutAndRedirectToLandingPage } = useAuthentication();
  const breakpoint = Grid.useBreakpoint();
  const { t } = useTranslation();
  const history = useHistory();

  const applicationModules: ApplicationModule[] = useMemo(() => {
    if (!user) {
      return [];
    }
    const protocolFillerModule = {
      url: urls.protocolFiller.main(),
      image: landingCheckboxList,
      title: t("protocol"),
      description: t("fillProtocolDescription"),
    };
    const backofficeModule = {
      url: urls.backOffice.main(),
      image: ladingLayout,
      title: t("officeLoginTitle"),
      description: t("officeLoginDescription"),
    };
    const availableModules = [];
    if (user.canViewProtocolFiller) {
      availableModules.push(protocolFillerModule);
    }
    if (user.canViewBackoffice) {
      availableModules.push(backofficeModule);
    }

    return availableModules;
  }, [t, user]);

  if (!user) {
    return null;
  }

  if (applicationModules.length === 1) {
    return <Redirect to={applicationModules[0]!.url} />;
  }

  return (
    <LandingPageTopBarLayout
      user={user}
      onLogoutClick={logoutAndRedirectToLandingPage}
    >
      <ContentWrapper>
        {!applicationModules.length ? (
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you don't have access to any module"
          />
        ) : typeof breakpoint.xs === "undefined" ? null : breakpoint.sm ? (
          <DesktopWrapper>
            <Space size="middle">
              {applicationModules.map((cardData) => (
                <BorderedCard
                  bordered
                  key={cardData.url}
                  onClick={() => history.push(cardData.url)}
                  hoverable
                  style={{ width: 210 }}
                  cover={
                    <CoverWrapper>
                      <img
                        src={cardData.image}
                        alt={cardData.description}
                        style={{ width: 112 }}
                      />
                    </CoverWrapper>
                  }
                >
                  <BorderedCard.Meta
                    title={cardData.title}
                    description={cardData.description}
                  />
                </BorderedCard>
              ))}
            </Space>
          </DesktopWrapper>
        ) : (
          <MobileWrapper>
            {applicationModules.map((cardData) => (
              <BorderedCard
                bordered
                key={cardData.url}
                style={{ width: "100%", marginBottom: 12 }}
                hoverable
                onClick={() => history.push(cardData.url)}
              >
                <BorderedCard.Meta
                  avatar={
                    <MiniCoverWrapper>
                      <img
                        src={cardData.image}
                        alt={cardData.description}
                        style={{ width: 50 }}
                      />
                    </MiniCoverWrapper>
                  }
                  title={cardData.title}
                  description={cardData.description}
                />
              </BorderedCard>
            ))}
          </MobileWrapper>
        )}
      </ContentWrapper>
    </LandingPageTopBarLayout>
  );
}
