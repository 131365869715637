import { Checkbox } from "antd";
import { PropsWithChildren } from "react";

import { DropdownMenuDivider } from "src/components";

import { FilterItem } from "./ListView";

interface Props {
  filterItemName: string;
  checkboxLabel: string;
}

export function DividerCheckboxFilterItem(props: PropsWithChildren<Props>) {
  return (
    <DropdownMenuDivider
      topContent={
        <FilterItem valuePropName="checked" name={props.filterItemName} noStyle>
          <Checkbox>{props.checkboxLabel}</Checkbox>
        </FilterItem>
      }
    >
      {props.children}
    </DropdownMenuDivider>
  );
}
