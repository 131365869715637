import { Avatar, AvatarProps, Typography } from "antd";

const colorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

interface Props extends AvatarProps {
  user: {
    firstName: string;
    lastName: string;
  };
}

export function UserAvatar({ user, ...avatarProps }: Props): JSX.Element {
  const backgroundColor =
    colorList[(user.firstName + user.lastName).length % colorList.length];
  return (
    <Avatar
      {...avatarProps}
      style={{
        ...avatarProps.style,
        backgroundColor,
      }}
    >
      <Typography.Text strong style={{ color: "inherit" }}>
        {`${user.firstName.length && user.firstName[0]}${
          user.lastName.length && user.lastName[0]
        }`}
      </Typography.Text>
    </Avatar>
  );
}
