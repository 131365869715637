import styled from "styled-components";

export const QuestionLevelElement = styled.div`
  border: 1px solid ${(props) => props.theme.colors.outline};
  padding: 5px 10px;
  background: ${(props) => props.theme.colors.background};
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
`;
