import { Button, Result, Space } from "antd";
import { useState } from "react";
import styled from "styled-components";

import { useAuthentication } from "src/authentication";

import { commonResultProps } from "./common";

const StyledCenter = styled.div`
  margin: auto;
`;

interface Props {
  status: "success" | "error";
  title: string;
  subTitle: string | JSX.Element;
  logoutButtonText: string;
  extraContent: JSX.Element;
}

export function ProtocolResolutionComponent(props: Props) {
  const { user, logoutAndRedirectToLandingPage } = useAuthentication();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  return (
    <Result
      {...commonResultProps}
      status={props.status}
      title={props.title}
      subTitle={<StyledCenter>{props.subTitle}</StyledCenter>}
      extra={
        <>
          <Space
            direction="vertical"
            style={{
              marginRight: 0, // Because every .ant-result-extra > * gets margin-right: 8px
              width: "100%",
            }}
          >
            {props.extraContent}
          </Space>

          {!!user && (
            <StyledCenter>
              <Button
                type="primary"
                style={{ marginTop: 32 }}
                key="logout"
                loading={isLoggingOut}
                onClick={async () => {
                  setIsLoggingOut(true);
                  await logoutAndRedirectToLandingPage();
                  setIsLoggingOut(false);
                }}
              >
                {props.logoutButtonText}
              </Button>
            </StyledCenter>
          )}
        </>
      }
    />
  );
}
