import { HistoryOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Collapse,
  Divider,
  Empty,
  Form,
  Input,
  Space,
  Tooltip,
} from "antd";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { StandardCustomVehicleFieldType } from "src/company";
import {
  ChoiceFieldComponent,
  ChoiceFieldComponentProps,
  DateFieldComponent,
  DateMonthFieldComponent,
  FieldValue,
  Icon,
  MultipleImagesImmediateUploader,
  NumberFieldComponent,
  SingleImageImmediateUploader,
} from "src/components";
import { StorageUploadedImage } from "src/storage";
import { urls } from "src/urls";
import { useToggleArrayById } from "src/utils";

import {
  ListCustomVehicleField,
  RetrieveVehicleCustomVehicleField,
} from "../models";
import { filterCustomVehicleFields } from "../utils";
import { CommonFieldProtocolResponse } from "./CommonFieldProtocolResponse";
import { ProtocolResponseLabelWithStatus } from "./ProtocolResponseLabelWithStatus";
import { ProtocolResponseRow } from "./ProtocolResponseRow";

export interface CustomFieldValuesFormState {
  customFieldValues: CustomFieldValues;
}

export type CustomFieldValues = Record<
  string,
  | { value: FieldValue }
  | { value: FieldValue; note: string; photos: StorageUploadedImage[] }
>;

interface Props {
  vehicleId?: string;
  customVehicleFieldValues: Record<string, RetrieveVehicleCustomVehicleField>;
  displayMode: "creatingVehicle" | "editingVehicle";
  customVehicleFields: ListCustomVehicleField[];
  displayOnlyNotOk: boolean;
  searchPhrase?: string;
}

const fieldTypesConfig: Record<
  StandardCustomVehicleFieldType,
  {
    component: ((props: any) => JSX.Element) | FunctionComponent;
    getComponentProps: (field: ListCustomVehicleField) => any;
  }
> = {
  text: {
    component: Input,
    getComponentProps: () => ({}),
  },
  number: {
    component: NumberFieldComponent,
    getComponentProps: () => ({}),
  },
  date: {
    component: DateFieldComponent,
    getComponentProps: () => ({}),
  },
  "date-month": {
    component: DateMonthFieldComponent,
    getComponentProps: () => ({}),
  },
  choice: {
    component: ChoiceFieldComponent,
    getComponentProps: (
      field
    ): Omit<ChoiceFieldComponentProps, "value" | "onChange"> => ({
      choices: field.choices.map((choice) => ({ ...choice, value: choice.id })),
      verticalListWidth: 240,
    }),
  },
  "photo-action": {
    component: SingleImageImmediateUploader,
    getComponentProps: (field) => ({
      uploaderId: field.id,
    }),
  },
};

export function CustomVehicleFieldsFormItems({
  searchPhrase = "",
  ...props
}: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { t: tBackoffice } = useTranslation("backoffice");
  const [
    editedVehicleFields,
    toggleEditedVehicleField,
    clearAllEditedVehicleFields,
  ] = useToggleArrayById<{ id: string }>();
  const editedVehicleFieldIds = editedVehicleFields.map((row) => row.id);
  const { customVehicleFieldValues, customVehicleFields, displayOnlyNotOk } =
    props;

  useEffect(() => {
    clearAllEditedVehicleFields();
  }, [clearAllEditedVehicleFields, customVehicleFieldValues]);

  const filteredCustomVehicleFields = useMemo(() => {
    return filterCustomVehicleFields(
      customVehicleFields,
      customVehicleFieldValues,
      {
        searchPhrase,
        displayOnlyNotOk,
      }
    );
  }, [
    customVehicleFields,
    customVehicleFieldValues,
    displayOnlyNotOk,
    searchPhrase,
  ]);

  return (
    <>
      {filteredCustomVehicleFields.length ? (
        filteredCustomVehicleFields.map((customVehicleField) => {
          if (customVehicleField.type === "damages") {
            return null;
          }
          const isFieldEdited =
            editedVehicleFieldIds.includes(customVehicleField.id) ||
            props.displayMode === "creatingVehicle";
          const fieldConfig = fieldTypesConfig[customVehicleField.type];
          const FieldComponent = fieldConfig.component;
          const additionalProps =
            fieldConfig.getComponentProps(customVehicleField);
          const customVehicleFieldValue =
            props.customVehicleFieldValues[customVehicleField.id];

          return (
            <ProtocolResponseRow
              key={customVehicleField.id}
              leftContent={
                <ProtocolResponseLabelWithStatus
                  label={customVehicleField.label}
                  status={customVehicleFieldValue?.valueObject.status}
                >
                  {isFieldEdited ? (
                    <>
                      <Form.Item
                        name={[
                          "customFieldValues",
                          customVehicleField.id,
                          "value",
                        ]}
                        noStyle
                      >
                        <FieldComponent {...additionalProps} />
                      </Form.Item>
                      {customVehicleField.type === "choice" && (
                        <Collapse
                          defaultActiveKey={
                            props.displayMode === "editingVehicle"
                              ? "photos_note"
                              : undefined
                          }
                          style={{ marginTop: 10 }}
                        >
                          <Collapse.Panel
                            header={`${t("addAComment")} / ${t("addPhotos")}`}
                            key="photos_note"
                          >
                            <Form.Item
                              name={[
                                "customFieldValues",
                                customVehicleField.id,
                                "note",
                              ]}
                              label={t("addAComment")}
                            >
                              <Input.TextArea />
                            </Form.Item>
                            <Form.Item
                              name={[
                                "customFieldValues",
                                customVehicleField.id,
                                "photos",
                              ]}
                              label={t("addPhotos")}
                              style={{ marginBottom: 0 }}
                            >
                              <MultipleImagesImmediateUploader
                                uploaderId={customVehicleField.id}
                              />
                            </Form.Item>
                          </Collapse.Panel>
                        </Collapse>
                      )}
                    </>
                  ) : (
                    <CommonFieldProtocolResponse
                      {...{
                        fieldLabel: customVehicleField.label,
                        type: customVehicleField.type,
                        value: "",
                        note: "",
                        photos: [],
                        choices: [],
                        isMultichoice: false,
                        ...(customVehicleFieldValue
                          ? {
                              value:
                                customVehicleFieldValue.valueObject
                                  .valueFormatted,
                              note: customVehicleFieldValue.valueObject.note,
                              photos:
                                customVehicleFieldValue.valueObject.photos,
                              choices: [
                                {
                                  label:
                                    customVehicleFieldValue.valueObject
                                      .valueFormatted,
                                  id: "",
                                  wasSelected: true,
                                },
                              ],
                            }
                          : {}),
                      }}
                    />
                  )}
                </ProtocolResponseLabelWithStatus>
              }
              rightContent={
                isFieldEdited ? (
                  props.displayMode === "editingVehicle" ? (
                    <Button
                      danger
                      shape="round"
                      size="small"
                      onClick={() =>
                        toggleEditedVehicleField({ id: customVehicleField.id })
                      }
                      title={`${tBackoffice(
                        "vehicleView.customFields.cancel"
                      )} - ${customVehicleField.label}`}
                    >
                      <Space>
                        <Icon icon="times" />
                        {tBackoffice("vehicleView.customFields.cancel")}
                      </Space>
                    </Button>
                  ) : null
                ) : (
                  <Space size="small" align="center">
                    <Button
                      shape="round"
                      size="small"
                      onClick={() =>
                        toggleEditedVehicleField({ id: customVehicleField.id })
                      }
                      title={`${tBackoffice(
                        "vehicleView.customFields.edit"
                      )} - ${customVehicleField.label}`}
                    >
                      <Space>
                        <Icon icon="edit" />
                        {tBackoffice("vehicleView.customFields.edit")}
                      </Space>
                    </Button>
                    {!!props.vehicleId ? (
                      <>
                        <Divider type="vertical" style={{ height: 30 }} />
                        <Tooltip
                          title={tBackoffice(
                            "vehicleView.customFields.showHistoryOfChanges"
                          )}
                        >
                          <Badge
                            count={
                              customVehicleFieldValue &&
                              customVehicleFieldValue.valueObject.sourceResponse
                                ? "P"
                                : undefined
                            }
                            data-testid={`source-response-for-${customVehicleField.id}`}
                            color="blue"
                            style={{ top: 4, right: 4 }}
                          >
                            <Button
                              icon={<HistoryOutlined />}
                              shape="round"
                              size="small"
                              onClick={() =>
                                history.push(
                                  customVehicleField.customVehicleFieldsGroupObject
                                    ? urls.backOffice.vehicleFieldWithGroupValueHistory(
                                        props.vehicleId,
                                        customVehicleField.id,
                                        customVehicleField
                                          .customVehicleFieldsGroupObject.id
                                      )
                                    : urls.backOffice.vehicleFieldWithoutGroupValueHistory(
                                        props.vehicleId,
                                        customVehicleField.id
                                      )
                                )
                              }
                              title={`${tBackoffice(
                                "vehicleView.customFields.showHistoryOfChanges"
                              )} - ${customVehicleField.label}`}
                            >
                              {tBackoffice(
                                "vehicleView.customFields.historyAndCharges"
                              )}
                            </Button>
                          </Badge>
                        </Tooltip>
                      </>
                    ) : null}
                  </Space>
                )
              }
            />
          );
        })
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            props.customVehicleFields.length === 0 ? (
              <div>
                <>
                  {tBackoffice("vehicleView.createCustomVehicleFieldsIn")}{" "}
                  <Link
                    to={urls.backOffice.settings.customVehicleFields()}
                    data-testid="create-custom-vehicle-fields-in-page"
                  >
                    {tBackoffice("settings.label")}
                  </Link>
                </>
              </div>
            ) : undefined
          }
        />
      )}
    </>
  );
}
