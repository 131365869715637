import { useCompany } from "@inspecto/common";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { MultiLanguageProvider, MultiLanguageTextInput } from "src/company";
import { MaxWidth700WithSaveButtonForm } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { DefaultLanguageRequiredFormItem } from "../../../components";
import {
  CreateUpdateTemplateTag,
  ListRetrieveTemplateTag,
} from "../../../models";

interface Props {
  existingTemplateTag: ListRetrieveTemplateTag | null;
  onSuccessfulSave?: () => void;
}

export function TemplateTagForm(props: Props) {
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const company = useCompany();

  return (
    <MaxWidth700WithSaveButtonForm<CreateUpdateTemplateTag, void>
      saveCallback={async (values) => {
        if (props.existingTemplateTag) {
          await backOfficeApi.updateTemplateTag(
            props.existingTemplateTag.id,
            values
          );
          props.onSuccessfulSave?.();
          message.success(t("settings.genericTags.savedSuccessfully"));
        } else {
          const newTemplateTag = await backOfficeApi.createTemplateTag(values);

          history.replace(
            urls.backOffice.settings.editTemplateTag(newTemplateTag.id)
          );
          message.success(t("settings.genericTags.savedSuccessfully"));
        }
      }}
      initialValues={props.existingTemplateTag || undefined}
    >
      <MultiLanguageProvider initialLanguage={company.language}>
        <DefaultLanguageRequiredFormItem
          requiredLanguage="company"
          maxLength={64}
          name="labelTranslations"
          label={t("settings.genericTags.fields.label")}
        >
          <MultiLanguageTextInput />
        </DefaultLanguageRequiredFormItem>
      </MultiLanguageProvider>
    </MaxWidth700WithSaveButtonForm>
  );
}
