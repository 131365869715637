import { Form } from "antd";
import { useTranslation } from "react-i18next";

import { ModalForm } from "src/components/Form/ModalForm";

import { backOfficeApi } from "../api";
import { ChargedEmployee } from "../models";
import { ChargedEmployeeStatusSelect } from "./ChargedEmployeeStatusSelect";

interface InitialValues {
  status: ChargedEmployee["status"] | "mixed";
}

export interface ChargedEmployeeStatusChangeModalProps {
  closeModal: () => void;
  onSuccessfulSave: () => void;
  initialValues: InitialValues | undefined;
  chargedEmployeeIds: string[];
}

export function ChargedEmployeeStatusChangeModal(
  props: ChargedEmployeeStatusChangeModalProps
): JSX.Element {
  const { t } = useTranslation("backoffice");
  const [form] = Form.useForm();

  return (
    <ModalForm<InitialValues>
      open
      initialValues={props.initialValues}
      saveCallback={async ({ status }) => {
        if (status === "mixed") {
          return;
        }

        await backOfficeApi.changeChargedEmployeeStatuses(
          props.chargedEmployeeIds.map((id) => ({
            status: status,
            id: id,
          }))
        );
        props.onSuccessfulSave();
      }}
      form={form}
      closeModal={props.closeModal}
      cancelButtonText={t("employeeCharges.cancelChargingEmployee")}
      confirmButtonText={t("employeeCharges.saveChargedEmployee")}
      formTitle={t("employeeCharges.editSelectedButtonText")}
    >
      <Form.Item
        name="status"
        label={t("employeeCharges.chargeEmployeeStatusLabel")}
        rules={[
          {
            required: true,
            message: t("employeeCharges.noStatusSelectedErrorMessage"),
            validator: async (rule, value) => {
              if (value === "mixed") {
                return Promise.reject();
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <ChargedEmployeeStatusSelect
          extraOptions={[
            {
              value: "mixed",
              label: "-",
            },
          ]}
        />
      </Form.Item>
    </ModalForm>
  );
}
