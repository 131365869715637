import { Space } from "antd";

import { FormErrorsAlert } from "./FormErrorsAlert";

export function GlobalFormErrors(props: { errors: string[] }) {
  return (
    <FormErrorsAlert visible={props.errors.length > 0}>
      <Space direction="vertical">
        {props.errors.length > 0 && <>{props.errors.map((error) => error)}</>}
      </Space>
    </FormErrorsAlert>
  );
}
