import { PaginationResults } from "@inspecto/common";

import { api, Api } from "src/utils";

import {
  LinkTypeList,
  CreateLinkType,
  GetLinkType,
  UpdateLinkType,
} from "../models/LinkType";

export class LinkTypesApi {
  constructor(private api: Api) {}

  async getList(
    pageNumber: number
  ): Promise<PaginationResults<LinkTypeList[]>> {
    return await this.api.getPaginatedList("/backoffice/settings/link-types/", {
      pageNumber,
    });
  }

  async getElement(id: string): Promise<GetLinkType> {
    return await this.api.get(`/backoffice/settings/link-types/${id}/`);
  }

  async create(data: CreateLinkType): Promise<GetLinkType> {
    return await this.api.post("/backoffice/settings/link-types/", data);
  }

  async update(
    id: UpdateLinkType["id"],
    el: UpdateLinkType
  ): Promise<GetLinkType> {
    return await this.api.put(`/backoffice/settings/link-types/${id}/`, el);
  }
}

export const linkTypesApi = new LinkTypesApi(api);
