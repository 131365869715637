import { Form, message, Select, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MaxWidth700WithSaveButtonForm } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { useEmployeesWithEmailOptions } from "../../../hooks/useEmployeesWithEmailOptions";
import { BackOfficeLayout } from "../../BackOfficeLayout";

interface NotificationSettingsFormState {
  usersToBeNotifiedAboutNewDamages: string[];
}

export function NewDamagesNotificationsSettings() {
  const { t } = useTranslation("backoffice");

  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettingsFormState>({
      usersToBeNotifiedAboutNewDamages: [],
    });
  const [isLoadingNotificationSettings, setIsLoadingNotificationSettings] =
    useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    backOfficeApi.notifications
      .getNotificationSettings()
      .then((notificationSettings) => {
        setNotificationSettings({
          ...notificationSettings,
        });
        setIsLoadingNotificationSettings(false);
      });
  }, []);

  const { employeeManagerOptions, isLoadingEmployees } =
    useEmployeesWithEmailOptions();

  return (
    <BackOfficeLayout
      breadcrumbs={[
        {
          label: t("settings.label"),
        },
        {
          label: t("settings.notifications.label"),
          url: urls.backOffice.settings.notifications.baseView(),
        },
      ]}
      pageTitle={t("settings.notifications.notificationTypes.newDamages")}
    >
      <BackOfficeLayout.Content>
        {isLoadingNotificationSettings ? (
          <Skeleton />
        ) : (
          <MaxWidth700WithSaveButtonForm<NotificationSettingsFormState>
            initialValues={notificationSettings}
            saveCallback={async (data) => {
              await backOfficeApi.notifications.saveNotificationSettings(data);

              message.success(t("settings.notifications.savedSuccessfully"));
            }}
            form={form}
          >
            <Form.Item
              label={t(
                "settings.notifications.usersToBeNotifiedAboutNewDamages"
              )}
              name="usersToBeNotifiedAboutNewDamages"
            >
              <Select
                mode="multiple"
                options={employeeManagerOptions}
                loading={isLoadingEmployees}
                showArrow
                optionFilterProp="label"
              />
            </Form.Item>
          </MaxWidth700WithSaveButtonForm>
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
