import { Select, Tag, TagProps } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Damage } from "../models";

export const damagesStatusSelectWidth = 150;

function getDamageStatusColor(
  damageStatus: Damage["status"]
): TagProps["color"] {
  switch (damageStatus) {
    case "new":
      return "red";
    case "ignored":
      return "blue";
    case "for_repair":
      return "orange";
    case "invalid":
      return "default";
    case "resolved":
      return "green";
  }
}

export function DamageStatusSelect(props: {
  status?: Damage["status"];
  onStatusChange?: (newStatus: Damage["status"]) => Promise<void>;
}) {
  const { t } = useTranslation("backoffice");

  const statusOptions = useMemo<
    { label: string; value: Damage["status"] }[]
  >(() => {
    return [
      { label: t("damages.statuses.new"), value: "new" },
      { label: t("damages.statuses.ignored"), value: "ignored" },
      { label: t("damages.statuses.forRepair"), value: "for_repair" },
      { label: t("damages.statuses.invalid"), value: "invalid" },
      { label: t("damages.statuses.resolved"), value: "resolved" },
    ];
  }, [t]);

  const [isSaving, setIsSaving] = useState(false);

  return (
    <Select<Damage["status"]>
      style={{ width: damagesStatusSelectWidth }}
      size="small"
      loading={isSaving}
      onClick={(e) => e.stopPropagation()}
      defaultValue="new"
      value={props.status}
      onChange={async (newStatus) => {
        setIsSaving(true);
        if (props.onStatusChange) {
          await props.onStatusChange(newStatus);
        }
        setIsSaving(false);
      }}
    >
      {statusOptions.map((option) => (
        <Select.Option value={option.value} key={option.value}>
          <Tag color={getDamageStatusColor(option.value)}>{option.label}</Tag>
        </Select.Option>
      ))}
    </Select>
  );
}
