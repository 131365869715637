import { Form, Input, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CommonForm } from "src/components";
import { Button } from "src/components/Button";
import { FullScreenCardLogoPage } from "src/components/FullScreenCardLogoPage";

import { authenticationApi } from "./api";

export function ForgotPassword() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  return (
    <FullScreenCardLogoPage headerText={t("resettingPassword")}>
      <CommonForm<{ email: string }>
        form={form}
        onIsSavingChange={setIsResettingPassword}
        onSuccessfulSave={() => {
          message.success(t("sentResetEmail"));
          form.resetFields();
        }}
        saveCallback={async (formValues) => {
          await authenticationApi.forgotPassword(formValues.email);
        }}
      >
        <Form.Item label={t("email")} name="email">
          <Input />
        </Form.Item>
        <Button type="submit" isLoading={isResettingPassword}>
          {t("resetPassword")}
        </Button>
      </CommonForm>
    </FullScreenCardLogoPage>
  );
}
