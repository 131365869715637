import { LineOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Typography,
} from "antd";
import produce from "immer";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DamagesRequiredFields } from "src/company";
import {
  SingleImageImmediateUploader,
  useImmediateImageUploadersContext,
} from "src/components";
import { ModalForm } from "src/components/Form/ModalForm";
import { StorageUploadedImage } from "src/storage";
import { createGlobalErrorsError } from "src/utils";

import { useProtocolFillerContext } from "../contexts";
import { Damage, isDamagesResponsesDamages } from "../models";
import { ProtocolDamagesResponse } from "./ProtocolDamagesResponse";

const MarginBottomWrapper = styled.div`
  margin-bottom: 12px;
`;

const MarginBottom6Wrapper = styled.div`
  margin-bottom: 6px;
`;

function SmallPaddingCard(props: PropsWithChildren<{}>) {
  return (
    <Card bodyStyle={{ padding: 16, backgroundColor: "#fdfdfd" }}>
      {props.children}
    </Card>
  );
}

function DamagesSection(
  props: PropsWithChildren<{ title: string; testId: string }>
) {
  return (
    <>
      <MarginBottom6Wrapper>
        <Typography.Text strong>{`${props.title}:`}</Typography.Text>
      </MarginBottom6Wrapper>
      <MarginBottomWrapper>
        <SmallPaddingCard>
          {props.children ? (
            props.children
          ) : (
            <LineOutlined
              data-testid={`empty-damages-section-icon-${props.testId}`}
            />
          )}
        </SmallPaddingCard>
      </MarginBottomWrapper>
    </>
  );
}

interface Props {
  isRequired: boolean;
  questionId: string;
  customVehicleFieldId: string;
  requiredFields: DamagesRequiredFields;
  damages: Damage[];
}

export function DamagesQuestionComponent(props: Props) {
  const { t } = useTranslation("protocolFiller");
  const { message: imageUploadersMessage } =
    useImmediateImageUploadersContext();

  const { responses, setDamagesResponse } = useProtocolFillerContext();
  useProtocolFillerContext();

  const response = responses.damagesResponses[props.questionId];

  const [isCreating, setIsCreating] = useState(false);

  return (
    <>
      <DamagesSection
        testId="old-damages"
        title={t("damages.oldDamages")}
        children={
          props.damages && props.damages.length ? (
            <div>
              <ProtocolDamagesResponse
                damages={props.damages.map((damage, i) => ({
                  label: damage.label,
                  photo: damage.photo,
                  isNewAddition: false,
                  hasBeenCharged: false,
                  hasBeenPaidInFull: false,
                  key: String(i),
                }))}
              />
            </div>
          ) : undefined
        }
      />
      {props.isRequired ? (
        <MarginBottomWrapper>
          <MarginBottom6Wrapper>
            <Typography.Text strong>
              {t("damages.areThereNewDamages")}
            </Typography.Text>
          </MarginBottom6Wrapper>
          <Radio.Group
            defaultValue={
              isDamagesResponsesDamages(response)
                ? true
                : response === "NO_NEW_DAMAGES_CONFIRMED"
                ? false
                : undefined
            }
            optionType="button"
            buttonStyle="solid"
            onChange={(event: RadioChangeEvent) => {
              if (event.target.value) {
                confirmPresenceOfNewDamages();
              } else {
                confirmLackOfNewDamages();
              }
            }}
          >
            <Radio.Button value={true}>{t("yes")}</Radio.Button>
            <Radio.Button value={false}>{t("no")}</Radio.Button>
          </Radio.Group>
        </MarginBottomWrapper>
      ) : null}
      {(isDamagesResponsesDamages(response) || !props.isRequired) && (
        <>
          <DamagesSection
            testId="new-damages"
            title={t("damages.newDamages")}
            children={
              isDamagesResponsesDamages(response) && response.length ? (
                <div>
                  <ProtocolDamagesResponse
                    damages={response.map((damage, i) => ({
                      label: damage.label,
                      photo: damage.photo,
                      isNewAddition: true,
                      hasBeenCharged: false,
                      hasBeenPaidInFull: false,
                      key: String(i),
                    }))}
                    onDeleteClick={(key) => {
                      removeDamage(parseInt(key));
                    }}
                  />
                </div>
              ) : undefined
            }
          />
          <Button onClick={() => setIsCreating(true)} icon={<PlusOutlined />}>
            {t("damages.createDamage")}
          </Button>
        </>
      )}

      {isCreating && (
        <ModalForm<{ label?: string; photo?: StorageUploadedImage }>
          open={true}
          formTitle={t("damages.createDamage")}
          closeModal={() => setIsCreating(false)}
          confirmButtonText={t("damages.save")}
          cancelButtonText={t("damages.cancel")}
          saveCallback={async (newDamage) => {
            if (imageUploadersMessage) {
              throw createGlobalErrorsError([imageUploadersMessage]);
            }

            addDamage({
              label: newDamage.label || "",
              photo: newDamage.photo || null,
            });
          }}
        >
          <Form.Item
            name="label"
            label={t("damages.fieldLabels.label")}
            rules={[
              {
                max: 1024,
                required: [
                  DamagesRequiredFields.LABEL,
                  DamagesRequiredFields.PHOTO_AND_LABEL,
                ].includes(props.requiredFields),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="photo"
            label={t("damages.fieldLabels.photo")}
            rules={[
              {
                required: [
                  DamagesRequiredFields.PHOTO,
                  DamagesRequiredFields.PHOTO_AND_LABEL,
                ].includes(props.requiredFields),
              },
            ]}
          >
            <SingleImageImmediateUploader uploaderId={props.questionId} />
          </Form.Item>
        </ModalForm>
      )}
    </>
  );

  function removeDamage(removedDamageIndex: number) {
    if (response === "NO_NEW_DAMAGES_CONFIRMED") {
      return;
    }
    setDamagesResponse(
      props.questionId,
      (response || []).filter((damage, index) => index !== removedDamageIndex)
    );
  }

  function addDamage(newDamage: Damage) {
    if (response === "NO_NEW_DAMAGES_CONFIRMED") {
      return;
    }

    setDamagesResponse(
      props.questionId,
      produce(response || [], (draft) => {
        draft.push(newDamage);
      })
    );
  }

  function confirmPresenceOfNewDamages() {
    setDamagesResponse(props.questionId, []);
  }

  function confirmLackOfNewDamages() {
    setDamagesResponse(props.questionId, "NO_NEW_DAMAGES_CONFIRMED");
  }
}
