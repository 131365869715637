import { ApiError } from "@inspecto/common";
import { message } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Spinner } from "src/components";
import { useQueryParams } from "src/hooks";
import { urls } from "src/urls";

import { protocolFillerApi } from "../api";

export function LoginViaQRCode() {
  const { t } = useTranslation("protocolFiller");
  const queryParams = useQueryParams();
  const history = useHistory();

  useEffect(() => {
    const vehiclePlateNumber: string | null =
      queryParams.get("vehiclePlateNumber");

    if (!vehiclePlateNumber) {
      history.replace(urls.protocolFiller.main());
      return;
    }

    protocolFillerApi
      .getVehicleByPlateNumber(vehiclePlateNumber)
      .then((vehicle) => {
        history.replace(
          urls.protocolFiller.vehicleTypeAndVehicle(
            vehicle.vehicleType,
            vehicle.id
          )
        );
      })
      .catch((err) => {
        let errorMessage = t("qrCodes.somethingWentWrong");
        if (err instanceof ApiError) {
          if (err.statusCode === 404) {
            errorMessage = t("qrCodes.vehicleNotFound");
          }
        }

        message.error(errorMessage);
        history.replace(urls.protocolFiller.main());
      });
  }, [queryParams, t, history]);

  return <Spinner text={t("loading")} />;
}
