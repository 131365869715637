import { KeyboardEvent } from "react";

export function allowOnlyIntegersKeyboardEventHandler(
  event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
) {
  const key = event.key;

  // Allow only digits, Backspace, Delete, Arrow keys, and Enter
  if (
    !/[0-9]/.test(key) &&
    key !== "Backspace" &&
    key !== "Delete" &&
    key !== "ArrowLeft" &&
    key !== "ArrowRight" &&
    key !== "Enter"
  ) {
    event.preventDefault();
  }
}
