import { Form, message, Select } from "antd";
import { useTranslation } from "react-i18next";

import { ModalForm } from "src/components";

import { backOfficeApi } from "../../../api";
import {
  CreateUpdateVehicle,
  RetrieveVehicle,
  VehicleType,
} from "../../../models";
import {
  StandardVehicleFormItems,
  StandardVehicleFormItemsProps,
} from "./StandardVehicleFormItems";

interface Props {
  currentVehicleValue: RetrieveVehicle;
  vehicleGroupsOptions: StandardVehicleFormItemsProps["vehicleGroupsOptions"];
  vehicleFieldsPresetOptions: StandardVehicleFormItemsProps["vehicleFieldsPresetOptions"];
  vehicleTypeOptions: { label: string; value: string }[];
  reloadVehicleAndFieldValues: () => void;
  closeModal: () => void;
}

export function UpdateVehicleFormModal(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  const initialValues: CreateUpdateVehicle = {
    ...props.currentVehicleValue,
    companyDepartment: props.currentVehicleValue.companyDepartment.id,
  };

  return (
    <ModalForm<CreateUpdateVehicle, CreateUpdateVehicle>
      open
      formTitle={t("vehicleView.basicInformation")}
      cancelButtonText={t("cancel")}
      confirmButtonText={t("save")}
      closeModal={props.closeModal}
      initialValues={initialValues}
      saveCallback={async (values) => {
        return await backOfficeApi.updateVehicle(props.currentVehicleValue.id, {
          ...values,
          vehicleFieldsPreset: values.vehicleFieldsPreset || null,
        });
      }}
      onSuccessfulSave={() => {
        props.reloadVehicleAndFieldValues();
        message.success(t("vehicleSuccessfullySaved"));
      }}
    >
      <Form.Item
        required
        name="vehicleType"
        label={t("vehicleType")}
        htmlFor="vehicle-form-select-vehicle-type"
      >
        <Select<VehicleType["id"]>
          id="vehicle-form-select-vehicle-type"
          options={props.vehicleTypeOptions}
          style={{ maxWidth: 300 }}
        />
      </Form.Item>
      <StandardVehicleFormItems
        vehicleGroupsOptions={props.vehicleGroupsOptions}
        vehicleFieldsPresetOptions={props.vehicleFieldsPresetOptions}
      />
    </ModalForm>
  );
}
