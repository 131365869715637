import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useVehicles } from "../hooks";
import { Vehicle } from "../models";

interface VehicleFromParamsContext {
  vehicle: Vehicle | null;
}

const vehicleFromParamsContext = createContext<VehicleFromParamsContext>({
  vehicle: null,
});

export function VehicleFromParamsProvider(
  props: PropsWithChildren<{}>
): JSX.Element {
  const { getVehicleById } = useVehicles();
  const { vehicleId } = useParams<{ vehicleId?: string }>();
  const vehicle = useMemo(
    () => (vehicleId ? getVehicleById(vehicleId) : null),
    [getVehicleById, vehicleId]
  );

  return (
    <vehicleFromParamsContext.Provider
      value={{
        vehicle,
      }}
    >
      {props.children}
    </vehicleFromParamsContext.Provider>
  );
}

export function useVehicleFromParams(): VehicleFromParamsContext {
  const { vehicle } = useContext(vehicleFromParamsContext);
  return { vehicle };
}
