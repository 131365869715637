import { PAGE_SIZE } from "@inspecto/common";
import { Skeleton, Table, TableProps } from "antd";
import { TablePaginationConfig } from "antd/lib/table/interface";
import { ReactNode } from "react";

import { myTheme } from "src/theme";

import { GlobalFormErrors } from "../Form/GlobalFormErrors";

export interface BasicTableProps<RecordType>
  extends Pick<
    TableProps<RecordType>,
    | "loading"
    | "columns"
    | "dataSource"
    | "rowKey"
    | "onRow"
    | "rowSelection"
    | "onChange"
    | "expandable"
  > {
  hoverableRows?: boolean;
  verticalAlignTop?: boolean;
  pagination:
    | false
    | Pick<TablePaginationConfig, "current" | "total" | "pageSize">;
  stickyActions?: () => ReactNode;
  errors?: string[];
}

export function BasicTable<RecordType extends object>({
  errors = [],
  ...props
}: BasicTableProps<RecordType>): JSX.Element {
  const hasData = !!props.dataSource && !!props.dataSource.length;
  const rowClassNames = [
    ...(props.hoverableRows ? ["ins-cursor-pointer"] : []),
    ...(props.verticalAlignTop ? ["ins-vertical-align-top"] : []),
  ];

  return (
    <Table
      size="middle"
      loading={props.loading && hasData}
      locale={{
        ...(props.loading && !hasData
          ? { emptyText: <Skeleton /> }
          : !props.loading && errors.length
          ? {
              emptyText: <GlobalFormErrors errors={errors} />,
            }
          : {}),
      }}
      showSorterTooltip={false}
      pagination={
        props.pagination && {
          current: props.pagination.current,
          total: props.pagination.total,
          hideOnSinglePage: true,
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
          size: "default",
        }
      }
      columns={props.columns}
      dataSource={props.dataSource}
      rowKey={props.rowKey}
      rowClassName={rowClassNames.join(" ")}
      onRow={props.onRow}
      onChange={props.onChange}
      summary={
        props.stickyActions
          ? () => (
              <Table.Summary fixed="top">
                <Table.Summary.Row style={{ backgroundColor: "#fcfcfc" }}>
                  <Table.Summary.Cell index={0} colSpan={99}>
                    {props.stickyActions?.()}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )
          : undefined
      }
      sticky={
        props.stickyActions
          ? {
              offsetHeader: myTheme.commonVariables.backOfficeHeaderHeight,
            }
          : undefined
      }
      rowSelection={props.rowSelection}
      expandable={props.expandable}
    />
  );
}
