import { useCallback, useEffect, useState } from "react";

interface StateLoading {
  state: "loading";
  error: undefined;
  data: undefined;
}

interface StateError {
  state: "error";
  error: unknown;
  data: undefined;
}

interface StateDataFetched<TResultData> {
  state: "dataFetched";
  error: undefined;
  data: TResultData;
}

export function useFetch<TResultData>(
  dataFetcher: () => Promise<TResultData>
): [
  StateLoading | StateError | StateDataFetched<TResultData>,
  () => void,
  () => void
] {
  const [state, setState] = useState<
    StateLoading | StateError | StateDataFetched<TResultData>
  >({
    state: "loading",
    error: undefined,
    data: undefined,
  });

  const refetchWithoutLoadingState = useCallback(async () => {
    try {
      const resultData = await dataFetcher();
      setState({
        state: "dataFetched",
        error: undefined,
        data: resultData,
      });
    } catch (e) {
      setState({
        state: "error",
        error: e,
        data: undefined,
      });
    }
  }, [dataFetcher]);

  const fetchAndSetData = useCallback(async () => {
    setState({
      state: "loading",
      error: undefined,
      data: undefined,
    });

    await refetchWithoutLoadingState();
  }, [refetchWithoutLoadingState]);

  useEffect(() => {
    fetchAndSetData();
  }, [fetchAndSetData]);

  return [state, fetchAndSetData, refetchWithoutLoadingState];
}
