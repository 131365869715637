import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { NewDamagesNotificationsSettings } from "./NewDamagesNotificationsSettings";
import { NewProtocolNotificationsSettings } from "./NewProtocolNotificationsSettings";
import { NotificationsSettingsListView } from "./NotificationsSettingsListView";

export function NotificationsSettingsRouting() {
  return (
    <Switch>
      <Route path={urls.backOffice.settings.notifications.newDamages()} exact>
        <NewDamagesNotificationsSettings />
      </Route>
      <Route path={urls.backOffice.settings.notifications.newProtocols()} exact>
        <NewProtocolNotificationsSettings />
      </Route>
      <Route path={urls.backOffice.settings.notifications.baseView()} exact>
        <NotificationsSettingsListView />
      </Route>
    </Switch>
  );
}
