import { useState } from "react";
import { useTranslation } from "react-i18next";

import { backOfficeApi } from "../api";
import { ChargeEmployeeModal, ChargeEmployeeModalProps } from "../components";

interface ChargedEmployeeModalState {
  historicalCustomVehicleFieldValueId?: string;
  responseId?: string;
  damageId?: string;
  chargedEmployeeId: string | null;
  initialValues: ChargeEmployeeModalProps["initialValues"];
}

export function useChargedEmployeeModal(
  onActionFinish: () => void
): [JSX.Element | null, (state: ChargedEmployeeModalState | null) => void] {
  const { t } = useTranslation("backoffice");
  const [chargedEmployeeState, setChargedEmployeeState] =
    useState<ChargedEmployeeModalState | null>(null);

  const modal: JSX.Element | null = !!chargedEmployeeState ? (
    chargedEmployeeState.chargedEmployeeId ? (
      <ChargeEmployeeModal
        mode="edit"
        initialValues={chargedEmployeeState.initialValues}
        title={t("employeeCharges.editChargedEmployee")}
        cancelButtonText={t("employeeCharges.cancelChargingEmployee")}
        confirmButtonText={t("employeeCharges.saveChargedEmployee")}
        unexpectedErrorMessage={t(
          "employeeCharges.somethingWentWrongWhenChargingEmployee"
        )}
        performAction={async (employee, amount, status, comment, currency) => {
          if (!chargedEmployeeState.chargedEmployeeId) {
            return;
          }
          await backOfficeApi.editChargedEmployee(
            chargedEmployeeState.chargedEmployeeId,
            {
              employee,
              amount,
              status,
              comment,
              currency,
            }
          );
          onActionFinish();
        }}
        closeModal={() => setChargedEmployeeState(null)}
      />
    ) : (
      <ChargeEmployeeModal
        mode="add"
        initialValues={chargedEmployeeState.initialValues}
        title={t("employeeCharges.chargeEmployee")}
        cancelButtonText={t("employeeCharges.cancelChargingEmployee")}
        confirmButtonText={t("employeeCharges.saveChargedEmployee")}
        unexpectedErrorMessage={t(
          "employeeCharges.somethingWentWrongWhenChargingEmployee"
        )}
        performAction={async (employee, amount, status, comment, currency) => {
          await backOfficeApi.chargeEmployee({
            response: chargedEmployeeState?.responseId,
            damage: chargedEmployeeState?.damageId,
            historicalCustomVehicleFieldValue:
              chargedEmployeeState?.historicalCustomVehicleFieldValueId,
            employee,
            amount,
            comment,
            status,
            currency,
          });
          onActionFinish();
        }}
        closeModal={() => setChargedEmployeeState(null)}
      />
    )
  ) : null;
  return [modal, setChargedEmployeeState];
}
