import { useTranslation } from "react-i18next";

import { urls } from "src/urls";

import { BackOfficeLayout } from "../../BackOfficeLayout";
import { VehicleGroupForm } from "./VehicleGroupForm";

export function CreateVehicleGroupFormView() {
  const { t } = useTranslation("backoffice");

  return (
    <BackOfficeLayout
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.vehicleGroups.label"),
          url: urls.backOffice.settings.vehicleGroups(),
        },
      ]}
      pageTitle={t("settings.vehicleGroups.editingLabel")}
    >
      <BackOfficeLayout.Content>
        <VehicleGroupForm existingVehicleGroup={null} />
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
