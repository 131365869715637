import styled from "styled-components";

export const FormFields = styled.div`
  margin: 30px 0;
  flex: 1;
  max-width: 700px;
`;

export const FormInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;
