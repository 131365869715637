import { useCallback } from "react";

import { backOfficeApi } from "../api";
import { useFetchOptions } from "./useFetchOptions";

interface UseUserRoleOptions {
  isLoadingUserRoles: boolean;
  userRoleOptions: { label: string; value: string }[];
}

export function useUserRoleOptions(): UseUserRoleOptions {
  const fetcher = useCallback(() => backOfficeApi.getUserRoleOptions(), []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isLoadingUserRoles, userRoleOptions] = useFetchOptions(
    fetcher,
    useCallback((userRole) => userRole, [])
  );

  return { isLoadingUserRoles, userRoleOptions };
}
