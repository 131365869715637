import { PaginationResults, usePaginatedResults } from "@inspecto/common";
import {
  Badge,
  BadgeProps,
  Button,
  Form,
  FormInstance,
  message,
  Modal,
  Space,
  TableColumnType,
  Tabs,
  TabsProps,
} from "antd";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import {
  FiltersAndPaginationForm,
  FormItemBasicTable,
  FormItemBasicTableProps,
} from "src/components";
import { useQueryParams } from "src/hooks";
import { modifyColumnsWithOrdering } from "src/utils/antdSortingUtils";

import {
  CommonViewState,
  commonViewInitialState,
  Option,
  useCommonStateContext,
} from "../contexts";

const FiltersRowWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: end;

  &:last-of-type {
    margin-bottom: 16px;
  }
`;

const FiltersTabsRowWrapper = styled.div`
  padding-top: 8px;
`;

type SelectableRowAction<Item extends { id: string }> = {
  selectionErrorMessage: string;
  isCorrectNumberOfRows?: (selectedRowsCount: number) => boolean;
  callback: (selectedRows: Item[], notSelectedRows: Item[]) => void;
  buttonLabel: string;
  badge?: BadgeProps;
};

type RecursivePartialLocal<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartialLocal<U>[]
        : T[P] extends object
        ? RecursivePartialLocal<T[P]>
        : T[P];
    }
  : {};

type QueryParamsFilters<TViewName extends keyof CommonViewState> =
  PickWhenTypeMatches<CommonViewState[TViewName], Option | null>;

type PickWhenTypeMatches<TObject, TExpectedType> = {
  [TObjectKey in keyof TObject]-?: TObject[TObjectKey] extends TExpectedType
    ? TObjectKey
    : never;
}[keyof TObject];

type StickyTableAction = {
  buttonLabel: string;
  callback?: () => Promise<void>;
  href?: string;
  icon?: JSX.Element;
};

export type ColumnsListViewProps<TItem> =
  | TableColumnType<TItem>[]
  | ((currentPage: TItem[], selectedRows: TItem[]) => TableColumnType<TItem>[]);

export interface SelectableRowRequirements<TItem> {
  isRowSelectionRequired: (row: TItem) => boolean;
  deselectionDisallowedMessages: {
    title: string;
    info: string;
  };
  renderDisallowedRow: (row: TItem) => string;
}

export interface ListViewProps<
  Item extends { id: string },
  ViewName extends keyof CommonViewState
> extends Pick<FormItemBasicTableProps<Item>, "expandable"> {
  children: (props: { listElement: ReactNode }) => JSX.Element;
  debouncedFilterProps?: (keyof CommonViewState[ViewName] & string)[];
  sortedTableProps?: string[];
  dataGetter: (
    filters: CommonViewState[ViewName]
  ) => Promise<PaginationResults<Item[]> | null>;
  columns: ColumnsListViewProps<Item>;
  commonStateViewName: ViewName;
  isCommonStateUpdateEnabled?: boolean;
  filtersForm: FormInstance;
  queryParamsFilters?: QueryParamsFilters<ViewName>[];
  selectableRowActions?:
    | SelectableRowAction<Item>[]
    | ((
        currentPage: Item[],
        selectedRows: Item[]
      ) => SelectableRowAction<Item>[]);
  selectableRowRequirements?: SelectableRowRequirements<Item>;
  isRowSelected?: (row: Item) => boolean;
  stickyTableActions?: StickyTableAction[];
  tableParamsChangeConfirmationMessage?: string;
}

function hasCorrectNumberOfRowsSelected<Item extends { id: string }>(
  action: SelectableRowAction<Item>,
  selectedRows: Item[]
): boolean {
  return (
    !action.isCorrectNumberOfRows ||
    action.isCorrectNumberOfRows(selectedRows.length)
  );
}

function isValidOption(sth: any): sth is Option {
  return (
    typeof sth === "object" &&
    sth !== null &&
    sth.hasOwnProperty("value") &&
    typeof sth.value === "string" &&
    sth.hasOwnProperty("label") &&
    typeof sth.label === "string"
  );
}

export function FiltersRow(props: { children: ReactNode }) {
  return (
    <FiltersRowWrapper>
      <Space size="middle">{props.children}</Space>
    </FiltersRowWrapper>
  );
}

export const FilterItem = styled(Form.Item)<{
  $inputWidth?: "short" | "medium" | "long" | "veryLong";
}>`
  margin-bottom: 0;
  ${(props) => {
    switch (props.$inputWidth) {
      case "veryLong":
        return "min-width: 450px";
      case "long":
        return "min-width: 340px";
      case "medium":
        return "min-width: 240px";
      case "short":
        return "min-width: 150px";
      default:
        return "";
    }
  }};
`;

export function FilterTabsRow(props: {
  name: string;
  options: { label: string; value: string }[];
}) {
  const { t } = useTranslation("backoffice");

  const tabsItems: TabsProps["items"] = useMemo(
    () => [
      {
        key: "",
        label: t("allVehicleTypesTabLabel"),
      },
      ...props.options.map((option) => ({
        key: option.value,
        label: option.label,
      })),
    ],
    [props.options, t]
  );

  if (props.options.length < 2) {
    return null;
  }

  return (
    <FiltersTabsRowWrapper>
      <FilterItem name={props.name} valuePropName="activeKey">
        <Tabs type="card" size="large" items={tabsItems} />
      </FilterItem>
    </FiltersTabsRowWrapper>
  );
}

const StickyActionsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

function StickyActionButton(props: StickyTableAction) {
  const { t } = useTranslation();
  const { callback } = props;
  const [isLoading, setIsLoading] = useState(false);

  const onClick = useCallback(async () => {
    if (callback) {
      try {
        setIsLoading(true);
        await callback();
      } catch (e) {
        message.error(t("somethingWentWrong"));
      } finally {
        setIsLoading(false);
      }
    }
  }, [callback, t]);

  return (
    <Button
      size="small"
      icon={props.icon}
      shape="round"
      onClick={onClick}
      href={props.href}
      loading={isLoading}
    >
      {props.buttonLabel}
    </Button>
  );
}

export function ListView<
  Item extends { id: string },
  ViewName extends keyof CommonViewState & string
>({
  debouncedFilterProps,
  dataGetter,
  columns: rawColumnsFromProps,
  commonStateViewName,
  selectableRowActions,
  stickyTableActions,
  queryParamsFilters,
  sortedTableProps,
  isCommonStateUpdateEnabled = true,
  filtersForm: filtersFormFromParent,
  isRowSelected,
  ...props
}: ListViewProps<Item, ViewName>) {
  const history = useHistory();
  const [filtersForm] = Form.useForm<CommonViewState[ViewName]>(
    filtersFormFromParent
  );
  const submitFormDebounced = useDebouncedCallback(filtersForm.submit, 300);
  const commonState = useCommonStateContext();
  const initialFormValues = isCommonStateUpdateEnabled
    ? commonState[commonStateViewName]
    : commonViewInitialState[commonStateViewName];
  const [previousFormValues, setPreviousFormValues] =
    useState<CommonViewState[ViewName]>(initialFormValues);
  const [selectedRows, setSelectedRows] = useState<Item[] | "initializing">(
    "initializing"
  );

  useEffect(() => {
    filtersForm.submit();
  }, [filtersForm]);

  const onSuccessfulFetch: (results: Item[]) => void = useCallback(
    (results) => {
      if (isRowSelected) {
        setSelectedRows(results.filter((row) => isRowSelected?.(row)));
      } else {
        setSelectedRows([]);
      }
    },
    [isRowSelected]
  );

  const { totalItems, currentPage, isLoading, getData } = usePaginatedResults<
    CommonViewState[ViewName],
    Item
  >({ dataGetter, onSuccessfulFetch });

  const queryParams = useQueryParams();
  useEffect(() => {
    if (!queryParamsFilters) {
      return;
    }
    const filtersToApply: Partial<
      Record<QueryParamsFilters<ViewName>, Option>
    > = {};
    for (const queryParamsFilter of queryParamsFilters) {
      try {
        const filterValueFromParam = queryParams.get(
          queryParamsFilter as string
        );
        if (!filterValueFromParam) {
          continue;
        }
        const parsedParam = JSON.parse(
          filterValueFromParam
            ? decodeURIComponent(filterValueFromParam)
            : "null"
        );
        queryParams.delete(queryParamsFilter as string);
        if (isValidOption(parsedParam)) {
          filtersToApply[queryParamsFilter] = parsedParam;
        }
      } catch {}
    }
    if (Object.keys(filtersToApply).length > 0) {
      filtersForm.setFieldsValue(
        filtersToApply as RecursivePartialLocal<CommonViewState[ViewName]>
      );
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [history, queryParams, queryParamsFilters, filtersForm]);

  const columns = useMemo(() => {
    const tableParams = filtersForm.getFieldValue("tableParams");
    let returnValue =
      typeof rawColumnsFromProps === "function"
        ? selectedRows !== "initializing"
          ? rawColumnsFromProps(currentPage, selectedRows)
          : []
        : rawColumnsFromProps;

    return modifyColumnsWithOrdering(
      returnValue,
      sortedTableProps || [],
      tableParams?.sortByField || ""
    );
  }, [
    filtersForm,
    rawColumnsFromProps,
    currentPage,
    selectedRows,
    sortedTableProps,
  ]);

  const handleValuesChange = useCallback(
    (
      changedValues: Partial<CommonViewState[ViewName]>,
      allValues: CommonViewState[ViewName]
    ) => {
      setPreviousFormValues(allValues);

      if (!Object.keys(changedValues).includes("tableParams")) {
        filtersForm.setFields([
          {
            name: "tableParams",
            value: {
              pageNumber: 1,
              sortByField: filtersForm.getFieldValue("tableParams").sortByField,
            },
          },
        ]);
      }

      if (debouncedFilterProps) {
        for (const fieldName of debouncedFilterProps) {
          if (Object.keys(changedValues).includes(fieldName)) {
            submitFormDebounced();
            return;
          }
        }
      }

      filtersForm.submit();
    },
    [debouncedFilterProps, filtersForm, submitFormDebounced]
  );

  const handleValuesChangeOrDisplayConfirmationModal = useCallback(
    (
      changedValues: Partial<CommonViewState[ViewName]>,
      allValues: CommonViewState[ViewName]
    ) => {
      if (props.tableParamsChangeConfirmationMessage) {
        Modal.confirm({
          title: props.tableParamsChangeConfirmationMessage,
          onOk: () => handleValuesChange(changedValues, allValues),
          onCancel: () => {
            const rolledBackFieldsValues: CommonViewState[ViewName] = {
              ...Object.keys(allValues).reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: undefined,
                }),
                {}
              ),
              ...previousFormValues,
            };

            filtersForm.setFieldsValue(
              rolledBackFieldsValues as unknown as RecursivePartialLocal<
                CommonViewState[ViewName]
              >
            );
          },
        });
      } else {
        handleValuesChange(changedValues, allValues);
      }
    },
    [
      filtersForm,
      handleValuesChange,
      previousFormValues,
      props.tableParamsChangeConfirmationMessage,
    ]
  );

  const handleSave = useCallback(
    async (values) => getData({ ...values }),
    [getData]
  );

  const handleSuccessfulSave = useCallback(
    (savedRecord) => {
      if (isCommonStateUpdateEnabled) {
        commonState.setFilters({
          viewName: commonStateViewName,
          filters: savedRecord,
        });
      }
    },
    [commonState, commonStateViewName, isCommonStateUpdateEnabled]
  );

  const stickyActions = useCallback(() => {
    const selectableRowActionsToUse = selectableRowActions
      ? typeof selectableRowActions === "function"
        ? selectedRows !== "initializing"
          ? selectableRowActions(currentPage, selectedRows)
          : []
        : selectableRowActions
      : null;
    return (
      <StickyActionsWrapper>
        <div>
          <Space>
            {selectableRowActionsToUse
              ? selectableRowActionsToUse.map((action, index) => {
                  const button = (
                    <Button
                      shape="round"
                      type={
                        selectedRows !== "initializing" &&
                        hasCorrectNumberOfRowsSelected(action, selectedRows)
                          ? "primary"
                          : "default"
                      }
                      size="small"
                      onClick={() => {
                        if (
                          selectedRows === "initializing" ||
                          !hasCorrectNumberOfRowsSelected(action, selectedRows)
                        ) {
                          message.info(action.selectionErrorMessage);
                          return;
                        }

                        const selectedRowsIds = selectedRows.map(
                          (row) => row.id
                        );
                        const notSelectedRows = currentPage.filter(
                          (row) => !selectedRowsIds.includes(row.id)
                        );
                        action.callback(selectedRows, notSelectedRows);
                      }}
                    >
                      {action.buttonLabel}
                    </Button>
                  );
                  return (
                    <Fragment key={index}>
                      {action.badge ? (
                        <Badge {...action.badge}>{button}</Badge>
                      ) : (
                        button
                      )}
                    </Fragment>
                  );
                })
              : null}
          </Space>
        </div>
        <div>
          <Space size="small">
            {stickyTableActions &&
              stickyTableActions.map((action, index) => (
                <StickyActionButton {...action} key={index} />
              ))}
          </Space>
        </div>
      </StickyActionsWrapper>
    );
  }, [currentPage, selectableRowActions, selectedRows, stickyTableActions]);

  return (
    <FiltersAndPaginationForm
      form={filtersForm}
      onValuesChange={handleValuesChangeOrDisplayConfirmationModal}
      saveCallback={handleSave}
      onSuccessfulSave={handleSuccessfulSave}
      initialValues={initialFormValues}
    >
      {props.children({
        listElement: (
          <Form.Item name="tableParams" valuePropName="tableParams">
            <FormItemBasicTable
              rowKey="id"
              loading={isLoading}
              columns={columns}
              dataSource={currentPage}
              total={totalItems}
              stickyActions={
                (selectableRowActions || stickyTableActions) && stickyActions
              }
              expandable={props.expandable}
              rowSelection={
                selectableRowActions && selectedRows !== "initializing"
                  ? {
                      selectedRowKeys: selectedRows.map((row) => row.id),
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows) => {
                        if (props.selectableRowRequirements) {
                          let rowsDisallowedToBeDeselected: Item[] = [];

                          currentPage.forEach((row) => {
                            if (
                              props.selectableRowRequirements?.isRowSelectionRequired(
                                row
                              )
                            ) {
                              if (!selectedRowKeys.includes(row.id)) {
                                rowsDisallowedToBeDeselected.push(row);
                                selectedRows.push(row);
                              }
                            }
                          });

                          if (rowsDisallowedToBeDeselected.length) {
                            Modal.warning({
                              title:
                                props.selectableRowRequirements
                                  .deselectionDisallowedMessages.title,
                              content: (
                                <div>
                                  <ul>
                                    {rowsDisallowedToBeDeselected.map((row) => (
                                      <li>
                                        <strong>
                                          {props.selectableRowRequirements?.renderDisallowedRow(
                                            row
                                          )}
                                        </strong>
                                      </li>
                                    ))}
                                  </ul>
                                  <div>
                                    {
                                      props.selectableRowRequirements
                                        .deselectionDisallowedMessages.info
                                    }
                                  </div>
                                </div>
                              ),
                            });
                          }
                        }

                        setSelectedRows(selectedRows);
                      },
                    }
                  : undefined
              }
            />
          </Form.Item>
        ),
      })}
    </FiltersAndPaginationForm>
  );
}
