import { CalendarOutlined } from "@ant-design/icons";
import {
  DATE_PICKER_DATE_FORMAT,
  DATE_MONTH_PICKER_DATE_FORMAT,
} from "@inspecto/common";
import { Button, Input, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PickerProps, DayJsPicker, RangePickerProps } from "./DayJsPicker";

const DateMonthPickerWrapper = styled.div`
  width: 200px;
`;

export function DatePicker(
  props: Omit<PickerProps<Dayjs>, "onChange"> & {
    onChange: (date: Dayjs | null) => void;
  }
): JSX.Element {
  const { t } = useTranslation("translation");

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const propsValueFormatted =
    props.value?.format(DATE_PICKER_DATE_FORMAT) || "";
  const [inputValue, setInputValue] = useState<string>(propsValueFormatted);

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const currentValue = event.currentTarget.value;
      setInputValue(currentValue);

      if (currentValue === "") {
        return props.onChange(null);
      }

      try {
        const parsedDate = dayjs.utc(
          `${currentValue}`,
          DATE_PICKER_DATE_FORMAT,
          true
        );
        if (parsedDate.isValid() && props.onChange) {
          props.onChange(parsedDate);
        }
      } catch {}
    },
    [props]
  );

  useEffect(() => {
    setInputValue(propsValueFormatted);
  }, [propsValueFormatted]);

  return (
    <Space>
      <div style={{ position: "relative" }}>
        <Input
          allowClear
          status={propsValueFormatted !== inputValue ? "error" : undefined}
          style={{
            width: 144,
          }}
          value={inputValue}
          onChange={onInputChange}
          onBlur={() => setInputValue(propsValueFormatted)}
          placeholder={DATE_PICKER_DATE_FORMAT}
        />
        {isDatePickerVisible && (
          <DayJsPicker
            {...props}
            defaultOpen
            inputReadOnly
            onOpenChange={(isVisible) => setIsDatePickerVisible(isVisible)}
            style={{
              visibility: "hidden",
              width: 0,
              height: 0,
              padding: 0,
              margin: 0,
              position: "absolute",
              left: 0,
              bottom: 0,
            }}
            format={DATE_PICKER_DATE_FORMAT}
          />
        )}
      </div>
      {isDatePickerVisible ? (
        <Button
          key="close"
          icon={<CalendarOutlined />}
          onClick={() => setIsDatePickerVisible(false)}
        >
          {t("calendar")}
        </Button>
      ) : (
        <Button
          key="open"
          icon={<CalendarOutlined />}
          onClick={() => setIsDatePickerVisible(true)}
        >
          {t("calendar")}
        </Button>
      )}
    </Space>
  );
}

export function DateMonthPicker(props: PickerProps<Dayjs>): JSX.Element {
  return (
    <DateMonthPickerWrapper>
      <DayJsPicker
        {...props}
        style={{ width: "100%" }}
        picker="month"
        format={DATE_MONTH_PICKER_DATE_FORMAT}
        inputReadOnly={true}
      />
    </DateMonthPickerWrapper>
  );
}

export function RangePicker(props: RangePickerProps<Dayjs>): JSX.Element {
  return (
    <DayJsPicker.RangePicker {...props} format={DATE_PICKER_DATE_FORMAT} />
  );
}
