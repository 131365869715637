import { Col, Row, Typography } from "antd";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  isSignatureWithSignature,
  isSignatureWithSigner,
  RetrieveProtocolSignature,
} from "../models";
import { TextResponseWrapper } from "./CommonFieldProtocolResponse";
import { NoImageResponse } from "./ProtocolResponse";

const SignatureImage = styled.img({
  width: "100%",
  maxWidth: 380,
});

interface Props {
  signature: RetrieveProtocolSignature;
}

const SignatureWrapper = styled.div`
  padding-top: 10px;
`;

export function ProtocolSignature({ ...props }: Props): JSX.Element {
  const { t } = useTranslation("backoffice");

  let signerElement: ReactNode = null;
  let signatureElement: ReactNode = null;
  if (isSignatureWithSigner(props.signature.type)) {
    signerElement = props.signature.signer ? (
      <TextResponseWrapper>
        {props.signature.signer.firstName} {props.signature.signer.lastName}
      </TextResponseWrapper>
    ) : (
      <TextResponseWrapper />
    );
  }

  if (isSignatureWithSignature(props.signature.type)) {
    signatureElement = (
      <SignatureWrapper>
        {props.signature.signature ? (
          <SignatureImage src={props.signature.signature} />
        ) : (
          <NoImageResponse
            icon="signature"
            text={t("protocolView.noSignatureResponse")}
          />
        )}
      </SignatureWrapper>
    );
  }

  return (
    <Row gutter={[16, 6]}>
      <Col xs={24}>
        <Typography.Text strong>{props.signature.label}</Typography.Text>
      </Col>
      <Col xs={24}>
        {signerElement}
        {signatureElement}
      </Col>
    </Row>
  );
}
