function getLocation(href: string): HTMLHyperlinkElementUtils {
  const location = document.createElement("a");
  location.href = href;
  return location;
}

export function getFilenameFromUrl(url: string): string {
  const location = getLocation(url);
  const pathname = location.pathname;
  return pathname.substring(pathname.lastIndexOf("/") + 1);
}
