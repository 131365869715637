import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { useDeleteConfirmationModal } from "src/hooks";

interface Props {
  onRemoveConfirm(): Promise<void>;
  confirmationTitle?: string;
  content?: JSX.Element;
  width?: number;
  confirmationPhrase?: string;
}

export function RemoveButton(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  const { showDeleteConfirmationModal, deleteConfirmationModalContext } =
    useDeleteConfirmationModal();

  return (
    <>
      <Tooltip title={t("tableActions.delete")}>
        <Button
          danger
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            showDeleteConfirmationModal({
              title: props.confirmationTitle,
              async onOk() {
                await props.onRemoveConfirm();
              },
              width: props.width,
              content: props.content,
              confirmationPhrase: props.confirmationPhrase,
            });
          }}
          icon={<DeleteOutlined />}
        />
      </Tooltip>
      {deleteConfirmationModalContext}
    </>
  );
}
