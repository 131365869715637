import { StorageUploadedImage } from "src/storage";

import {
  CommonImmediateFileUploader,
  ImmediateImageUploaderProps,
} from "./CommonImmediateFileUploader";

export interface MultipleImagesImmediateUploaderProps
  extends Pick<ImmediateImageUploaderProps, "uploaderId"> {
  value?: StorageUploadedImage[];
  onChange?(images: StorageUploadedImage[]): void;
}

export function MultipleImagesImmediateUploader({
  ...props
}: MultipleImagesImmediateUploaderProps) {
  return (
    <CommonImmediateFileUploader
      uploadType="images"
      singleImageMode={false}
      {...props}
    />
  );
}
