import {
  ChoiceResponse,
  CommonRequirementType,
  getPhotosAndNoteRequirementsForChoices,
  PhotosAndNoteRequirementsWithId,
} from "src/components";

import { ValidationError } from "../models";

export type PossibleRequiredChoiceField = keyof Pick<
  ChoiceResponse,
  "photos" | "note"
>;

const validationErrorByFieldName: Record<
  PossibleRequiredChoiceField,
  ValidationError
> = {
  photos: ValidationError.REQUIRED_PHOTOS,
  note: ValidationError.REQUIRED_NOTE,
};

export function getChoicesWithPhotosAndNoteResponseValidationErrors(
  choices: PhotosAndNoteRequirementsWithId[],
  response: ChoiceResponse | undefined = {
    photos: [],
    note: "",
    selectedChoiceIds: [],
  }
): ValidationError[] {
  const requiredFields: PossibleRequiredChoiceField[] = [];
  const photosAndNoteRequirements = getPhotosAndNoteRequirementsForChoices(
    choices,
    response.selectedChoiceIds
  );

  if (
    photosAndNoteRequirements.photosRequirement ===
    CommonRequirementType.Required
  ) {
    requiredFields.push("photos");
  }

  if (
    photosAndNoteRequirements.noteRequirement === CommonRequirementType.Required
  ) {
    requiredFields.push("note");
  }

  return requiredFields.reduce<ValidationError[]>(
    (validationErrors, currentField) => {
      return !response[currentField]?.length
        ? validationErrors.concat(validationErrorByFieldName[currentField])
        : validationErrors;
    },
    []
  );
}
