import { BackofficeLanguage } from "@inspecto/common";
import produce from "immer";
import * as uuid from "uuid";

import { Page, Question, Template } from "../../../models";

export function cloneQuestion<T extends Question>(
  question: T,
  clonedQuestionLabelSuffix: string = "",
  isCloningTemplate: boolean = false
): T {
  return produce(question, (draft) => {
    if (clonedQuestionLabelSuffix) {
      for (const language in draft.label) {
        if (draft.label[language as BackofficeLanguage]) {
          draft.label[
            language as BackofficeLanguage
          ] += ` (${clonedQuestionLabelSuffix})`;
        }
      }
    }
    draft.frontendId = uuid.v4();
    draft.id = null;

    if (!isCloningTemplate) {
      if ("customVehicleFieldId" in draft) {
        draft.customVehicleFieldId = "";
      }
    }

    if ("choices" in draft) {
      for (const choice of draft.choices) {
        choice.id = null;
        choice.frontendId = uuid.v4();
      }
    }
  });
}

export function clonePage(
  page: Page,
  clonedQuestionLabelSuffix: string = "",
  isCloningTemplate: boolean = false
): Page {
  return produce(page, (draft) => {
    draft.id = null;
    draft.frontendId = uuid.v4();

    draft.questions = draft.questions.map((question) =>
      cloneQuestion(question, clonedQuestionLabelSuffix, isCloningTemplate)
    );
  });
}

export function cloneTemplate(
  template: Template,
  clonedTemplateLabelSuffix: string
): Template {
  return produce(template, (draft) => {
    draft.id = null;
    draft.frontendId = uuid.v4();

    if (clonedTemplateLabelSuffix) {
      for (const language in draft.label) {
        if (draft.label[language as BackofficeLanguage]) {
          draft.label[
            language as BackofficeLanguage
          ] += ` (${clonedTemplateLabelSuffix})`;
        }
      }
    }

    draft.pages = draft.pages.map((page) => clonePage(page, "", true));

    for (const page of draft.signaturePages) {
      page.id = null;
    }
  });
}
