import { PropsWithChildren } from "react";
import styled from "styled-components";

import { CompanyLogo, CompanyLogoProps } from "src/company";
import { useDeploymentInfo, DeploymentInfoMessage } from "src/deploymentInfo";

const FixedTopBar = styled.header<{ isInDeployment: boolean }>`
  position: fixed;
  top: ${(props) =>
    props.isInDeployment
      ? `${props.theme.commonVariables.deploymentInfoMessageHeight}px`
      : 0};
  left: 0;
  right: 0;
  width: 100%;
  height: ${(props) =>
    `${props.theme.commonVariables.protocolFillerHeaderHeight}px`};
  background-color: #fff;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.whitePanelBorderColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: 16px;
  z-index: 2;
`;

const ChildrenWrapper = styled.div<{
  isInDeployment: boolean;
  maxWidth: string;
}>`
  max-width: ${(props) => props.maxWidth};
  margin: auto;
  padding: ${(props) =>
    `${
      (props.isInDeployment
        ? props.theme.commonVariables.deploymentInfoMessageHeight
        : 0) +
      props.theme.commonVariables.protocolFillerHeaderHeight +
      8
    }px 8px 8px`};
  position: relative;
  z-index: 1;
`;

interface Props {
  maxWidth: string;
  onLogoClick: CompanyLogoProps["onClick"];
  topRightContent?: JSX.Element;
}

export function TopBarLayout(props: PropsWithChildren<Props>) {
  const { isInDeployment } = useDeploymentInfo();

  return (
    <>
      {isInDeployment && <DeploymentInfoMessage />}
      <FixedTopBar isInDeployment={isInDeployment}>
        <CompanyLogo
          onClick={props.onLogoClick}
          size={{
            maxHeight: 38,
            maxWidth: 120,
          }}
        />
        {props.topRightContent}
      </FixedTopBar>
      <ChildrenWrapper
        isInDeployment={isInDeployment}
        maxWidth={props.maxWidth}
      >
        {props.children}
      </ChildrenWrapper>
    </>
  );
}
