import { RedoOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  onRestoreConfirm(): Promise<void>;
  confirmationTitle: string;
}

export function RestoreButton(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  const [modal, modalContext] = Modal.useModal();

  return (
    <>
      <Tooltip title={t("tableActions.restore")}>
        <Button
          size="small"
          type="primary"
          ghost
          icon={<RedoOutlined />}
          onClick={() => {
            modal.confirm({
              title: props.confirmationTitle,
              okText: t("restoreObjectConfirmationModal.yesButton"),
              cancelText: t("restoreObjectConfirmationModal.noButton"),
              closable: true,
              maskClosable: true,
              async onOk() {
                await props.onRestoreConfirm();
              },
            });
          }}
        />
      </Tooltip>
      {modalContext}
    </>
  );
}
