import React from "react";

import { ListCustomVehicleField } from "../models";
import { AvatarFieldType } from "./AvatarFieldType";

interface Props {
  customVehicleField: ListCustomVehicleField;
  onEditCustomVehicleFieldClick?: (id: string) => void;
  onCloneCustomVehicleFieldClick?: (id: string) => void;
}

export const CustomAvatarFieldType: React.FC<Props> = ({
  customVehicleField,
  onEditCustomVehicleFieldClick,
  onCloneCustomVehicleFieldClick,
}) => {
  return (
    <AvatarFieldType
      onEditClick={
        onEditCustomVehicleFieldClick
          ? () => onEditCustomVehicleFieldClick(customVehicleField.id)
          : undefined
      }
      onCloneClick={
        onCloneCustomVehicleFieldClick
          ? () => onCloneCustomVehicleFieldClick(customVehicleField.id)
          : undefined
      }
      questionType={customVehicleField.type}
      label={customVehicleField.label}
      choices={
        customVehicleField.type === "choice"
          ? customVehicleField.choices.map((choice) => choice.label)
          : []
      }
      vehicleTypes={customVehicleField.vehicleTypes.map(({ label, id }) => ({
        label,
        value: id,
      }))}
    />
  );
};
