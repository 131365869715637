import { FilePdfTwoTone } from "@ant-design/icons";
import { EMAIL_REGEX, useCompany } from "@inspecto/common";
import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  Select,
} from "antd";
import Schema, { Rules } from "async-validator";
import { useTranslation } from "react-i18next";

import { useAuthentication } from "src/authentication";
import { DropdownMenuDivider, Icon, ModalForm, Spinner } from "src/components";
import { fullName } from "src/utils";

import {
  backOfficeApi,
  SendProtocolViaEmailData,
  SendProtocolViaEmailResponse,
} from "../api";
import { useCompanyFooters } from "../hooks";
import { useEmployeesWithEmailOptions } from "../hooks/useEmployeesWithEmailOptions";
import { ListEmployee, RetrieveFilledOutProtocol } from "../models";

interface SendEmailForm extends SendProtocolViaEmailData {}

interface Props {
  protocol: RetrieveFilledOutProtocol;
  closeModal(): void;
  onSuccess: (data: SendProtocolViaEmailResponse) => void;
}

export function SendProtocolPdfViaEmailModal(props: Props) {
  const { t } = useTranslation("backoffice");
  const { user } = useAuthentication();
  const company = useCompany();

  const { isLoadingCompanyFooters, companyFooterOptions } = useCompanyFooters();
  const { isLoadingEmployees, employeeManagerOptions } =
    useEmployeesWithEmailOptions((employee: ListEmployee) => ({
      value: `${fullName(employee)} <${employee.email}>`,
      label: `${fullName(employee)} <${employee.email}>`,
    }));

  const initialValues: SendEmailForm = {
    recipientFullName: "",
    recipientEmail: "",
    emailSubject: `[${company.name}] ${t("protocol")} - "${
      props.protocol.protocolObject.label
    }" - ${props.protocol.vehicleObject.plateNumber} - ${
      props.protocol.protocolObject.createdAtFormatted
    }`,
    emailMessage: "",
    includeProtocolAuthor: false,
    footer: null,
  };

  if (!user) {
    return null;
  }

  return isLoadingCompanyFooters ? (
    <Spinner />
  ) : (
    <ModalForm<SendEmailForm>
      open
      darkBackground
      saveCallback={handleSave}
      closeModal={props.closeModal}
      formTitle={t("sendProtocolPdfViaEmailModal.title")}
      cancelButtonText={t("sendProtocolPdfViaEmailModal.cancel")}
      confirmButtonText={t("sendProtocolPdfViaEmailModal.send")}
      initialValues={initialValues}
    >
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Card
            title={
              <Space size="middle">
                <Icon icon="envelope" />
                {t("sendProtocolPdfViaEmailModal.sender")}
              </Space>
            }
            bordered={false}
          >
            <Typography.Text>
              {t("sendProtocolPdfViaEmailModal.fullName")}:{" "}
            </Typography.Text>
            <Typography.Text strong>{fullName(user)}</Typography.Text>
          </Card>
        </Col>
        <Col span={24}>
          <Card
            bodyStyle={{ paddingBottom: 0 }}
            title={
              <Space size="middle">
                <Icon icon="envelopeOpen" />
                {t("sendProtocolPdfViaEmailModal.recipients")}
              </Space>
            }
            bordered={false}
          >
            <Form.Item
              label={t("sendProtocolPdfViaEmailModal.fullName")}
              name="recipientFullName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("sendProtocolPdfViaEmailModal.email")}
              name="recipientEmail"
              rules={[{ required: true }]}
            >
              <Input type="email" />
            </Form.Item>

            <Form.Item
              label={t("sendProtocolPdfViaEmailModal.CC")}
              name="ccEmails"
              rules={[
                {
                  message: t(
                    "sendProtocolPdfViaEmailModal.pleaseEnterValidEmailAddresses"
                  ),
                  validator: async (_, value) => {
                    const descriptor: Rules = {
                      value: {
                        type: "array",
                        defaultField: {
                          pattern: EMAIL_REGEX,
                        },
                      },
                    };
                    const schema = new Schema(descriptor);
                    await schema.validate({ value });
                  },
                },
              ]}
            >
              <Select
                mode="tags"
                options={employeeManagerOptions}
                loading={isLoadingEmployees}
                dropdownRender={(menu) => (
                  <DropdownMenuDivider
                    topContent={
                      <Typography.Text type="secondary">
                        {t("sendProtocolPdfViaEmailModal.typeAnEmailOrSelect")}
                      </Typography.Text>
                    }
                  >
                    {menu}
                  </DropdownMenuDivider>
                )}
              />
            </Form.Item>
          </Card>
        </Col>
        <Col span={24}>
          <Card
            bodyStyle={{ paddingBottom: 0 }}
            title={
              <Space size="middle">
                <Icon icon="commentDots" />
                {t("sendProtocolPdfViaEmailModal.messageForRecipient")}
              </Space>
            }
            bordered={false}
          >
            <Form.Item
              label={t("sendProtocolPdfViaEmailModal.emailSubject")}
              name="emailSubject"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("sendProtocolPdfViaEmailModal.emailMessage")}
              name="emailMessage"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item label={t("sendProtocolPdfViaEmailModal.attachment")}>
              <Space size="middle">
                <FilePdfTwoTone
                  twoToneColor="#c20b0a"
                  style={{ fontSize: "30px" }}
                />
                <Typography.Text strong>
                  {props.protocol.protocolObject.label} -{" "}
                  {props.protocol.vehicleObject.plateNumber} -{" "}
                  {props.protocol.protocolObject.createdAtFormatted}.pdf
                </Typography.Text>
              </Space>
            </Form.Item>
          </Card>
        </Col>
        <Col span={24}>
          <Card
            bodyStyle={{ paddingBottom: 0 }}
            title={
              <Space size="middle">
                <Icon icon="cog" />
                {t("sendProtocolPdfViaEmailModal.options")}
              </Space>
            }
            bordered={false}
          >
            <Form.Item name="includeProtocolAuthor" valuePropName="checked">
              <Checkbox>
                {t("sendProtocolPdfViaEmailModal.includeProtocolAuthor")}
              </Checkbox>
            </Form.Item>
            {companyFooterOptions.length > 0 && (
              <Form.Item
                name="footer"
                label={t("sendProtocolPdfViaEmailModal.footer")}
              >
                <Select
                  style={{ maxWidth: 300 }}
                  options={companyFooterOptions}
                  allowClear
                  placeholder={t("sendProtocolPdfViaEmailModal.footerNone")}
                />
              </Form.Item>
            )}
          </Card>
        </Col>
      </Row>
    </ModalForm>
  );

  async function handleSave(values: SendEmailForm): Promise<void> {
    const response = await backOfficeApi.sendProtocolViaEmail(
      props.protocol.protocolObject.id,
      values
    );
    props.onSuccess(response);
  }
}
