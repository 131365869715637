import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { CompanyDepartmentsRouter } from "./companyDepartments";
import {
  CreateCustomVehicleFieldsGroupView,
  CustomVehicleFieldsGroupsListView,
  EditCustomVehicleFieldsGroupView,
} from "./customVehicleFieldsGroups";
import { LinkTypesSettingsRouting } from "./linkTypes";
import { NotificationsSettingsRouting } from "./notifications";
import {
  CreateTemplateTagView,
  EditTemplateTagView,
  TemplateTagsListView,
} from "./templateTags";
import {
  CustomVehicleFieldFormView,
  CustomVehicleFieldsListView,
} from "./vehicleFields";
import {
  CreateVehicleFieldsPresetView,
  EditVehicleFieldsPresetView,
  VehicleFieldsPresetsListView,
} from "./vehicleFieldsPresets";
import {
  CreateVehicleGroupFormView,
  EditVehicleGroupFormViewLayout,
  VehicleGroupsListView,
} from "./vehicleGroups";

export function SettingsRouter(props: {
  enableCompanyDepartmentsRoute: boolean;
  enableLinkTypesRoute: boolean;
}) {
  return (
    <Switch>
      <Route path={urls.backOffice.settings.vehicleGroups()} exact>
        <VehicleGroupsListView />
      </Route>
      <Route path={urls.backOffice.settings.createVehicleGroup()} exact>
        <CreateVehicleGroupFormView />
      </Route>
      <Route path={urls.backOffice.settings.editVehicleGroup()} exact>
        <EditVehicleGroupFormViewLayout />
      </Route>
      <Route path={urls.backOffice.settings.customVehicleFieldsGroups()} exact>
        <CustomVehicleFieldsGroupsListView />
      </Route>
      <Route
        path={urls.backOffice.settings.createCustomVehicleFieldsGroup()}
        exact
      >
        <CreateCustomVehicleFieldsGroupView />
      </Route>
      <Route
        path={urls.backOffice.settings.editCustomVehicleFieldsGroup()}
        exact
      >
        <EditCustomVehicleFieldsGroupView />
      </Route>
      <Route path={urls.backOffice.settings.templateTags()} exact>
        <TemplateTagsListView />
      </Route>
      <Route path={urls.backOffice.settings.createTemplateTag()} exact>
        <CreateTemplateTagView />
      </Route>
      <Route path={urls.backOffice.settings.editTemplateTag()} exact>
        <EditTemplateTagView />
      </Route>
      <Route path={urls.backOffice.settings.createCustomVehicleField()} exact>
        <CustomVehicleFieldFormView />
      </Route>
      <Route path={urls.backOffice.settings.cloneCustomVehicleField()} exact>
        <CustomVehicleFieldFormView isCloning />
      </Route>
      <Route path={urls.backOffice.settings.editCustomVehicleField()} exact>
        <CustomVehicleFieldFormView />
      </Route>
      <Route path={urls.backOffice.settings.customVehicleFields()} exact>
        <CustomVehicleFieldsListView />
      </Route>
      <Route path={urls.backOffice.settings.vehicleFieldsPresets()} exact>
        <VehicleFieldsPresetsListView />
      </Route>
      <Route path={urls.backOffice.settings.createVehicleFieldsPreset()} exact>
        <CreateVehicleFieldsPresetView />
      </Route>
      <Route path={urls.backOffice.settings.editVehicleFieldsPreset()} exact>
        <EditVehicleFieldsPresetView />
      </Route>
      <Route path={urls.backOffice.settings.notifications.baseView()}>
        <NotificationsSettingsRouting />
      </Route>
      {props.enableLinkTypesRoute && (
        <Route path={urls.backOffice.settings.linkTypes.baseView()}>
          <LinkTypesSettingsRouting />
        </Route>
      )}
      {props.enableCompanyDepartmentsRoute && (
        <Route path={urls.backOffice.settings.companyDepartments()}>
          <CompanyDepartmentsRouter />
        </Route>
      )}
    </Switch>
  );
}
