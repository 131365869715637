import { Select, SelectProps, Tag } from "antd";
import { SelectValue } from "antd/es/select";
import { useTranslation } from "react-i18next";

import { tagColorByChargedEmployeeStatus } from "../common";
import { ChargedEmployeeStatus } from "../models";

interface Props<T> extends Omit<SelectProps<T>, "options"> {
  labelsType?: "fullName" | "shortName";
  labelsAsTags?: boolean;
  extraOptions?: SelectProps<T>["options"];
}

export function ChargedEmployeeStatusSelect<T extends SelectValue>({
  labelsType = "fullName",
  labelsAsTags = false,
  ...props
}: Props<T>): JSX.Element {
  const { t } = useTranslation("backoffice");

  return (
    <Select<T>
      {...props}
      style={{
        textAlign: "left",
        ...props.style,
      }}
      options={[
        ...(props.extraOptions || []),
        ...Object.values(ChargedEmployeeStatus).map((value) => {
          const label = t(`chargedEmployeeStatus.${labelsType}.${value}`);
          return {
            label: labelsAsTags ? (
              <Tag color={tagColorByChargedEmployeeStatus[value]}>{label}</Tag>
            ) : (
              label
            ),
            value: value,
          };
        }),
      ]}
    />
  );
}
