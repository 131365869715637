import { TFunction } from "react-i18next";

import { FieldValue } from "src/components";

import { ChargedEmployee } from "./Charges";
import { RetrieveCustomVehicleField } from "./CustomVehicleFields";
import { Protocol } from "./Protocol";

export const notNoneCustomVehicleFieldValueStatuses = [
  "ok",
  "warning",
  "not_ok",
] as const;

export const allCustomVehicleFieldValueStatuses = [
  ...notNoneCustomVehicleFieldValueStatuses,
  "none",
] as const;

export type CustomVehicleFieldValueStatus =
  typeof allCustomVehicleFieldValueStatuses[number];

export type NotNoneCustomVehicleFieldValueStatus =
  typeof notNoneCustomVehicleFieldValueStatuses[number];

export const labelTranslationKeyByCustomVehicleFieldStatus: Record<
  CustomVehicleFieldValueStatus,
  "ok" | "notOk" | "warning" | "none"
> = {
  ok: "ok",
  not_ok: "notOk",
  warning: "warning",
  none: "none",
};

export function getNotNoneCustomVehicleFieldValueStatusesOptions(
  t: TFunction<"backoffice">
): { label: string; value: NotNoneCustomVehicleFieldValueStatus }[] {
  return notNoneCustomVehicleFieldValueStatuses.map((status) => ({
    value: status,
    label: t(
      `fleetState.statuses.${labelTranslationKeyByCustomVehicleFieldStatus[status]}`
    ),
  }));
}

export interface RetrieveVehicleCustomVehicleField {
  fieldObject: RetrieveCustomVehicleField;
  valueObject: {
    value: FieldValue;
    valueFormatted: string;
    note: string;
    photos: { photo: string }[];
    status: CustomVehicleFieldValueStatus;
    sourceResponse: {
      id: string;
      protocolObject: Protocol;
    } | null;
  };
}

export enum VehicleFieldValueHistoryChangeReason {
  MODEL_VIEW_SET = "MODEL_VIEW_SET",
  PUBLIC_API_CREATE_VEHICLE = "PUBLIC_API_CREATE_VEHICLE",
  PUBLIC_API_UPDATE_VEHICLE = "PUBLIC_API_UPDATE_VEHICLE",
  CSV_VEHICLES_IMPORT = "CSV_VEHICLES_IMPORT",
  PROTOCOL_APPLY_RESPONSE_VALUE = "PROTOCOL_APPLY_RESPONSE_VALUE",
  PROTOCOL_CUSTOM_FIELD_UPDATE = "PROTOCOL_CUSTOM_FIELD_UPDATE",
  PERIODICAL_DATE_CHECK = "PERIODICAL_DATE_CHECK",
  FIELD_CONDITION_REMOVAL = "FIELD_CONDITION_REMOVAL",
  FIELD_CONDITION_UPDATE = "FIELD_CONDITION_UPDATE",
  REFERENCE_FIELD_VALUE_CHANGE = "REFERENCE_FIELD_VALUE_CHANGE",
  VEHICLE_FIELDS_PRESET_ASSIGNMENT_CHANGE = "VEHICLE_FIELDS_PRESET_ASSIGNMENT_CHANGE",
  VEHICLE_FIELDS_PRESET_UPDATE = "VEHICLE_FIELDS_PRESET_UPDATE",
  VEHICLE_FIELDS_PRESET_REMOVAL = "VEHICLE_FIELDS_PRESET_REMOVAL",
}

export interface RetrieveVehicleCustomVehicleFieldValueHistory {
  historyId: string;
  historyChangeReason?: VehicleFieldValueHistoryChangeReason;
  historyDate: string;
  historyType: "+" | "~" | "-";
  historyUser: { firstName: string; lastName: string; id: string } | null;
  status: CustomVehicleFieldValueStatus;
  chargedEmployeeObject: ChargedEmployee | null;
  note: RetrieveVehicleCustomVehicleField["valueObject"]["note"];
  photos: RetrieveVehicleCustomVehicleField["valueObject"]["photos"];
  sourceResponse: RetrieveVehicleCustomVehicleField["valueObject"]["sourceResponse"];
  valueFormatted: RetrieveVehicleCustomVehicleField["valueObject"]["valueFormatted"];
}
