import { HomeOutlined, PoweroffOutlined } from "@ant-design/icons";
import { Button, Result, Typography } from "antd";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Route, RouteProps, useHistory } from "react-router-dom";
import styled from "styled-components";

import { LandingPageTopBarLayout } from "src/components";
import { urls } from "src/urls";

import { useAuthentication } from "./context";
import { LoginPage } from "./LoginPage";
import { User } from "./models";

const StyledButton = styled(Button)`
  margin: 0 5px 10px;
`;

export function DisplayLoginPageIfNotAuthorized({
  children,
  requiredPermission,
}: PropsWithChildren<{ requiredPermission?: keyof User }>) {
  const { user, logoutAndRedirectToLandingPage } = useAuthentication();
  const { t } = useTranslation("translation");
  const history = useHistory();

  if (!user) {
    return <LoginPage />;
  }

  if (requiredPermission && !user[requiredPermission]) {
    return (
      <LandingPageTopBarLayout
        user={user}
        onLogoutClick={logoutAndRedirectToLandingPage}
      >
        <Result
          status="403"
          title={t("errors.noAccess.title")}
          subTitle={
            <div>
              <div>{t("errors.noAccess.description")}</div>
              <Typography.Text keyboard>
                {history.location.pathname}
              </Typography.Text>
            </div>
          }
          extra={
            <>
              <StyledButton
                onClick={logoutAndRedirectToLandingPage}
                ghost
                type="primary"
                icon={<PoweroffOutlined />}
              >
                {t("logout")}
              </StyledButton>
              <StyledButton
                onClick={() => history.push(urls.landingPage())}
                type="primary"
                icon={<HomeOutlined />}
              >
                {t("errors.noAccess.returnToHomePage")}
              </StyledButton>
            </>
          }
        />
      </LandingPageTopBarLayout>
    );
  }
  return <>{children}</>;
}

export function ProtectedRoute({
  requiredPermission,
  children,
  ...props
}: PropsWithChildren<{ requiredPermission?: keyof User }> & RouteProps) {
  return (
    <Route {...props}>
      {/*Needs to be within route in a separate component so that we don't return LoginPage instead of route */}
      <DisplayLoginPageIfNotAuthorized requiredPermission={requiredPermission}>
        {children}
      </DisplayLoginPageIfNotAuthorized>
    </Route>
  );
}
