import { useState } from "react";

export function useHover() {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const onTouchStart = () => {
    setIsMobile(true);
    setIsHovered(true);
  };

  const onTouchEnd = () => {
    setIsMobile(true);
    setIsHovered(false);
  };

  const onMouseEnter = () => {
    if (isMobile) {
      return;
    }
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    if (isMobile) {
      return;
    }
    setIsHovered(false);
  };

  return {
    forceSetIsHovered: setIsHovered,
    isHovered,
    onTouchStart,
    onTouchEnd,
    onMouseEnter,
    onMouseLeave,
  };
}
