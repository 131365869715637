import { useAllPaginatedElements } from "@inspecto/common";
import { message, Select, Skeleton } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import {
  AssignVehiclesListView,
  DividerCheckboxFilterItem,
  FilterItem,
} from "../../../components";
import { getAssignedItemTagRenderer } from "../../../components/AssignedItemsTagsRenderer";
import { useVehicleGroups } from "../../../hooks";
import {
  RetrieveVehicleFieldsPreset,
  SimpleVehicle,
  VehicleFieldsPresetObject,
} from "../../../models";
import { BackOfficeLayout } from "../../BackOfficeLayout";
import { contentMinWidth } from "./common";
import { VehicleFieldsPresetForm } from "./VehicleFieldsPresetForm";

export function EditVehicleFieldsPresetView() {
  const { t } = useTranslation("backoffice");
  const { vehicleFieldsPresetId } =
    useParams<{ vehicleFieldsPresetId: string }>();
  const [vehicleFieldsPreset, setVehicleFieldsPreset] =
    useState<RetrieveVehicleFieldsPreset | null>(null);
  const { vehicleGroupOptions } = useVehicleGroups();

  const getVehicleFieldsPresets = useCallback(
    (pageNumber) => backOfficeApi.getVehicleFieldsPresets(pageNumber),
    []
  );
  const [
    vehicleFieldsPresets,
    isLoadingVehicleFieldsPresets,
    fetchAllVehicleFieldsPresets,
  ] = useAllPaginatedElements(getVehicleFieldsPresets);
  const vehicleFieldsPresetsOptions: { label: string; value: string }[] =
    useMemo(
      () =>
        vehicleFieldsPresets.map((vehicleFieldsPreset) => ({
          label: vehicleFieldsPreset.label,
          value: vehicleFieldsPreset.id,
        })),
      [vehicleFieldsPresets]
    );

  const extraColumns = useCallback(
    (selectedRows: SimpleVehicle[]) => [
      {
        dataIndex: "vehicleFieldsPresetObject",
        width: 400,
        title: t("settings.vehicleFieldsPresets.vehicleFieldsPresetLabel"),
        render: getAssignedItemTagRenderer<
          SimpleVehicle,
          VehicleFieldsPresetObject
        >(
          selectedRows,
          {
            id: vehicleFieldsPresetId,
            label: vehicleFieldsPreset?.label || "",
          },
          (preset) => preset.label
        ),
      },
    ],
    [t, vehicleFieldsPreset?.label, vehicleFieldsPresetId]
  );

  const retrieveCurrentVehicleFieldsPreset = useCallback(() => {
    setVehicleFieldsPreset(null);

    backOfficeApi
      .getVehicleFieldsPreset(vehicleFieldsPresetId)
      .then((data) => setVehicleFieldsPreset(data))
      .catch(() => message.error("somethingWentWrong"));
  }, [vehicleFieldsPresetId]);

  const isVehicleAssigned = useCallback(
    (vehicle) => vehicle.vehicleFieldsPreset === vehicleFieldsPreset?.id,
    [vehicleFieldsPreset?.id]
  );

  useEffect(() => {
    retrieveCurrentVehicleFieldsPreset();
  }, [retrieveCurrentVehicleFieldsPreset]);

  return (
    <BackOfficeLayout
      contentMinWidth={contentMinWidth}
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.vehicleFieldsPresets.label"),
          url: urls.backOffice.settings.vehicleFieldsPresets(),
        },
      ]}
      pageTitle={t("settings.vehicleFieldsPresets.vehicleFieldsPresetLabel")}
    >
      <BackOfficeLayout.Content>
        {vehicleFieldsPreset && !isLoadingVehicleFieldsPresets ? (
          <>
            <VehicleFieldsPresetForm
              initialValues={vehicleFieldsPreset}
              onSave={async (values) => {
                const updatedVehicleFieldsPreset =
                  await backOfficeApi.updateVehicleFieldsPreset(
                    vehicleFieldsPreset.id,
                    values
                  );
                fetchAllVehicleFieldsPresets();
                return updatedVehicleFieldsPreset;
              }}
              afterSuccessfulSaveWithoutRedirect={() => {
                retrieveCurrentVehicleFieldsPreset();
              }}
            />
            <AssignVehiclesListView
              vehicleGroupOptions={vehicleGroupOptions}
              isVehicleAssigned={isVehicleAssigned}
              assignVehicles={async (payload) =>
                backOfficeApi.assignVehiclePresetToVehicles(
                  vehicleFieldsPreset.id,
                  payload
                )
              }
              extraColumns={extraColumns}
              extraFilters={
                <FilterItem name="vehicleFieldsPreset" $inputWidth="medium">
                  <Select
                    allowClear
                    style={{ width: 350 }}
                    options={vehicleFieldsPresetsOptions}
                    placeholder={t(
                      "settings.vehicleFieldsPresets.vehicleFieldsPresetLabel"
                    )}
                    dropdownRender={(menu) => (
                      <DividerCheckboxFilterItem
                        checkboxLabel={t(
                          "assignVehiclesList.excludeVehiclesWithSelectedPresets"
                        )}
                        filterItemName="excludeVehicleFieldsPreset"
                      >
                        {menu}
                      </DividerCheckboxFilterItem>
                    )}
                  />
                </FilterItem>
              }
            />
          </>
        ) : (
          <Skeleton />
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
