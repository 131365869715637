import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { CreateVehicleView } from "./CreateVehicleView";
import { VehicleCardView } from "./VehicleCardView";
import { VehiclesListView } from "./VehiclesListView";

export function VehiclesRouter() {
  return (
    <Switch>
      <Route path={urls.backOffice.vehicles()} exact>
        <VehiclesListView />
      </Route>
      <Route path={urls.backOffice.createVehicle()} exact>
        <CreateVehicleView />
      </Route>
      <Route path={urls.backOffice.vehicleCard()}>
        <VehicleCardView />
      </Route>
    </Switch>
  );
}
