export interface BackendPaginationResults<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T;
}
export interface PaginationResults<T> extends BackendPaginationResults<T> {}

export class ApiError extends Error {
  constructor(public statusCode: number, public body: any) {
    super("ApiError");

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export class NoConnectionError extends Error {
  constructor() {
    super("NoConnectionError");

    Object.setPrototypeOf(this, NoConnectionError.prototype);
  }
}
