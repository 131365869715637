import { Space, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { Icon } from "src/components";
import { fullName } from "src/utils";

import { tagColorByChargedEmployeeStatus } from "../common";
import { ChargedEmployee, ChargedEmployeeStatus } from "../models";

interface Props {
  chargedEmployee: ChargedEmployee | null;
  showEmpty?: boolean;
  showStatusTag?: boolean;
}

export function ChargedEmployeeComponent(props: Props) {
  const { t } = useTranslation("backoffice");

  if (!props.chargedEmployee) {
    return props.showEmpty ? (
      <Typography.Text type="secondary">
        {t("employeeCharges.noCharge")}
      </Typography.Text>
    ) : null;
  }
  return (
    <Space align="start">
      <Icon icon="euroSign" />
      <div>
        <div>
          <Typography.Text
            delete={
              props.chargedEmployee.status === ChargedEmployeeStatus.CANCELED
            }
          >
            {t("employeeCharges.employeeHasBeenCharged", {
              fullName: fullName(props.chargedEmployee.employeeObject),
            })}{" "}
            <Typography.Text strong>
              {props.chargedEmployee.amount}&nbsp;
              {props.chargedEmployee.currency}
            </Typography.Text>
            {props.chargedEmployee.comment && (
              <>
                {" "}
                {t("employeeCharges.employeeHasBeenChargedWithComment")}
                {" - "}"{props.chargedEmployee.comment}"
              </>
            )}
          </Typography.Text>
        </div>
        {props.showStatusTag && (
          <Tag
            style={{
              marginTop: 10,
            }}
            color={
              tagColorByChargedEmployeeStatus[props.chargedEmployee.status]
            }
          >
            {t(
              `chargedEmployeeStatus.fullName.${props.chargedEmployee.status}`
            )}
          </Tag>
        )}
      </div>
    </Space>
  );
}
