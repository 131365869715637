import { Divider } from "antd";
import styled from "styled-components";

import { myTheme } from "src/theme";

export const FixedToolbar = styled.div`
  position: fixed;
  color: #fff;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #34363e;
  z-index: ${myTheme.zIndexes.devToolbar};
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

export const ToolbarToggler = styled.div`
  position: fixed;
  z-index: ${myTheme.zIndexes.devToolbar + 1};
  right: 7px;
  bottom: 7px;
`;

export const Item = styled.div<{ horizontal?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.horizontal ? "center" : "unset")};
  ${(props) => (props.horizontal ? "" : ": 10px 0;")}
`;

export const TopLeftDevInformation = styled.div`
  position: fixed;
  z-index: ${myTheme.zIndexes.devToolbar + 1};
  left: 2px;
  top: 2px;
  pointer-events: none;
  background: rgb(255 235 59 / 80%);
  font-weight: bold;
  font-family: monospace;
  padding: 0 6px;
  font-size: 10px;
`;

export function VerticalDivider(): JSX.Element {
  return <Divider type="vertical" style={{ borderLeftColor: "#717278" }} />;
}

export function HorizontalDivider(): JSX.Element {
  return (
    <Divider
      type="horizontal"
      style={{ borderTopColor: "#55565a", margin: 0 }}
    />
  );
}
