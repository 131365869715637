import { Divider } from "antd";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function SelectAddLink(
  props: PropsWithChildren<{
    addUrl: string;
  }>
): JSX.Element {
  const { t } = useTranslation("backoffice");

  return (
    <>
      {props.children}
      <Divider style={{ margin: "8px 0" }} />
      <div style={{ padding: "5px 18px", textAlign: "center" }}>
        <Link to={props.addUrl} target="_blank">
          + {t("add")}
        </Link>
      </div>
    </>
  );
}
