import { useCallback } from "react";

import { backOfficeApi } from "../api";
import { ListVehicleFieldsPreset } from "../models";
import { useFetchOptions } from "./useFetchOptions";

export interface UseVehicleFieldsPresets {
  vehicleFieldsPresets: ListVehicleFieldsPreset[];
  isLoadingVehicleFieldsPresets: boolean;
  vehicleFieldsPresetOptions: {
    label: string;
    value: ListVehicleFieldsPreset["id"];
  }[];
  refresh: () => void;
}

export function useVehicleFieldsPresets(): UseVehicleFieldsPresets {
  const mapper = useCallback(
    (vehicleFieldsPreset) => ({
      value: vehicleFieldsPreset.id,
      label: vehicleFieldsPreset.label,
    }),
    []
  );
  const fetcher = useCallback(async () => {
    const vehicleFieldsPresets = await backOfficeApi.getVehicleFieldsPresets();
    return vehicleFieldsPresets.results;
  }, []);

  const [
    vehicleFieldsPresets,
    isLoadingVehicleFieldsPresets,
    vehicleFieldsPresetOptions,
    refresh,
  ] = useFetchOptions<ListVehicleFieldsPreset>(fetcher, mapper);

  return {
    isLoadingVehicleFieldsPresets,
    vehicleFieldsPresetOptions,
    vehicleFieldsPresets,
    refresh,
  };
}
