import {
  ArrowRightOutlined,
  CopyOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { fetchAllPaginatedElements } from "@inspecto/common";
import {
  Alert,
  Button,
  Form,
  Space,
  TableColumnType,
  Tag,
  Typography,
} from "antd";
import { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { MultiLineTag, RemoveButton } from "src/components";
import { getTranslatedField } from "src/locales";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { ReorderListGroup, ReorderListItemsModal } from "../../../components";
import { ListCustomVehicleField } from "../../../models";
import { BaseCustomVehicleFieldsListView } from "../../BaseCustomVehicleFieldsListView";

export function CustomVehicleFieldsListView() {
  const { t } = useTranslation("backoffice");
  const { t: globalTranslation } = useTranslation("translation");
  const [filtersForm] = Form.useForm();
  const history = useHistory();

  const [allCustomVehicleFields, setAllCustomVehicleFields] = useState<
    ListCustomVehicleField[]
  >([]);

  const reorderListGroups: Record<string, ReorderListGroup> = useMemo(() => {
    const output: Record<string, ReorderListGroup> = {};

    for (const customVehicleField of allCustomVehicleFields) {
      const groupObject = customVehicleField.customVehicleFieldsGroupObject
        ? customVehicleField.customVehicleFieldsGroupObject
        : {
            id: "no-group",
            label: t("vehicleView.customVehicleFields"),
            order: -1,
            labelTranslations: getTranslatedField(""),
          };
      if (!output[groupObject.id]) {
        output[groupObject.id] = {
          groupHeader: groupObject.label,
          groupId: groupObject.id,
          items: [],
          order: groupObject.order,
        };
      }

      const group = output[groupObject.id];
      if (group) {
        group.items.push({
          id: customVehicleField.id,
          label: customVehicleField.label,
          description: customVehicleField.vehicleTypes
            .map((vehicleType) => vehicleType.label)
            .join(", "),
        });
      }
    }

    return output;
  }, [allCustomVehicleFields, t]);

  const removeCustomVehicleField = useCallback(
    async (removingCustomVehicleFieldId: string) => {
      try {
        await backOfficeApi.removeCustomVehicleField(
          removingCustomVehicleFieldId
        );
      } catch (e) {
        toast.error(globalTranslation("somethingWentWrong"));
        return;
      }

      filtersForm.submit();
      toast.success(
        t("settings.customVehicleFields.successfullyDeletedMessage")
      );
    },
    [filtersForm, globalTranslation, t]
  );

  const columns = useMemo<TableColumnType<ListCustomVehicleField>[]>(
    () => [
      {
        dataIndex: "label",
        title: t("settings.customVehicleFields.label"),
        width: "auto",
      },
      {
        dataIndex: "type",
        title: t("settings.customVehicleFields.fields.type"),
        width: 200,
        render: (value: ListCustomVehicleField["type"]) => (
          <Tag>{t(`fieldType.${value}`)}</Tag>
        ),
      },
      {
        dataIndex: "vehicleTypes",
        title: t("settings.customVehicleFields.vehicleTypes"),
        width: "250px",
        render: (value: ListCustomVehicleField["vehicleTypes"]) => (
          <>
            {value.map((vehicleType) => (
              <MultiLineTag key={vehicleType.id}>
                {vehicleType.label}
              </MultiLineTag>
            ))}
          </>
        ),
      },
      {
        dataIndex: "customVehicleFieldsGroupObject",
        width: 200,
        title: t("settings.customVehicleFieldsGroups.editingLabel"),
        render: (
          customVehicleFieldsGroupObject: ListCustomVehicleField["customVehicleFieldsGroupObject"]
        ) => {
          return customVehicleFieldsGroupObject ? (
            <MultiLineTag key={customVehicleFieldsGroupObject.id}>
              <Typography.Text
                style={{
                  maxWidth: 170,
                }}
                ellipsis={{ tooltip: true }}
              >
                {customVehicleFieldsGroupObject.label}
              </Typography.Text>
            </MultiLineTag>
          ) : (
            "-"
          );
        },
      },
      {
        key: "action",
        width: "300px",
        align: "right",
        render: (value: ListCustomVehicleField) => (
          <Space size="small">
            <Button
              size="small"
              onClick={() =>
                history.push(
                  urls.backOffice.settings.editCustomVehicleField(value.id)
                )
              }
              icon={<ArrowRightOutlined />}
            >
              {t("tableActions.edit")}
            </Button>
            <Button
              size="small"
              onClick={() =>
                history.push(
                  urls.backOffice.settings.cloneCustomVehicleField(value.id)
                )
              }
              icon={<CopyOutlined />}
            >
              {t("tableActions.clone")}
            </Button>
            <RemoveButton
              onRemoveConfirm={async () => removeCustomVehicleField(value.id)}
              width={500}
              confirmationPhrase={t("deleteConfirmationPhrase")}
              content={
                <Alert
                  message={
                    <>
                      <Trans
                        t={t}
                        i18nKey="settings.customVehicleFields.deleteConfirmation"
                      />
                      {value.type === "damages" && (
                        <>
                          <br />
                          <br />
                          <Trans
                            t={t}
                            i18nKey="settings.customVehicleFields.damagesDeleteConfirmation"
                          />
                        </>
                      )}
                    </>
                  }
                  type="warning"
                />
              }
            />
          </Space>
        ),
      },
    ],
    [history, t, removeCustomVehicleField]
  );

  return (
    <BaseCustomVehicleFieldsListView
      sortedTableProps={["customVehicleFieldsGroupObject", "label", "type"]}
      commonStateViewName="customVehicleFieldsView"
      filtersForm={filtersForm}
      columns={columns}
      breadcrumbs={[{ label: t("settings.label") }]}
      pageTitle={t("settings.customVehicleFields.label")}
      createPageUrl={urls.backOffice.settings.createCustomVehicleField()}
      stickyTableActions={[
        {
          buttonLabel: t("reorderListItems.title"),
          callback: async () => {
            const customVehicleFields = await fetchAllPaginatedElements(
              (pageNumber) =>
                backOfficeApi.getCustomVehicleFields({
                  tableParams: { pageNumber },
                })
            );
            setAllCustomVehicleFields(customVehicleFields);
          },
          icon: <SwapOutlined />,
        },
      ]}
      additionalRenderChildren={
        <>
          {allCustomVehicleFields.length > 0 && (
            <ReorderListItemsModal
              groups={reorderListGroups}
              closeModal={() => setAllCustomVehicleFields([])}
              onSuccess={() => {
                filtersForm.submit();
              }}
              save={async (items) => {
                await backOfficeApi.reorderCustomVehicleFields(
                  items.map((item) => item.id)
                );
              }}
              modalTitle={t("reorderListItems.title")}
            />
          )}
        </>
      }
    />
  );
}
