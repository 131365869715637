export interface Position {
  latitude: number;
  longitude: number;
  accuracy: number;
}

type WatcherCleanup = () => void;

function geoLocationPositionToPosition(
  position: GeolocationPosition
): Position {
  const coords = position.coords;
  return {
    latitude: coords.latitude,
    longitude: coords.longitude,
    accuracy: coords.accuracy,
  };
}

export function watchCurrentPosition(
  onPositionChanged: (position: Position) => void,
  onError: (error: GeolocationPositionError) => void
): WatcherCleanup {
  const watchId = navigator.geolocation.watchPosition(
    (position) => {
      onPositionChanged(geoLocationPositionToPosition(position));
    },
    (e) => {
      onError(e);
    },
    { enableHighAccuracy: true }
  );

  return () => {
    navigator.geolocation.clearWatch(watchId);
  };
}
