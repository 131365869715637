import { Form, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { MaxWidth700WithSaveButtonForm } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { VehicleGroup } from "../../../models";

interface Props {
  existingVehicleGroup: VehicleGroup | null;
  onSuccessfulSave?: () => void;
}

export function VehicleGroupForm(props: Props) {
  const { t } = useTranslation("backoffice");
  const history = useHistory();

  return (
    <MaxWidth700WithSaveButtonForm<VehicleGroup, void>
      saveCallback={async (values) => {
        if (props.existingVehicleGroup) {
          await backOfficeApi.updateVehicleGroup(
            props.existingVehicleGroup.id,
            values
          );
          props.onSuccessfulSave?.();
          message.success(t("settings.genericGroups.savedSuccessfully"));
        } else {
          const newVehicleGroup = await backOfficeApi.createVehicleGroup(
            values
          );

          history.replace(
            urls.backOffice.settings.editVehicleGroup(newVehicleGroup.id)
          );
          message.success(t("settings.genericGroups.savedSuccessfully"));
        }
      }}
      initialValues={props.existingVehicleGroup || undefined}
    >
      <Form.Item
        name="label"
        label={t("settings.genericGroups.fields.label")}
        rules={[{ required: true }, { max: 64 }]}
      >
        <Input />
      </Form.Item>
    </MaxWidth700WithSaveButtonForm>
  );
}
