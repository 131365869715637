import { Button, FormInstance, Space } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { submitFormWithOnSuccessfulSaveContext } from "src/components";

import { siteContentHorizontalPaddings } from "../../BackOffice.styles";

const StyledStickyFooter = styled.div`
  position: sticky;
  z-index: 1;
  bottom: 0;
  margin-left: -${siteContentHorizontalPaddings}px;
  margin-right: -${siteContentHorizontalPaddings}px;
  padding-left: ${siteContentHorizontalPaddings}px;
  padding-right: ${siteContentHorizontalPaddings}px;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  border-top: 1px solid ${(props) => props.theme.colors.whitePanelBorderColor};
`;

interface Props {
  isSaving: boolean;
  form: FormInstance;
}

export function StickySaveButtons(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  return (
    <StyledStickyFooter>
      <Space>
        <Button
          loading={props.isSaving}
          type="primary"
          onClick={() => {
            submitFormWithOnSuccessfulSaveContext(props.form, true);
          }}
        >
          {t("saveAndClose")}
        </Button>
        <Button
          loading={props.isSaving}
          type="ghost"
          onClick={() => {
            submitFormWithOnSuccessfulSaveContext(props.form, false);
          }}
        >
          {t("save")}
        </Button>
      </Space>
    </StyledStickyFooter>
  );
}
