import { Position } from "@inspecto/common";
import { Modal } from "antd";
import { useEffect, useRef } from "react";
import styled from "styled-components";

import { embedGoogleMapsPosition } from "src/utils/embedGoogleMapsPosition";

interface Props {
  position: Position;

  onClose(): void;
}

const MapView = styled.div`
  height: 460px;
  background-color: #dcdcdc;
`;

export function GoogleMapEmbedModal({ position, onClose }: Props) {
  const mapDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    (async () => {
      if (!mapDivRef.current) {
        return;
      }
      await embedGoogleMapsPosition(
        mapDivRef.current,
        position.latitude,
        position.longitude,
        position.accuracy
      );
    })();
  }, [mapDivRef, position]);

  return (
    <Modal open onCancel={onClose} onOk={onClose}>
      <MapView ref={mapDivRef} />
    </Modal>
  );
}
