import { Button, Space, Tooltip } from "antd";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "./";

interface Props {
  onClick: (event: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  mode?: "text" | "tooltip";
}

export function ChargeEmployeeIconButton({
  mode = "tooltip",
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  const icon = <Icon icon="euroSign" size={16} />;

  return (
    <Tooltip
      title={t("employeeCharges.chargeEmployee")}
      open={mode === "text" ? false : undefined}
    >
      <Button
        title={t("employeeCharges.chargeEmployee")}
        disabled={props.disabled}
        size="small"
        type="default"
        shape={mode === "tooltip" ? "circle" : "round"}
        icon={mode === "tooltip" ? icon : undefined}
        onClick={props.onClick}
      >
        {mode === "text" ? (
          <Space>
            {icon}
            {t("employeeCharges.chargeEmployee")}
          </Space>
        ) : null}
      </Button>
    </Tooltip>
  );
}
