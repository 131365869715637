import {
  InternalMultiLanguageTextInput,
  InternalMultiLanguageTextInputProps,
} from "./InternalMultiLanguageInput";

interface Props extends Omit<InternalMultiLanguageTextInputProps, "variant"> {}

export function MultiLanguageTextarea(props: Props): JSX.Element | null {
  return <InternalMultiLanguageTextInput variant="textarea" {...props} />;
}
