export function fullName(user: {
  firstName: string;
  lastName: string;
}): string {
  return `${user.firstName} ${user.lastName}`;
}

export function fullNameReversed(user: {
  firstName: string;
  lastName: string;
}): string {
  return `${user.lastName} ${user.firstName}`;
}
