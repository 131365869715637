import { useCompany } from "@inspecto/common";
import { useContext, useEffect, useState } from "react";

import { useAuthentication, User } from "src/authentication";
import { languageContext } from "src/locales";

export function LanguageConfig(props: {
  children: JSX.Element;
  languageToUse: keyof Pick<User, "language" | "protocolFillerLanguage">;
}): JSX.Element {
  const {
    dispatch: languageDispatch,
    value: { language: currentLanguage },
  } = useContext(languageContext);
  const { user } = useAuthentication();
  const company = useCompany();
  const [initialLanguage] = useState(currentLanguage);

  useEffect(() => {
    if (!user) {
      return;
    }
    const language = user[props.languageToUse];
    const languageToSet = language ? language : company[props.languageToUse];

    languageDispatch({
      type: "setLanguage",
      payload: { language: languageToSet },
    });

    return () => {
      languageDispatch({
        type: "setLanguage",
        payload: { language: initialLanguage },
      });
    };
  }, [
    company,
    company.protocolFillerLanguage,
    initialLanguage,
    languageDispatch,
    props.languageToUse,
    user,
  ]);

  return props.children;
}
