import { Divider } from "antd";
import { useTranslation } from "react-i18next";

export function OrDivider(props: {
  margin?: "top" | "both";
  text?: string;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <Divider
      plain
      style={{
        ...(props.margin === "top" ? { marginBottom: 0 } : {}),
        textTransform: "uppercase",
      }}
    >
      {props.text || t("or")}
    </Divider>
  );
}
