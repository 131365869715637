import { message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  updateItem: (id: string, data: { isActive: boolean }) => Promise<any>;
  successMessage?: string;
}

export function useRestoreItem(props: Props) {
  const { t } = useTranslation("backoffice");
  const [isRestoring, setIsRestoring] = useState(false);

  async function restore(id: string): Promise<void> {
    try {
      setIsRestoring(true);
      await props.updateItem(id, {
        isActive: true,
      });
      message.success(
        props.successMessage || t("genericOperationSuccessMessage")
      );
    } catch (e) {
      message.error(t("somethingWentWrong"));
    }
    setIsRestoring(false);
  }

  return {
    isRestoring,
    restore,
  };
}
