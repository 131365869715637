import { FileDoneOutlined, LoginOutlined } from "@ant-design/icons";
import { PAGE_SIZE } from "@inspecto/common";
import {
  Empty,
  Modal,
  ModalProps,
  Pagination,
  Skeleton,
  Space,
  Timeline,
  Typography,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ChargeEmployeeIconButton } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../api";
import { useChargedEmployeeModal } from "../hooks";
import {
  RetrieveVehicleCustomVehicleFieldValueHistory,
  VehicleFieldValueHistoryChangeReason,
} from "../models";
import { ChargedEmployeeComponent } from "./ChargedEmployeeComponent";
import {
  CommonFieldProtocolResponse,
  CommonFieldProtocolResponseProps,
} from "./CommonFieldProtocolResponse";
import { StatusIcon } from "./StatusIcon";
import { UserDateTimelineItem } from "./UserDateTimelineItem";

const HistoryChangeReasonWrapper = styled.div`
  margin: 10px 7px 10px 7px;
`;

const ResponseWrapper = styled.div`
  flex: 0 1 420px;
`;

const ActionsWrapper = styled.div`
  flex: 1 1 auto;
  padding-left: 20px;
`;

const ChargedEmployeeWrapper = styled.div`
  padding-top: 10px;
`;

export interface VehicleFieldHistoryModalProps extends ModalProps {
  vehicleFieldType: CommonFieldProtocolResponseProps["type"];
  vehicleId: string;
  vehicleFieldId: string;
  vehicleFieldLabel: string;
}

export function VehicleFieldHistoryModal({
  vehicleFieldId,
  vehicleId,
  ...props
}: VehicleFieldHistoryModalProps) {
  const { t } = useTranslation("backoffice");
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [customVehicleFieldHistory, setCustomVehicleFieldHistory] = useState<
    RetrieveVehicleCustomVehicleFieldValueHistory[]
  >([]);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    const data = await backOfficeApi.getVehicleCustomVehicleFieldHistory(
      vehicleId,
      vehicleFieldId,
      { pageNumber: currentPage }
    );
    setCustomVehicleFieldHistory(data.results);
    setTotal(data.count);
    setIsLoading(false);
  }, [currentPage, vehicleFieldId, vehicleId]);

  const [chargedEmployeeModal, setChargedEmployeeState] =
    useChargedEmployeeModal(loadData);

  const prefixByChangeType: Record<
    RetrieveVehicleCustomVehicleFieldValueHistory["historyType"],
    string
  > = {
    "+": t("vehicleFieldHistory.historyType.added"),
    "-": t("vehicleFieldHistory.historyType.removed"),
    "~": t("vehicleFieldHistory.historyType.changed"),
  };

  const textByChangeReason: Record<
    VehicleFieldValueHistoryChangeReason,
    string
  > = {
    [VehicleFieldValueHistoryChangeReason.MODEL_VIEW_SET]: t(
      "vehicleFieldHistory.historyChangeReason.vehiclePage"
    ),
    [VehicleFieldValueHistoryChangeReason.PUBLIC_API_CREATE_VEHICLE]: t(
      "vehicleFieldHistory.historyChangeReason.publicApi"
    ),
    [VehicleFieldValueHistoryChangeReason.PUBLIC_API_UPDATE_VEHICLE]: t(
      "vehicleFieldHistory.historyChangeReason.publicApi"
    ),
    [VehicleFieldValueHistoryChangeReason.CSV_VEHICLES_IMPORT]: t(
      "vehicleFieldHistory.historyChangeReason.fileImport"
    ),
    [VehicleFieldValueHistoryChangeReason.PROTOCOL_APPLY_RESPONSE_VALUE]: t(
      "vehicleFieldHistory.historyChangeReason.appliedFromProtocol"
    ),
    [VehicleFieldValueHistoryChangeReason.PROTOCOL_CUSTOM_FIELD_UPDATE]: t(
      "vehicleFieldHistory.historyChangeReason.protocol"
    ),
    [VehicleFieldValueHistoryChangeReason.PERIODICAL_DATE_CHECK]: t(
      "vehicleFieldHistory.historyChangeReason.periodicalDateCheck"
    ),
    [VehicleFieldValueHistoryChangeReason.FIELD_CONDITION_REMOVAL]: t(
      "vehicleFieldHistory.historyChangeReason.fieldConditionRemoval"
    ),
    [VehicleFieldValueHistoryChangeReason.FIELD_CONDITION_UPDATE]: t(
      "vehicleFieldHistory.historyChangeReason.fieldConditionUpdate"
    ),
    [VehicleFieldValueHistoryChangeReason.REFERENCE_FIELD_VALUE_CHANGE]: t(
      "vehicleFieldHistory.historyChangeReason.referenceFieldValueChange"
    ),
    [VehicleFieldValueHistoryChangeReason.VEHICLE_FIELDS_PRESET_ASSIGNMENT_CHANGE]:
      t(
        "vehicleFieldHistory.historyChangeReason.vehicleFieldsPresetAssignmentChange"
      ),
    [VehicleFieldValueHistoryChangeReason.VEHICLE_FIELDS_PRESET_UPDATE]: t(
      "vehicleFieldHistory.historyChangeReason.vehicleFieldsPresetUpdate"
    ),
    [VehicleFieldValueHistoryChangeReason.VEHICLE_FIELDS_PRESET_REMOVAL]: t(
      "vehicleFieldHistory.historyChangeReason.vehicleFieldsPresetRemoval"
    ),
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Modal
      {...props}
      title={t("vehicleFieldHistory.title") + " " + props.vehicleFieldLabel}
      width={600}
      open
      okButtonProps={{ style: { display: "none" } }}
      cancelText={t("close")}
    >
      {isLoading ? (
        <Skeleton />
      ) : customVehicleFieldHistory.length ? (
        <>
          <Timeline>
            {customVehicleFieldHistory.map((history, index) => (
              <UserDateTimelineItem
                marginBottom={30}
                dot={
                  history.status !== "none" ? (
                    <StatusIcon status={history.status} />
                  ) : undefined
                }
                key={`${index}-${history.historyDate}`}
                user={history.historyUser}
                datetime={history.historyDate}
                datePrefix={prefixByChangeType[history.historyType]}
                description={
                  <div>
                    {history.historyChangeReason && (
                      <HistoryChangeReasonWrapper>
                        <Space size="small" style={{ alignItems: "start" }}>
                          <LoginOutlined />
                          <span
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {t("vehicleFieldHistory.changeReason")}:
                          </span>
                          {history.historyChangeReason ? (
                            <Typography.Text italic>
                              {textByChangeReason[history.historyChangeReason]}
                            </Typography.Text>
                          ) : (
                            "-"
                          )}
                        </Space>
                      </HistoryChangeReasonWrapper>
                    )}
                    {history.sourceResponse &&
                      history.historyChangeReason &&
                      [
                        VehicleFieldValueHistoryChangeReason.PROTOCOL_CUSTOM_FIELD_UPDATE,
                        VehicleFieldValueHistoryChangeReason.PROTOCOL_APPLY_RESPONSE_VALUE,
                      ].includes(history.historyChangeReason) && (
                        <HistoryChangeReasonWrapper>
                          <Space size="small" style={{ alignItems: "start" }}>
                            <FileDoneOutlined />
                            <span
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              {t("protocol")}:
                            </span>
                            <Link
                              to={urls.backOffice.protocol(
                                history.sourceResponse.protocolObject.id
                              )}
                              target="_blank"
                            >
                              {history.sourceResponse.protocolObject.label}
                            </Link>
                          </Space>
                        </HistoryChangeReasonWrapper>
                      )}
                  </div>
                }
              >
                <ResponseWrapper>
                  <CommonFieldProtocolResponse
                    {...{
                      fieldLabel: "",
                      type: props.vehicleFieldType,
                      isMultichoice: false,
                      value: history.valueFormatted,
                      note: history.note,
                      photos: history.photos,
                      choices: [
                        {
                          label: history.valueFormatted,
                          id: "",
                          wasSelected: true,
                        },
                      ],
                    }}
                  />
                  {!!history.chargedEmployeeObject && (
                    <ChargedEmployeeWrapper>
                      <ChargedEmployeeComponent
                        chargedEmployee={history.chargedEmployeeObject}
                        showStatusTag
                      />
                    </ChargedEmployeeWrapper>
                  )}
                </ResponseWrapper>
                <ActionsWrapper>
                  <ChargeEmployeeIconButton
                    onClick={() =>
                      setChargedEmployeeState({
                        historicalCustomVehicleFieldValueId: history.historyId,
                        responseId: history.sourceResponse?.id,
                        initialValues: history.chargedEmployeeObject
                          ? { ...history.chargedEmployeeObject }
                          : undefined,
                        chargedEmployeeId: history.chargedEmployeeObject
                          ? history.chargedEmployeeObject.id
                          : null,
                      })
                    }
                  />
                </ActionsWrapper>
              </UserDateTimelineItem>
            ))}
          </Timeline>
          {total > PAGE_SIZE && (
            <Pagination
              showSizeChanger={false}
              current={currentPage}
              total={total}
              pageSize={PAGE_SIZE}
              onChange={setCurrentPage}
            />
          )}
        </>
      ) : (
        <Empty />
      )}
      {chargedEmployeeModal}
    </Modal>
  );
}
