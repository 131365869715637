import { useTranslation } from "react-i18next";

import { MultiSelect, SelectProps } from "src/components";

import { SimpleVehicleGroup } from "../../models";
import { DividerCheckboxFilterItem } from "../DividerCheckboxFilterItem";
import { FilterItem } from "../ListView";

interface Props {
  options: SelectProps<string>["options"];
  isExcludeVehicleGroupsCheckboxVisible?: boolean;
}

export function VehicleGroupsFilterItem({
  isExcludeVehicleGroupsCheckboxVisible = true,
  ...props
}: Props) {
  const { t } = useTranslation("backoffice");

  return (
    <>
      <FilterItem name="vehicleGroups">
        <MultiSelect<SimpleVehicleGroup["id"]>
          style={{ width: 350 }}
          options={props.options}
          placeholder={t("vehiclesView.vehicleGroups")}
          noMargin
          dropdownRender={
            isExcludeVehicleGroupsCheckboxVisible
              ? (menu) => (
                  <DividerCheckboxFilterItem
                    checkboxLabel={t("excludeItemsWithSelectedGroups")}
                    filterItemName="excludeVehicleGroups"
                  >
                    {menu}
                  </DividerCheckboxFilterItem>
                )
              : undefined
          }
        />
      </FilterItem>
      {/* Hidden input to include the value in the form when the dropdown is closed and menu is not rendered */}
      <FilterItem hidden name="excludeVehicleGroups" />
    </>
  );
}
