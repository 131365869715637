import { Link, LinkProps } from "react-router-dom";

import { useAuthentication } from "src/authentication";
import { Permission } from "src/authentication/models";

export function RestrictedLink({
  requiredPermission,
  ...props
}: LinkProps & { requiredPermission: Permission }) {
  const { hasPerm } = useAuthentication();
  return hasPerm(requiredPermission) ? (
    <Link {...props} />
  ) : (
    <>{props.children}</>
  );
}
