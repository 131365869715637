import { RedoOutlined } from "@ant-design/icons";
import { ProtocolFillerLanguage, useCompany } from "@inspecto/common";
import { Alert, Button, Col, message, Row, Skeleton, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAuthentication } from "src/authentication";
import {
  getCompanyAvailableBackofficeLanguages,
  getCompanyAvailableProtocolFillerLanguages,
} from "src/company/models";
import { FlagImage } from "src/components";
import {
  getLanguageConfig,
  UserLanguage,
  UserProtocolFillerLanguage,
} from "src/locales";
import { urls } from "src/urls";

import { backOfficeApi } from "../../api";
import { useRestoreDeletedEmployee } from "../../hooks";
import { useUserRoleOptions } from "../../hooks/useUserRoleOptions";
import { RetrieveCreateUpdateEmployee } from "../../models";
import { BackOfficeLayout } from "../BackOfficeLayout";
import { EmployeeForm } from "./EmployeeForm";

type LanguageOption<TLanguage> = {
  label: JSX.Element;
  value: TLanguage;
};

function LabelWithFlag(props: {
  language: ProtocolFillerLanguage;
  text: string;
}): JSX.Element {
  return (
    <Space size="middle">
      <FlagImage
        alt={props.language}
        src={getLanguageConfig(props.language).countryFlag}
      />
      {props.text}
    </Space>
  );
}

export function EmployeeFormView() {
  const company = useCompany();
  const { user } = useAuthentication();
  const { t } = useTranslation("backoffice");
  const { t: tTranslation } = useTranslation("translation");

  const { employeeId } = useParams<{ employeeId?: string }>();
  const [existingEmployee, setExistingEmployee] =
    useState<RetrieveCreateUpdateEmployee | null>(null);

  const [isLoadingEmployee, setIsLoadingEmployee] = useState(true);
  const { userRoleOptions: creatableUserRoles, isLoadingUserRoles } =
    useUserRoleOptions();
  const [isResendingActivationEmail, setIsResendingActivationEmail] =
    useState(false);
  const { isRestoring, restore } = useRestoreDeletedEmployee();

  const fetchEmployee = useCallback(async () => {
    if (!employeeId) {
      setIsLoadingEmployee(false);
      return;
    }
    setIsLoadingEmployee(true);

    const employee = await backOfficeApi.getEmployee(employeeId);

    setExistingEmployee(employee);
    setIsLoadingEmployee(false);
  }, [employeeId]);

  useEffect(() => {
    fetchEmployee();
  }, [fetchEmployee]);

  const languageOptions: LanguageOption<UserLanguage>[] =
    languagesToSortedOptions(getCompanyAvailableBackofficeLanguages(company));
  const protocolFillerLanguageOptions: LanguageOption<UserProtocolFillerLanguage>[] =
    languagesToSortedOptions(
      getCompanyAvailableProtocolFillerLanguages(company)
    );
  languageOptions.push({
    label: (
      <LabelWithFlag
        language={company.language}
        text={`${tTranslation("languages.companyDefault")} (${tTranslation(
          `languages.${company.language}`
        )})`}
      />
    ),
    value: "",
  });
  protocolFillerLanguageOptions.push({
    label: (
      <LabelWithFlag
        language={company.protocolFillerLanguage}
        text={`${tTranslation(
          "languages.companyProtocolFillerDefault"
        )} (${tTranslation(`languages.${company.protocolFillerLanguage}`)})`}
      />
    ),
    value: "",
  });

  return (
    <BackOfficeLayout
      breadcrumbs={[
        { label: t("employees"), url: urls.backOffice.employees() },
      ]}
      pageTitle={!employeeId ? t("createEmployee") : t("editEmployee")}
    >
      <BackOfficeLayout.Content>
        {isLoadingEmployee || isLoadingUserRoles || !user ? (
          <Skeleton />
        ) : (
          <Row gutter={[0, 24]}>
            {!!existingEmployee ? (
              existingEmployee.isActive ? (
                !!existingEmployee.email &&
                !existingEmployee.hasPassword && (
                  <Col xs={24}>
                    <Alert
                      message={t(
                        "employeeFormView.userForActivationAlert.userIsNotActivatedTitle"
                      )}
                      description={t(
                        "employeeFormView.userForActivationAlert.userHasToClickActivationLinkDescription"
                      )}
                      type="info"
                      action={
                        <Button
                          size="small"
                          type="primary"
                          loading={isResendingActivationEmail}
                          onClick={() =>
                            resendEmployeeActivationEmail(existingEmployee.id)
                          }
                        >
                          {t(
                            "employeeFormView.userForActivationAlert.resendActivationLinkButton"
                          )}
                        </Button>
                      }
                    />
                  </Col>
                )
              ) : (
                <Col xs={24}>
                  <Alert
                    message={t("employeeFormView.employeeIsDeleted")}
                    type="warning"
                    action={
                      <Button
                        size="small"
                        icon={<RedoOutlined />}
                        loading={isRestoring}
                        onClick={async () => {
                          if (existingEmployee) {
                            await restore(existingEmployee.id);
                            fetchEmployee();
                          }
                        }}
                      >
                        {t("employeeFormView.restoreDeletedUser")}
                      </Button>
                    }
                  />
                </Col>
              )
            ) : null}
            <Col xs={24}>
              <EmployeeForm
                currentEmployeeValue={existingEmployee}
                languageOptions={languageOptions}
                protocolFillerLanguageOptions={protocolFillerLanguageOptions}
                userRoleOptions={creatableUserRoles}
                availableCompanyDepartments={
                  company.allowManagingCompanyDepartments
                    ? user.allowedCompanyDepartments.map(
                        (companyDepartment) => ({
                          label: companyDepartment.name,
                          value: companyDepartment.id,
                        })
                      )
                    : []
                }
              />
            </Col>
          </Row>
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );

  async function resendEmployeeActivationEmail(
    employeeId: string
  ): Promise<void> {
    try {
      setIsResendingActivationEmail(true);
      await backOfficeApi.resendEmployeeActivationEmail(employeeId);
      message.success(t("employeeFormView.activationEmailSuccessfullyResent"));
    } catch (e) {
      message.error(t("somethingWentWrong"));
    }
    setIsResendingActivationEmail(false);
  }

  function languagesToSortedOptions<TLanguage extends ProtocolFillerLanguage>(
    languages: readonly TLanguage[]
  ): LanguageOption<TLanguage>[] {
    return languages
      .map((language) => ({
        language,
        translatedLanguage: tTranslation(`languages.${language}`),
      }))
      .sort((a, b) => (a.translatedLanguage < b.translatedLanguage ? -1 : 1))
      .map(({ language, translatedLanguage }) => ({
        label: <LabelWithFlag language={language} text={translatedLanguage} />,
        value: language,
      }));
  }
}
