import { PlusOutlined, SmallDashOutlined } from "@ant-design/icons";
import { useAllPaginatedElements } from "@inspecto/common";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  message,
  Row,
  Skeleton,
  Space,
} from "antd";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { isFieldTypeSupportsCondition } from "src/company";
import {
  CommonForm,
  DarkBgListContainer,
  Icon,
  RemoveButton,
} from "src/components";
import { myTheme } from "src/theme";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import {
  ConditionBuilder,
  CustomVehicleFieldSelectorModal,
  FormItemValue,
  NotNoneVehicleFieldStatusSelect,
  QuestionTypeWithAvatar,
  TextResponseWrapper,
} from "../../../components";
import { useCustomVehicleFieldConditions } from "../../../hooks";
import {
  CreateUpdateVehicleFieldChoiceOverwrites,
  CreateUpdateVehicleFieldsPreset,
  CreateUpdateVehicleFieldsPresetOverwrite,
  RetrieveVehicleFieldsPreset,
} from "../../../models";
import { StickySaveButtons } from "../../vehicles/components/StickySaveButtons";

const EmptyChoiceOverwritePlaceholder = styled.div`
  border: 1px dashed ${myTheme.colors.whitePanelBorderColor};
  color: ${myTheme.colors.whitePanelBorderColor};
  font-size: 24px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function LabelledNotNoneVehicleFieldStatusSelect<TValue>(props: {
  id: string;
  disabled?: boolean;
  suffixElement: JSX.Element | null;
  value?: TValue;
  onChange?: (value: TValue) => void;
}) {
  const { t } = useTranslation("backoffice");

  return (
    <FormItemValue value={props.value} onChange={props.onChange}>
      {({ value, onChange }) => (
        <Space>
          <label htmlFor={props.id}>
            {`${t("settings.customVehicleFields.choiceSetsStatus")}:`}
          </label>
          <NotNoneVehicleFieldStatusSelect
            disabled={props.disabled}
            id={props.id}
            onChange={onChange}
            value={value}
          />
          {props.suffixElement}
        </Space>
      )}
    </FormItemValue>
  );
}

const defaultEmptyItem: CreateUpdateVehicleFieldsPresetOverwrite = {
  customVehicleField: null,
  vehicleFieldConditionOverwrite: null,
  vehicleFieldChoiceOverwrites: [],
};

interface Props {
  initialValues?: CreateUpdateVehicleFieldsPreset;
  onSave(record: CreateUpdateVehicleFieldsPreset): Promise<{ id: string }>;
  afterSuccessfulSaveWithoutRedirect: (savedRecord: { id: string }) => void;
}

export function VehicleFieldsPresetForm(props: Props) {
  const history = useHistory();
  const { t } = useTranslation("backoffice");
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { isLoadingConditions, conditions } =
    useCustomVehicleFieldConditions(t);
  const dataGetter = useCallback(
    (pageNumber: number) =>
      backOfficeApi.getCustomVehicleFields({ tableParams: { pageNumber } }),
    []
  );
  const [_customVehicleFields, isLoadingCustomVehicleFields] =
    useAllPaginatedElements(dataGetter);
  const customVehicleFields = useMemo(
    () =>
      _customVehicleFields.filter(
        (field) =>
          isFieldTypeSupportsCondition(field.type) || field.type === "choice"
      ),
    [_customVehicleFields]
  );

  if (isLoadingCustomVehicleFields || isLoadingConditions) {
    return <Skeleton />;
  }

  const previousConditionBadge = (
    <Badge
      count={t("settings.vehicleFieldsPresets.previousCondition")}
      style={{
        fontWeight: "600",
      }}
    />
  );

  const newConditionBadge = (
    <Badge
      count={t("settings.vehicleFieldsPresets.newCondition")}
      style={{
        backgroundColor: "#52c41a",
        fontWeight: "600",
      }}
    />
  );

  return (
    <CommonForm<CreateUpdateVehicleFieldsPreset, { id: string }>
      saveCallback={props.onSave}
      initialValues={props.initialValues}
      onIsSavingChange={setIsSaving}
      form={form}
      onSuccessfulSave={(
        formValues,
        savedRecord: RetrieveVehicleFieldsPreset,
        shouldRedirect: boolean | undefined
      ) => {
        if (shouldRedirect) {
          history.replace(urls.backOffice.settings.vehicleFieldsPresets());
        } else {
          props.afterSuccessfulSaveWithoutRedirect(savedRecord);
        }
        message.success(t("settings.vehicleFieldsPresets.savedSuccessfully"));
      }}
    >
      <Form.Item
        name="label"
        label={t("settings.vehicleFieldsPresets.fields.label")}
        rules={[{ required: true }, { max: 64 }]}
      >
        <Input style={{ maxWidth: 400 }} />
      </Form.Item>
      <Form.Item
        required
        label={t("settings.vehicleFieldsPresets.fields.overwrites")}
      >
        <Form.List
          name="vehicleFieldIsOkOverwrites"
          rules={[
            {
              validator: (rule, value) => {
                if (!value || value.length < 1) {
                  return Promise.reject(
                    t(
                      "settings.vehicleFieldsPresets.youNeedAtLeastOneOverwrite"
                    )
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {(
            vehicleFieldIsOkOverwritesFields,
            {
              add: addVehicleFieldIsOkOverwrite,
              remove: removeVehicleFieldIsOkOverwrite,
            },
            { errors: vehicleFieldIsOkOverwritesErrors }
          ) => (
            <div
              style={{
                maxWidth: 1100,
              }}
            >
              <DarkBgListContainer>
                {vehicleFieldIsOkOverwritesFields.length
                  ? vehicleFieldIsOkOverwritesFields.map(
                      (
                        vehicleFieldIsOkOverwritesField,
                        vehicleFieldIsOkOverwritesFieldIndex
                      ) => (
                        <Form.Item
                          name={vehicleFieldIsOkOverwritesField.name}
                          key={vehicleFieldIsOkOverwritesField.key}
                        >
                          <FormItemValue<CreateUpdateVehicleFieldsPresetOverwrite>>
                            {({ value: overwrite }) => {
                              if (typeof overwrite === "undefined") {
                                // this should never happen
                                return <></>;
                              }

                              const assignedCustomVehicleField =
                                customVehicleFields.find(
                                  (field) =>
                                    field.id === overwrite.customVehicleField
                                );

                              return assignedCustomVehicleField ? (
                                <Card
                                  size="small"
                                  title={
                                    <QuestionTypeWithAvatar
                                      questionType={
                                        assignedCustomVehicleField.type
                                      }
                                      label={assignedCustomVehicleField.label}
                                    />
                                  }
                                  extra={
                                    <RemoveButton
                                      onRemoveConfirm={async () =>
                                        removeVehicleFieldIsOkOverwrite(
                                          vehicleFieldIsOkOverwritesFieldIndex
                                        )
                                      }
                                    />
                                  }
                                >
                                  {isFieldTypeSupportsCondition(
                                    assignedCustomVehicleField.type
                                  ) ? (
                                    <Row gutter={[24, 24]}>
                                      <Col xs={11}>
                                        <Form.Item
                                          label={
                                            <Space>
                                              {t("conditions.valueIsOkWhen")}
                                              {previousConditionBadge}
                                            </Space>
                                          }
                                          style={{ marginBottom: 0 }}
                                        >
                                          <ConditionBuilder
                                            customVehicleFieldId={
                                              assignedCustomVehicleField.id
                                            }
                                            disabled
                                            value={
                                              assignedCustomVehicleField.condition
                                            }
                                            fieldType={
                                              assignedCustomVehicleField.type
                                            }
                                            conditions={conditions}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col
                                        xs={2}
                                        style={{
                                          textAlign: "center",
                                          marginTop: 62,
                                        }}
                                      >
                                        <Icon
                                          icon="arrowRight"
                                          size={24}
                                          color="primary"
                                        />
                                      </Col>
                                      <Col xs={11}>
                                        <Form.Item
                                          name={[
                                            vehicleFieldIsOkOverwritesField.name,
                                            "vehicleFieldConditionOverwrite",
                                          ]}
                                          label={
                                            <Space>
                                              {t("conditions.valueIsOkWhen")}
                                              {newConditionBadge}
                                            </Space>
                                          }
                                          required
                                          style={{ marginBottom: 0 }}
                                        >
                                          <ConditionBuilder
                                            customVehicleFieldId={
                                              assignedCustomVehicleField.id
                                            }
                                            fieldType={
                                              assignedCustomVehicleField.type
                                            }
                                            conditions={conditions}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  ) : (
                                    <List>
                                      <Form.List
                                        name={[
                                          vehicleFieldIsOkOverwritesField.name,
                                          "vehicleFieldChoiceOverwrites",
                                        ]}
                                      >
                                        {(
                                          vehicleFieldChoiceOverwritesFields,
                                          {
                                            add: addVehicleFieldChoiceOverwrite,
                                            remove:
                                              removeVehicleFieldChoiceOverwrite,
                                          },
                                          {
                                            errors:
                                              vehicleFieldChoiceOverwritesErrors,
                                          }
                                        ) => (
                                          <>
                                            {assignedCustomVehicleField.choices
                                              .map((choice) => {
                                                const vehicleFieldChoiceOverwrites: CreateUpdateVehicleFieldChoiceOverwrites[] =
                                                  form.getFieldValue([
                                                    "vehicleFieldIsOkOverwrites",
                                                    vehicleFieldIsOkOverwritesFieldIndex,
                                                    "vehicleFieldChoiceOverwrites",
                                                  ]) || [];

                                                const matchedVehicleFieldChoiceOverwrite =
                                                  vehicleFieldChoiceOverwrites.find(
                                                    (
                                                      vehicleFieldChoiceOverwritesField
                                                    ) =>
                                                      vehicleFieldChoiceOverwritesField.customVehicleFieldChoice ===
                                                      choice.id
                                                  );

                                                return {
                                                  ...choice,
                                                  formItemName:
                                                    matchedVehicleFieldChoiceOverwrite
                                                      ? vehicleFieldChoiceOverwrites.indexOf(
                                                          matchedVehicleFieldChoiceOverwrite
                                                        )
                                                      : null,
                                                };
                                              })
                                              .map(
                                                (
                                                  assignedCustomVehicleFieldChoice
                                                ) => (
                                                  <List.Item
                                                    style={{
                                                      display: "block",
                                                    }}
                                                    key={
                                                      assignedCustomVehicleFieldChoice.id
                                                    }
                                                  >
                                                    <Row>
                                                      <Col xs={10}>
                                                        <TextResponseWrapper>
                                                          {
                                                            assignedCustomVehicleFieldChoice.label
                                                          }
                                                        </TextResponseWrapper>
                                                        <Form.Item
                                                          style={{
                                                            marginBottom: 0,
                                                          }}
                                                        >
                                                          <LabelledNotNoneVehicleFieldStatusSelect
                                                            disabled
                                                            value={
                                                              assignedCustomVehicleFieldChoice.setsStatus
                                                            }
                                                            id={`previous-sets-status-${assignedCustomVehicleFieldChoice.id}`}
                                                            suffixElement={
                                                              assignedCustomVehicleFieldChoice.formItemName !==
                                                              null
                                                                ? previousConditionBadge
                                                                : null
                                                            }
                                                          />
                                                        </Form.Item>
                                                      </Col>
                                                      <Col
                                                        xs={4}
                                                        style={{
                                                          textAlign: "center",
                                                          marginTop: 10,
                                                        }}
                                                      >
                                                        <Icon
                                                          icon="arrowRight"
                                                          size={24}
                                                          color={
                                                            assignedCustomVehicleFieldChoice.formItemName !==
                                                            null
                                                              ? "primary"
                                                              : "gray"
                                                          }
                                                          style={{
                                                            marginBottom: 10,
                                                          }}
                                                        />
                                                        <Checkbox
                                                          checked={
                                                            assignedCustomVehicleFieldChoice.formItemName !==
                                                            null
                                                          }
                                                          onChange={() => {
                                                            if (
                                                              assignedCustomVehicleFieldChoice.formItemName !==
                                                              null
                                                            ) {
                                                              removeVehicleFieldChoiceOverwrite(
                                                                assignedCustomVehicleFieldChoice.formItemName
                                                              );
                                                            } else {
                                                              addVehicleFieldChoiceOverwrite(
                                                                {
                                                                  customVehicleFieldChoice:
                                                                    assignedCustomVehicleFieldChoice.id,
                                                                  setsStatus:
                                                                    assignedCustomVehicleFieldChoice.setsStatus ===
                                                                    "ok"
                                                                      ? "not_ok"
                                                                      : assignedCustomVehicleFieldChoice.setsStatus ===
                                                                        "not_ok"
                                                                      ? "ok"
                                                                      : "warning",
                                                                }
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {t(
                                                            "settings.vehicleFieldsPresets.changeChoiceExpectedAnswer"
                                                          )}
                                                        </Checkbox>
                                                      </Col>
                                                      <Col xs={10}>
                                                        {assignedCustomVehicleFieldChoice.formItemName !==
                                                        null ? (
                                                          <>
                                                            <TextResponseWrapper>
                                                              {
                                                                assignedCustomVehicleFieldChoice.label
                                                              }
                                                            </TextResponseWrapper>
                                                            <Form.Item
                                                              style={{
                                                                marginBottom: 0,
                                                              }}
                                                              name={[
                                                                assignedCustomVehicleFieldChoice.formItemName,
                                                                "setsStatus",
                                                              ]}
                                                            >
                                                              <LabelledNotNoneVehicleFieldStatusSelect
                                                                id={`new-sets-status-${assignedCustomVehicleFieldChoice.id}`}
                                                                suffixElement={
                                                                  newConditionBadge
                                                                }
                                                              />
                                                            </Form.Item>
                                                          </>
                                                        ) : (
                                                          <EmptyChoiceOverwritePlaceholder>
                                                            <SmallDashOutlined />
                                                          </EmptyChoiceOverwritePlaceholder>
                                                        )}
                                                      </Col>
                                                    </Row>
                                                  </List.Item>
                                                )
                                              )}
                                            <Form.ErrorList
                                              errors={
                                                vehicleFieldChoiceOverwritesErrors
                                              }
                                            />
                                          </>
                                        )}
                                      </Form.List>
                                    </List>
                                  )}
                                </Card>
                              ) : (
                                <Form.Item
                                  name={[
                                    vehicleFieldIsOkOverwritesField.name,
                                    "customVehicleField",
                                  ]}
                                >
                                  <CustomVehicleFieldSelectorModal
                                    onCancel={() =>
                                      removeVehicleFieldIsOkOverwrite(
                                        vehicleFieldIsOkOverwritesFieldIndex
                                      )
                                    }
                                    customVehicleFields={customVehicleFields}
                                    excludeCustomVehicleFieldIds={(
                                      (form.getFieldValue(
                                        "vehicleFieldIsOkOverwrites"
                                      ) ||
                                        []) as CreateUpdateVehicleFieldsPresetOverwrite[]
                                    )
                                      .map(
                                        (overwrite) =>
                                          overwrite.customVehicleField
                                      )
                                      .filter(
                                        (
                                          idOrNull: string | null
                                        ): idOrNull is string =>
                                          idOrNull !== null
                                      )}
                                  />
                                </Form.Item>
                              );
                            }}
                          </FormItemValue>
                        </Form.Item>
                      )
                    )
                  : null}
                <Form.ErrorList errors={vehicleFieldIsOkOverwritesErrors} />
                <Button
                  type="dashed"
                  onClick={() => addVehicleFieldIsOkOverwrite(defaultEmptyItem)}
                  block
                  icon={<PlusOutlined />}
                >
                  {t("settings.vehicleFieldsPresets.addVehicleField")}
                </Button>
              </DarkBgListContainer>
            </div>
          )}
        </Form.List>
      </Form.Item>
      <StickySaveButtons isSaving={isSaving} form={form} />
    </CommonForm>
  );
}
