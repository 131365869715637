import styled from "styled-components";

const AsteriskWrapper = styled.span`
  color: ${(props) => props.theme.colors.requiredFieldAsterisk};
  padding-right: 6px;
`;

export function Asterisk(): JSX.Element {
  return <AsteriskWrapper>*</AsteriskWrapper>;
}
