import { Avatar, Button, Space, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DamagesCustomVehicleFieldType } from "src/company";
import { Icon } from "src/components";

import { getIconName, Question } from "../models";

interface Props {
  isGrayedOut?: boolean;
  questionType: Question["questionType"] | DamagesCustomVehicleFieldType;
  label: string;
  onEditClick?: () => void;
  onCloneClick?: () => void;
  choices?: string[];
  vehicleTypes?: { label: string; value: string }[];
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftInner = styled.div`
  display: flex;
  align-items: center;
`;

const RightInner = styled(Space)`
  padding-left: 10px;
`;

const AvatarWrapper = styled.div`
  align-self: flex-start;
`;

const ChoicesWrapper = styled.div`
  margin-top: 4px;
  font-size: 0.8rem;
`;

const VehicleTypesWrapper = styled.div`
  margin-top: 4px;
`;

const ColoredAvatar = styled(Avatar)`
  color: ${(props) => props.theme.colors.text.dark};
  background-color: ${(props) => props.theme.colors.text.light};
  margin-right: 10px;
`;

export function SimpleAvatarFieldType(props: {
  questionType: Props["questionType"];
}) {
  return (
    <ColoredAvatar icon={<Icon icon={getIconName(props.questionType)} />} />
  );
}

export function AvatarFieldType({
  choices = [],
  vehicleTypes = [],
  isGrayedOut = false,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation("backoffice");

  return (
    <Wrapper
      style={{
        opacity: isGrayedOut ? 0.5 : 1,
      }}
    >
      <LeftInner>
        <AvatarWrapper>
          <SimpleAvatarFieldType questionType={props.questionType} />
        </AvatarWrapper>
        <div>
          <Typography.Text strong style={{ color: "inherit" }}>
            {props.label}
          </Typography.Text>
          {!!choices.length && (
            <ChoicesWrapper>
              {t("draggableChoicesList.label")}: {choices.join(", ")}
            </ChoicesWrapper>
          )}
          {!!vehicleTypes.length && (
            <VehicleTypesWrapper>
              {vehicleTypes.map((vehicleType) => (
                <Tag key={vehicleType.value}>{vehicleType.label}</Tag>
              ))}
            </VehicleTypesWrapper>
          )}
        </div>
      </LeftInner>
      {(props.onCloneClick || props.onEditClick) && (
        <RightInner>
          {props.onCloneClick && (
            <Button
              size="small"
              onClick={props.onCloneClick}
              title={`${t("builder.customVehicleFieldSelectorModal.clone")} - ${
                props.label
              }`}
            >
              {t("builder.customVehicleFieldSelectorModal.clone")}
            </Button>
          )}
          {props.onEditClick && (
            <Button
              size="small"
              onClick={props.onEditClick}
              title={`${t("builder.customVehicleFieldSelectorModal.edit")} - ${
                props.label
              }`}
            >
              {t("builder.customVehicleFieldSelectorModal.edit")}
            </Button>
          )}
        </RightInner>
      )}
    </Wrapper>
  );
}
