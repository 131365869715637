import { useCompany } from "@inspecto/common";
import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useAuthentication } from "src/authentication";

import { ListVehicleFieldsPreset, VehicleGroup } from "../../../models";

export interface StandardVehicleFormItemsProps {
  vehicleGroupsOptions: { label: string; value: VehicleGroup["id"] }[];
  vehicleFieldsPresetOptions: {
    label: string;
    value: ListVehicleFieldsPreset["id"];
  }[];
}

export function StandardVehicleFormItems(
  props: StandardVehicleFormItemsProps
): JSX.Element {
  const { t } = useTranslation("backoffice");
  const company = useCompany();
  const { user } = useAuthentication();

  return (
    <>
      <Form.Item
        label={t("plateNumber")}
        name="plateNumber"
        rules={[
          { required: true },
          {
            max: 128,
          },
        ]}
      >
        <Input style={{ maxWidth: 240 }} />
      </Form.Item>
      {company.allowManagingCompanyDepartments && user && (
        <Form.Item
          label={t("settings.companyDepartments.singleItemLabel")}
          name="companyDepartment"
          rules={[{ required: true }]}
        >
          <Select
            options={user.allowedCompanyDepartments.map(
              (companyDepartment) => ({
                label: companyDepartment.name,
                value: companyDepartment.id,
              })
            )}
            style={{ maxWidth: 280 }}
          />
        </Form.Item>
      )}

      <Form.Item label={t("vehicleGroups")} name="vehicleGroups">
        <Select
          options={props.vehicleGroupsOptions}
          mode="multiple"
          showArrow
          style={{ maxWidth: 500 }}
        />
      </Form.Item>
      <Form.Item
        label={t("vehicleView.vehicleFieldsPreset")}
        name="vehicleFieldsPreset"
      >
        <Select
          showArrow
          allowClear
          options={props.vehicleFieldsPresetOptions}
          style={{ maxWidth: 300 }}
        />
      </Form.Item>
    </>
  );
}
