import { api, Api } from "src/utils";

import { CommonViewState } from "../contexts";
import { FleetStateCustomVehicleFieldValue } from "../models";

export class FleetStateApi {
  constructor(private api: Api) {}

  async getFleetStateCustomVehicleFieldValues(
    customVehicleFieldId: string,
    fleetStateCommonView: CommonViewState["fleetStateCustomVehicleFieldValuesListView"]
  ) {
    return this.api.getPaginatedList<FleetStateCustomVehicleFieldValue[]>(
      `/backoffice/custom-vehicle-fields/${customVehicleFieldId}/values/`,
      fleetStateCommonView.tableParams,
      {
        status__in: fleetStateCommonView.statusFilters.join(","),
        vehicle_type__in: fleetStateCommonView.vehicleTypeFilters.join(","),
        vehicle_groups__in: fleetStateCommonView.vehicleGroupFilters.join(","),
        plate_number: fleetStateCommonView.plateNumber,
        ...(fleetStateCommonView.tableParams.sortByField
          ? { order_by: fleetStateCommonView.tableParams.sortByField }
          : {}),
      }
    );
  }
}

export const fleetStateApi = new FleetStateApi(api);
