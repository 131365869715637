import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  mb?: number;
  mt?: number;
  pb?: number;
  pt?: number;
}

export function Box(props: Props): JSX.Element {
  const Wrapper = styled.div({
    ...(props.mb ? { marginBottom: props.mb } : {}),
    ...(props.mt ? { marginTop: props.mt } : {}),
    ...(props.pb ? { paddingBottom: props.pb } : {}),
    ...(props.pt ? { paddingTop: props.pt } : {}),
  });
  return <Wrapper>{props.children}</Wrapper>;
}
