import { Col, Divider, Grid, Row } from "antd";
import { ReactNode } from "react";

interface ResponseRowProps {
  leftContent: ReactNode;
  rightContent: ReactNode;
}

export function ProtocolResponseRow(props: ResponseRowProps): JSX.Element {
  const breakpoint = Grid.useBreakpoint();
  const isSingleColumn = !breakpoint.md;
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12} xl={9} xxl={8}>
          {props.leftContent}
        </Col>
        <Col
          xs={24}
          md={12}
          xxl={10}
          style={isSingleColumn ? {} : { paddingTop: 28 }}
        >
          {props.rightContent}
        </Col>
      </Row>
      <Divider />
    </>
  );
}
