import { RadioGroupOfTypeButton } from "src/components";

import { useScrollToElement } from "../hooks";
import { ListCustomVehicleField } from "../models";
import { CustomAvatarFieldType } from "./CustomAvatarFieldType";

export interface CustomVehicleFieldRadioButtonsSelectorProps {
  value?: string;
  onChange?: (value: string | null) => void;
  customVehicleFields: ListCustomVehicleField[];
  onEditCustomVehicleFieldClick?(id: string): void;
  onCloneCustomVehicleFieldClick?(id: string): void;
}

export function CustomVehicleFieldRadioButtonsSelector(
  props: CustomVehicleFieldRadioButtonsSelectorProps
): JSX.Element {
  const [containerRef, customVehicleFieldRefs] = useScrollToElement({
    value: props.value,
    items: props.customVehicleFields,
  });

  return (
    <div ref={containerRef}>
      <RadioGroupOfTypeButton
        value={props.value}
        onChange={props.onChange}
        height={465}
        mode="vertical"
        total={props.customVehicleFields.length}
        items={props.customVehicleFields.map((customVehicleField) => ({
          label: (
            <div ref={customVehicleFieldRefs[customVehicleField.id]}>
              <CustomAvatarFieldType
                customVehicleField={customVehicleField}
                onEditCustomVehicleFieldClick={
                  props.onEditCustomVehicleFieldClick
                }
                onCloneCustomVehicleFieldClick={
                  props.onCloneCustomVehicleFieldClick
                }
              />
            </div>
          ),
          value: customVehicleField.id,
        }))}
      />
    </div>
  );
}
