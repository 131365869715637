import {
  allBackofficeLanguages,
  BackofficeLanguage,
  ProtocolFillerLanguage,
  Company,
} from "@inspecto/common";

import { Field, FieldType, fieldTypes } from "src/components";
import { Damage } from "src/protocolFiller/models";

export function getCompanyAvailableProtocolFillerLanguages(
  company: Company
): readonly ProtocolFillerLanguage[] {
  return company.availableLanguages;
}

function isBackofficeLanguage(
  language: ProtocolFillerLanguage
): language is BackofficeLanguage {
  return (allBackofficeLanguages as readonly ProtocolFillerLanguage[]).includes(
    language
  ); // Do you know how to fix it without using "as"?
}

export function getCompanyAvailableBackofficeLanguages(
  company: Company
): BackofficeLanguage[] {
  return company.availableLanguages.filter(isBackofficeLanguage);
}

export enum DamagesRequiredFields {
  PHOTO = "photo",
  LABEL = "label",
  PHOTO_AND_LABEL = "photo_and_label",
}

export type DamagesCustomVehicleFieldType = "damages";

export type StandardCustomVehicleFieldType = FieldType;
export type CustomVehicleFieldType =
  | StandardCustomVehicleFieldType
  | DamagesCustomVehicleFieldType;

export function isFieldTypeSupportsCondition(
  fieldType: CustomVehicleFieldType
): boolean {
  return !["photo-action", "damages", "choice"].includes(fieldType);
}

export interface DamagesField {
  type: DamagesCustomVehicleFieldType;
  damagesRequiredFields: DamagesRequiredFields;
  damages: Damage[];
}

export type CustomVehicleField = Field | DamagesField;

export const standardCustomFieldTypes: StandardCustomVehicleFieldType[] = [
  ...fieldTypes,
];

export const damagesCustomFieldType: DamagesCustomVehicleFieldType = "damages";

export const customFieldTypes: CustomVehicleFieldType[] = [
  ...standardCustomFieldTypes,
  damagesCustomFieldType,
];

export const customFieldTypesWithoutFleetStateSupport: CustomVehicleFieldType[] =
  ["photo-action"];

export const customFieldTypesForFleetState = customFieldTypes.filter(
  (fieldType) => !customFieldTypesWithoutFleetStateSupport.includes(fieldType)
);
