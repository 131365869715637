import { Button, Result } from "antd";
import React from "react";
import { TFunction } from "react-i18next";

import { PageLayout } from "./PageLayout";

interface Props {
  onShow: () => void;
  onReloadClick: () => void;
  t: TFunction<"protocolFiller">;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  componentDidMount() {
    this.props.onShow();
  }

  static getDerivedStateFromError(e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageLayout>
          <Result
            status="warning"
            title={this.props.t("errorScreen.somethingWentWrongTitle")}
            subTitle={this.props.t("errorScreen.tryReloadingPageSubTitle")}
            extra={
              <Button type="primary" onClick={this.props.onReloadClick}>
                {this.props.t("errorScreen.reloadPageButton")}
              </Button>
            }
          />
        </PageLayout>
      );
    }

    return this.props.children;
  }
}
