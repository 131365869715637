import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import React from "react";
import { CSSProperties } from "styled-components";

import { myTheme } from "src/theme";

import { CustomVehicleFieldValueStatus } from "../models";

export function StatusIcon({
  iconSize = 16,
  ...props
}: {
  status: CustomVehicleFieldValueStatus;
  className?: string;
  iconSize?: 16 | 28;
  block?: boolean;
}): JSX.Element {
  const statusIconStyle: CSSProperties = {
    fontSize: iconSize,
    height: iconSize,
    ...(props.block ? { display: "block" } : {}),
  };
  const commonProps = {
    className: props.className,
    style: statusIconStyle,
  };
  switch (props.status) {
    case "ok":
      return (
        <CheckCircleTwoTone
          {...commonProps}
          twoToneColor={myTheme.colors.status.ok}
        />
      );
    case "not_ok":
      return (
        <CloseCircleTwoTone
          {...commonProps}
          twoToneColor={myTheme.colors.status.notOk}
        />
      );
    case "warning":
      return (
        <ExclamationCircleTwoTone {...commonProps} twoToneColor="orange" />
      );
    case "none":
      return <></>;
  }
}
