export function base64StringToFile(base64String: string, fileName: string) {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const chunk = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(chunk.length);
    for (let i = 0; i < chunk.length; i++) {
      byteNumbers[i] = chunk.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: "image/jpeg" });
  return new File([blob], fileName, { type: "image/jpeg" });
}
