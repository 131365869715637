import { useTranslation } from "react-i18next";

import { backOfficeApi } from "../../api";
import { useProtocolTypes } from "../../hooks";
import {
  DatePeriodFormState,
  formatDatePeriod,
  getDatePeriodFormInitialValues,
} from "./common";
import { DatePeriodFormItem, ReportFormLayout } from "./components";
import { ExpandableProtocolTypesSelectorFormItem } from "./components/ExpandableProtocolTypesSelectorFormItem";

interface NumberOfProtocolsPerUserReportFormState extends DatePeriodFormState {
  protocolTypes: string[];
}

export function NumberOfProtocolsPerUserReportView() {
  const { t } = useTranslation("backoffice");
  const { protocolTypes, protocolTypeOptions, isLoadingProtocolTypes } =
    useProtocolTypes();

  return (
    <ReportFormLayout<NumberOfProtocolsPerUserReportFormState>
      reportTitle={t("reportsView.reports.numberOfProtocolsPerUser")}
      startDownloading={async (data) =>
        backOfficeApi.reports.startDownloadProtocolsPerUser({
          ...formatDatePeriod(data),
          protocolTypes:
            data.protocolTypes.length === protocolTypes.length
              ? []
              : data.protocolTypes,
        })
      }
      formInitialValues={
        isLoadingProtocolTypes
          ? "loading"
          : {
              protocolTypes,
              ...getDatePeriodFormInitialValues(),
            }
      }
    >
      <DatePeriodFormItem />
      <ExpandableProtocolTypesSelectorFormItem
        protocolTypeOptions={protocolTypeOptions}
        isLoadingProtocolTypes={isLoadingProtocolTypes}
      />
    </ReportFormLayout>
  );
}
