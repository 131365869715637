import produce, { castDraft } from "immer";
import { useCallback, useState } from "react";

export function useToggleArrayById<T extends { id: string }>(
  list?: T[]
): readonly [T[], (element: T) => void, () => void, (elements: T[]) => void] {
  const [state, setState] = useState<T[]>(list || []);

  const toggle = useCallback((toggledElement: T) => {
    setState((state) =>
      produce(state, (draft) => {
        const isElementInArray = draft.findIndex(
          (el) => el.id === toggledElement.id
        );

        if (isElementInArray === -1) {
          draft.push(castDraft(toggledElement));
        } else {
          draft.splice(isElementInArray, 1);
        }
      })
    );
  }, []);

  const clearAll = useCallback(() => {
    setState([]);
  }, []);

  return [state, toggle, clearAll, setState] as const;
}
