import { Alert } from "antd";
import QRScannerLibrary from "qr-scanner";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const VideoWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: #dcdcdc;
  position: relative;
`;

const AlertWrapper = styled.div`
  padding: 10px;
`;

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export function QRScanner({
  onSuccessfulScan,
}: {
  onSuccessfulScan: (scanned: string) => void;
}) {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isCameraNotFound, setIsCameraNotFound] = useState(false);
  const [isCameraAccessDenied, setIsCameraAccessDenied] = useState(false);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const qrScanner = new QRScannerLibrary(
      videoRef.current,
      (result) => {
        if ("data" in result) {
          onSuccessfulScan(result["data"]);
        }
      },
      {
        highlightCodeOutline: true,
        highlightScanRegion: true,
        onDecodeError: (error) => {
          if (
            typeof error === "string" &&
            error.includes(QRScannerLibrary.NO_QR_CODE_FOUND)
          ) {
            return;
          }
          throw error;
        },
      }
    );

    qrScanner.start().catch((e) => {
      if (typeof e === "string" && e.includes("Camera not found")) {
        setIsCameraNotFound(true);
      }
    });
    return () => {
      qrScanner.destroy();
      setIsCameraNotFound(false);
    };
  }, [videoRef, onSuccessfulScan]);

  useEffect(() => {
    if (navigator && navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        // @ts-ignore TS2322: Type '"camera"' is not assignable to type 'PermissionName'.
        .query({ name: "camera" })
        .then(({ state }) => {
          if (state === "denied") {
            setIsCameraAccessDenied(true);
          }
        })
        .catch(() => {
          setIsCameraAccessDenied(true);
        });
    }
  }, []);

  return (
    <VideoWrapper>
      {isCameraAccessDenied ? (
        <AlertWrapper>
          <Alert
            message={t("qrScanner.cameraBlockedAlert.title")}
            description={t("qrScanner.cameraBlockedAlert.description")}
            type="error"
            showIcon
          />
        </AlertWrapper>
      ) : isCameraNotFound ? (
        <AlertWrapper>
          <Alert
            message={t("qrScanner.cameraNotFoundAlert.title")}
            description={t("qrScanner.cameraNotFoundAlert.description")}
            type="error"
            showIcon
          />
        </AlertWrapper>
      ) : null}
      <StyledVideo ref={videoRef} />
    </VideoWrapper>
  );
}
