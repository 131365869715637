import { ArrowRightOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Space,
  TableColumnType,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useTheme } from "styled-components";

import { MultiSelect } from "src/components";
import { urls } from "src/urls";
import { DATE_TIME_FORMAT, TIME_FORMAT } from "src/utils";

import { backOfficeApi } from "../../api";
import {
  CustomVehicleFieldStatusBadge,
  FilterItem,
  FiltersRow,
} from "../../components";
import { CommonViewState } from "../../contexts";
import { useFetch, useVehicleGroups, useVehicleTypes } from "../../hooks";
import { FleetStateCustomVehicleFieldValue } from "../../models";
import { Loading } from "../BackOfficeLayout";
import { BackOfficeListViewLayout } from "../BackOfficeListViewLayout";

export function FleetStateCustomVehicleFieldValuesListView() {
  const [form] = Form.useForm();

  const { customVehicleFieldId } = useParams<{
    customVehicleFieldId: string;
  }>();
  const customVehicleFieldDataFetcher = useCallback(
    async () => backOfficeApi.getCustomVehicleField(customVehicleFieldId),
    [customVehicleFieldId]
  );
  const [customVehicleFieldState] = useFetch(customVehicleFieldDataFetcher);
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const theme = useTheme();
  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();
  const { isLoadingVehicleGroups, vehicleGroupOptions } = useVehicleGroups();

  const dataGetter = useCallback(
    async (
      filters: CommonViewState["fleetStateCustomVehicleFieldValuesListView"]
    ) => {
      return await backOfficeApi.fleetState.getFleetStateCustomVehicleFieldValues(
        customVehicleFieldId,
        filters
      );
    },
    [customVehicleFieldId]
  );

  const statusOptions = useMemo<
    { label: string; value: FleetStateCustomVehicleFieldValue["status"] }[]
  >(
    () => [
      { label: t("fleetState.statuses.ok"), value: "ok" },
      { label: t("fleetState.statuses.notOk"), value: "not_ok" },
      { label: t("fleetState.statuses.none"), value: "none" },
      { label: t("fleetState.statuses.warning"), value: "warning" },
    ],
    [t]
  );

  const columns = useMemo<TableColumnType<FleetStateCustomVehicleFieldValue>[]>(
    () => [
      {
        width: "220px",
        title: t("fleetState.fieldNames.plateNumber"),
        dataIndex: ["vehicleObject", "plateNumber"],
        render: (_, fleetStateValue: FleetStateCustomVehicleFieldValue) => (
          <>
            <div>{fleetStateValue.vehicleObject.plateNumber}</div>
            {fleetStateValue.vehicleFieldsPresetLabel ? (
              <Tag style={{ marginTop: 6 }}>
                <Typography.Text
                  style={{
                    maxWidth: 180,
                  }}
                  ellipsis={{ tooltip: true }}
                >
                  {fleetStateValue.vehicleFieldsPresetLabel}
                </Typography.Text>
              </Tag>
            ) : null}
          </>
        ),
      },
      {
        width: "120px",
        dataIndex: "status",
        title: t("fleetState.fieldNames.status"),
        render: (value: FleetStateCustomVehicleFieldValue["status"]) => (
          <CustomVehicleFieldStatusBadge status={value} />
        ),
      },
      {
        width: "auto",
        dataIndex: "value",
        title: t("fleetState.fieldNames.value"),
      },
      {
        width: "180px",
        dataIndex: "updateDate",
        title: t("fleetState.fieldNames.updateDate"),
        render: (value: FleetStateCustomVehicleFieldValue["updateDate"]) => (
          <span style={{ color: theme.colors.text.gray }}>
            {value &&
              dayjs(value).calendar(dayjs(), {
                sameDay: `[${t("calendar.todayAt")}] ${TIME_FORMAT}`,
                lastDay: `[${t("calendar.yesterdayAt")}] ${TIME_FORMAT}`,
                lastWeek: `dddd [${t(
                  "calendar.lastDayOfWeekAt"
                )}] ${TIME_FORMAT}`,
                sameElse: DATE_TIME_FORMAT,
              })}
          </span>
        ),
      },
      {
        key: "action",
        width: 170,
        align: "right",
        render: (value: FleetStateCustomVehicleFieldValue) => {
          return (
            <Space size="small">
              <Button
                size="small"
                onClick={() => {
                  let url = urls.backOffice.vehicleCard(value.vehicleObject.id);

                  if (customVehicleFieldState.state === "dataFetched") {
                    if (customVehicleFieldState.data.type === "damages") {
                      url = urls.backOffice.vehicleCardDamages(
                        value.vehicleObject.id
                      );
                    }
                    if (
                      customVehicleFieldState.data
                        .customVehicleFieldsGroupObject
                    ) {
                      url = urls.backOffice.vehicleCardCustomVehicleFieldsGroup(
                        value.vehicleObject.id,
                        customVehicleFieldState.data
                          .customVehicleFieldsGroupObject.id
                      );
                    }
                  }
                  history.push(url);
                }}
                icon={<ArrowRightOutlined />}
              >
                {t("vehicleCard")}
              </Button>
            </Space>
          );
        },
      },
    ],
    [customVehicleFieldState, history, t, theme.colors.text.gray]
  );

  return (
    <BackOfficeListViewLayout
      breadcrumbs={[
        {
          label: t("fleetState.pageTitle"),
          url: urls.backOffice.fleetState.customVehicleFields(),
        },
      ]}
      dataGetter={dataGetter}
      columns={columns}
      commonStateViewName="fleetStateCustomVehicleFieldValuesListView"
      debouncedFilterProps={["plateNumber"]}
      filtersForm={form}
      sortedTableProps={[
        "status",
        "updateDate",
        "vehicleObject_plateNumber",
        "value",
      ]}
      filters={
        <>
          <FiltersRow>
            <FilterItem name="vehicleTypeFilters" $inputWidth="medium">
              <MultiSelect
                options={vehicleTypeOptions}
                loading={isLoadingVehicleTypes}
                placeholder={t("fleetState.fieldNames.vehicleTypes")}
                noMargin
              />
            </FilterItem>
            <FilterItem name="vehicleGroupFilters" $inputWidth="medium">
              <MultiSelect
                options={vehicleGroupOptions}
                loading={isLoadingVehicleGroups}
                placeholder={t("fleetState.fieldNames.vehicleGroups")}
                noMargin
              />
            </FilterItem>
          </FiltersRow>
          <FiltersRow>
            <FilterItem name="statusFilters" $inputWidth="medium">
              <MultiSelect<FleetStateCustomVehicleFieldValue["status"]>
                options={statusOptions}
                placeholder={t("fleetState.fieldNames.status")}
                noMargin
              />
            </FilterItem>
            <FilterItem name="plateNumber" $inputWidth="long">
              <Input.Search
                placeholder={t("fleetState.fieldNames.plateNumber")}
              />
            </FilterItem>
          </FiltersRow>
        </>
      }
      pageTitle={
        customVehicleFieldState.state === "dataFetched"
          ? customVehicleFieldState.data.label
          : Loading
      }
    />
  );
}
