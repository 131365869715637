import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { CreateLinkTypeView } from "./CreateLinkTypeView";
import { EditLinkTypeView } from "./EditLinkTypeView";
import { LinkTypesSettingsListView } from "./LinkTypesSettingsListView";

export function LinkTypesSettingsRouting() {
  return (
    <Switch>
      <Route path={urls.backOffice.settings.linkTypes.baseView()} exact>
        <LinkTypesSettingsListView />
      </Route>
      <Route path={urls.backOffice.settings.linkTypes.create()} exact>
        <CreateLinkTypeView />
      </Route>
      <Route path={urls.backOffice.settings.linkTypes.edit()}>
        <EditLinkTypeView />
      </Route>
    </Switch>
  );
}
