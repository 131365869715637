import { ApiError } from "@inspecto/common";
import { useCallback, useEffect, useState } from "react";

import { backOfficeApi } from "../api";
import { RetrieveFilledOutProtocol } from "../models";

type UseProtocol = [
  RetrieveFilledOutProtocol | null,
  boolean,
  string,
  (useLoading?: boolean) => void
];

export function useProtocol(protocolId: string): UseProtocol {
  const [protocol, setProtocol] = useState<RetrieveFilledOutProtocol | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [fetchingFailedError, setFetchingFailedError] = useState<string>("");

  const fetchProtocol = useCallback(
    async (useLoading: boolean = true) => {
      if (useLoading) {
        setIsLoading(true);
      }

      try {
        const protocol = await backOfficeApi.getProtocol(protocolId);
        setProtocol(protocol);
      } catch (e) {
        if (e instanceof ApiError) {
          setFetchingFailedError(Object.values(e.body).join(" "));
        } else {
          setFetchingFailedError(
            "Unexpected error occurred when trying to fetch the Protocol. Please contact the administrator."
          );
        }
      }

      setIsLoading(false);
    },
    [protocolId]
  );

  useEffect(() => {
    fetchProtocol();
  }, [fetchProtocol]);

  return [protocol, isLoading, fetchingFailedError, fetchProtocol];
}
