import { Tooltip } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";

import { Asterisk } from "./Asterisk";
import { Icon } from "./Icon";

const Wrapper = styled.div<{ $noMargin: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: ${(props) =>
    !props.$noMargin && props.theme.commonVariables.inputSpacing};
  max-width: 700px;
`;

const Label = styled.div`
  margin-bottom: 2px;
  min-height: ${(props) => props.theme.commonVariables.inputLabelHeight};
  vertical-align: middle;
  color: ${(props) => props.theme.colors.text.dark};
  display: flex;
`;

const TooltipIconWrapper = styled.div`
  margin-left: 5px;
`;

interface Props {
  label?: string;
  children: ReactNode;
  isRequired?: boolean;
  helpTooltip?: string;
  noMargin?: boolean;
}

export function InputWrapper(props: Props): JSX.Element {
  return (
    <Wrapper $noMargin={props.noMargin || false}>
      {props.label && (
        <Label>
          {props.isRequired && <Asterisk />}
          {props.label}{" "}
          {props.helpTooltip && (
            <Tooltip title={props.helpTooltip}>
              <TooltipIconWrapper>
                <Icon icon="questionCircle" color="gray" />
              </TooltipIconWrapper>
            </Tooltip>
          )}{" "}
        </Label>
      )}
      {props.children}
    </Wrapper>
  );
}
