import { ProtocolFillerLanguage } from "@inspecto/common";
import { createContext, ReactNode, useContext, useState } from "react";

import { languageContext } from "src/locales";

interface MultiLanguageContextState {
  currentLanguage?: ProtocolFillerLanguage;
  initialLanguage?: ProtocolFillerLanguage;
  setCurrentLanguage: (language: ProtocolFillerLanguage) => void;
}

export const multiLanguageContext = createContext<MultiLanguageContextState>({
  currentLanguage: undefined,
  initialLanguage: undefined,
  setCurrentLanguage: () => null,
});

interface Props {
  children: ReactNode;
  initialLanguage?: ProtocolFillerLanguage;
  synchronizeMultiLanguageInputs?: boolean;
}

export function MultiLanguageProvider(props: Props): JSX.Element {
  const { value: languageContextValue } = useContext(languageContext);
  const [currentLanguage, setCurrentLanguage] =
    useState<ProtocolFillerLanguage>(languageContextValue.language);

  return (
    <multiLanguageContext.Provider
      value={{
        initialLanguage: props.initialLanguage,
        currentLanguage: props.synchronizeMultiLanguageInputs
          ? currentLanguage
          : undefined,
        setCurrentLanguage,
      }}
    >
      {props.children}
    </multiLanguageContext.Provider>
  );
}
