import { Form } from "antd";
import { useTranslation } from "react-i18next";

import { RangePicker } from "src/components";

export function DatePeriodFormItem() {
  const { t } = useTranslation("backoffice");

  return (
    <Form.Item
      label={t("reportsView.fieldNames.datePeriod")}
      name="datePeriod"
      rules={[{ required: true }]}
    >
      <RangePicker allowClear />
    </Form.Item>
  );
}
