import { ReactNode } from "react";
import styled from "styled-components";

type BgColor = "black" | "white" | "transparent";

const OverlayPositioner = styled.div<{
  color: BgColor;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.color === "white"
      ? "#FFFFFFAA"
      : props.color === "transparent"
      ? "none"
      : "#00000099"};
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface FullScreenOverlayProps {
  children: ReactNode;
  onBackdropClick?(): void;
  color?: BgColor;
}

export function FullScreenOverlay(props: FullScreenOverlayProps) {
  const color = props.color || "white";
  return (
    <OverlayPositioner
      color={color}
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          event.stopPropagation();
          props.onBackdropClick?.();
        }
      }}
    >
      {props.children}
    </OverlayPositioner>
  );
}
