import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useDeploymentInfo } from "./DeploymentInfoProvider";

const DeploymentInfoMessageWrapper = styled.header<{ marginLeft?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: calc(100% - ${(props) => props.marginLeft || 0}px);
  height: ${(props) =>
    props.theme.commonVariables.deploymentInfoMessageHeight}px;
  background-color: #ffe07c;
  color: #3c4043;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.whitePanelBorderColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: 16px;
  margin-left: ${(props) => props.marginLeft || 0}px;
  z-index: 2;
`;

const NoWrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function DeploymentInfoMessage(props: { marginLeft?: number }) {
  const { t } = useTranslation();
  const { openModal } = useDeploymentInfo();

  return (
    <DeploymentInfoMessageWrapper marginLeft={props.marginLeft}>
      <Space size="middle">
        <LoadingOutlined style={{ fontSize: 20, color: "#6d3a01" }} />
        <div>
          <NoWrap>
            <Typography.Text strong>
              {t("deploymentInfo.deploymentMessageTitle")}
            </Typography.Text>
          </NoWrap>
          <NoWrap>{t("deploymentInfo.deploymentMessageContent")}</NoWrap>
        </div>
      </Space>
      <Button type="text" icon={<InfoCircleOutlined />} onClick={openModal} />
    </DeploymentInfoMessageWrapper>
  );
}
