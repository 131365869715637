import { Currency } from "@inspecto/common";

import { EmployeeWithId } from "./Employee";
import { VehicleObject } from "./VehicleObject";

export enum ChargedEmployeeStatus {
  UNPAID = "unpaid",
  PAID_BY_EMPLOYEE = "paid_by_employee",
  PAID_BY_INSURANCE = "paid_by_insurance",
  CANCELED = "canceled",
}

export interface ChargedEmployee {
  id: string;
  employee: string;
  employeeObject: EmployeeWithId;
  date: string;
  amount: string;
  currency: Currency;
  status: ChargedEmployeeStatus;
  comment: string;
}

export interface ResponseChargedEmployee extends ChargedEmployee {
  responseObject: {
    vehicleId: string;
    vehiclePlateNumber: string;
    protocolId: string;
  };
  damageObject: null;
  historicalCustomVehicleFieldValueObject: null;
  vehiclePlateNumber: string;
}

export interface DamageChargedEmployee extends ChargedEmployee {
  responseObject: null;
  damageObject: {
    vehicleId: string;
    vehiclePlateNumber: string;
    protocolId: string;
  } | null;
  historicalCustomVehicleFieldValueObject: null;
}

export interface HistoricalCustomVehicleFieldValueChargedEmployee
  extends ChargedEmployee {
  responseObject: null;
  damageObject: null;
  historicalCustomVehicleFieldValueObject: {
    vehicleObject: VehicleObject;
    customVehicleFieldId: string;
    customVehicleFieldsGroupId: string | null;
  };
}

export type HistoricalCustomVehicleFieldValueWithResponseChargedEmployee = Omit<
  ResponseChargedEmployee,
  "historicalCustomVehicleFieldValueObject"
> &
  Omit<HistoricalCustomVehicleFieldValueChargedEmployee, "responseObject">;

export type ListChargedEmployee =
  | ResponseChargedEmployee
  | DamageChargedEmployee
  | HistoricalCustomVehicleFieldValueChargedEmployee
  | HistoricalCustomVehicleFieldValueWithResponseChargedEmployee;
