import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { EmployeeFormView } from "./EmployeeFormView";
import { EmployeesListView } from "./EmployeesListView";

export function EmployeesRouter() {
  return (
    <Switch>
      <Route path={urls.backOffice.employees()} exact>
        <EmployeesListView />
      </Route>
      <Route path={urls.backOffice.createEmployee()} exact>
        <EmployeeFormView />
      </Route>
      <Route path={urls.backOffice.editEmployee()} exact>
        <EmployeeFormView />
      </Route>
    </Switch>
  );
}
