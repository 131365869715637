import styled from "styled-components";

import { FormProps } from "./CommonForm";
import { WithSaveButtonForm } from "./WithSaveButtonForm";

export const MaxWidth700FormWrapper = styled.div`
  max-width: 700px;
`;

export function MaxWidth700WithSaveButtonForm<
  RecordType extends Record<string, any>,
  TSaveOutput = void
>(props: FormProps<RecordType, TSaveOutput>) {
  return (
    <MaxWidth700FormWrapper>
      <WithSaveButtonForm {...props} />
    </MaxWidth700FormWrapper>
  );
}
