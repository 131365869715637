import {
  allProtocolFillerLanguages,
  BackofficeLanguage,
  ProtocolFillerLanguage,
} from "@inspecto/common";
import type { Locale as AntdLocale } from "antd/lib/locale-provider";
import antdLocaleBgBG from "antd/lib/locale/bg_BG";
import antdLocaleCsCZ from "antd/lib/locale/cs_CZ";
import antdLocaleDeDE from "antd/lib/locale/de_DE";
import antdLocaleElGR from "antd/lib/locale/el_GR";
import antdLocaleEnGB from "antd/lib/locale/en_GB";
import antdLocaleEsES from "antd/lib/locale/es_ES";
import antdLocalefiFI from "antd/lib/locale/fi_FI";
import antdLocaleFrFR from "antd/lib/locale/fr_FR";
import antdLocaleHuHU from "antd/lib/locale/hu_HU";
import antdLocaleHyAm from "antd/lib/locale/hy_AM";
import antdLocaleItIT from "antd/lib/locale/it_IT";
import antdLocaleKaGE from "antd/lib/locale/ka_GE";
import antdLocaleLtLT from "antd/lib/locale/lt_LT";
import antdLocalePlPL from "antd/lib/locale/pl_PL";
import antdLocaleRoRO from "antd/lib/locale/ro_RO";
import antdLocaleRuRU from "antd/lib/locale/ru_RU";
import antdLocaleSkSK from "antd/lib/locale/sk_SK";
import antdLocaleSlSI from "antd/lib/locale/sl_SI";
import antdLocaleSrRS from "antd/lib/locale/sr_RS";
import antdLocaleSvSE from "antd/lib/locale/sv_SE";
import antdLocaleTrTR from "antd/lib/locale/tr_TR";
import antdLocaleUkUA from "antd/lib/locale/uk_UA";
import antdLocaleUzUZ from "antd/lib/locale/uz_UZ";
import dayjs from "dayjs";
import dayJsLocaleBg from "dayjs/locale/bg";
import dayJsLocaleCs from "dayjs/locale/cs";
import dayJsLocaleDe from "dayjs/locale/de";
import dayJsLocaleEl from "dayjs/locale/el";
import dayJsLocaleEn from "dayjs/locale/en";
import dayJsLocaleEs from "dayjs/locale/es";
import dayJsLocaleFi from "dayjs/locale/fi";
import dayJsLocaleFr from "dayjs/locale/fr";
import dayJsLocaleHu from "dayjs/locale/hu";
import dayJsLocaleHy from "dayjs/locale/hy-am";
import dayJsLocaleIt from "dayjs/locale/it";
import dayJsLocaleKa from "dayjs/locale/ka";
import dayJsLocaleLt from "dayjs/locale/lt";
import dayJsLocalePl from "dayjs/locale/pl";
import dayJsLocaleRo from "dayjs/locale/ro";
import dayJsLocaleRu from "dayjs/locale/ru";
import dayJsLocaleSk from "dayjs/locale/sk";
import dayJsLocaleSl from "dayjs/locale/sl";
import dayJsLocaleSr from "dayjs/locale/sr";
import dayJsLocaleSv from "dayjs/locale/sv";
import dayJsLocaleTr from "dayjs/locale/tr";
import dayJsLocaleUk from "dayjs/locale/uk";
import dayJsLocaleUz from "dayjs/locale/uz";
import flagAm from "flag-icons/flags/4x3/am.svg";
import flagBa from "flag-icons/flags/4x3/ba.svg";
import flagBg from "flag-icons/flags/4x3/bg.svg";
import flagCz from "flag-icons/flags/4x3/cz.svg";
import flagDe from "flag-icons/flags/4x3/de.svg";
import flagEs from "flag-icons/flags/4x3/es.svg";
import flagFi from "flag-icons/flags/4x3/fi.svg";
import flagFr from "flag-icons/flags/4x3/fr.svg";
import flagGb from "flag-icons/flags/4x3/gb.svg";
import flagGe from "flag-icons/flags/4x3/ge.svg";
import flagGr from "flag-icons/flags/4x3/gr.svg";
import flagHu from "flag-icons/flags/4x3/hu.svg";
import flagIt from "flag-icons/flags/4x3/it.svg";
import flagLt from "flag-icons/flags/4x3/lt.svg";
import flagPl from "flag-icons/flags/4x3/pl.svg";
import flagRo from "flag-icons/flags/4x3/ro.svg";
import flagRs from "flag-icons/flags/4x3/rs.svg";
import flagRu from "flag-icons/flags/4x3/ru.svg";
import flagSe from "flag-icons/flags/4x3/se.svg";
import flagSi from "flag-icons/flags/4x3/si.svg";
import flagSk from "flag-icons/flags/4x3/sk.svg";
import flagTr from "flag-icons/flags/4x3/tr.svg";
import flagUa from "flag-icons/flags/4x3/ua.svg";
import flagUz from "flag-icons/flags/4x3/uz.svg";
import flagXx from "flag-icons/flags/4x3/xx.svg";
import { get } from "lodash";

type DayjsLocale = Exclude<
  // dayJS ILocale - which doesn't seem exported
  Parameters<typeof dayjs.locale>[0],
  string | undefined
>;

interface LanguageConfig {
  antLocale: AntdLocale;
  countryFlag: string;
  support: "full" | "protocolFiller";
  dayJSLocale: DayjsLocale;
}

export type UserLanguage = BackofficeLanguage | "";
export type UserProtocolFillerLanguage = ProtocolFillerLanguage | "";

export const modifiedDayJsLocalePl = {
  ...dayJsLocalePl,
  // this is in order to be compliant with the way django formats month names (uppercase instead of lowercase)
  months: [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ],
};

const configByLanguage: Record<ProtocolFillerLanguage, LanguageConfig> = {
  pl: {
    antLocale: antdLocalePlPL,
    countryFlag: flagPl,
    support: "full",
    dayJSLocale: modifiedDayJsLocalePl,
  },
  plgener: {
    antLocale: antdLocalePlPL,
    countryFlag: flagPl,
    support: "full",
    dayJSLocale: modifiedDayJsLocalePl,
  },
  en: {
    antLocale: antdLocaleEnGB,
    countryFlag: flagGb,
    support: "full",
    dayJSLocale: dayJsLocaleEn,
  },
  ro: {
    antLocale: antdLocaleRoRO,
    countryFlag: flagRo,
    support: "full",
    dayJSLocale: dayJsLocaleRo,
  },
  tr: {
    antLocale: antdLocaleTrTR,
    countryFlag: flagTr,
    support: "full",
    dayJSLocale: dayJsLocaleTr,
  },
  cs: {
    antLocale: antdLocaleCsCZ,
    countryFlag: flagCz,
    support: "full",
    dayJSLocale: dayJsLocaleCs,
  },
  es: {
    antLocale: antdLocaleEsES,
    countryFlag: flagEs,
    support: "full",
    dayJSLocale: dayJsLocaleEs,
  },
  fr: {
    antLocale: antdLocaleFrFR,
    countryFlag: flagFr,
    support: "full",
    dayJSLocale: dayJsLocaleFr,
  },
  hu: {
    antLocale: antdLocaleHuHU,
    countryFlag: flagHu,
    support: "full",
    dayJSLocale: dayJsLocaleHu,
  },
  uk: {
    antLocale: antdLocaleUkUA,
    countryFlag: flagUa,
    support: "full",
    dayJSLocale: dayJsLocaleUk,
  },
  ru: {
    antLocale: antdLocaleRuRU,
    countryFlag: flagRu,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleRu,
  },
  de: {
    antLocale: antdLocaleDeDE,
    countryFlag: flagDe,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleDe,
  },
  it: {
    antLocale: antdLocaleItIT,
    countryFlag: flagIt,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleIt,
  },
  sr: {
    antLocale: antdLocaleSrRS,
    countryFlag: flagRs,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleSr,
  },
  sk: {
    antLocale: antdLocaleSkSK,
    countryFlag: flagSk,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleSk,
  },
  sl: {
    antLocale: antdLocaleSlSI,
    countryFlag: flagSi,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleSl,
  },
  el: {
    antLocale: antdLocaleElGR,
    countryFlag: flagGr,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleEl,
  },
  bs: {
    antLocale: antdLocaleEnGB,
    countryFlag: flagBa,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleEn,
  },
  lt: {
    antLocale: antdLocaleLtLT,
    countryFlag: flagLt,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleLt,
  },
  bg: {
    antLocale: antdLocaleBgBG,
    countryFlag: flagBg,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleBg,
  },
  ka: {
    antLocale: antdLocaleKaGE,
    countryFlag: flagGe,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleKa,
  },
  uz: {
    antLocale: antdLocaleUzUZ,
    countryFlag: flagUz,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleUz,
  },
  hy: {
    antLocale: antdLocaleHyAm,
    countryFlag: flagAm,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleHy,
  },
  fi: {
    antLocale: antdLocalefiFI,
    countryFlag: flagFi,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleFi,
  },
  sv: {
    antLocale: antdLocaleSvSE,
    countryFlag: flagSe,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleSv,
  },
};

export function getLanguageConfig(
  language: ProtocolFillerLanguage
): LanguageConfig {
  return get(configByLanguage, language, {
    antLocale: antdLocaleEnGB,
    countryFlag: flagXx,
    support: "protocolFiller",
    dayJSLocale: dayJsLocaleEn,
  });
}

export type TranslatedField = Record<ProtocolFillerLanguage, string>;

export const emptyTranslatedField: TranslatedField = {
  pl: "",
  plgener: "",
  en: "",
  ro: "",
  ru: "",
  uk: "",
  de: "",
  hu: "",
  it: "",
  fr: "",
  tr: "",
  sr: "",
  sk: "",
  sl: "",
  el: "",
  bs: "",
  lt: "",
  bg: "",
  es: "",
  fi: "",
  cs: "",
  ka: "",
  uz: "",
  hy: "",
  sv: "",
};

export function getTranslatedField(
  value: string,
  appendLanguageSuffix: boolean = false
): TranslatedField {
  return allProtocolFillerLanguages.reduce<TranslatedField>(
    (acc, curr) => ({
      ...acc,
      [curr]: `${value}${appendLanguageSuffix ? curr.toUpperCase() : ""}`,
    }),
    { ...emptyTranslatedField }
  );
}
