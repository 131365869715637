import { useTranslation } from "react-i18next";

import { urls } from "src/urls";

import { BackOfficeLayout } from "../../BackOfficeLayout";
import { CustomVehicleFieldsGroupForm } from "./CustomVehicleFieldsGroupForm";

export function CreateCustomVehicleFieldsGroupView() {
  const { t } = useTranslation("backoffice");

  return (
    <BackOfficeLayout
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.customVehicleFieldsGroups.label"),
          url: urls.backOffice.settings.customVehicleFieldsGroups(),
        },
      ]}
      pageTitle={t("settings.customVehicleFieldsGroups.editingLabel")}
    >
      <BackOfficeLayout.Content>
        <CustomVehicleFieldsGroupForm existingCustomVehicleFieldsGroup={null} />
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
