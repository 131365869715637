import { TagProps } from "antd";
import { TFunction } from "react-i18next";

import { ChargedEmployeeStatus, Damage } from "./models";

export const tagColorByChargedEmployeeStatus: Record<
  ChargedEmployeeStatus,
  TagProps["color"]
> = {
  [ChargedEmployeeStatus.UNPAID]: "error",
  [ChargedEmployeeStatus.PAID_BY_EMPLOYEE]: "success",
  [ChargedEmployeeStatus.PAID_BY_INSURANCE]: "blue",
  [ChargedEmployeeStatus.CANCELED]: "default",
};

export const tagColorByDamageStatus: Record<
  Damage["status"],
  TagProps["color"]
> = {
  new: "red",
  ignored: "blue",
  for_repair: "orange",
  invalid: "default",
  resolved: "green",
};

export function getDamageStatusOptions(
  t: TFunction<"backoffice">
): { label: string; value: Damage["status"] }[] {
  return [
    { label: t("damages.statuses.new"), value: "new" },
    { label: t("damages.statuses.ignored"), value: "ignored" },
    { label: t("damages.statuses.forRepair"), value: "for_repair" },
    { label: t("damages.statuses.invalid"), value: "invalid" },
    { label: t("damages.statuses.resolved"), value: "resolved" },
  ];
}
