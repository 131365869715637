import { useEffect } from "react";

import { api } from "src/utils";

export function ProtocolFillerApiConfig(props: {
  children: JSX.Element;
}): JSX.Element {
  useEffect(() => {
    api.inspectoModule = "protocol_filler";
    return () => {
      api.inspectoModule = "backoffice";
    };
  }, []);

  return props.children;
}
