import { Form } from "antd";
import { useTranslation } from "react-i18next";

import { getHaveFieldsUpdatedCallback } from "src/utils";

import { ExpandableSection } from "../../../components/ExpandableSection";
import { CustomVehicleFieldsSelector } from "./CustomVehicleFieldsSelector";

export function CustomVehicleFieldsSelectorFormItem() {
  const { t } = useTranslation("backoffice");

  return (
    <Form.Item
      noStyle
      shouldUpdate={getHaveFieldsUpdatedCallback("vehicleType")}
    >
      {({ getFieldValue }) => {
        return (
          <ExpandableSection>
            {(isExpanded) => (
              <Form.Item
                name="customVehicleFields"
                label={t("reportsView.fieldNames.customVehicleFields")}
              >
                <CustomVehicleFieldsSelector
                  vehicleType={getFieldValue("vehicleType")}
                  removeStickyOffsetHeader={!isExpanded}
                />
              </Form.Item>
            )}
          </ExpandableSection>
        );
      }}
    </Form.Item>
  );
}
