import styled from "styled-components";

import { FullScreenOverlay, FullScreenOverlayProps } from "./FullScreenOverlay";

interface Props {
  text?: string;
  backgroundColor?: FullScreenOverlayProps["color"];
}

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;

  & .path {
    stroke: ${(props) => props.theme.colors.primary.primary};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export function Spinner(props: Props) {
  return (
    <FullScreenOverlay color={props.backgroundColor}>
      <div>
        <div style={{ textAlign: "center" }}>
          <StyledSpinner viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </StyledSpinner>
        </div>
        <div>{props.text}</div>
      </div>
    </FullScreenOverlay>
  );
}
