import styled from "styled-components";

export const flagHeight = "12px";

export const FlagImage = styled.img`
  width: 16px;
  height: ${flagHeight};
  position: relative;
  top: -1px;
  border-radius: 2px;
`;
