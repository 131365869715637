import { Alert, Form } from "antd";
import { PropsWithChildren, useEffect, useState } from "react";

export function FormErrorsAlert(
  props: PropsWithChildren<{ visible: boolean }>
) {
  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerElement) {
      containerElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [containerElement]);

  if (!props.visible) {
    return null;
  }

  return (
    <Form.Item>
      <div ref={setContainerElement}>
        <Alert message={props.children} type="error" />
      </div>
    </Form.Item>
  );
}
