import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ImagesGallery } from "src/components";

import {
  ChoiceResponse,
  DateMonthResponse,
  DateResponse,
  NumberResponse,
  PhotoActionResponse,
  TextResponse,
} from "../models";
import {
  ChoiceResponseComponent,
  ChoiceResponseComponentProps,
} from "./ChoiceResponseComponent";
import { NoImageResponse } from "./ProtocolResponse";

export const TextResponseWrapper = styled.div`
  min-height: 44px;
  padding: 10px 17px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.protocolResponseBorder};
  background-color: ${(props) => props.theme.colors.protocolResponseBackground};
`;

interface CommonProps {
  fieldLabel: string;
}

interface ChoiceResponseProps
  extends ChoiceResponseComponentProps,
    CommonProps {
  type: ChoiceResponse["type"];
}

interface TextDateNumberResponseProps extends CommonProps {
  type:
    | TextResponse["type"]
    | NumberResponse["type"]
    | DateResponse["type"]
    | DateMonthResponse["type"]
    | PhotoActionResponse["type"];
  value: string;
}

export type CommonFieldProtocolResponseProps =
  | TextDateNumberResponseProps
  | ChoiceResponseProps;

export function CommonFieldProtocolResponse(
  props: CommonFieldProtocolResponseProps
): JSX.Element {
  const { t } = useTranslation("backoffice");
  switch (props.type) {
    case "text":
    case "number":
    case "date":
    case "date-month":
      return <TextResponseWrapper>{props.value}</TextResponseWrapper>;
    case "choice":
      return <ChoiceResponseComponent {...props} />;
    case "photo-action":
      return props.value ? (
        <ImagesGallery
          images={[
            {
              src: props.value,
              alt: props.fieldLabel,
            },
          ]}
          width={150}
        />
      ) : (
        <NoImageResponse
          icon="photo"
          text={t("protocolView.noPhotoResponse")}
        />
      );
    default:
      return <div></div>;
  }
}
