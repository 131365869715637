import { Button } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DateFieldComponent, DateFieldProps } from "src/components";
import { dayJsToISOTimeZero } from "src/utils";

const ButtonWithMargins = styled(Button)`
  margin: 10px 10px 0 0;
`;

interface Props extends DateFieldProps {
  suggestedValues: number[];
}

export function DateFieldWithSuggestedValues(props: Props) {
  const { t } = useTranslation("translation");

  return (
    <div>
      <DateFieldComponent {...props} />
      <div>
        {props.suggestedValues.map((suggestedValue) => {
          const suggestedDateISO = dayJsToISOTimeZero(
            dayjs().add(suggestedValue, "day")
          );
          const suggestedValueIsSelected = props.value === suggestedDateISO;

          return (
            <ButtonWithMargins
              size="small"
              ghost={suggestedValueIsSelected}
              type={suggestedValueIsSelected ? "primary" : "dashed"}
              onClick={() => props.onChange?.(suggestedDateISO)}
            >
              {t("todayPlus", {
                something: t("numberOfDays", {
                  numberOfDays: suggestedValue,
                }),
              })}
            </ButtonWithMargins>
          );
        })}
      </div>
    </div>
  );
}
