import styled from "styled-components";

import { useHover } from "src/utils/useHover";

import { Icon } from "./Icon";

const Choice = styled.div<{ isHovered: boolean; isSelected: boolean }>`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.primary.primary};
  color: ${(props) => props.theme.colors.primary.primary};
  background: ${(props) =>
    props.isHovered
      ? props.theme.colors.gray200
      : props.isSelected
      ? "#e8faff"
      : props.theme.colors.background};
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: ${(props) => (props.isSelected ? "500" : "normal")};
`;

const ChoiceLabel = styled.div`
  flex: 1 1 auto;
  display: flex;
  padding: 15px 10px;
  text-transform: uppercase;
  line-height: 1.2;
`;

const ChoiceCheckbox = styled.div`
  flex: 0 0 56px;
  border-left: 1px solid ${(props) => props.theme.colors.primary.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  isSelected: boolean;
  label: string;

  onClick(): void;
}

export function ChoiceSelectionButton(props: Props) {
  const { isHovered, onMouseLeave, onTouchEnd, onTouchStart, onMouseEnter } =
    useHover();

  return (
    <Choice
      isHovered={isHovered}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
      onMouseEnter={onMouseEnter}
      onClick={props.onClick}
      isSelected={props.isSelected}
    >
      <ChoiceLabel>{props.label}</ChoiceLabel>
      <ChoiceCheckbox>
        {" "}
        {props.isSelected && <Icon icon="check" size={25} />}
      </ChoiceCheckbox>
    </Choice>
  );
}
