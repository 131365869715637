import { Tag } from "antd";
import styled from "styled-components";

type Color = "green" | "red" | "gray" | "orange";

const StatusBadgeComponent = styled(Tag)`
  width: 100px;
  text-align: center;
  text-transform: uppercase;
`;

function getColorMapping(color: Color): Exclude<Color, "gray"> | "default" {
  switch (color) {
    case "gray":
      return "default";
    case "green":
    case "red":
    case "orange":
      return color;
  }
}

export interface StatusBadgeProps {
  statusText: string;
  color: Color;
}

export function StatusBadge(props: StatusBadgeProps) {
  return (
    <StatusBadgeComponent color={getColorMapping(props.color)}>
      {props.statusText}
    </StatusBadgeComponent>
  );
}
