import { useCallback } from "react";

import { backOfficeApi } from "../api";
import { ListRetrieveCustomVehicleFieldsGroup } from "../models";
import { useFetchOptions } from "./useFetchOptions";

export interface UseCustomVehicleFieldsGroups {
  customVehicleFieldsGroups: ListRetrieveCustomVehicleFieldsGroup[];
  isLoadingCustomVehicleFieldsGroups: boolean;
  customVehicleFieldsGroupOptions: {
    label: string;
    value: ListRetrieveCustomVehicleFieldsGroup["id"];
  }[];
  refresh: () => void;
}

export function useCustomVehicleFieldsGroups(): UseCustomVehicleFieldsGroups {
  const mapper = useCallback(
    (customVehicleFieldsGroup) => ({
      value: customVehicleFieldsGroup.id,
      label: customVehicleFieldsGroup.label,
    }),
    []
  );
  const fetcher = useCallback(async () => {
    const customVehicleFieldsGroups =
      await backOfficeApi.getCustomVehicleFieldsGroups();
    return customVehicleFieldsGroups.results;
  }, []);

  const [
    customVehicleFieldsGroups,
    isLoadingCustomVehicleFieldsGroups,
    customVehicleFieldsGroupOptions,
    refresh,
  ] = useFetchOptions<ListRetrieveCustomVehicleFieldsGroup>(fetcher, mapper);

  return {
    isLoadingCustomVehicleFieldsGroups,
    customVehicleFieldsGroupOptions,
    customVehicleFieldsGroups,
    refresh,
  };
}
