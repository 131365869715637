import { ApiError } from "@inspecto/common";
import { message, Modal, Progress } from "antd";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { backOfficeApi } from "../api";

interface TaskData {
  id: string;
  onSuccess: (data: any) => void;
}

interface CeleryTaskProgressContext {
  handleTask: (task: TaskData) => void;
}

const initialState: CeleryTaskProgressContext = {
  handleTask: () => null,
};

const celeryTaskProgressContext =
  createContext<CeleryTaskProgressContext>(initialState);

export function CeleryTaskProgressProvider(
  props: PropsWithChildren<{}>
): JSX.Element {
  const { t } = useTranslation("backoffice");
  const [task, setTask] = useState<TaskData | null>(null);
  const [progress, setProgress] = useState<{
    percent: number;
    total: number;
  } | null>(null);

  const getProgress = useCallback((taskId: string): Promise<string> => {
    return backOfficeApi.getCeleryTaskProgress<string>(taskId).then((data) => {
      setProgress({
        percent: data.progress.percent,
        total: data.progress.total,
      });

      switch (data.state) {
        case "PENDING":
        case "PROGRESS":
          const getProgressAgainAfterTime = async () => {
            await new Promise((resolve) =>
              setTimeout(() => resolve(null), 2000)
            );
            return getProgress(taskId);
          };
          return getProgressAgainAfterTime();
        case "SUCCESS":
          return data.result;
        case "FAILURE":
          throw new ApiError(400, data.result);
      }
    });
  }, []);

  const clearState = useCallback(() => {
    setTask(null);
    setProgress(null);
  }, []);

  const startFetchingCeleryTask = useCallback(
    async (task: TaskData) => {
      try {
        const result = await getProgress(task.id);
        task.onSuccess(result);
      } catch (e) {
        message.error(t("somethingWentWrong"));
      }
      clearState();
    },
    [clearState, getProgress, t]
  );

  useEffect(() => {
    if (task) {
      startFetchingCeleryTask(task);
    }
  }, [startFetchingCeleryTask, task]);

  return (
    <celeryTaskProgressContext.Provider
      value={{
        handleTask: setTask,
      }}
    >
      {props.children}
      {!!progress && (
        <Modal
          open
          footer={null}
          title={t("celeryTaskProgress.downloading")}
          closable={false}
        >
          <p>
            {t("celeryTaskProgress.generatingNumberOfItems", {
              numberOfItems: progress.total,
            })}
          </p>
          <Progress percent={Math.floor(progress.percent)} />
        </Modal>
      )}
    </celeryTaskProgressContext.Provider>
  );
}

export function useCeleryTaskProgress(): CeleryTaskProgressContext {
  return useContext(celeryTaskProgressContext);
}
