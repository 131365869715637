import { isObject, mapValues, trim } from "lodash";

export function stripTags<T extends object>(
  resources: T,
  languageTag: string
): T {
  let mappingFunc = (val: string | object): string | object => {
    if (isObject(val)) {
      return mapValues(val, mappingFunc);
    } else {
      if (val.startsWith(languageTag)) {
        return trim(val.replace(languageTag, ""));
      } else {
        return val;
      }
    }
  };
  return mapValues(resources, mappingFunc) as unknown as T;
}
