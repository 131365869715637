import { Page, Question } from "../../../models";

export function canCreateNewQuestionInPage(page: Page) {
  return page.questions.reduce<boolean>((canCreate, question) => {
    return (
      canCreate &&
      !["damages", "choice", "multi-choice"].includes(question.questionType)
    );
  }, true);
}

export function canSelectQuestionType(
  questionType: Question["questionType"],
  question: Question | null,
  page: Page
): boolean {
  const isEmptyPage = page.questions.length < 1;
  const isCurrentQuestionTheOnlyOneOnPage =
    page.questions.length === 1 &&
    !!page.questions[0] &&
    page.questions[0].frontendId === question?.frontendId;

  if (["choice", "multi-choice"].includes(questionType)) {
    return isEmptyPage || isCurrentQuestionTheOnlyOneOnPage;
  }
  return true;
}
