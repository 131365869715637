import { TFunction } from "react-i18next";

import {
  ListTemplate,
  TemplateTagsRequirementsByVehicleType,
} from "../../../models";

export function getTemplateTagsRequiredForTemplateExplanation(
  t: TFunction<"backoffice">,
  templateTagsRequirementsByVehicleType: TemplateTagsRequirementsByVehicleType | null,
  vehicleTypes: ListTemplate["vehicleTypes"]
) {
  if (!!templateTagsRequirementsByVehicleType) {
    const templateTagsRequiredForVehicleTypes = vehicleTypes.filter(
      (vehicleType) => templateTagsRequirementsByVehicleType[vehicleType.id]
    );

    if (templateTagsRequiredForVehicleTypes.length) {
      return {
        tagsAreRequiredText: t("settings.templateTags.tagsAreRequired"),
        templateTagsRequiredForTemplateExplanation: t(
          "settings.templateTags.templateTagsRequiredForTemplateExplanation",
          {
            affectedVehicleTypes: templateTagsRequiredForVehicleTypes
              .map((item) => item.label)
              .join(", "),
          }
        ),
      };
    }
  }
}
