import produce from "immer";
import { invertBy } from "lodash";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { CompressionPreset } from "src/utils";

export type ImmediateImageUploaderStatus = "uploading" | "error" | "done";

interface CommonContextValue {
  status: ImmediateImageUploaderStatus;
  setUploaderStatus: (
    uploaderId: string,
    status: ImmediateImageUploaderStatus
  ) => void;
  message: string | null;
  allowSelectingImageFromPhoneGallery: boolean;
  useExternalCameraApplicationInMobileApp: boolean;
  compressionPreset: CompressionPreset;
}

interface UploadingContextValue extends CommonContextValue {
  status: "uploading";
  uploaderIds: string[];
}

interface ErrorContextValue extends CommonContextValue {
  status: "error";
  uploaderIds: string[];
}

interface DoneContextValue extends CommonContextValue {
  status: "done";
}

type ContextValue =
  | UploadingContextValue
  | ErrorContextValue
  | DoneContextValue;

const defaultValue: ContextValue = {
  status: "done",
  setUploaderStatus: () => null,
  message: null,
  allowSelectingImageFromPhoneGallery: false,
  useExternalCameraApplicationInMobileApp: false,
  compressionPreset: "standard",
};

export const immediateImageUploadersContext =
  createContext<ContextValue>(defaultValue);

type UploadersStatuses = Record<string, ImmediateImageUploaderStatus>;

export function ImmediateImageUploadersContextProvider({
  allowSelectingImageFromPhoneGallery = false,
  useExternalCameraApplicationInMobileApp = false,
  compressionPreset = "standard",
  ...props
}: PropsWithChildren<{
  allowSelectingImageFromPhoneGallery?: boolean;
  useExternalCameraApplicationInMobileApp?: boolean;
  compressionPreset?: CompressionPreset;
}>): JSX.Element {
  const { t } = useTranslation();
  const [uploadersStatuses, setUploadersStatuses] = useState<UploadersStatuses>(
    {}
  );

  const setUploaderStatus = useCallback(
    (uploaderId: string, status: ImmediateImageUploaderStatus) => {
      setUploadersStatuses(
        produce(uploadersStatuses, (uploadersStatusesDraft) => {
          uploadersStatusesDraft[uploaderId] = status;
        })
      );
    },
    [uploadersStatuses]
  );

  const uploaderIdsByStatus = invertBy(uploadersStatuses) as Partial<
    Record<ImmediateImageUploaderStatus, string[]>
  >;

  let errorUploaderIds: string[] =
    uploaderIdsByStatus["error"] && uploaderIdsByStatus["error"].length
      ? uploaderIdsByStatus["error"]
      : [];
  let uploadingUploaderIds: string[] =
    uploaderIdsByStatus["uploading"] && uploaderIdsByStatus["uploading"].length
      ? uploaderIdsByStatus["uploading"]
      : [];

  const commonValue: CommonContextValue = {
    setUploaderStatus,
    status: "done",
    message: null,
    allowSelectingImageFromPhoneGallery,
    useExternalCameraApplicationInMobileApp,
    compressionPreset,
  };

  const value: ContextValue = errorUploaderIds.length
    ? {
        ...commonValue,
        status: "error",
        uploaderIds: errorUploaderIds,
      }
    : uploadingUploaderIds.length
    ? {
        ...commonValue,
        status: "uploading",
        uploaderIds: uploadingUploaderIds,
      }
    : {
        ...commonValue,
        status: "done",
      };

  const messageByStatus: Record<ImmediateImageUploaderStatus, string | null> = {
    uploading: t("formValidationErrors.filesAreStillUploading"),
    error: t("formValidationErrors.filesDidNotUploadCorrectly"),
    done: null,
  };

  return (
    <immediateImageUploadersContext.Provider
      value={{
        ...value,
        message: messageByStatus[value.status],
      }}
    >
      {props.children}
    </immediateImageUploadersContext.Provider>
  );
}

export const useImmediateImageUploadersContext = () =>
  useContext(immediateImageUploadersContext);
