import { Card, Checkbox, Col, Form, Row, Select, Space } from "antd";
import { useTranslation } from "react-i18next";

import { Icon, ModalForm, Spinner } from "src/components";

import { backOfficeApi, DownloadPdfProtocolData } from "../api";
import { useCompanyFooters } from "../hooks";

type DownloadPdfForm = DownloadPdfProtocolData;

interface Props {
  protocolId: string;
  closeModal(): void;
}

export function DownloadProtocolPdfModal(props: Props) {
  const { t } = useTranslation("backoffice");

  const { isLoadingCompanyFooters, companyFooterOptions } = useCompanyFooters();

  return isLoadingCompanyFooters ? (
    <Spinner />
  ) : (
    <ModalForm<DownloadPdfForm>
      open
      darkBackground
      saveCallback={handleSave}
      closeModal={props.closeModal}
      formTitle={t("downloadProtocolPdfModal.title")}
      cancelButtonText={t("downloadProtocolPdfModal.cancel")}
      confirmButtonText={t("tableActions.download")}
      initialValues={{ includeProtocolAuthor: false, footer: null }}
    >
      <Row>
        <Col span={24}>
          <Card
            title={
              <Space size="middle">
                <Icon icon="cog" />
                {t("downloadProtocolPdfModal.options")}
              </Space>
            }
            bordered={false}
            bodyStyle={{ paddingBottom: 0 }}
          >
            <Form.Item name="includeProtocolAuthor" valuePropName="checked">
              <Checkbox>
                {t("downloadProtocolPdfModal.includeProtocolAuthor")}
              </Checkbox>
            </Form.Item>
            {companyFooterOptions.length > 0 && (
              <Form.Item
                name="footer"
                label={t("downloadProtocolPdfModal.footer")}
              >
                <Select
                  options={companyFooterOptions}
                  allowClear
                  placeholder={t("downloadProtocolPdfModal.footerNone")}
                  style={{ maxWidth: 300 }}
                />
              </Form.Item>
            )}
          </Card>
        </Col>
      </Row>
    </ModalForm>
  );

  async function handleSave(values: DownloadPdfForm): Promise<void> {
    return backOfficeApi.downloadProtocolPdf(props.protocolId, values);
  }
}
