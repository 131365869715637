import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormListFieldData, List, Row, Select } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { myTheme } from "src/theme";

import { SingleNewProtocolNotificationSetting } from "./models";

interface Props {
  employeeManagerOptions: { label: string; value: string }[];
  formListFieldData: FormListFieldData;
  remove: () => void;
  allValues: SingleNewProtocolNotificationSetting[];
  currentValue: SingleNewProtocolNotificationSetting;
}

export function NewProtocolNotificationsSettingInput(props: Props) {
  const { t } = useTranslation("backoffice");

  const getRemainingEmployeeManagerOptions = useMemo(() => {
    const selectedUsers = new Set(props.allValues.map((value) => value.user));

    return props.employeeManagerOptions.filter(
      (option) =>
        (props.currentValue && props.currentValue.user === option.value) ||
        !selectedUsers.has(option.value)
    );
  }, [props.allValues, props.employeeManagerOptions, props.currentValue]);

  return (
    <List.Item>
      <Row style={{ width: "100%" }} align="stretch" gutter={8}>
        <Col xs={9}>
          <Form.Item
            name={[props.formListFieldData.name, "user"]}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t("settings.notifications.isRequired"),
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={getRemainingEmployeeManagerOptions}
              showArrow
              showSearch
              filterOption
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>
        <Col
          xs={2}
          style={{
            paddingTop: 10,
            textAlign: "center",
          }}
        >
          <ArrowRightOutlined
            style={{ color: myTheme.colors.primary.primary, fontSize: 24 }}
          />
        </Col>
        <Col xs={11}>
          <Form.Item
            name={[props.formListFieldData.name, "protocolStatusCondition"]}
            noStyle
          >
            <Select
              style={{ width: "100%" }}
              options={[
                {
                  value: "always",
                  label: t(
                    "settings.notifications.protocolStatusConditions.always"
                  ),
                },
                {
                  value: "onNotOk",
                  label: t(
                    "settings.notifications.protocolStatusConditions.onNotOk"
                  ),
                },
              ]}
              showArrow
            />
          </Form.Item>
        </Col>
        <Col xs={2}>
          <Button
            danger
            onClick={(event) => {
              event.stopPropagation();
              props.remove();
            }}
            icon={<DeleteOutlined />}
          />
        </Col>
      </Row>
    </List.Item>
  );
}
