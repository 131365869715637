import { useCallback } from "react";

import { backOfficeApi } from "../api";
import { useFetchOptions } from "./useFetchOptions";

export function useDamagesCustomVehicleFields() {
  const [
    damageCustomVehicleFields,
    isLoadingDamagesCustomVehicleFields,
    damagesCustomVehicleFieldOptions,
  ] = useFetchOptions(
    useCallback(
      () =>
        backOfficeApi
          .getCustomVehicleFields({
            tableParams: { pageNumber: 1 },
            type: "damages",
          })
          .then((response) => response.results),
      []
    ),
    useCallback((element) => ({ value: element.id, label: element.label }), [])
  );

  return {
    damageCustomVehicleFields,
    isLoadingDamagesCustomVehicleFields,
    damagesCustomVehicleFieldOptions,
  };
}
