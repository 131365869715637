import { Select, SelectProps, Space } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getNotNoneCustomVehicleFieldValueStatusesOptions } from "../models";
import { StatusIcon } from "./StatusIcon";

export function NotNoneVehicleFieldStatusSelect(
  props: Pick<SelectProps, "value" | "onChange" | "id" | "disabled">
) {
  const { t } = useTranslation("backoffice");

  const notNoneStatusOptions = useMemo(
    () =>
      getNotNoneCustomVehicleFieldValueStatusesOptions(t).map((option) => ({
        label: (
          <Space>
            <StatusIcon status={option.value} />
            {option.label}
          </Space>
        ),
        value: option.value,
      })),
    [t]
  );

  return (
    <Select
      size="small"
      style={{ width: 200 }}
      options={notNoneStatusOptions}
      {...props}
    />
  );
}
