import { Popconfirm, Typography } from "antd";
import { ReactNode } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Icon, IconButton } from "src/components";

import { PageLevelElement } from "./PageLevelElement";

const PageTopHandle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 5px 0 0;
  position: relative;
  height: 35px;
  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.gray200};
  }

  transition: background-color ease-in-out 0.2s;
`;

const PageContentWrapper = styled.div`
  width: 100%;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
`;

const PageName = styled.div<{ left: number }>`
  position: absolute;
  left: ${(props) => props.left}px;
  display: flex;
  align-items: center;
`;

interface Props {
  pageFrontendId: string;
  pageTitle: string;
  index: number;
  children: ReactNode;
  onDeleteConfirm: () => void;
  onPageEdit?: () => void;
  pageTitleAppendix?: string;
}

export function PageCommonComponent(props: Props) {
  const { t } = useTranslation("backoffice");
  return (
    <>
      <Draggable draggableId={props.pageFrontendId} index={props.index}>
        {(provided) => (
          <PageLevelElement
            {...provided.draggableProps}
            ref={provided.innerRef}
            data-test="single-page-element"
          >
            <PageContentWrapper>
              <PageTopHandle {...provided.dragHandleProps}>
                <PageName left={props.onPageEdit ? 0 : 10}>
                  {props.onPageEdit && (
                    <IconButton
                      icon="pen"
                      size={15}
                      onClick={props.onPageEdit}
                    />
                  )}
                  <Typography.Text
                    style={{
                      maxWidth: 250,
                    }}
                    ellipsis={{ tooltip: true }}
                  >
                    {props.pageTitle} {props.index + 1}
                    {props.pageTitleAppendix && ` (${props.pageTitleAppendix})`}
                  </Typography.Text>
                </PageName>
                <Icon icon="gripLines" />
                <ActionsWrapper>
                  <Popconfirm
                    onConfirm={props.onDeleteConfirm}
                    title={t("builder.confirmations.page.delete")}
                  >
                    <IconButton icon="trashAlt" size={15} />
                  </Popconfirm>
                </ActionsWrapper>
              </PageTopHandle>
              {props.children}
            </PageContentWrapper>
          </PageLevelElement>
        )}
      </Draggable>
    </>
  );
}
