import { ArrowRightOutlined } from "@ant-design/icons";
import { Form, Input, Button, Row, Col, Divider, InputRef } from "antd";
import { debounce, isNull } from "lodash";
import {
  ChangeEventHandler,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as uuid from "uuid";

import { useAuthentication } from "src/authentication";
import { ModalForm, ModalFormProps, MultiSelect } from "src/components";
import { emptyTranslatedField } from "src/locales";
import { urls } from "src/urls";
import { isSingleElementDefined } from "src/utils";

import {
  CustomVehicleFieldRadioButtonsSelector,
  CustomVehicleFieldRadioButtonsSelectorProps,
  FormItemValue,
} from "../../../components";
import { CustomVehicleFieldCheckboxSelector } from "../../../components/CustomVehicleFieldCheckboxSelector";
import { useCustomVehicleFieldsGroups, useVehicleTypes } from "../../../hooks";
import {
  CustomFieldUpdateQuestion,
  isCustomFieldUpdateQuestion,
  Page,
  RetrieveCustomVehicleField,
  VehicleType,
} from "../../../models";
import { normalizeStringForSearch } from "../../../utils";
import { useTemplateContext } from "../TemplateEditorView";

interface FormState {
  customVehicleFieldIds: CustomFieldUpdateQuestion["customVehicleFieldId"][];
}

interface CustomFieldUpdateQuestionWithNullableFieldId
  extends Omit<CustomFieldUpdateQuestion, "customVehicleFieldId"> {
  customVehicleFieldId:
    | CustomFieldUpdateQuestion["customVehicleFieldId"]
    | null;
}

type SaveCallback = (customVehicleField: RetrieveCustomVehicleField) => void;

export interface CustomVehicleFieldSelectorModalProps
  extends Pick<ModalFormProps, "disableMotion"> {
  question: CustomFieldUpdateQuestionWithNullableFieldId;
  closeModal: () => void;
  onNextClick: (data: CustomFieldUpdateQuestion) => void;
  onAddMultiple: (data: CustomFieldUpdateQuestion[]) => void;
  onEditCustomVehicleFieldClick: (customVehicleFieldId: string) => void;
  onCloneCustomVehicleFieldClick: (
    customVehicleFieldId: string,
    onSuccessfulSave?: SaveCallback
  ) => void;
  onCreateCustomVehicleFieldClick: (onSuccessfulSave?: SaveCallback) => void;
}

export function CustomVehicleFieldSelectorModal(
  props: CustomVehicleFieldSelectorModalProps
) {
  const { user } = useAuthentication();
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<string[]>(
    []
  );
  const [selectedFieldGroups, setSelectedFieldGroups] = useState<string[]>([]);
  const { t } = useTranslation("backoffice");
  const [form] = Form.useForm();
  const searchInputRef = createRef<InputRef>();

  const { vehicleTypeOptions, isLoadingVehicleTypes } = useVehicleTypes();
  const {
    customVehicleFieldsGroupOptions,
    isLoadingCustomVehicleFieldsGroups,
  } = useCustomVehicleFieldsGroups();

  const { customVehicleFields, form: templateForm } = useTemplateContext();

  const handleSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setSearchValue(e.target.value),
    []
  );

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchInputRef]);

  const remainingAvailableCustomVehicleFields = useMemo(() => {
    const templatePages: Page[] | undefined =
      templateForm.getFieldValue("pages");

    // filter out all the custom vehicle fields that are already assigned to questions
    // excluding the one from this question (we need it for the <select /> options
    const selectedCustomVehicleFieldIds = (templatePages || [])
      .flatMap((page) => page.questions)
      .filter(isCustomFieldUpdateQuestion)
      .filter((question) => props.question.frontendId !== question.frontendId)
      .map((question) => question.customVehicleFieldId);
    return customVehicleFields.filter(
      (customVehicleField) =>
        !selectedCustomVehicleFieldIds.includes(customVehicleField.id)
    );
  }, [customVehicleFields, props.question.frontendId, templateForm]);

  const remainingAvailableCustomVehicleFieldsAfterFilters = useMemo(
    () =>
      remainingAvailableCustomVehicleFields
        .filter((field) =>
          normalizeStringForSearch(field.label).includes(
            normalizeStringForSearch(searchValue)
          )
        )
        .filter((field) => {
          const fieldVehicleTypes = field.vehicleTypes.map((type) => type.id);
          return selectedVehicleTypes.reduce(
            (hasAllSelectedTypes, selectedVehicleType) => {
              return (
                hasAllSelectedTypes &&
                fieldVehicleTypes.includes(selectedVehicleType)
              );
            },
            true
          );
        })
        .filter((field) => {
          return (
            selectedFieldGroups.length === 0 ||
            (field.customVehicleFieldsGroup &&
              selectedFieldGroups.includes(field.customVehicleFieldsGroup))
          );
        }),
    [
      remainingAvailableCustomVehicleFields,
      searchValue,
      selectedFieldGroups,
      selectedVehicleTypes,
    ]
  );
  const formInitialValues: FormState = {
    customVehicleFieldIds: props.question.customVehicleFieldId
      ? [props.question.customVehicleFieldId]
      : [],
  };

  return (
    <>
      <ModalForm<FormState>
        open
        width={775}
        disableMotion={props.disableMotion}
        form={form}
        saveCallback={async (formState) => {
          if (!formState.customVehicleFieldIds.length) {
            // This should not happen because of antd form validation
            return;
          }

          if (isSingleElementDefined(formState.customVehicleFieldIds)) {
            const selectedCustomVehicleField =
              remainingAvailableCustomVehicleFields.find(
                (field) => field.id === formState.customVehicleFieldIds[0]
              );

            props.onNextClick({
              ...props.question,
              label: selectedCustomVehicleField
                ? selectedCustomVehicleField.labelTranslations
                : emptyTranslatedField,
              customVehicleFieldId: formState.customVehicleFieldIds[0],
              isRequired:
                selectedCustomVehicleField &&
                selectedCustomVehicleField.type === "damages"
                  ? false
                  : props.question.isRequired,
            });
            return Promise.resolve();
          } else {
            const selectedCustomVehicleFields =
              remainingAvailableCustomVehicleFields.filter((field) =>
                formState.customVehicleFieldIds.includes(field.id)
              );
            props.onAddMultiple(
              selectedCustomVehicleFields.map((field) => ({
                ...props.question,
                frontendId: uuid.v4(),
                label: field ? field.labelTranslations : emptyTranslatedField,
                customVehicleFieldId: field.id,
                isRequired:
                  field && field.type === "damages"
                    ? false
                    : props.question.isRequired,
              }))
            );
          }
        }}
        closeModal={props.closeModal}
        formTitle={t("builder.createEditQuestion")}
        cancelButtonText={t("cancel")}
        confirmButtonText={t("builder.next")}
        initialValues={formInitialValues}
      >
        <Row gutter={[24, 0]}>
          <Col xs={12}>
            <Input.Search
              ref={searchInputRef} // autoFocus does not work in modals
              allowClear
              autoComplete="off"
              placeholder={t("search")}
              loading={false}
              style={{
                marginBottom: 14,
              }}
              onChange={debounce(handleSearch, 100)}
            />
          </Col>
          <Col xs={12}>
            <Button
              block
              type="primary"
              ghost={true}
              shape="round"
              onClick={() => {
                props.onCreateCustomVehicleFieldClick((customVehicleField) => {
                  form.setFieldsValue({
                    customVehicleFieldIds: [customVehicleField.id],
                  });
                });
              }}
            >
              + {t("settings.customVehicleFields.createFieldLabel")}
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 0]} style={{ marginBottom: 24 }}>
          <Col xs={12}>
            <MultiSelect<VehicleType["id"]>
              options={vehicleTypeOptions}
              noMargin
              loading={isLoadingVehicleTypes}
              value={selectedVehicleTypes}
              onChange={setSelectedVehicleTypes}
              placeholder={t("settings.customVehicleFields.vehicleTypes")}
            />
          </Col>
          <Col xs={12}>
            <MultiSelect<string>
              options={customVehicleFieldsGroupOptions}
              noMargin
              value={selectedFieldGroups}
              onChange={setSelectedFieldGroups}
              loading={isLoadingCustomVehicleFieldsGroups}
              placeholder={t("settings.customVehicleFieldsGroups.label")}
            />
          </Col>
        </Row>
        <Form.Item
          name="customVehicleFieldIds"
          label={t("builder.questionFields.customVehicleField")}
          rules={[{ required: true }]}
          style={{ marginBottom: 0 }}
        >
          <FormItemValue<string[]>>
            {({ value, onChange }) => {
              const commonProps: Omit<
                CustomVehicleFieldRadioButtonsSelectorProps,
                "value" | "onChange"
              > = {
                customVehicleFields:
                  remainingAvailableCustomVehicleFieldsAfterFilters,
                onEditCustomVehicleFieldClick:
                  props.onEditCustomVehicleFieldClick,
                onCloneCustomVehicleFieldClick: (id) => {
                  props.onCloneCustomVehicleFieldClick(
                    id,
                    (customVehicleField) => {
                      form.setFieldsValue({
                        customVehicleFieldIds: [customVehicleField.id],
                      });
                    }
                  );
                },
              };
              return user?.isStaff ? (
                <CustomVehicleFieldCheckboxSelector
                  value={value}
                  onChange={onChange}
                  {...commonProps}
                />
              ) : (
                <CustomVehicleFieldRadioButtonsSelector
                  value={isSingleElementDefined(value) ? value[0] : undefined}
                  onChange={(value) => onChange?.(isNull(value) ? [] : [value])}
                  {...commonProps}
                />
              );
            }}
          </FormItemValue>
        </Form.Item>
        <Divider />
        {t("builder.customVehicleFieldSelectorModal.goTo")}
        {": "}
        <Link
          to={urls.backOffice.settings.customVehicleFields()}
          target="_blank"
        >
          {t("settings.label")} - {t("settings.customVehicleFields.label")}{" "}
          <ArrowRightOutlined />
        </Link>
      </ModalForm>
    </>
  );
}
