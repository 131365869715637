import { LockOutlined } from "@ant-design/icons";
import styled from "styled-components";

const StyledWrapper = styled.div`
  font-size: 15px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(254 243 220);
  color: #5c3c00;
`;

export function LockedFeatureIcon() {
  return (
    <StyledWrapper>
      <LockOutlined />
    </StyledWrapper>
  );
}
