import { ApiError } from "@inspecto/common";

import { User } from "src/authentication";
import { authenticationApi } from "src/authentication/api";

export async function attemptLoginByQueryParams(
  companyId: string,
  originalUserFetch: () => Promise<User | null>,
  driverLoginPin: string
): Promise<User | null> {
  if (!driverLoginPin) {
    return originalUserFetch();
  }

  let user = null as User | null;
  try {
    const response = await authenticationApi.protocolfillerLogin(
      driverLoginPin,
      companyId
    );
    user = response.user;
  } catch (e) {
    if (!(e instanceof ApiError)) {
      throw e;
    }
  }

  return user;
}
