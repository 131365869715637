import { useCallback } from "react";

import { DateMonthFieldComponent, DateMonthFieldValue } from "src/components";

import { useProtocolFillerContext } from "../contexts";

interface Props {
  questionId: string;
}

export function DateMonthQuestionComponent(props: Props) {
  const { setDateMonthResponse, responses } = useProtocolFillerContext();

  const { dateMonthResponses } = responses;

  const setResponse = useCallback(
    (value: DateMonthFieldValue | undefined) => {
      setDateMonthResponse(props.questionId, value || null);
    },
    [setDateMonthResponse, props.questionId]
  );

  return (
    <DateMonthFieldComponent
      onChange={setResponse}
      value={dateMonthResponses[props.questionId]}
    />
  );
}
