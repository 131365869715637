import { useEffect, useState } from "react";
import { TFunction } from "react-i18next";

import { backOfficeApi } from "../api";
import { CustomVehicleFieldConditionOption } from "../models";

interface UseCustomVehicleFieldConditions {
  conditions: CustomVehicleFieldConditionOption[];
  isLoadingConditions: boolean;
}

export function useCustomVehicleFieldConditions(
  t: TFunction<"backoffice">
): UseCustomVehicleFieldConditions {
  const [isLoadingConditions, setIsLoadingConditions] = useState(true);
  const [conditions, setConditions] = useState<
    CustomVehicleFieldConditionOption[]
  >([]);

  useEffect(() => {
    setIsLoadingConditions(true);
    backOfficeApi
      .getCustomVehicleFieldConditionOptions()
      .then((options) => {
        setConditions(options);
        setIsLoadingConditions(false);
      })
      .catch((e) => {
        setIsLoadingConditions(false);
        throw e;
      });
  }, []);

  return {
    conditions,
    isLoadingConditions,
  };
}
