import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import qs from "qs";

import { CompanyApi } from "./moduleApis";
import {
  ApiError,
  BackendPaginationResults,
  NoConnectionError,
  PaginationResults,
} from "./types";
export abstract class CommonApi {
  private api: AxiosInstance;
  private errorHandlers: Record<number, () => void> = {};
  public abstract inspectoModule: "backoffice" | "protocol_filler";
  public company: CompanyApi;

  constructor(api: AxiosInstance) {
    this.api = api;
    this.company = new CompanyApi(this);
  }

  setErrorHandler(statusCode: number, callback: () => void): void {
    this.errorHandlers[statusCode] = callback;
  }

  protected async beforeEachRequest() {}

  async makeRequest<T>(config: AxiosRequestConfig) {
    await this.beforeEachRequest();
    try {
      return await this.api.request<T>({
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
        ...config,
        headers: {
          ...config.headers,
          "X-Inspecto-Module": this.inspectoModule,
        },
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          let body: object | string;

          if (err.response.data instanceof Blob) {
            try {
              const blobText = await this.blobToString(err.response.data);
              try {
                body = JSON.parse(blobText);
              } catch (e) {
                body = blobText;
              }
            } catch (e) {
              body = err.response.data;
            }
          } else {
            body = err.response.data;
          }

          const apiError = new ApiError(err.response.status, body);
          const errorHandler = this.errorHandlers[apiError.statusCode];

          if (errorHandler) {
            errorHandler();
          }
          throw apiError;
        } else {
          throw new NoConnectionError();
        }
      } else {
        throw err;
      }
    }
  }

  async getPaginatedList<T>(
    url: string,
    paginationOptions: { pageNumber: number; pageSize?: number },
    queryParams?: object
  ): Promise<PaginationResults<T>> {
    const response = await this.makeRequest<BackendPaginationResults<T>>({
      method: "get",
      url,
      params: {
        ...queryParams,
        page: paginationOptions.pageNumber,
        ...(paginationOptions.pageSize && {
          page_size: paginationOptions.pageSize,
        }),
      },
    });

    return response.data;
  }

  async get<T>(url: string, queryParams?: object): Promise<T> {
    const response = await this.makeRequest<T>({
      method: "get",
      url,
      params: queryParams,
    });

    return response.data;
  }

  async delete<T>(url: string): Promise<T> {
    const response = await this.makeRequest<T>({ method: "delete", url });

    return response.data;
  }

  async post<T>(url: string, body: any, queryParams?: any): Promise<T> {
    const response = await this.makeRequest<T>({
      method: "post",
      url,
      data: body,
      params: queryParams,
    });

    return response.data;
  }

  async patch<T>(url: string, body: any): Promise<T> {
    const response = await this.makeRequest<T>({
      method: "patch",
      url,
      data: body,
    });

    return response.data;
  }

  async put<T>(url: string, body: any): Promise<T> {
    const response = await this.makeRequest<T>({
      method: "put",
      url,
      data: body,
    });

    return response.data;
  }

  async blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const resultString = event.target?.result;
        resolve(typeof resultString === "string" ? resultString : "");
      };

      reader.onerror = function () {
        reject(new Error("Error reading the Blob as a string."));
      };

      reader.readAsText(blob);
    });
  }

  getFullUrl(url: string, queryParams?: object): string {
    return this.api.getUri({
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
      params: queryParams,
      url,
    });
  }
}
