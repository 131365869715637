import styled from "styled-components";

import { useHover } from "src/utils/useHover";

import { Icon, IconType } from "./Icon";

interface Props {
  icon: IconType;
  onClick?: () => void;
  size?: number;
  ariaLabel?: string;
}

const Wrapper = styled.div<{ isHovered: boolean }>`
  padding: 10px;
  color: ${(props) =>
    props.isHovered
      ? props.theme.colors.icons.hover
      : props.theme.colors.gray400};
  cursor: pointer;

  transition: color 0.1s ease-in-out;
`;

export function IconButton(props: Props) {
  const { onMouseEnter, onMouseLeave, onTouchStart, onTouchEnd, isHovered } =
    useHover();

  return (
    <Wrapper
      aria-label={props.ariaLabel}
      isHovered={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onClick={(event) => {
        if (!props.onClick) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        props.onClick();
      }}
    >
      <Icon icon={props.icon} size={props.size} />
    </Wrapper>
  );
}
