import { ApiError } from "@inspecto/common";
import { Form, Input, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { CommonForm } from "src/components";
import { Button } from "src/components/Button";
import { FullScreenCardLogoPage } from "src/components/FullScreenCardLogoPage";
import { UserLanguage } from "src/locales";
import { languageContext } from "src/locales/LanguageProvider";
import { urls } from "src/urls";

import { authenticationApi } from "./api";

interface FormValues {
  password: string;
  confirmPassword: string;
}

export function PasswordReset() {
  const { dispatch: languageDispatch } = useContext(languageContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { passwordResetToken } = useParams<{ passwordResetToken: string }>();
  const [isActivation, setIsActivation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState<UserLanguage>("");

  const [isSettingPassword, setIsSettingPassword] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const passwordResetLink = await authenticationApi.getPasswordResetLink(
          passwordResetToken
        );
        setIsActivation(passwordResetLink.isActivation);
        setLanguage(passwordResetLink.language);
      } catch (e) {
        if (e instanceof ApiError) {
          message.error(t("somethingWentWrong"));
          return;
        } else {
          throw e;
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [t, passwordResetToken]);

  useEffect(() => {
    if (language) {
      languageDispatch({
        type: "setLanguage",
        payload: { language },
      });
    }
  }, [language, languageDispatch]);

  const confirmPassword = async (data: FormValues) => {
    await authenticationApi.setPassword(
      passwordResetToken,
      data.password,
      data.confirmPassword
    );
  };

  return (
    <FullScreenCardLogoPage
      headerText={
        isActivation ? t("creatingNewPassword") : t("resettingPassword")
      }
      isLoading={isLoading}
    >
      <CommonForm<FormValues>
        onIsSavingChange={setIsSettingPassword}
        saveCallback={async (formValues) => {
          await confirmPassword(formValues);
        }}
        onSuccessfulSave={() => {
          toast.success(t("passwordSetSuccessfully"));
          history.push(urls.landingPage());
        }}
      >
        <Form.Item name="password" label={t("typeYourNewPassword")}>
          <Input type="password" />
        </Form.Item>
        <Form.Item name="confirmPassword" label={t("confirmYourPassword")}>
          <Input type="password" />
        </Form.Item>
        <Button type="submit" isLoading={isSettingPassword}>
          {t("setPassword")}
        </Button>
      </CommonForm>
    </FullScreenCardLogoPage>
  );
}
