import { Form, Input, Modal } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { CommonForm } from "src/components";

export function useDeleteConfirmationModal() {
  const { t } = useTranslation("backoffice");
  const { t: tTranslation } = useTranslation("translation");
  const [modal, deleteConfirmationModalContext] = Modal.useModal();
  const [form] = Form.useForm();

  const showDeleteConfirmationModal = useCallback(
    (data: {
      title?: string;
      onOk: () => Promise<void>;
      content?: JSX.Element | string;
      width?: number;
      confirmationPhrase?: string;
    }) =>
      modal.confirm({
        width: data.width,
        content: (
          <CommonForm saveCallback={data.onOk} form={form} layout="vertical">
            {data.content}
            {data.confirmationPhrase && (
              <Form.Item
                name="confirmationPhrase"
                validateStatus="warning"
                label={t("typeInPhraseToConfirm", {
                  phrase: data.confirmationPhrase,
                })}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(`^${data.confirmationPhrase}$`, "gi"),
                    message: tTranslation("thisFieldIsRequired"),
                  },
                ]}
                style={{ paddingTop: 20, marginBottom: 10 }}
              >
                <Input />
              </Form.Item>
            )}
          </CommonForm>
        ),
        title: data.title || t("deleteConfirmation"),
        okText: t("delete"),
        okButtonProps: {
          onClick: () => {
            form.submit();

            const confirmationPhrase = form.getFieldValue("confirmationPhrase");

            if (
              !!data.confirmationPhrase &&
              confirmationPhrase !== data.confirmationPhrase
            ) {
              // do not close the modal yet, so we can se the errors
              return true;
            }

            // close the modal
            return false;
          },
          danger: true,
        },
        cancelText: t("cancel"),
        closable: true,
        maskClosable: true,
        autoFocusButton: "cancel",
      }),
    [form, modal, t, tTranslation]
  );

  return { showDeleteConfirmationModal, deleteConfirmationModalContext };
}
