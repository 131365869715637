import { message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { backOfficeApi } from "../api";

export function useRestoreDeletedEmployee() {
  const { t } = useTranslation("backoffice");
  const [isRestoring, setIsRestoring] = useState(false);

  async function restore(employeeId: string): Promise<void> {
    try {
      setIsRestoring(true);
      await backOfficeApi.updateEmployee(employeeId, {
        isActive: true,
      });
      message.success(t("deletedEmployeeSuccessfullyRestoredMessage"));
    } catch (e) {
      message.error(t("somethingWentWrong"));
    }
    setIsRestoring(false);
  }

  return {
    isRestoring,
    restore,
  };
}
