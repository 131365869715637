import {
  EyeInvisibleOutlined,
  EyeOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import { useCompany } from "@inspecto/common";
import { Button, Col, Form, Grid, Input, Row, Space, Typography } from "antd";
import { Breakpoint } from "antd/es/_util/responsiveObserve";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  BorderedCard,
  HorizontalOrDivider,
  VerticalOrDivider,
} from "src/backOffice/components";
import { CommonForm, NoConnectionResultPage, Spinner } from "src/components";
import { FullScreenLogoPage } from "src/components/FullScreenLogoPage";
import { myTheme } from "src/theme";

import { authenticationApi } from "./api";
import { useAuthentication } from "./context";
import { AuthenticationMethod } from "./models";

const ButtonWrapper = styled.div``;

const ForgotPasswordWrapper = styled.div`
  margin-top: 20px;
`;

const LoginButton = styled(Button)`
  width: 200px;
  font-weight: 600;
`;

const Center = styled.div`
  text-align: center;
`;

const Container = styled.div<{ isDesktop: boolean }>`
  min-width: 300px;
  max-width: 840px;
  margin: auto;
  padding: ${(props) => (props.isDesktop ? "15px" : "0 10px")};
`;

const EmailAndPasswordFormWrapper = styled.div`
  max-width: 400px;
  margin: auto;
`;

function Title(props: PropsWithChildren<{ level: 3 | 4 }>) {
  return (
    <Typography.Title level={props.level} style={{ marginBottom: 26 }}>
      {props.children}
    </Typography.Title>
  );
}

interface PinCodeLoginForm {
  loginPin: string;
}

interface EmailPasswordLoginForm {
  email: string;
  password: string;
}

export function LoginPage() {
  const { t } = useTranslation();
  const { login } = useAuthentication();
  const company = useCompany();
  const [isLoggingInViaEmail, setIsLoggingInViaEmail] = useState(false);
  const [isLoggingInViaPin, setIsLoggingInViaPin] = useState(false);
  const isLoggingIn = isLoggingInViaEmail || isLoggingInViaPin;
  const [pinLoginForm] = Form.useForm<PinCodeLoginForm>();
  const [
    loadingAuthenticationMethodsLoadingState,
    setLoadingAuthenticationMethodsLoadingState,
  ] = useState<"loading" | "loaded" | "error">("loading");
  const [authenticationMethods, setAuthenticationMethods] = useState<
    AuthenticationMethod[]
  >([]);
  const breakpoint = Grid.useBreakpoint();

  const loginUsingEmailPassword = useCallback(
    async ({ email, password }: EmailPasswordLoginForm) => {
      if (isLoggingIn) {
        return;
      }
      const { user } = await authenticationApi.backofficeLogin(
        email,
        password,
        company.id
      );
      login(user);
    },
    [isLoggingIn, company.id, login]
  );
  const loginUsingPin = useCallback(
    async ({ loginPin }: PinCodeLoginForm) => {
      if (isLoggingIn) {
        return;
      }
      const { user } = await authenticationApi.protocolfillerLogin(
        loginPin,
        company.id
      );

      login(user);
    },
    [isLoggingIn, company.id, login]
  );

  const loadAuthenticationMethods = useCallback(() => {
    authenticationApi
      .getAuthenticationMethods(company.subdomain)
      .then((authenticationMethods) => {
        setAuthenticationMethods(authenticationMethods.map((el) => el.type));
        setLoadingAuthenticationMethodsLoadingState("loaded");
      })
      .catch(() => {
        setLoadingAuthenticationMethodsLoadingState("error");
      });
  }, [company.subdomain]);

  useEffect(() => loadAuthenticationMethods(), [loadAuthenticationMethods]);

  const hasLoginPin = useMemo(() => {
    return authenticationMethods.includes("login_pin");
  }, [authenticationMethods]);

  const hasEmailAndPassword = useMemo(() => {
    return authenticationMethods.includes("email_and_password");
  }, [authenticationMethods]);

  const hasBothLoginMethods = hasLoginPin && hasEmailAndPassword;

  const desktopBreakpoint: Breakpoint = "md";
  const isDesktop = !!breakpoint[desktopBreakpoint];
  const titleLevel = isDesktop ? 3 : 4;

  switch (loadingAuthenticationMethodsLoadingState) {
    case "error":
      return (
        <NoConnectionResultPage
          onRetryClick={() => loadAuthenticationMethods()}
        />
      );
    case "loading":
      return <Spinner />;
    case "loaded":
      return (
        <FullScreenLogoPage>
          <Container isDesktop={isDesktop}>
            <Row gutter={[0, 0]}>
              {hasLoginPin && (
                <Col
                  span={24}
                  {...{
                    [desktopBreakpoint]: {
                      span: 8,
                      order: 2,
                      offset: hasBothLoginMethods ? 0 : 8,
                    },
                  }}
                >
                  <Center>
                    <Title level={titleLevel}>
                      {t("loginPage.pinLoginTitle")}
                    </Title>
                    <CommonForm<PinCodeLoginForm>
                      form={pinLoginForm}
                      saveCallback={loginUsingPin}
                      autoComplete="off"
                      onIsSavingChange={setIsLoggingInViaPin}
                      onValuesChange={(changedValues) => {
                        if ("loginPin" in changedValues) {
                          if (changedValues.loginPin.length === 6) {
                            pinLoginForm.submit();
                          }
                        }
                      }}
                      isRetryableOnError
                    >
                      <Form.Item
                        name="loginPin"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <Input.Password
                          data-testid="loginPin"
                          inputMode="numeric"
                          autoFocus
                          size="large"
                          style={{
                            fontFamily: "monospace",
                            width: 156,
                          }}
                          iconRender={(visible) => {
                            const common = { style: { fontSize: "22px" } };
                            return visible ? (
                              <EyeOutlined {...common} />
                            ) : (
                              <EyeInvisibleOutlined {...common} />
                            );
                          }}
                          maxLength={6}
                        />
                      </Form.Item>
                      <LoginButton
                        htmlType="submit"
                        type="primary"
                        loading={isLoggingInViaPin}
                      >
                        {t("login")}
                      </LoginButton>
                    </CommonForm>
                  </Center>
                </Col>
              )}

              {hasBothLoginMethods && (
                <Col
                  span={24}
                  {...{
                    [desktopBreakpoint]: {
                      span: 4,
                      order: 1,
                    },
                  }}
                >
                  {isDesktop ? (
                    <VerticalOrDivider height={150} />
                  ) : (
                    <HorizontalOrDivider height={80} />
                  )}
                </Col>
              )}
              {hasEmailAndPassword && (
                <Col
                  span={24}
                  {...{
                    [desktopBreakpoint]: {
                      span: 12,
                      offset: hasBothLoginMethods ? 0 : 6,
                    },
                  }}
                >
                  <EmailAndPasswordFormWrapper>
                    <BorderedCard
                      style={{
                        backgroundColor: isDesktop ? "#fff" : "#f9fafb",
                      }}
                    >
                      <Title level={titleLevel}>
                        <Space
                          style={{
                            alignItems: "start",
                          }}
                        >
                          <SafetyOutlined
                            style={{
                              fontSize: isDesktop ? 30 : 24,
                              color: myTheme.colors.status.ok,
                              position: "relative",
                              top: 3,
                            }}
                          />
                          {t("loginPage.emailLogin")}
                        </Space>
                      </Title>
                      <CommonForm<EmailPasswordLoginForm>
                        saveCallback={loginUsingEmailPassword}
                        onIsSavingChange={setIsLoggingInViaEmail}
                        isRetryableOnError
                      >
                        <Form.Item
                          label={t("email")}
                          name="email"
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label={t("password")}
                          name="password"
                          rules={[{ required: true }]}
                        >
                          <Input.Password />
                        </Form.Item>

                        <ButtonWrapper>
                          <LoginButton
                            type="primary"
                            htmlType="submit"
                            loading={isLoggingInViaEmail}
                          >
                            {t("login")}
                          </LoginButton>
                        </ButtonWrapper>
                        <ForgotPasswordWrapper>
                          <Link to="/forgot-password">
                            {t("forgotPassword")}
                          </Link>
                        </ForgotPasswordWrapper>
                      </CommonForm>
                    </BorderedCard>
                  </EmailAndPasswordFormWrapper>
                </Col>
              )}
            </Row>
          </Container>
        </FullScreenLogoPage>
      );
  }
}
