export function getLocalStorageSize(): number {
  let totalLength = 0;

  for (const key in localStorage) {
    if (!localStorage.hasOwnProperty(key)) {
      continue;
    }
    totalLength += (localStorage[key].length + key.length) * 2;
  }
  return totalLength / 1024;
}

export function fromLocalStorage(key: string, defaultValue: any = {}): any {
  return JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue));
}
