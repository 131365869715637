import { useCompany } from "@inspecto/common";
import { Form, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { MultiLanguageTextInput } from "src/company";
import { ModalForm } from "src/components";
import { getTranslatedField, TranslatedField } from "src/locales";

import { DefaultLanguageRequiredFormItem } from "../../../components";
import { Page, Question } from "../../../models";
import { PageCommonComponent } from "./PageCommonComponent";
import { QuestionsList } from "./QuestionsList";

interface Props {
  page: Page;
  index: number;
  savePageTitle: (pageId: string, title: TranslatedField) => void;
  removePage: (pageFrontendId: string) => void;
  insertPage: (page: Page, index: number) => void;
  saveQuestion: (pageFrontendId: string, question: Question) => void;
  addQuestions: (pageFrontendId: string, questions: Question[]) => void;
  removeQuestion: (pageFrontendId: string, questionFrontendId: string) => void;
  insertQuestion: (
    pageFrontendId: string,
    question: Question,
    index: number
  ) => void;
  allPages: Page[];
}

export function PageComponent(props: Props) {
  const { t } = useTranslation("backoffice");
  const company = useCompany();

  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <PageCommonComponent
        pageTitle={t("builder.page")}
        pageFrontendId={props.page.frontendId}
        index={props.index}
        onDeleteConfirm={() => props.removePage(props.page.frontendId)}
        onPageEdit={() => setIsEditing(true)}
        pageTitleAppendix={props.page.title?.[company.protocolFillerLanguage]}
      >
        <QuestionsList
          page={props.page}
          allPages={props.allPages}
          saveQuestion={props.saveQuestion}
          addQuestions={props.addQuestions}
          removeQuestion={props.removeQuestion}
          insertQuestion={props.insertQuestion}
        />
      </PageCommonComponent>
      {isEditing && (
        <ModalForm<{ title: TranslatedField }>
          disableMotion
          destroy={{
            buttonText: t("builder.delete"),
            isDangerButton: true,
            unexpectedErrorMessageOnDestroy: t("builder.somethingWentWrong"),
            removeCallback: () => {
              props.savePageTitle(
                props.page.frontendId,
                getTranslatedField("")
              );
              setIsEditing(false);
              return Promise.resolve();
            },
          }}
          width={775}
          saveCallback={({ title }) => {
            props.savePageTitle(props.page.frontendId, title);
            setIsEditing(false);
            return Promise.resolve();
          }}
          open
          closeModal={() => setIsEditing(false)}
          formTitle={t("builder.editPage")}
          cancelButtonText={t("cancel")}
          confirmButtonText={t("save")}
          initialValues={props.page}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="frontendId" hidden>
            <Input />
          </Form.Item>
          <DefaultLanguageRequiredFormItem
            maxLength={64}
            name="title"
            label={t("builder.pageTitle")}
          >
            <MultiLanguageTextInput />
          </DefaultLanguageRequiredFormItem>
        </ModalForm>
      )}
    </>
  );
}
