import { Tag } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  AssignUnassignPayload,
  backOfficeApi,
  GetCustomVehicleFieldsFilters,
} from "../api";
import {
  ListCustomVehicleField,
  ListRetrieveCustomVehicleFieldsGroup,
} from "../models";
import { getAssignedItemTagRenderer } from "./AssignedItemsTagsRenderer";
import { AssignItemsListView } from "./AssignItemsListView";

interface Props {
  isCustomVehicleFieldAssigned: (
    customVehicleField: ListCustomVehicleField
  ) => boolean;
  assignCustomVehicleFields: (payload: AssignUnassignPayload) => Promise<void>;
  itemBeingAssigned: {
    id: string;
    label: string;
  };
}

export function AssignCustomVehicleFieldsListView(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");

  const columns = useCallback(
    (selectedRows: ListCustomVehicleField[]) => [
      {
        dataIndex: "label",
        title: t("settings.customVehicleFields.label"),
        width: "auto",
      },
      {
        dataIndex: "type",
        title: t("settings.customVehicleFields.fields.type"),
        width: 200,
        render: (value: ListCustomVehicleField["type"]) => (
          <Tag>{t(`fieldType.${value}`)}</Tag>
        ),
      },
      {
        dataIndex: "vehicleTypes",
        title: t("settings.customVehicleFields.vehicleTypes"),
        width: "250px",
        render: (value: ListCustomVehicleField["vehicleTypes"]) => (
          <>
            {value.map((vehicleType) => (
              <Tag key={vehicleType.id}>{vehicleType.label}</Tag>
            ))}
          </>
        ),
      },
      {
        dataIndex: "customVehicleFieldsGroupObject",
        width: 400,
        title: t("settings.customVehicleFieldsGroups.editingLabel"),
        render: getAssignedItemTagRenderer<
          ListCustomVehicleField,
          ListRetrieveCustomVehicleFieldsGroup
        >(selectedRows, props.itemBeingAssigned, (group) => group.label),
      },
    ],
    [props.itemBeingAssigned, t]
  );

  const dataGetter = useCallback(
    (viewFilters: GetCustomVehicleFieldsFilters) => {
      return backOfficeApi.getCustomVehicleFields({
        ...viewFilters,
        excludeTypes: ["damages"],
      });
    },
    []
  );

  return (
    <AssignItemsListView
      title={t("assignCustomVehicleFieldsList.title")}
      commonStateViewName="assignCustomVehicleFieldsListView"
      dataGetter={dataGetter}
      isItemAssigned={props.isCustomVehicleFieldAssigned}
      assignItems={props.assignCustomVehicleFields}
      columns={columns}
    />
  );
}
