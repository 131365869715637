import { Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { AssignVehiclesListView } from "../../../components";
import { useVehicleGroups } from "../../../hooks";
import { SimpleVehicle, VehicleGroup } from "../../../models";
import { BackOfficeLayout } from "../../BackOfficeLayout";
import { VehicleGroupForm } from "./VehicleGroupForm";

export function EditVehicleGroupFormViewLayout() {
  const { t } = useTranslation("backoffice");
  const { vehicleGroupId } = useParams<{ vehicleGroupId: string }>();

  const { vehicleGroupOptions, refresh: refreshVehicleGroups } =
    useVehicleGroups();

  const [existingVehicleGroup, setExistingVehicleGroup] =
    useState<VehicleGroup | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const isVehicleAssigned = useCallback(
    (vehicle: SimpleVehicle) =>
      vehicle.vehicleGroups.map((group) => group.id).includes(vehicleGroupId),
    [vehicleGroupId]
  );

  useEffect(() => {
    setIsLoading(true);
    backOfficeApi.getVehicleGroup(vehicleGroupId).then((vehicleGroup) => {
      setExistingVehicleGroup(vehicleGroup);

      setIsLoading(false);
    });
  }, [vehicleGroupId]);

  return (
    <BackOfficeLayout
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.vehicleGroups.label"),
          url: urls.backOffice.settings.vehicleGroups(),
        },
      ]}
      pageTitle={t("settings.vehicleGroups.editingLabel")}
      contentMinWidth={1200}
      headerDescription={
        <div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <VehicleGroupForm
              existingVehicleGroup={existingVehicleGroup}
              onSuccessfulSave={refreshVehicleGroups}
            />
          )}
        </div>
      }
    >
      <BackOfficeLayout.Content>
        <AssignVehiclesListView
          itemBeingAssigned={{
            id: vehicleGroupId,
            label: existingVehicleGroup?.label || "",
          }}
          vehicleGroupOptions={vehicleGroupOptions}
          isVehicleAssigned={isVehicleAssigned}
          assignVehicles={async (payload) =>
            backOfficeApi.assignVehicleGroupToVehicles(vehicleGroupId, payload)
          }
        />
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
