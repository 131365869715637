import { useAllPaginatedElements } from "@inspecto/common";
import { Button, Space } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { Icon } from "src/components";

import { backOfficeApi } from "../../api";
import { CustomVehicleFieldSelectorModal } from "../customVehicleFields";
import { QuestionTypeWithAvatar } from "../QuestionTypeWithAvatar";

interface Props {
  customVehicleFieldId: string | null;
  value?: string;
  onChange?: (value: string | null) => void;
  disabled?: boolean;
}

export function SingleCustomVehicleFieldSelector(props: Props) {
  const { t } = useTranslation("backoffice");
  const dataGetter = useCallback(
    (pageNumber: number) =>
      backOfficeApi.getCustomVehicleFields({ tableParams: { pageNumber } }),
    []
  );
  const [_customVehicleFields, isLoadingCustomVehicleFields] =
    useAllPaginatedElements(dataGetter);
  const customVehicleFields = useMemo(
    () =>
      _customVehicleFields
        .filter((field) => field.id !== props.customVehicleFieldId)
        .filter((field) => field.type === "number"),
    [_customVehicleFields, props.customVehicleFieldId]
  );

  const selectedCustomVehicleField = customVehicleFields.find(
    (field) => field.id === props.value
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isLoadingCustomVehicleFields) {
    return <Skeleton width={150} height={32} />;
  }

  return (
    <>
      {selectedCustomVehicleField ? (
        <QuestionTypeWithAvatar
          questionType={selectedCustomVehicleField.type}
          label={selectedCustomVehicleField.label}
          onChangeButtonClick={
            props.disabled ? undefined : () => setIsModalOpen(true)
          }
        />
      ) : (
        <Space>
          <Button
            disabled={props.disabled}
            shape="round"
            size="small"
            onClick={() => setIsModalOpen(true)}
          >
            <Space size="small">
              <Icon icon="carSide" />
              {t("selectVehicleField")}
            </Space>
          </Button>
        </Space>
      )}
      {isModalOpen && (
        <CustomVehicleFieldSelectorModal
          value={props.value}
          onChange={(value) => {
            props.onChange?.(value);
            setIsModalOpen(false);
          }}
          onCancel={() => setIsModalOpen(false)}
          customVehicleFields={customVehicleFields}
          excludeCustomVehicleFieldIds={[]}
        />
      )}{" "}
    </>
  );
}
