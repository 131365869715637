import { useTranslation } from "react-i18next";

import { MultipleImagesImmediateUploader } from "../fileUploaders";
import { InputWrapper } from "../InputWrapper";
import { TextAreaInput } from "../TextAreaInput";
import { CommonRequirementType } from "./models";
import { MultiChoiceOrChoiceFieldComponentProps } from "./MultiChoiceOrChoiceFieldComponent";
import { getPhotosAndNoteRequirementsForChoices } from "./selectors";

type PhotosAndNoteComponentProps = Pick<
  MultiChoiceOrChoiceFieldComponentProps,
  "value" | "choices" | "fileUploaderId" | "onChange"
>;

export function PhotosAndNoteComponent(
  props: PhotosAndNoteComponentProps
): JSX.Element {
  const { t } = useTranslation();
  const selectedChoiceIds = props.value?.selectedChoiceIds || [];

  const photosAndNoteRequirements = getPhotosAndNoteRequirementsForChoices(
    props.choices,
    selectedChoiceIds
  );

  return (
    <>
      {photosAndNoteRequirements.photosRequirement !==
      CommonRequirementType.None ? (
        <InputWrapper
          isRequired={
            photosAndNoteRequirements.photosRequirement ===
            CommonRequirementType.Required
          }
          label={t("addPhotos")}
        >
          <MultipleImagesImmediateUploader
            uploaderId={props.fileUploaderId}
            onChange={(images) => props.onChange?.({ photos: images })}
            value={props.value?.photos || []}
          />
        </InputWrapper>
      ) : null}
      {photosAndNoteRequirements.noteRequirement !==
      CommonRequirementType.None ? (
        <TextAreaInput
          isRequired={
            photosAndNoteRequirements.noteRequirement ===
            CommonRequirementType.Required
          }
          value={props.value?.note || ""}
          onChange={(note) => props.onChange?.({ note: note })}
          label={t("addAComment")}
        />
      ) : null}
    </>
  );
}
