import { useTranslation } from "react-i18next";

import { urls } from "src/urls";

import { BackOfficeLayout } from "../../BackOfficeLayout";
import { TemplateTagForm } from "./TemplateTagForm";

export function CreateTemplateTagView() {
  const { t } = useTranslation("backoffice");

  return (
    <BackOfficeLayout
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.templateTags.label"),
          url: urls.backOffice.settings.templateTags(),
        },
      ]}
      pageTitle={t("settings.templateTags.editingLabel")}
    >
      <BackOfficeLayout.Content>
        <TemplateTagForm existingTemplateTag={null} />
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
