import { Button, Divider, Space, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ChargeEmployeeIconButton } from "src/components";
import { Icon } from "src/components/Icon";

import { ResponseCommon } from "../models";
import { ChargedEmployeeComponent } from "./ChargedEmployeeComponent";
import { StatusIcon } from "./StatusIcon";

const StyledDivider = styled(Divider)`
  top: 0;
  height: ${(props) => props.theme.antd.height.small};
`;

interface Props
  extends Pick<
    ResponseCommon,
    | "status"
    | "statusOverriddenAt"
    | "statusOverriddenBy"
    | "comment"
    | "chargedEmployee"
  > {
  onStatusClick?: () => Promise<void>;
  onAddCommentClick?: () => void;
  onChargeEmployeeClick?: () => void;
  addCommentTooltipText: string;
  onApplyValueClick?: () => void;
  applyValueTooltipText: string;
}

export function ProtocolResponseActions(props: Props) {
  const { t } = useTranslation("backoffice");
  const [isChangingStatus, setIsChangingStatus] = useState(false);

  const overriddenDate = useMemo(() => {
    return dayjs(props.statusOverriddenAt).format("DD.MM.YYYY");
  }, [props.statusOverriddenAt]);
  const overriddenHour = useMemo(() => {
    return dayjs(props.statusOverriddenAt).format("HH:mm");
  }, [props.statusOverriddenAt]);

  const rightActionElements = [
    ...(props.onAddCommentClick
      ? [
          <Tooltip title={props.addCommentTooltipText}>
            <Button
              size="small"
              type="default"
              shape="circle"
              icon={<Icon icon="comment" size={16} />}
              onClick={props.onAddCommentClick}
              title={props.addCommentTooltipText}
            />
          </Tooltip>,
        ]
      : []),
    ...(props.onChargeEmployeeClick
      ? [<ChargeEmployeeIconButton onClick={props.onChargeEmployeeClick} />]
      : []),
    ...(props.onApplyValueClick
      ? [
          <Tooltip title={props.applyValueTooltipText}>
            <Button
              size="small"
              type="default"
              shape="circle"
              icon={<Icon icon="shareSquare" size={16} />}
              onClick={props.onApplyValueClick}
              data-testid="apply-value-button"
              title={props.applyValueTooltipText}
            />
          </Tooltip>,
        ]
      : []),
  ];

  return (
    <>
      <Space size="small" align="center">
        <StatusIcon block status={props.status} iconSize={28} />

        {props.onStatusClick ? (
          <Tooltip
            title={
              props.status === "warning"
                ? t("protocolView.changeStatusToOppositeDisabledForWarning")
                : t("protocolView.changeStatusToOpposite")
            }
          >
            <Button
              disabled={props.status === "warning"}
              size="small"
              type="default"
              shape="circle"
              loading={isChangingStatus}
              icon={<Icon icon="exchangeAlt" size={16} />}
              onClick={async () => {
                setIsChangingStatus(true);
                await props.onStatusClick?.();
                setIsChangingStatus(false);
              }}
              title={
                props.status === "warning"
                  ? t("protocolView.changeStatusToOppositeDisabledForWarning")
                  : t("protocolView.changeStatusToOpposite")
              }
            />
          </Tooltip>
        ) : null}
        {rightActionElements.length ? (
          <>
            <StyledDivider type="vertical" />
            {rightActionElements}
          </>
        ) : null}
      </Space>
      <Typography.Paragraph>
        <blockquote>
          {props.statusOverriddenBy && (
            <Space align="start">
              <Icon icon="exchangeAlt" />
              <Typography.Text mark>
                {t("editedAtBy", {
                  date: overriddenDate,
                  hour: overriddenHour,
                  firstName: props.statusOverriddenBy.firstName,
                  lastName: props.statusOverriddenBy.lastName,
                })}
              </Typography.Text>
            </Space>
          )}
          <ChargedEmployeeComponent
            chargedEmployee={props.chargedEmployee}
            showStatusTag
          />

          {!!props.comment && (
            <div
              style={{
                paddingTop:
                  props.chargedEmployee || props.statusOverriddenBy ? 16 : 0,
              }}
            >
              <Space>
                <strong>
                  {props.comment.author.firstName}{" "}
                  {props.comment.author.lastName}
                </strong>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  {dayjs(props.comment.date).format("DD.MM.YYYY, HH:mm")}
                </Typography.Text>
              </Space>
              <pre style={{ marginTop: 6, maxHeight: 130 }}>
                <div>{props.comment.text}</div>
              </pre>
            </div>
          )}
        </blockquote>
      </Typography.Paragraph>
    </>
  );
}
