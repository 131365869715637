import { createRef, RefObject, useEffect, useMemo } from "react";

import { isElementVisibleInsideContainer } from "../utils";

interface Props {
  value?: string;
  items: { id: string }[];
}

export function useScrollToElement(
  props: Props
): [RefObject<HTMLDivElement>, Record<string, RefObject<HTMLDivElement>>] {
  const containerRef = createRef<HTMLDivElement>();
  const itemRefs = useMemo(
    () =>
      props.items.reduce<Record<string, RefObject<HTMLDivElement>>>(
        (prev, curr) => {
          return {
            ...prev,
            [curr.id]: createRef<HTMLDivElement>(),
          };
        },
        {}
      ),
    [props.items]
  );

  useEffect(() => {
    if (props.value) {
      const foundRef = itemRefs[props.value];
      if (foundRef && foundRef.current) {
        const isVisible = containerRef.current
          ? isElementVisibleInsideContainer(
              containerRef.current,
              foundRef.current
            )
          : false;

        if (!isVisible) {
          foundRef.current.scrollIntoView({
            // behavior: "smooth", <- THIS HAS TO BE DISABLED
            // due to parent <FormModal /> having disableMotion=True. Otherwise, scrolling will not work.
            block: "center",
          });
        }
      }
    }
  }, [containerRef, itemRefs, props.value]);
  return [containerRef, itemRefs];
}
