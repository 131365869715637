import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  isSignatureWithSignature,
  isSignatureWithSigner,
  RetrieveProtocolSignature,
} from "../models";

interface Props {
  signature: RetrieveProtocolSignature;
  onFillOutSignatureButtonClick: () => void;
}

export function ProtocolSignatureActions(props: Props): JSX.Element | null {
  const { t } = useTranslation("backoffice");
  const isFillButtonVisible =
    (isSignatureWithSigner(props.signature.type) && !props.signature.signer) ||
    (isSignatureWithSignature(props.signature.type) &&
      !props.signature.signature);

  return isFillButtonVisible ? (
    <Button
      shape="round"
      size="small"
      icon={<EditOutlined />}
      onClick={props.onFillOutSignatureButtonClick}
    >
      {t("protocolView.fillOutSignatureButton")}
    </Button>
  ) : null;
}
