import {
  DownloadOutlined,
  EditOutlined,
  FolderOpenOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Image,
  Drawer,
  Skeleton,
  Space,
  Typography,
  Grid,
  Row,
  Col,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { RemoveButton, UserAvatar } from "src/components";
import { myTheme } from "src/theme";
import { downloadUri, formatToDateTime, fullName } from "src/utils";

import { backOfficeApi } from "../../../api";
import { FileTypeIcon } from "../../../components";
import { useFetch } from "../../../hooks";
import { Document } from "../../../models/Document";
import { filesFolderIconColor } from "../common";
import { CreateEditDocumentModal } from "./CreateEditDocumentModal";

interface Props {
  onClose: () => void;
  onDocumentsChange: () => void;
  vehicleId: string;
  vehiclePlateNumber: string;
}

const ContentWrapper = styled.div`
  max-width: 1000px;
  min-width: 600px;
`;

const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 25px;
`;

export function DocumentsDrawer({
  vehicleId,
  vehiclePlateNumber,
  onClose,
  onDocumentsChange,
}: Props) {
  const { t } = useTranslation("backoffice");
  const breakpoint = Grid.useBreakpoint();
  const isLessThanLg = !breakpoint.lg;
  const isLessThanXl = !breakpoint.xl;

  const [state, fetchAndSetData] = useFetch(
    useCallback(
      () => backOfficeApi.documents.getVehicleDocuments(vehicleId),
      [vehicleId]
    )
  );

  const [createEditSubDrawerState, setCreateEditSubDrawerState] = useState<{
    isOpen: boolean;
    document: Document | null;
  }>({ isOpen: false, document: null });

  const updateDocuments = useCallback(() => {
    fetchAndSetData();
    onDocumentsChange();
  }, [fetchAndSetData, onDocumentsChange]);

  useEffect(() => {
    updateDocuments();
  }, [updateDocuments]);

  const leftMargin = isLessThanLg ? 50 : isLessThanXl ? 150 : 400;

  return (
    <Drawer
      open
      onClose={onClose}
      placement="right"
      contentWrapperStyle={{
        width: `calc(100% - ${leftMargin}px)`,
      }}
      bodyStyle={{
        background: myTheme.antd.layoutBodyBackground,
      }}
    >
      <ContentWrapper>
        <Image.PreviewGroup>
          <Space align="baseline" size="middle">
            <FolderOpenOutlined
              style={{
                fontSize: 24,
                color: filesFolderIconColor,
                marginLeft: 15,
              }}
            />
            <Typography.Title level={3}>
              {t("vehicleView.filesFolder.title")}{" "}
              <Typography.Text type="secondary">
                ({vehiclePlateNumber})
              </Typography.Text>
            </Typography.Title>
          </Space>

          {state.state === "loading" && <Skeleton />}
          {state.state === "error" && (
            <Alert
              message={t(
                "vehicleView.filesFolder.somethingWentWrongWhileDownloading"
              )}
              type="error"
            />
          )}
          {state.state === "dataFetched" && (
            <>
              {state.data.map((document) => (
                <Card key={document.id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, paddingRight: 15 }}>
                      <Row>
                        <Col xs={18}>
                          <div style={{ paddingRight: 20 }}>
                            <Space style={{ alignItems: "start" }}>
                              <FileTypeIcon
                                extension={document.file.extension}
                                size={30}
                              />
                              <div>
                                <div>
                                  <Typography.Text strong>
                                    {document.label}
                                  </Typography.Text>
                                </div>
                                <Typography.Text type="secondary">
                                  {formatToDateTime(dayjs(document.createdAt))}
                                </Typography.Text>
                              </div>
                            </Space>
                          </div>
                        </Col>
                        <Col
                          xs={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography.Text
                            type="secondary"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <UserAvatar
                              user={document.createdByObject}
                              size={22}
                              style={{ verticalAlign: "top", marginRight: 8 }}
                            />

                            {fullName(document.createdByObject)}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </div>
                    <Space size="small">
                      <Tooltip title={t("tableActions.download")}>
                        <Button
                          ghost
                          type="primary"
                          size="small"
                          icon={<DownloadOutlined style={{ fontSize: 16 }} />}
                          onClick={() => {
                            downloadUri(
                              document.file.fileUrl,
                              `${vehiclePlateNumber} - ${document.label}${document.file.extension}`
                            );
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={t("tableActions.edit")}>
                        <Button
                          size="small"
                          icon={<EditOutlined />}
                          onClick={() => {
                            setCreateEditSubDrawerState({
                              isOpen: true,
                              document,
                            });
                          }}
                        />
                      </Tooltip>
                      <RemoveButton
                        onRemoveConfirm={async () => {
                          await backOfficeApi.documents.deleteVehicleDocument(
                            vehicleId,
                            document.id
                          );
                          updateDocuments();
                        }}
                      />
                    </Space>
                  </div>
                </Card>
              ))}
              <BottomWrapper>
                <Button
                  block
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setCreateEditSubDrawerState({
                      isOpen: true,
                      document: null,
                    });
                  }}
                >
                  {t("vehicleView.filesFolder.createNew")}
                </Button>
              </BottomWrapper>
            </>
          )}
          {createEditSubDrawerState.isOpen && (
            <CreateEditDocumentModal
              vehicleId={vehicleId}
              document={createEditSubDrawerState.document}
              onClose={(savedDocument: Document | undefined) => {
                setCreateEditSubDrawerState({ isOpen: false, document: null });
                if (savedDocument) {
                  updateDocuments();
                }
              }}
            />
          )}
        </Image.PreviewGroup>
      </ContentWrapper>
    </Drawer>
  );
}
