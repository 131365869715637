import { ApiError } from "@inspecto/common";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FullScreenCardLogoPage } from "src/components/FullScreenCardLogoPage";
import { WarningBadge } from "src/components/WarningBadge";
import { api } from "src/utils";
import { isDevelopment } from "src/utils/isDevelopment";

import { redirectToAnotherSubdomain } from "./utils";

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ErrorWrapper = styled.div`
  margin-bottom: 10px;
  text-align: center;
  height: 20px;
`;

export function CompanySwitcher(props: {
  forceSubdomain: (subdomain: string) => Promise<void>;
}) {
  const [newSubdomain, setNewSubdomain] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const goToSubdomain = async () => {
    setIsLoading(true);
    try {
      await api.company.getBySubdomain(newSubdomain);
      redirectToAnotherSubdomain(newSubdomain);
    } catch (e) {
      if (e instanceof ApiError) {
        setErrorVisible(true);
      } else {
        throw e;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FullScreenCardLogoPage headerText={t("loggingIn")}>
      <Form
        onFinish={async (values) => {
          await goToSubdomain();
        }}
        layout="vertical"
      >
        <Form.Item
          label={t("companyName")}
          name="subdomain"
          rules={[{ required: true }]}
        >
          <Input
            addonBefore="https://"
            addonAfter=".getinspecto.com"
            value={newSubdomain}
            onChange={(event) => {
              setNewSubdomain(event.currentTarget.value);
              setErrorVisible(false);
            }}
          />
        </Form.Item>
        <ErrorWrapper>
          {errorVisible && <WarningBadge text={t("couldNotFindCompany")} />}
        </ErrorWrapper>
        <ButtonWrapper>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
            disabled={errorVisible || !newSubdomain}
          >
            {t("login")}
          </Button>
        </ButtonWrapper>
      </Form>

      {isDevelopment() && (
        <ButtonWrapper>
          <Button
            loading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await props.forceSubdomain("paweltrans");
              setIsLoading(false);
            }}
          >
            Force test domain
          </Button>
        </ButtonWrapper>
      )}
    </FullScreenCardLogoPage>
  );
}
