import { useTranslation } from "react-i18next";

import { urls } from "src/urls";

import { FiltersRow } from "../../components";
import { BackOfficeLayout } from "../BackOfficeLayout";
import { damagesContentMinWidth, DamagesList } from "../DamagesList";

export function DamagesListView() {
  const { t } = useTranslation("backoffice");

  return (
    <BackOfficeLayout
      pageTitle={t("damages.pageTitle")}
      contentMinWidth={damagesContentMinWidth}
      createPageUrl={urls.backOffice.damages.create()}
    >
      <BackOfficeLayout.Content>
        <DamagesList>
          {({
            listElement,
            statusFiltersElement,
            selectedVehicleElement,
            selectedEmployeeElement,
            customVehicleFieldElement,
            vehicleTypeElement,
            vehicleGroupsElement,
          }) => (
            <>
              <BackOfficeLayout.Filters>
                <FiltersRow>
                  {statusFiltersElement}
                  {selectedVehicleElement}
                  {selectedEmployeeElement}
                </FiltersRow>
                <FiltersRow>
                  {vehicleTypeElement}
                  {vehicleGroupsElement}
                  {customVehicleFieldElement}
                </FiltersRow>
              </BackOfficeLayout.Filters>
              {listElement}
            </>
          )}
        </DamagesList>
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
