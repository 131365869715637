import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { Dashboard } from "./Dashboard";

export function DashboardRouter() {
  return (
    <Switch>
      <Route path={urls.backOffice.dashboard()} exact>
        <Dashboard />
      </Route>
    </Switch>
  );
}
