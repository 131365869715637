import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { BackOfficeLayout } from "../../BackOfficeLayout";
import { contentMinWidth } from "./common";
import { VehicleFieldsPresetForm } from "./VehicleFieldsPresetForm";

export function CreateVehicleFieldsPresetView() {
  const history = useHistory();
  const { t } = useTranslation("backoffice");

  return (
    <BackOfficeLayout
      contentMinWidth={contentMinWidth}
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.vehicleFieldsPresets.label"),
          url: urls.backOffice.settings.vehicleFieldsPresets(),
        },
      ]}
      pageTitle={t("settings.vehicleFieldsPresets.vehicleFieldsPresetLabel")}
    >
      <BackOfficeLayout.Content>
        <VehicleFieldsPresetForm
          onSave={async (values) => {
            return await backOfficeApi.createVehicleFieldsPreset(values);
          }}
          afterSuccessfulSaveWithoutRedirect={(savedRecord) => {
            history.replace(
              urls.backOffice.settings.editVehicleFieldsPreset(savedRecord.id)
            );
          }}
        />
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
