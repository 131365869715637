import {
  faComment,
  faCommentDots,
  faCopy,
  faEdit,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFilePdf,
  faEnvelope,
  faEnvelopeOpen,
  faCalendar,
  faCalendarDays,
  faClock,
  faArrowAltCircleDown,
  faTimesCircle as farTimesCircle,
  faShareSquare,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCalculator,
  faCarSide,
  faArrowCircleLeft,
  faArrowCircleRight,
  faCamera,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faMinus,
  faExclamationCircle,
  faEye,
  faPen,
  faSearch,
  faSpinner,
  faTimesCircle,
  faTrailer,
  faGripLinesVertical,
  faGripHorizontal,
  faTruck,
  IconDefinition,
  faGripLines,
  faPlusCircle,
  faFont,
  faSignature,
  faTasks,
  faQuestionCircle,
  faTimes,
  faExchangeAlt,
  faEuroSign,
  faSyncAlt,
  faArrowRight,
  faList,
  faBolt,
  faExclamationTriangle,
  faCog,
  faInfo,
  faGripVertical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AriaAttributes, CSSProperties } from "react";
import styled, { DefaultTheme } from "styled-components";

export type IconType =
  | "trashAlt"
  | "pen"
  | "truck"
  | "trailer"
  | "checkCircle"
  | "timesCircle"
  | "timesCircleRegular"
  | "times"
  | "arrowCircleLeft"
  | "arrowCircleRight"
  | "camera"
  | "caretLeft"
  | "caretRight"
  | "check"
  | "exclamationCircle"
  | "eye"
  | "spinner"
  | "gripLinesVertical"
  | "gripHorizontal"
  | "gripVertical"
  | "gripLines"
  | "plusCircle"
  | "tasks"
  | "signature"
  | "font"
  | "questionCircle"
  | "list"
  | "search"
  | "exchangeAlt"
  | "comment"
  | "commentDots"
  | "euroSign"
  | "syncAlt"
  | "eyeRegular"
  | "eyeSlashRegular"
  | "bolt"
  | "exclamationTriangle"
  | "copy"
  | "arrowAltCircleDown"
  | "filePdf"
  | "envelope"
  | "envelopeOpen"
  | "calculator"
  | "carSide"
  | "clock"
  | "calendar"
  | "calendarDays"
  | "edit"
  | "minus"
  | "shareSquare"
  | "info"
  | "arrowRight"
  | "cog";

const fontMappings: Record<IconType, IconDefinition> = {
  trashAlt: faTrashAlt,
  pen: faPen,
  truck: faTruck,
  trailer: faTrailer,
  checkCircle: faCheckCircle,
  timesCircle: faTimesCircle,
  timesCircleRegular: farTimesCircle,
  arrowCircleLeft: faArrowCircleLeft,
  arrowCircleRight: faArrowCircleRight,
  arrowRight: faArrowRight,
  camera: faCamera,
  caretLeft: faCaretLeft,
  caretRight: faCaretRight,
  check: faCheck,
  exclamationCircle: faExclamationCircle,
  spinner: faSpinner,
  eye: faEye,
  search: faSearch,
  gripLinesVertical: faGripLinesVertical,
  gripHorizontal: faGripHorizontal,
  gripLines: faGripLines,
  gripVertical: faGripVertical,
  plusCircle: faPlusCircle,
  font: faFont,
  signature: faSignature,
  tasks: faTasks,
  questionCircle: faQuestionCircle,
  times: faTimes,
  list: faList,
  exchangeAlt: faExchangeAlt,
  comment: faComment,
  commentDots: faCommentDots,
  euroSign: faEuroSign,
  edit: faEdit,
  syncAlt: faSyncAlt,
  eyeRegular: farEye,
  eyeSlashRegular: farEyeSlash,
  bolt: faBolt,
  exclamationTriangle: faExclamationTriangle,
  copy: faCopy,
  arrowAltCircleDown: faArrowAltCircleDown,
  filePdf: faFilePdf,
  envelope: faEnvelope,
  envelopeOpen: faEnvelopeOpen,
  calculator: faCalculator,
  carSide: faCarSide,
  clock: faClock,
  calendar: faCalendar,
  calendarDays: faCalendarDays,
  cog: faCog,
  minus: faMinus,
  shareSquare: faShareSquare,
  info: faInfo,
};

export type IconColor = "white" | "dark" | "gray" | "green" | "red" | "primary";

export interface IconProps extends AriaAttributes {
  icon: IconType;
  color?: IconColor;
  size?: number;
  spin?: boolean;
  style?: CSSProperties;
}

function getColor(color: IconColor, theme: DefaultTheme) {
  switch (color) {
    case "white":
      return theme.colors.background;
    case "dark":
      return theme.colors.text.dark;
    case "gray":
      return theme.colors.gray400;
    case "green":
      return theme.colors.status.ok;
    case "primary":
      return theme.colors.primary.primary;
    case "red":
      return theme.colors.status.notOk;
  }
}

const IconWrapper = styled.div<{ color?: IconColor; size?: number }>`
  vertical-align: middle;

  ${(props) => props.color && `color: ${getColor(props.color, props.theme)}`};
  ${(props) => props.size && `font-size: ${props.size}px;`}
  ${(props) => props.size && `height: ${props.size}px;`}
  ${(props) => props.size && `line-height: ${props.size}px;`}

  .fa-spin {
    animation: fa-spin 0.8s linear infinite;
  }
`;

export function Icon({ color, size, icon, spin, ...props }: IconProps) {
  return (
    <IconWrapper color={color} size={size} {...props}>
      <FontAwesomeIcon icon={fontMappings[icon]} spin={spin} />
    </IconWrapper>
  );
}
