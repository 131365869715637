import { useTranslation } from "react-i18next";
import styled from "styled-components";

const DividerVertical = styled.div<{ $height: number }>`
  background-color: ${(props) => props.theme.colors.whitePanelBorderColor};
  margin: auto;
  width: 1px;
  height: ${(props) => props.$height}px;
`;

const DividerHorizontal = styled.div`
  background-color: ${(props) => props.theme.colors.whitePanelBorderColor};
  margin: auto;
  height: 1px;
  flex: 1 1 100%;
`;

const OrText = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 0;
`;

export function VerticalOrDivider(props: { height: number }) {
  const { t } = useTranslation();

  return (
    <div>
      <DividerVertical $height={props.height} />
      <OrText>{t("or")}</OrText>
      <DividerVertical $height={props.height} />
    </div>
  );
}

const HorizontalDividerWrapper = styled.div<{ $height: number }>`
  height: ${(props) => props.$height}px;
  display: flex;
  justify-content: center;
`;

export function HorizontalOrDivider(props: { height: number }) {
  const { t } = useTranslation();

  return (
    <HorizontalDividerWrapper $height={props.height}>
      <DividerHorizontal />
      <OrText
        style={{
          flex: "1 0 0",
          padding: "0 15px",
          lineHeight: `${props.height}px`,
        }}
      >
        {t("or")}
      </OrText>
      <DividerHorizontal />
    </HorizontalDividerWrapper>
  );
}
