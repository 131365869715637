import { useMemo } from "react";

import { SingleImageImmediateUploader } from "src/components";

import { useProtocolFillerContext } from "../contexts";
import { PhotoActionQuestion } from "../models";

export function PhotoActionQuestionComponent(props: {
  question: PhotoActionQuestion;
}) {
  const { setPhotoActionResponse, responses } = useProtocolFillerContext();
  const { photoActionResponses } = responses;

  const response = useMemo(
    () => photoActionResponses[props.question.id],
    [photoActionResponses, props.question.id]
  );

  return (
    <SingleImageImmediateUploader
      onChange={(uploadedImage) => {
        setPhotoActionResponse(props.question.id, uploadedImage);
      }}
      value={response}
      uploaderId={props.question.id}
    />
  );
}
