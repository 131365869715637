import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Spinner } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { useFetch, useVehicleTypes } from "../../../hooks";
import { GetLinkType } from "../../../models/LinkType";
import { BackOfficeLayout } from "../../BackOfficeLayout";
import { LinkTypeForm } from "./LinkTypeForm";

export function EditLinkTypeView() {
  const { t } = useTranslation("backoffice");

  const { linkTypeId: existingLinkTypeId } =
    useParams<{ linkTypeId: string }>();

  const [existingLinkTypeState, fetchAndSetData] = useFetch<GetLinkType>(
    useCallback(
      async () => await backOfficeApi.linkTypes.getElement(existingLinkTypeId),
      [existingLinkTypeId]
    )
  );

  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();

  useEffect(() => {
    fetchAndSetData();
  }, [fetchAndSetData]);

  return (
    <BackOfficeLayout
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.linkTypes.listLabel"),
          url: urls.backOffice.settings.linkTypes.baseView(),
        },
      ]}
      pageTitle={t("settings.linkTypes.editLabel")}
      contentMinWidth={1200}
    >
      <BackOfficeLayout.Content>
        {isLoadingVehicleTypes || existingLinkTypeState.state === "loading" ? (
          <Spinner />
        ) : (
          <LinkTypeForm
            onSave={(el) => {
              if (el.id) {
                return backOfficeApi.linkTypes.update(el.id, el);
              } else {
                return backOfficeApi.linkTypes.create(el);
              }
            }}
            vehicleTypeOptions={vehicleTypeOptions}
            initialValue={existingLinkTypeState.data as GetLinkType}
          />
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
