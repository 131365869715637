import { useCompany } from "@inspecto/common";
import { Button, Form, Input, Modal, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CommonForm,
  ImmediateImageUploadersContextProvider,
  SingleFileImmediateUploader,
} from "src/components";

import { backOfficeApi } from "../../../api";
import { Document } from "../../../models/Document";

interface Props {
  vehicleId: string;
  document: Document | null;
  onClose: (savedDocument?: Document) => void;
}

export function CreateEditDocumentModal(props: Props) {
  const [form] = Form.useForm<Document>();
  const { allowStoringHighQualityImages } = useCompany();
  const { t } = useTranslation("backoffice");
  const [isSavingForm, setIsSavingForm] = useState(false);

  const isEditingExistingDocument = !!props.document?.id;

  return (
    <Modal
      open
      onCancel={() => props.onClose()}
      title={
        isEditingExistingDocument
          ? t("vehicleView.filesFolder.edit")
          : t("vehicleView.filesFolder.create")
      }
      footer={
        <Space>
          <Button onClick={() => props.onClose()} loading={isSavingForm}>
            {t("cancel")}
          </Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            loading={isSavingForm}
          >
            {t("save")}
          </Button>
        </Space>
      }
    >
      <ImmediateImageUploadersContextProvider
        compressionPreset={
          allowStoringHighQualityImages ? "highQuality" : "standard"
        }
      >
        <CommonForm
          form={form}
          layout="vertical"
          saveCallback={async (data: Document) => {
            setIsSavingForm(true);
            let savedDocument: Document | undefined = undefined;
            try {
              if (isEditingExistingDocument) {
                savedDocument =
                  await backOfficeApi.documents.editVehicleDocument(
                    props.vehicleId,
                    data
                  );
              } else {
                savedDocument =
                  await backOfficeApi.documents.createVehicleDocument(
                    props.vehicleId,
                    data
                  );
              }
              props.onClose(savedDocument);
            } finally {
              setIsSavingForm(false);
            }
          }}
          initialValues={props.document || undefined}
        >
          <Form.Item name="id" hidden />
          <Form.Item
            name="label"
            rules={[{ required: true }]}
            label={t("vehicleView.filesFolder.label")}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="file"
            rules={[{ required: true }]}
            label={t("vehicleView.filesFolder.file")}
          >
            <SingleFileImmediateUploader uploaderId="edit-or-create-document" />
          </Form.Item>
        </CommonForm>
      </ImmediateImageUploadersContextProvider>
    </Modal>
  );
}
