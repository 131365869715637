import { uniqBy } from "lodash";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { protocolFillerApi } from "../api";
import { Template } from "../models";

interface TemplateListFilters {
  vehicleTypeId?: string;
  vehicleGroupIds?: string[];
  selectedTemplateTagIds?: string[];
}

interface TemplatesContext {
  templates: Template[];
  templateTags: Template["templateTags"];
  loadingState: "loading" | "error" | "loaded";
  templateListFilters: TemplateListFilters;

  reloadTemplates: () => void;
  getTemplateById: (templateId: string) => Template | null;
  setTemplateListFilters: (
    setFilters: (filters: TemplateListFilters) => TemplateListFilters
  ) => void;
}

const templatesContext = createContext<TemplatesContext>({
  templates: [],
  templateListFilters: {},
  templateTags: [],
  loadingState: "loading",
  reloadTemplates: () => {},
  getTemplateById: () => null,
  setTemplateListFilters: () => {},
});

const { Provider } = templatesContext;

function filterTemplateByVehicleTypeId(
  template: Template,
  templateListFilters: TemplateListFilters
) {
  return (
    !templateListFilters.vehicleTypeId ||
    template.vehicleType.includes(templateListFilters.vehicleTypeId)
  );
}

function filterTemplateByVehicleGroupIds(
  template: Template,
  templateListFilters: TemplateListFilters
) {
  return (
    template.vehicleGroups.length === 0 ||
    template.vehicleGroups.some((group) =>
      templateListFilters.vehicleGroupIds?.includes(group)
    )
  );
}
export function TemplatesProvider(props: PropsWithChildren<{}>) {
  const [templates, setTemplates] = useState<TemplatesContext["templates"]>([]);
  const [templateListFilters, setTemplateListFilters] =
    useState<TemplateListFilters>({});

  const [loadingState, setLoadingState] =
    useState<TemplatesContext["loadingState"]>("loading");

  const filteredTemplateTags = useMemo(
    () =>
      uniqBy(
        templates
          .filter((template) =>
            filterTemplateByVehicleGroupIds(template, templateListFilters)
          )
          .filter((template) =>
            filterTemplateByVehicleTypeId(template, templateListFilters)
          )
          .flatMap((template) => template.templateTags),
        "id"
      ),
    [templateListFilters, templates]
  );

  const reloadTemplates = useCallback(() => {
    setLoadingState("loading");
    protocolFillerApi
      .getTemplates()
      .then((templates) => {
        setTemplates(templates);
        setLoadingState("loaded");
      })
      .catch(() => setLoadingState("error"));
  }, []);

  const getTemplateById = useCallback(
    (templateId: string) => {
      if (loadingState !== "loaded") {
        return null;
      }
      const template = templates.find((template) => template.id === templateId);

      return template || null;
    },
    [templates, loadingState]
  );

  const filteredTemplates: TemplatesContext["templates"] = useMemo(() => {
    if (loadingState !== "loaded") {
      return [];
    }
    return templates
      .filter((template) =>
        filterTemplateByVehicleGroupIds(template, templateListFilters)
      )
      .filter((template) =>
        filterTemplateByVehicleTypeId(template, templateListFilters)
      )
      .filter(
        (template) =>
          !templateListFilters.selectedTemplateTagIds ||
          !templateListFilters.selectedTemplateTagIds.length ||
          templateListFilters.selectedTemplateTagIds.every((selectedTag) =>
            template.templateTags.map((tag) => tag.id).includes(selectedTag)
          )
      );
  }, [templates, loadingState, templateListFilters]);

  useEffect(() => {
    reloadTemplates();
  }, [reloadTemplates]);

  return (
    <Provider
      value={{
        loadingState,
        templates: filteredTemplates,
        templateTags: filteredTemplateTags,
        setTemplateListFilters,
        reloadTemplates,
        getTemplateById,
        templateListFilters,
      }}
    >
      {props.children}
    </Provider>
  );
}

export function useTemplates() {
  return useContext(templatesContext);
}
