import { Input, Switch, TableColumnType, Tag } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { fullNameReversed } from "src/utils";

import { AssignUnassignPayload, backOfficeApi } from "../api";
import { CommonViewState } from "../contexts";
import { ListEmployee } from "../models";
import { AssignItemsListView } from "./AssignItemsListView";
import { FilterItem, FiltersRow, SelectableRowRequirements } from "./ListView";

export interface AssignUsersListViewProps {
  header?: string;
  isUserAssigned: (user: ListEmployee) => boolean;
  assignUsers: (payload: AssignUnassignPayload) => Promise<void>;
  extraFilters?: JSX.Element;
  extraColumns: (
    selectedRows: ListEmployee[]
  ) => TableColumnType<ListEmployee>[];
  selectableRowRequirements?: SelectableRowRequirements<ListEmployee>;
}

export function AssignUsersListView({
  extraColumns,
  ...props
}: AssignUsersListViewProps): JSX.Element {
  const { t } = useTranslation("backoffice");

  const columns: (
    selectedRows: ListEmployee[]
  ) => TableColumnType<ListEmployee>[] = useCallback(
    (selectedRows) => [
      {
        title: t("name"),
        width: "auto",
        render: (user: ListEmployee) => (
          <>
            {!user.isActive && (
              <Tag color="red">{t("employeesView.deletedUserTag")}</Tag>
            )}
            {fullNameReversed(user)}
          </>
        ),
      },
      {
        dataIndex: "email",
        title: t("email"),
        width: "auto",
        render: (value) => value || "-",
      },
      {
        dataIndex: "userRoleName",
        title: t("userRole"),
        width: "140px",
        render: (value) => t(value),
      },
      ...extraColumns(selectedRows),
    ],
    [extraColumns, t]
  );

  const dataGetter = useCallback(
    (filters: CommonViewState["assignUsersListView"]) => {
      return backOfficeApi.getAllEmployees({
        ...filters,
        userRoles: [],
        includeInactive: filters.includeInactive,
        searchIn: "name",
        status: "all",
        emailFilter: "all",
      });
    },
    []
  );

  return (
    <AssignItemsListView
      title={
        typeof props.header === "undefined"
          ? t("assignUsersList.title")
          : props.header
      }
      selectableRowRequirements={props.selectableRowRequirements}
      commonStateViewName="assignUsersListView"
      debouncedFilterProps={["searchTerm"]}
      dataGetter={dataGetter}
      isItemAssigned={props.isUserAssigned}
      assignItems={props.assignUsers}
      filters={
        <>
          <FiltersRow>
            <FilterItem
              label={t("employeesView.showDeletedUsersSwitchLabel")}
              valuePropName="checked"
              name="includeInactive"
            >
              <Switch />
            </FilterItem>
          </FiltersRow>
          <FiltersRow>
            <FilterItem name="searchTerm">
              <Input.Search allowClear placeholder={t("search")} />
            </FilterItem>
            {props.extraFilters}
          </FiltersRow>
        </>
      }
      columns={columns}
    />
  );
}
