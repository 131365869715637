import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { TemplateEditorView } from "./TemplateEditorView";
import { TemplatesListView } from "./TemplatesListView";

export function TemplateBuilderRouter() {
  return (
    <Switch>
      <Route path={urls.backOffice.templateBuilder()} exact>
        <TemplatesListView />
      </Route>
      <Route path={urls.backOffice.templateBuilderNew()} exact>
        <TemplateEditorView />
      </Route>
      <Route path={urls.backOffice.templateBuilderEdit()} exact>
        <TemplateEditorView />
      </Route>
      <Route path={urls.backOffice.templateBuilderClone()} exact>
        <TemplateEditorView isCloning />
      </Route>
    </Switch>
  );
}
