import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  vehicleTypeOptions: { label: string; value: string }[];
}

export function VehicleTypeFormItem(props: Props) {
  const { t } = useTranslation("backoffice");
  const vehicleTypeOptionsWithAllOption = props.vehicleTypeOptions.concat([
    {
      label: t("reportsView.filters.allVehicleTypesLabel"),
      value: "",
    },
  ]);

  return (
    <Form.Item label={t("vehicleType")} name="vehicleType">
      <Select
        style={{ maxWidth: 400 }}
        options={vehicleTypeOptionsWithAllOption}
      />
    </Form.Item>
  );
}
