import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { CompanyDepartmentsListView } from "./CompanyDepartmentsListView";
import { CreateCompanyDepartmentView } from "./CreateCompanyDepartmentView";
import { EditCompanyDepartmentRouter } from "./EditCompanyDepartmentRouter";

export function CompanyDepartmentsRouter() {
  return (
    <Switch>
      <Route path={urls.backOffice.settings.companyDepartments()} exact>
        <CompanyDepartmentsListView />
      </Route>
      <Route path={urls.backOffice.settings.createCompanyDepartment()} exact>
        <CreateCompanyDepartmentView />
      </Route>
      <Route path={urls.backOffice.settings.editCompanyDepartment()}>
        <EditCompanyDepartmentRouter />
      </Route>
    </Switch>
  );
}
