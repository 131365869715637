import { Image, ImageProps, Space } from "antd";

import emptyPhotoImage from "src/static/empty-photo.svg";

interface Props {
  images: { src: string; alt: string }[];
  allowDeletion?: boolean;
  height?: ImageProps["height"];
  width?: ImageProps["width"];
  onDeleteImage?(imageIndex: number): void;
}

export function ImagesGallery(props: Props) {
  return (
    <Image.PreviewGroup>
      <Space wrap>
        {props.images.map((image) => (
          <Image
            preview={!image.src.length ? false : undefined}
            alt={image.alt}
            src={image.src || emptyPhotoImage}
            key={image.src}
            height={props.height}
            width={props.width}
          />
        ))}
      </Space>
    </Image.PreviewGroup>
  );
}
