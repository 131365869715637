import { Button, Input, Modal } from "antd";
import { debounce } from "lodash";
import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ListCustomVehicleField } from "../../models";
import { normalizeStringForSearch } from "../../utils";
import { CustomVehicleFieldRadioButtonsSelector } from "../CustomVehicleFieldRadioButtonsSelector";

interface Props {
  value?: string;
  onChange?: (value: string | null) => void;
  onCancel: () => void;
  customVehicleFields: ListCustomVehicleField[];
  excludeCustomVehicleFieldIds: string[];
}

export function CustomVehicleFieldSelectorModal(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  const [searchValue, setSearchValue] = useState<string>("");
  const handleSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setSearchValue(e.target.value),
    []
  );

  const customVehicleFieldsAvailableForSelection = useMemo(
    () =>
      props.customVehicleFields.filter(
        (field) => !props.excludeCustomVehicleFieldIds.includes(field.id)
      ),
    [props.excludeCustomVehicleFieldIds, props.customVehicleFields]
  );

  const customVehicleFieldsAfterSearch =
    customVehicleFieldsAvailableForSelection.filter((field) =>
      normalizeStringForSearch(field.label).includes(
        normalizeStringForSearch(searchValue)
      )
    );

  return (
    <Modal
      open
      width={600}
      title={t("selectVehicleField")}
      footer={[<Button onClick={props.onCancel}>{t("cancel")}</Button>]}
      onCancel={props.onCancel}
    >
      <Input.Search
        allowClear
        autoFocus
        autoComplete="off"
        placeholder={t("search")}
        loading={false}
        style={{
          marginBottom: 14,
        }}
        onChange={debounce(handleSearch, 100)}
      />
      <CustomVehicleFieldRadioButtonsSelector
        value={props.value}
        onChange={props.onChange}
        customVehicleFields={customVehicleFieldsAfterSearch}
      />
    </Modal>
  );
}
