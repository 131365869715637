import { Checkbox, Input, InputNumber, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { NumberFieldComponent } from "src/components";

import {
  CustomVehicleFieldConditionOptionArgument,
  CustomVehicleFieldConditionOptionArgumentType,
} from "../../models";
import { SingleCustomVehicleFieldSelector } from "./SingleCustomVehicleFieldSelector";

interface Props {
  customVehicleFieldId: string | null;
  input: CustomVehicleFieldConditionOptionArgument;
  value: number | string | null;
  onChange(newValue: number | string | null): void;
  disabled?: boolean;
}

function ConditionInputComponentByType(props: {
  customVehicleFieldId: string | null;
  inputType: CustomVehicleFieldConditionOptionArgumentType;
  onChange: Props["onChange"];
  value: Props["value"];
  disabled: boolean;
}) {
  switch (props.inputType) {
    case "text":
      return (
        <Input
          value={(props.value as string) || ""}
          onChange={(event) => props.onChange(event.target.value)}
          size="small"
          disabled={props.disabled}
        />
      );
    case "decimal":
      return (
        <NumberFieldComponent
          value={props.value === null ? undefined : (props.value as number)}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      );
    case "integer":
      return (
        <InputNumber
          value={props.value === null ? undefined : (props.value as number)}
          onChange={props.onChange}
          disabled={props.disabled}
          precision={0}
        />
      );
    case "CustomVehicleField":
      return (
        <SingleCustomVehicleFieldSelector
          customVehicleFieldId={props.customVehicleFieldId}
          value={typeof props.value === "string" ? props.value : undefined}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      );
  }
}

export function ConditionInput(props: Props) {
  const { t } = useTranslation("backoffice");
  const [isEnabled, setIsEnabled] = useState(
    !props.input.optional || (props.value !== "" && props.value !== null)
  );

  return (
    <Space direction="vertical" size={4}>
      {props.input.optional ? (
        <Checkbox
          disabled={props.disabled}
          checked={isEnabled}
          onChange={(e) => {
            const { checked } = e.target;
            if (!checked) {
              props.onChange(null);
            }
            setIsEnabled(checked);
          }}
        >
          {t(`conditions.inputs.${props.input.label}`)}
        </Checkbox>
      ) : (
        t(`conditions.inputs.${props.input.label}`)
      )}
      <ConditionInputComponentByType
        customVehicleFieldId={props.customVehicleFieldId}
        inputType={props.input.type}
        onChange={props.onChange}
        value={props.value}
        disabled={!isEnabled || !!props.disabled}
      />
    </Space>
  );
}
