import { Col, Row, Space, Typography } from "antd";
import React, { ReactNode } from "react";

import { CustomVehicleFieldValueStatus } from "../models";
import { StatusIcon } from "./StatusIcon";

interface Props {
  label: string;
  status?: CustomVehicleFieldValueStatus;
  children: ReactNode;
}

export function ProtocolResponseLabelWithStatus(props: Props): JSX.Element {
  const labelElement = <Typography.Text strong>{props.label}</Typography.Text>;
  return (
    <Row gutter={[16, 6]}>
      <Col xs={24}>
        {!!props.status ? (
          <Space align="start">
            <StatusIcon status={props.status} />
            {labelElement}
          </Space>
        ) : (
          labelElement
        )}
      </Col>
      <Col xs={24}>{props.children}</Col>
    </Row>
  );
}
