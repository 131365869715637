import { DownOutlined, PoweroffOutlined } from "@ant-design/icons";
import { Button, Progress, Typography, Space, Avatar, Popover } from "antd";
import { ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAuthentication } from "src/authentication";
import { FlagImage, Spinner, UserAvatar } from "src/components";
import { TopBarLayout } from "src/components/TopBarLayout";
import { getLanguageConfig, languageContext } from "src/locales";
import { myTheme } from "src/theme";
import { fullName } from "src/utils";

import { useVehicleFromParams } from "../contexts";
import { SelectLanguageModal } from "./SelectLanguageModal";

export const protocolFillerTopBarLayoutMaxWidth = "500px";

const SelectedTemplateLabel = styled.div`
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PageCounter = styled.div`
  flex: 1 0 auto;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BreadcrumbsAndProgressWrapper = styled.div`
  padding: 0 8px;
  font-size: 13px;
`;

const TopRightContainer = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  height: 36px;
`;

const UsernameAndPlateNumber = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;

const PlateNumber = styled.div`
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
  margin-left: 20px;
`;

const ProtocolNameAndCounterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 0 6px;
`;

const NoWrapEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const AppWrapper = styled.div`
  flex: 1;
  z-index: 1;
  position: relative;
`;

const ProgressBarWrapper = styled.div`
  font-size: 0;
  line-height: 0;
  margin-bottom: 10px;
`;

const BottomNavigationWrapper = styled.div`
  z-index: 2;
  height: 96px;
`;

const BottomNavigation = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  border-top: 1px solid ${(props) => props.theme.colors.whitePanelBorderColor};
  background-color: ${(props) => props.theme.colors.background};
`;

const ButtonsWrapper = styled.div`
  margin: auto;
  max-width: ${protocolFillerTopBarLayoutMaxWidth};
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 10px 0;
`;

const AvatarsSectionWrapper = styled.div`
  flex: 0 0 auto;
  overflow: visible;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 10px;
`;

const ChangeLanguageButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 12px;
`;

function ProtocolNameAndProgress(props: {
  protocolName?: string;
  progressText?: string;
  pageName?: string;
  percent: number;
}): JSX.Element {
  return (
    <BreadcrumbsAndProgressWrapper>
      {props.protocolName && (
        <ProtocolNameAndCounterWrapper>
          <SelectedTemplateLabel>
            <Typography.Text>{props.protocolName}</Typography.Text>
          </SelectedTemplateLabel>
          {props.progressText && (
            <PageCounter>
              <Typography.Text>{props.progressText}</Typography.Text>
            </PageCounter>
          )}
        </ProtocolNameAndCounterWrapper>
      )}
      <ProgressBarWrapper>
        <Progress
          status="active"
          percent={props.percent}
          showInfo={false}
          strokeColor={myTheme.colors.primary.primary}
          trailColor="#fff"
          strokeWidth={5}
          style={{ lineHeight: 0, fontSize: 0 }}
        />
      </ProgressBarWrapper>
      <div style={{ margin: "5px 0" }}>
        <Typography.Text strong style={{ fontSize: 15 }}>
          {props.pageName}
        </Typography.Text>
      </div>
    </BreadcrumbsAndProgressWrapper>
  );
}

export interface PageLayoutProgress {
  current: number;
  total: number;
  protocolName: string;
  pageName: string;
}

interface Props {
  children: ReactNode;
  onLogoClick?: (navigateToLandingPage: () => void) => void;
  onNextClick?: () => void;
  onBackClick?: () => void;
  progress?: PageLayoutProgress | "finish";
  overlaySpinnerText?: string;
}

export function PageLayout({
  onLogoClick = (navigateToLandingPage) => navigateToLandingPage(),
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation("protocolFiller");
  const { t: tTranslation } = useTranslation("translation");

  const { user, logoutAndRedirectToLandingPage, reloadUser, hasPerm } =
    useAuthentication();
  const { value: currentLanguage } = useContext(languageContext);
  const { vehicle: vehicleFromParams } = useVehicleFromParams();
  const [isLanguageSelectorModalVisible, setIsLanguageSelectorModalVisible] =
    useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  return (
    <PageWrapper>
      <AppWrapper>
        <TopBarLayout
          maxWidth={protocolFillerTopBarLayoutMaxWidth}
          onLogoClick={onLogoClick}
          topRightContent={
            user ? (
              <TopRightContainer data-test="top-bar-info-area">
                <UsernameAndPlateNumber>
                  {vehicleFromParams && (
                    <PlateNumber>
                      <Typography.Text strong>
                        {vehicleFromParams.plateNumber}
                      </Typography.Text>
                    </PlateNumber>
                  )}
                </UsernameAndPlateNumber>
                <Popover
                  open={isUserMenuOpen}
                  onOpenChange={setIsUserMenuOpen}
                  trigger="click"
                  placement="bottomRight"
                  overlayInnerStyle={{
                    width: 230,
                    margin: 0,
                  }}
                  title={
                    <NoWrapEllipsis style={{ padding: "5px 0" }}>{`${fullName(
                      user
                    )}`}</NoWrapEllipsis>
                  }
                  content={
                    <div>
                      {hasPerm("protocol_filler.change_language") && (
                        <ChangeLanguageButton
                          block
                          onClick={() => {
                            setIsLanguageSelectorModalVisible(true);
                            setIsUserMenuOpen(false);
                          }}
                        >
                          <NoWrapEllipsis>
                            <Space size="small">
                              <Avatar size={28}>
                                <FlagImage
                                  alt={user.protocolFillerLanguage}
                                  src={
                                    getLanguageConfig(currentLanguage.language)
                                      .countryFlag
                                  }
                                />
                              </Avatar>
                              <Typography.Text strong>
                                {tTranslation(
                                  `languages.${currentLanguage.language}`
                                )}
                              </Typography.Text>
                            </Space>
                          </NoWrapEllipsis>
                          <DownOutlined style={{ marginLeft: 8 }} />
                        </ChangeLanguageButton>
                      )}

                      <Button
                        onClick={logoutAndRedirectToLandingPage}
                        block
                        ghost
                        type="primary"
                        size="small"
                        icon={<PoweroffOutlined />}
                      >
                        {t("logout")}
                      </Button>
                    </div>
                  }
                >
                  <AvatarsSectionWrapper>
                    {hasPerm("protocol_filler.change_language") && (
                      <Avatar
                        data-testid="language-flag-avatar"
                        size={28}
                        style={{
                          position: "relative",
                          marginRight: -8,
                          zIndex: -1,
                        }}
                      >
                        <FlagImage
                          alt={user.protocolFillerLanguage}
                          src={
                            getLanguageConfig(currentLanguage.language)
                              .countryFlag
                          }
                        />
                      </Avatar>
                    )}
                    <UserAvatar
                      data-testid="logout-menu"
                      user={user}
                      style={{
                        boxSizing: "content-box",
                        border: "2px solid #fff",
                      }}
                    />
                  </AvatarsSectionWrapper>
                </Popover>
              </TopRightContainer>
            ) : undefined
          }
        >
          {props.overlaySpinnerText && (
            <Spinner text={props.overlaySpinnerText} />
          )}
          {typeof props.progress !== "undefined" && (
            <>
              {props.progress === "finish" ? (
                <ProtocolNameAndProgress percent={100} />
              ) : (
                <>
                  <ProtocolNameAndProgress
                    protocolName={props.progress.protocolName}
                    progressText={`${props.progress.current}/${props.progress.total}`}
                    percent={
                      props.progress.total === 0
                        ? 0
                        : (props.progress.current * 100) / props.progress.total
                    }
                    pageName={props.progress.pageName}
                  />
                </>
              )}
            </>
          )}
          {props.children}
        </TopBarLayout>
      </AppWrapper>
      {(props.onNextClick || props.onBackClick) && (
        <BottomNavigationWrapper>
          <BottomNavigation>
            <ButtonsWrapper>
              <ButtonWrapper>
                {props.onBackClick && (
                  <Button
                    block
                    ghost
                    type="primary"
                    onClick={props.onBackClick}
                  >
                    {t("back")}
                  </Button>
                )}
              </ButtonWrapper>
              <ButtonWrapper>
                {props.onNextClick && (
                  <Button block type="primary" onClick={props.onNextClick}>
                    {t("next")}
                  </Button>
                )}
              </ButtonWrapper>
            </ButtonsWrapper>
          </BottomNavigation>
        </BottomNavigationWrapper>
      )}
      {user && isLanguageSelectorModalVisible && (
        <SelectLanguageModal
          userId={user.id}
          currentUserProtocolFillerLanguage={user.protocolFillerLanguage}
          reloadUser={reloadUser}
          closeModal={() => setIsLanguageSelectorModalVisible(false)}
        />
      )}
    </PageWrapper>
  );
}
