import { BellOutlined, PlusOutlined } from "@ant-design/icons";
import { fetchAllPaginatedElements } from "@inspecto/common";
import { Avatar, Button, Card, Form, List, message, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as uuid from "uuid";

import { CommonForm, MaxWidth700FormWrapper } from "src/components";
import { myTheme } from "src/theme";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { useEmployeesWithEmailOptions } from "../../../hooks/useEmployeesWithEmailOptions";
import { ListTemplate } from "../../../models";
import { BackOfficeLayout } from "../../BackOfficeLayout";
import { StickySaveButtons } from "../../vehicles/components/StickySaveButtons";
import { SingleNewProtocolNotificationSetting } from "./models";
import { NewProtocolNotificationsSettingInput } from "./NewProtocolNotificationsSettingInput";

interface NotificationSettingsFormState {
  newProtocolNotificationsSettings: {
    [key: string]: SingleNewProtocolNotificationSetting[];
  };
}

export function NewProtocolNotificationsSettings() {
  const { t } = useTranslation("backoffice");
  const history = useHistory();

  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettingsFormState>({
      newProtocolNotificationsSettings: {},
    });
  const [isSaving, setIsSaving] = useState(false);
  const [templates, setTemplates] = useState<ListTemplate[]>([]);
  const [isLoadingNotificationSettings, setIsLoadingNotificationSettings] =
    useState(true);
  const [form] = Form.useForm();

  const [isLoadingTemplateTypes, setIsLoadingTemplateTypes] = useState(true);

  useEffect(() => {
    backOfficeApi.notifications
      .getNotificationSettings()
      .then((notificationSettings) => {
        const newProtocolNotificationsSettings =
          notificationSettings.newProtocolNotificationsSettings.reduce<
            NotificationSettingsFormState["newProtocolNotificationsSettings"]
          >((previousValue, currentValue) => {
            const currentTemplateValue =
              previousValue[currentValue.template] || [];

            currentTemplateValue.push({
              ...currentValue,
              frontendId: uuid.v4(),
            });

            previousValue[currentValue.template] = currentTemplateValue;

            return previousValue;
          }, {});

        setNotificationSettings({
          ...notificationSettings,
          newProtocolNotificationsSettings,
        });
        setIsLoadingNotificationSettings(false);
      });
  }, []);

  useEffect(() => {
    fetchAllPaginatedElements((pageNumber) =>
      backOfficeApi.getTemplates({
        tableParams: {
          pageNumber,
        },
      })
    ).then((templates) => {
      setTemplates(templates);
      setIsLoadingTemplateTypes(false);
    });
  }, []);

  const { employeeManagerOptions, isLoadingEmployees } =
    useEmployeesWithEmailOptions();

  return (
    <BackOfficeLayout
      breadcrumbs={[
        {
          label: t("settings.label"),
        },
        {
          label: t("settings.notifications.label"),
          url: urls.backOffice.settings.notifications.baseView(),
        },
      ]}
      pageTitle={t("settings.notifications.notificationTypes.newProtocols")}
    >
      <BackOfficeLayout.Content>
        {isLoadingEmployees ||
        isLoadingNotificationSettings ||
        isLoadingTemplateTypes ? (
          <Skeleton />
        ) : (
          <MaxWidth700FormWrapper>
            <CommonForm<NotificationSettingsFormState>
              onIsSavingChange={setIsSaving}
              initialValues={notificationSettings}
              saveCallback={async (data) => {
                const newProtocolNotificationsSettings: NotificationSettingsFormState["newProtocolNotificationsSettings"][string][number][] =
                  [];
                for (const templateId in data.newProtocolNotificationsSettings) {
                  const templateNotificationSettings =
                    data.newProtocolNotificationsSettings[templateId];
                  if (templateNotificationSettings) {
                    newProtocolNotificationsSettings.push(
                      ...templateNotificationSettings
                    );
                  }
                }

                await backOfficeApi.notifications.saveNotificationSettings({
                  newProtocolNotificationsSettings,
                });

                message.success(t("settings.notifications.savedSuccessfully"));
              }}
              onSuccessfulSave={(_formValues, _savedRecord, shouldRedirect) => {
                if (shouldRedirect) {
                  history.push(
                    urls.backOffice.settings.notifications.baseView()
                  );
                }
              }}
              form={form}
            >
              {templates.map((template) => (
                <Card
                  size="small"
                  key={template.id}
                  style={{ marginBottom: 30 }}
                  title={
                    <>
                      <Avatar
                        icon={<BellOutlined />}
                        style={{
                          marginRight: 10,
                          color: myTheme.colors.text.dark,
                          backgroundColor: myTheme.colors.text.light,
                        }}
                      />
                      {t("settings.notifications.templateLabel", {
                        templateLabel: template.label,
                      })}
                    </>
                  }
                >
                  <Form.List
                    name={["newProtocolNotificationsSettings", template.id]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        <List size="small">
                          {fields.map((field) => (
                            <NewProtocolNotificationsSettingInput
                              key={field.key}
                              formListFieldData={field}
                              employeeManagerOptions={employeeManagerOptions} // TODO: Fix this, doesn't work if there's two empty ones.
                              remove={() => remove(field.name)}
                              allValues={form.getFieldValue([
                                "newProtocolNotificationsSettings",
                                template.id,
                              ])}
                              currentValue={form.getFieldValue([
                                "newProtocolNotificationsSettings",
                                template.id,
                                field.name,
                              ])}
                            />
                          ))}
                          <List.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add({
                                  frontendId: uuid.v4(),
                                  template: template.id,
                                  user: null,
                                  protocolStatusCondition: "onNotOk",
                                  id: null,
                                });
                              }}
                              block
                              icon={<PlusOutlined />}
                            >
                              {t(
                                "settings.notifications.addNotificationForEmployee"
                              )}
                            </Button>
                          </List.Item>
                        </List>
                        <Form.ErrorList errors={errors} />
                      </>
                    )}
                  </Form.List>
                </Card>
              ))}
              <StickySaveButtons isSaving={isSaving} form={form} />
            </CommonForm>
          </MaxWidth700FormWrapper>
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
