import { CheckSquareOutlined } from "@ant-design/icons";
import { fetchAllPaginatedElements } from "@inspecto/common";
import {
  Badge,
  Button,
  message,
  Modal,
  Space,
  Table,
  TableColumnType,
  Tag,
} from "antd";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { customFieldTypesWithoutFleetStateSupport } from "src/company";
import { myTheme } from "src/theme";

import { backOfficeApi } from "../../../api";
import { SimpleAvatarFieldType } from "../../../components";
import { useAllCustomVehicleFieldsSupportedByFleetState } from "../../../hooks";
import {
  ListCustomVehicleField,
  TemplateCustomVehicleFields,
} from "../../../models";

export interface CustomVehicleFieldsSelectorProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  vehicleType: string;
  removeStickyOffsetHeader?: boolean;
}

export function CustomVehicleFieldsSelector(
  props: CustomVehicleFieldsSelectorProps
): JSX.Element {
  const { t } = useTranslation("backoffice");
  const [isTemplatesFetching, setIsTemplatesFetching] = useState(false);
  const [allTemplates, setAllTemplates] = useState<
    TemplateCustomVehicleFields[]
  >([]);
  const { customVehicleFields, isLoadingCustomVehicleFields } =
    useAllCustomVehicleFieldsSupportedByFleetState(props.vehicleType);

  const fetchTemplates = useCallback(async () => {
    setIsTemplatesFetching(true);
    try {
      const templates = await fetchAllPaginatedElements((pageNumber) =>
        backOfficeApi.getTemplatesCustomVehicleFields(pageNumber)
      );
      templates.sort((a) => (!a.customVehicleFields.length ? 0 : -1));
      setAllTemplates(
        templates.map((template) => ({
          ...template,
          customVehicleFields: template.customVehicleFields.filter(
            (field) =>
              !customFieldTypesWithoutFleetStateSupport.includes(field.type)
          ),
        }))
      );
    } catch (e) {
      message.error(t("somethingWentWrong"));
    } finally {
      setIsTemplatesFetching(false);
    }
  }, [t]);

  const columns = useMemo<TableColumnType<ListCustomVehicleField>[]>(
    () => [
      {
        title: t("reportsView.fieldNames.selectAll"),
        key: "label",
        render: (value: ListCustomVehicleField) => (
          <Space>
            <SimpleAvatarFieldType questionType={value.type} />
            {value.label}
          </Space>
        ),
      },
      {
        dataIndex: "vehicleTypes",
        title: "",
        width: "250px",
        render: (value: ListCustomVehicleField["vehicleTypes"]) => (
          <>
            {value.map((vehicleType) => (
              <Tag key={vehicleType.id}>{vehicleType.label}</Tag>
            ))}
          </>
        ),
      },
    ],
    [t]
  );

  return (
    <>
      <Table
        loading={isLoadingCustomVehicleFields}
        size="small"
        pagination={false}
        rowKey="id"
        dataSource={customVehicleFields}
        columns={columns}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) =>
            props.onChange?.(selectedRows.map((row) => row.id)),
          selectedRowKeys: props.value,
        }}
        summary={() => (
          <Table.Summary fixed="top">
            <Table.Summary.Row style={{ backgroundColor: "#fcfcfc" }}>
              <Table.Summary.Cell index={0} colSpan={99}>
                <Button
                  icon={<CheckSquareOutlined />}
                  size="small"
                  onClick={fetchTemplates}
                  loading={isTemplatesFetching}
                >
                  {t(
                    "reportsView.fieldNames.pickCustomVehicleFieldsFromTemplate"
                  )}
                </Button>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        sticky={{
          offsetHeader: props.removeStickyOffsetHeader
            ? undefined
            : myTheme.commonVariables.backOfficeHeaderHeight,
        }}
      />
      {allTemplates.length > 0 && (
        <Modal
          open
          onCancel={() => setAllTemplates([])}
          okButtonProps={{
            style: {
              display: "none",
            },
          }}
          title={t(
            "reportsView.fieldNames.pickCustomVehicleFieldsFromTemplate"
          )}
        >
          {allTemplates.map((template) => (
            <Button
              block
              onClick={() => {
                props.onChange?.(
                  template.customVehicleFields.map((field) => field.id)
                );
                setAllTemplates([]);
              }}
              style={{
                marginBottom: 4,
                textAlign: "left",
                whiteSpace: "initial",
                height: "auto",
              }}
              disabled={!template.customVehicleFields.length}
            >
              <Space>
                {template.label}
                <Badge
                  count={template.customVehicleFields.length}
                  showZero
                  color={myTheme.colors.gray200}
                  style={{ color: "#000" }}
                />
              </Space>
            </Button>
          ))}
        </Modal>
      )}
    </>
  );
}
