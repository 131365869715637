import { api, Api } from "src/utils";

import { Document } from "../models/Document";

export class DocumentsApi {
  constructor(private api: Api) {}

  async getVehicleDocuments(vehicleId: string) {
    return this.api.get<Document[]>(
      `/backoffice/vehicles/${vehicleId}/documents/`
    );
  }

  async getVehicleDocumentsCount(vehicleId: string) {
    return this.api.get<{ count: number }>(
      `/backoffice/vehicles/${vehicleId}/documents/count/`
    );
  }

  async createVehicleDocument(
    vehicleId: string,
    document: Document
  ): Promise<Document> {
    return this.api.post(
      `/backoffice/vehicles/${vehicleId}/documents/`,
      document
    );
  }

  async editVehicleDocument(
    vehicleId: string,
    data: Document
  ): Promise<Document> {
    return this.api.put(
      `/backoffice/vehicles/${vehicleId}/documents/${data.id}/`,
      data
    );
  }

  async deleteVehicleDocument(vehicleId: string, documentId: string) {
    return this.api.delete(
      `/backoffice/vehicles/${vehicleId}/documents/${documentId}`
    );
  }
}

export const documentsApi = new DocumentsApi(api);
