import { Form } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { MultiLanguageTextarea } from "src/company";
import { Select, SwitchInput } from "src/components";
import { getHaveFieldsUpdatedCallback } from "src/utils";

import { DefaultLanguageRequiredFormItem } from "../../../components";
import { isSignatureWithSigner, SignatureType } from "../../../models";
import { PageCommonComponent } from "./PageCommonComponent";

interface Props {
  fieldPrefix: number;
  index: number;
  removePage: (index: number) => void;
  pageFrontendId: string;
  userRoleOptions: { label: string; value: string }[];
  isLoadingUserRoleOptions: boolean;
}

const ContentWrapper = styled.div`
  padding: 20px;
`;

export function SignaturePageComponent(props: Props) {
  const { t } = useTranslation("backoffice");

  return (
    <PageCommonComponent
      pageTitle={t("builder.signaturePage")}
      pageFrontendId={props.pageFrontendId}
      index={props.index}
      onDeleteConfirm={() => props.removePage(props.index)}
    >
      <ContentWrapper>
        <Form.Item name={["signaturePages", props.fieldPrefix, "id"]} hidden />
        <DefaultLanguageRequiredFormItem
          name={["signaturePages", props.fieldPrefix, "label"]}
          label={t("builder.signaturePageTitleInputLabel")}
        >
          <MultiLanguageTextarea />
        </DefaultLanguageRequiredFormItem>
        <Form.Item
          name={["signaturePages", props.fieldPrefix, "type"]}
          label={t("builder.signatureTypeSelectLabel")}
        >
          <Select
            noMargin
            options={[
              {
                label: t("builder.signatureType.signatureOnly"),
                value: SignatureType.SIGNATURE_ONLY,
              },
              {
                label: t("builder.signatureType.signerOnly"),
                value: SignatureType.SIGNER_ONLY,
              },
              {
                label: t("builder.signatureType.signatureAndSigner"),
                value: SignatureType.SIGNATURE_AND_SIGNER,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={getHaveFieldsUpdatedCallback(
            `signaturePages.${props.fieldPrefix}.type`
          )}
          noStyle
        >
          {({ getFieldValue }) =>
            isSignatureWithSigner(
              getFieldValue(["signaturePages", props.fieldPrefix, "type"])
            ) && (
              <Form.Item
                name={["signaturePages", props.fieldPrefix, "signerRoles"]}
                label={t("builder.signerRoleSelectLabel")}
                rules={[{ required: true }]}
              >
                <Select
                  noMargin
                  mode="multiple"
                  options={props.userRoleOptions}
                />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item
          name={["signaturePages", props.fieldPrefix, "isRequired"]}
          noStyle
          valuePropName="isChecked"
          trigger="onToggle"
        >
          <SwitchInput label={t("builder.signatureRequiredToggleLabel")} />
        </Form.Item>
      </ContentWrapper>
    </PageCommonComponent>
  );
}
