import * as Sentry from "@sentry/react";
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Spinner } from "src/components";

import { localStorageMigrations } from "../localStorageMigrations";
import { fromLocalStorage } from "../utils";

const MIGRATIONS_APPLIED_LOCAL_STORAGE_KEY = "migrations_applied";

export function ProtocolFillerLocalStorageMigrator(
  props: PropsWithChildren<{}>
): JSX.Element {
  const { t } = useTranslation("protocolFiller");
  useEffect(() => {
    function logErrorAndClearLocalStorage(e: any) {
      Sentry.captureException(e);
      localStorage.clear();
    }
    async function migrate() {
      try {
        const migrationsApplied: number = fromLocalStorage(
          MIGRATIONS_APPLIED_LOCAL_STORAGE_KEY,
          0
        );

        for (const indexAsString in localStorageMigrations) {
          const index = parseInt(indexAsString);
          const currentMigrationNumber: number = index + 1;
          const currentMigration = localStorageMigrations[index];

          if (currentMigrationNumber > migrationsApplied && currentMigration) {
            try {
              await currentMigration();
            } catch (e) {
              logErrorAndClearLocalStorage(e);
            } finally {
              localStorage.setItem(
                MIGRATIONS_APPLIED_LOCAL_STORAGE_KEY,
                String(currentMigrationNumber)
              );
            }
          }
        }
      } catch (e) {
        logErrorAndClearLocalStorage(e);
      } finally {
        setIsReady(true);
      }
    }

    migrate();
  }, []);

  const [isReady, setIsReady] = useState(false);
  return isReady ? (
    <div>{props.children}</div>
  ) : (
    <Spinner text={t("loading")} />
  );
}
