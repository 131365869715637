import { isNil } from "lodash";

import {
  CustomFieldUpdateQuestion,
  isRequiredModelQuestion,
  PhotoActionQuestion,
  QuestionResponseValidationError,
  Responses,
  Signature,
  TemplatePage,
  TemplateQuestion,
  TemplateSignaturePage,
  TextQuestion,
  ValidationError,
} from "../models";
import { getChoiceResponseValidationErrors } from "./getChoiceResponseValidationErrors";
import { getResponseValidationErrors } from "./getResponseValidationErrors";

export function areTextResponsesValid(
  textQuestions: TextQuestion[],
  responses: Record<string, string>
): boolean {
  return textQuestions
    .filter((question) => question.isRequired)
    .reduce<boolean>(
      (areAllAnswered, textQuestion) =>
        areAllAnswered && !!responses[textQuestion.id],
      true
    );
}

export function arePhotoActionResponsesValid(
  photoActionQuestions: PhotoActionQuestion[],
  responses: Record<string, File | null>
): boolean {
  return photoActionQuestions
    .filter((question) => question.isRequired)
    .reduce<boolean>(
      (areAllAnswered, textQuestion) =>
        areAllAnswered && !!responses[textQuestion.id],
      true
    );
}

export function isTextResponseNotEmpty(
  questionId: string,
  textResponses: Responses["textResponses"]
): boolean {
  return !!textResponses[questionId];
}

export function isDamagesResponseNotEmpty(
  questionId: string,
  damagesResponses: Responses["damagesResponses"]
): boolean {
  const damagesResponse = damagesResponses[questionId];
  return !!damagesResponse && !!damagesResponse.length;
}

export function isNumberResponseNotEmpty(
  questionId: string,
  numberResponses: Responses["numberResponses"]
): boolean {
  return !isNil(numberResponses[questionId]);
}

export function isDateResponseNotEmpty(
  questionId: string,
  dateResponses: Responses["dateResponses"]
): boolean {
  return !!dateResponses[questionId];
}

export function isDateMonthResponseNotEmpty(
  questionId: string,
  dateMonthResponses: Responses["dateMonthResponses"]
): boolean {
  return !!dateMonthResponses[questionId];
}

export function isChoiceResponseNotEmpty(
  questionId: string,
  choiceResponses: Responses["choiceResponses"]
): boolean {
  const choiceResponse = choiceResponses[questionId];
  return choiceResponse ? !!choiceResponse.selectedChoiceIds.length : false;
}

export function getResponseValidationErrorsForCustomFieldVerification(
  question: CustomFieldUpdateQuestion,
  responses: Responses
): ValidationError[] {
  switch (question.customVehicleField.type) {
    case "text":
      return isTextResponseNotEmpty(question.id, responses.textResponses)
        ? []
        : [ValidationError.NEW_VALUE_CANNOT_BE_EMPTY];
    case "number":
      return isNumberResponseNotEmpty(question.id, responses.numberResponses)
        ? []
        : [ValidationError.NEW_VALUE_CANNOT_BE_EMPTY];
    case "date":
      return isDateResponseNotEmpty(question.id, responses.dateResponses)
        ? []
        : [ValidationError.NEW_VALUE_CANNOT_BE_EMPTY];
    case "date-month":
      return isDateMonthResponseNotEmpty(
        question.id,
        responses.dateMonthResponses
      )
        ? []
        : [ValidationError.NEW_VALUE_CANNOT_BE_EMPTY];
    case "choice":
      return isChoiceResponseNotEmpty(question.id, responses.choiceResponses)
        ? getChoiceResponseValidationErrors(
            question.customVehicleField.choices,
            question.isRequired,
            responses.choiceResponses[question.id]
          )
        : [ValidationError.NEW_VALUE_CANNOT_BE_EMPTY];
    default:
      return [];
  }
}

const alwaysRequiredQuestionTypes: TemplateQuestion["questionType"][] = [
  "choice",
  "multi-choice",
];

export function isQuestionRequired(question: TemplateQuestion): boolean {
  if (alwaysRequiredQuestionTypes.includes(question.questionType)) {
    return true;
  }

  return isRequiredModelQuestion(question) && question.isRequired;
}

export function arePageResponsesValid(
  page: TemplatePage,
  responses: Responses
): boolean {
  return page.questions.reduce<boolean>(
    (previousValue, question) =>
      previousValue &&
      !getResponseValidationErrors(
        question,
        responses,
        isQuestionRequired(question)
      ).length,
    true
  );
}

export function getPageQuestionsResponseValidationErrors(
  page: TemplatePage,
  responses: Responses
): QuestionResponseValidationError[] {
  return page.questions.reduce<QuestionResponseValidationError[]>(
    (acc, question) => {
      const errors = getResponseValidationErrors(
        question,
        responses,
        isQuestionRequired(question)
      );
      if (!errors.length) {
        return acc;
      } else {
        return [...acc, { questionId: question.id, errors }];
      }
    },
    []
  );
}

export function isSignaturePageValid(
  signaturePage: TemplateSignaturePage,
  signatures: Record<string, Signature>
): boolean {
  switch (signaturePage.type) {
    case "signature_only":
      return (
        !signaturePage.isRequired || !!signatures[signaturePage.id]?.signature
      );
    case "signer_only":
      return (
        !signaturePage.isRequired ||
        !!signatures[signaturePage.id]?.signer?.value
      );
    case "signature_and_signer":
      return (
        !signaturePage.isRequired ||
        (!!signatures[signaturePage.id]?.signature &&
          !!signatures[signaturePage.id]?.signer?.value)
      );
  }
  return false;
}
