import { Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { urls } from "src/urls";

import { BackOfficeLayout } from "../../BackOfficeLayout";
import {
  CustomVehicleFieldForm,
  useCustomVehicleFieldFormSubmit,
} from "./CustomVehicleFieldForm";

export function CustomVehicleFieldFormView(props: { isCloning?: boolean }) {
  const { t } = useTranslation("backoffice");
  const { customVehicleFieldId } = useParams<{
    customVehicleFieldId?: string;
  }>();
  const history = useHistory();

  const { setFormSubmitter, setIsSaving, pageTitle, saveButtonProps } =
    useCustomVehicleFieldFormSubmit(!!customVehicleFieldId);

  return (
    <BackOfficeLayout
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.customVehicleFields.label"),
          url: urls.backOffice.settings.customVehicleFields(),
        },
      ]}
      pageTitle={pageTitle}
    >
      <BackOfficeLayout.Content>
        <CustomVehicleFieldForm
          onIsSavingChange={setIsSaving}
          customVehicleFieldId={customVehicleFieldId}
          setFormSubmitter={setFormSubmitter}
          isCloning={props.isCloning || false}
          onSuccessfulSave={() => {
            history.replace(urls.backOffice.settings.customVehicleFields());
            message.success(
              t("settings.customVehicleFields.savedSuccessfully")
            );
          }}
        />
        {!!saveButtonProps && <Button {...saveButtonProps} />}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
