import { ProtocolFillerLanguage, useCompany } from "@inspecto/common";
import { Form, FormItemProps } from "antd";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import { TranslatedField } from "src/locales";

interface Props extends Omit<FormItemProps, "rules"> {
  requiredLanguage?: "companyProtocolFiller" | "company";
  maxLength?: number;
}

export function DefaultLanguageRequiredFormItem({
  maxLength = 0,
  requiredLanguage = "companyProtocolFiller",
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  const { t: genericT } = useTranslation();
  const company = useCompany();

  const fieldIsRequiredForLanguageMessageKey =
    requiredLanguage === "companyProtocolFiller"
      ? "validationErrors.fieldIsRequiredForDefaultProtocolFillerLanguage"
      : "validationErrors.fieldIsRequiredForDefaultCompanyLanguage";
  const requiredLanguageCode =
    requiredLanguage === "companyProtocolFiller"
      ? company.protocolFillerLanguage
      : company.language;

  return (
    <Form.Item
      {...props}
      rules={[
        () => ({
          required: true,
          validateTrigger: "label",
          validator: async (_, valueByLanguage: TranslatedField | undefined) =>
            !!valueByLanguage && !isEmpty(valueByLanguage[requiredLanguageCode])
              ? Promise.resolve()
              : Promise.reject(),
          ...(company.availableLanguages.length > 1
            ? {
                message: t(fieldIsRequiredForLanguageMessageKey, {
                  language: requiredLanguageCode.toUpperCase(),
                  fieldName: props.label,
                }),
              }
            : {
                message: genericT("thisFieldIsRequired"),
              }),
        }),
        () => ({
          validateTrigger: "label",
          validator: async (
            _,
            valueByLanguage: TranslatedField | undefined
          ) => {
            if (maxLength <= 0 || !valueByLanguage) {
              return Promise.resolve();
            }

            const languageExceedingMaxLength =
              getLanguageExceedingMaxLength(valueByLanguage);
            return languageExceedingMaxLength
              ? Promise.reject(
                  t("validationErrors.fieldExceedsMaxLengthForLanguage", {
                    language: languageExceedingMaxLength.toUpperCase(),
                    fieldName: props.label,
                    max: maxLength,
                    current:
                      valueByLanguage[languageExceedingMaxLength]?.length || 0,
                  })
                )
              : Promise.resolve();
          },
        }),
      ]}
    />
  );

  function getLanguageExceedingMaxLength(
    valueByLanguage: TranslatedField
  ): ProtocolFillerLanguage | "" {
    const foundLanguage = Object.entries(valueByLanguage).find(
      ([_, value]) => value.length > maxLength
    );
    return foundLanguage ? (foundLanguage[0] as ProtocolFillerLanguage) : "";
  }
}
