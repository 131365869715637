import { Button, FormProps } from "antd";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { CommonForm, MaxWidth700FormWrapper, Spinner } from "src/components";
import { urls } from "src/urls";
import { downloadUri } from "src/utils";

import { useCeleryTaskProgress } from "../../../contexts";
import { BackOfficeLayout } from "../../BackOfficeLayout";

interface Props<TFormState extends Record<string, any>> {
  reportTitle: string;
  startDownloading(data: TFormState): Promise<{ taskResultId: string }>;
  formInitialValues: TFormState | "loading";
  onValuesChange?: FormProps<TFormState>["onValuesChange"];
}

export function ReportFormLayout<TFormState extends Record<string, any>>(
  props: PropsWithChildren<Props<TFormState>>
) {
  const { t } = useTranslation("backoffice");
  const { handleTask } = useCeleryTaskProgress();

  return (
    <BackOfficeLayout
      pageTitle={props.reportTitle}
      breadcrumbs={[
        {
          label: t("reportsView.pageTitle"),
          url: urls.backOffice.reports.main(),
        },
      ]}
    >
      <BackOfficeLayout.Content>
        <MaxWidth700FormWrapper>
          {props.formInitialValues === "loading" ? (
            <Spinner />
          ) : (
            <CommonForm<TFormState>
              onValuesChange={props.onValuesChange}
              saveCallback={async (data: TFormState) => {
                const result = await props.startDownloading(data);
                handleTask({
                  id: result.taskResultId,
                  onSuccess: (xlsxUrl: string) =>
                    downloadUri(
                      xlsxUrl,
                      `Inspecto - ${props.reportTitle}.xlsx`
                    ),
                });
              }}
              initialValues={props.formInitialValues}
            >
              {props.children}
              <Button htmlType="submit" type="primary">
                {t("tableActions.downloadXLSX")}
              </Button>
            </CommonForm>
          )}
        </MaxWidth700FormWrapper>
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
