import { useCallback, useEffect, useState } from "react";

import { fetchAllPaginatedElements } from "./fetchAllPaginatedElements";
import { PaginationResults } from "./types";

export function useAllPaginatedElements<TItem>(
  dataGetter: (pageNumber: number) => Promise<PaginationResults<TItem[]>>
) {
  const [isLoadingItems, setIsLoadingItems] = useState(true);
  const [items, setItems] = useState<TItem[]>([]);
  const fetchAllItems = useCallback(() => {
    setIsLoadingItems(true);

    fetchAllPaginatedElements<TItem>(dataGetter)
      .then((allElements) => {
        setItems(allElements);
      })
      .finally(() => {
        setIsLoadingItems(false);
      });
  }, [dataGetter]);

  useEffect(() => {
    fetchAllItems();
  }, [fetchAllItems]);

  return [items, isLoadingItems, fetchAllItems] as const;
}
