import { useTranslation } from "react-i18next";

import { backOfficeApi } from "../../api";
import { useProtocolTypes, useVehicleTypes } from "../../hooks";
import {
  DatePeriodFormState,
  formatDatePeriod,
  getDatePeriodFormInitialValues,
} from "./common";
import { DatePeriodFormItem, ReportFormLayout } from "./components";
import { ExpandableProtocolTypesSelectorFormItem } from "./components/ExpandableProtocolTypesSelectorFormItem";
import { VehicleTypeFormItem } from "./components/VehicleTypeFormItem";

interface NumberOfProtocolsPerVehicleReportViewFormState
  extends DatePeriodFormState {
  protocolTypes: string[];
  vehicleType: string;
}

export function NumberOfProtocolsPerVehicleReportView() {
  const { t } = useTranslation("backoffice");
  const { protocolTypes, protocolTypeOptions, isLoadingProtocolTypes } =
    useProtocolTypes();
  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();

  return (
    <ReportFormLayout<NumberOfProtocolsPerVehicleReportViewFormState>
      reportTitle={t("reportsView.reports.numberOfProtocolsPerVehicle")}
      startDownloading={async (data) =>
        backOfficeApi.reports.startDownloadNumberOfProtocolsPerVehicle({
          ...formatDatePeriod(data),
          protocolTypes:
            data.protocolTypes.length === protocolTypes.length
              ? []
              : data.protocolTypes,
          vehicleType: data.vehicleType || null,
        })
      }
      formInitialValues={
        isLoadingProtocolTypes || isLoadingVehicleTypes
          ? "loading"
          : {
              protocolTypes,
              ...getDatePeriodFormInitialValues(),
              vehicleType: "",
            }
      }
    >
      <DatePeriodFormItem />
      <VehicleTypeFormItem vehicleTypeOptions={vehicleTypeOptions} />

      <ExpandableProtocolTypesSelectorFormItem
        protocolTypeOptions={protocolTypeOptions}
        isLoadingProtocolTypes={isLoadingProtocolTypes}
      />
    </ReportFormLayout>
  );
}
