import { Checkbox, Form } from "antd";
import { useTranslation } from "react-i18next";

import {
  CustomVehicleFieldsParams,
  DateFromToParams,
  IncludeInitialDeficienciesParams,
  VehicleTypeParams,
} from "../../api/reportsApi";
import {
  useAllCustomVehicleFieldsSupportedByFleetState,
  useVehicleTypes,
} from "../../hooks";
import {
  DatePeriodFormState,
  formatDatePeriod,
  getDatePeriodFormInitialValues,
} from "./common";
import {
  DatePeriodFormItem,
  CustomVehicleFieldsSelectorFormItem,
  ReportFormLayout,
} from "./components";
import { VehicleTypeFormItem } from "./components/VehicleTypeFormItem";

interface FormState extends DatePeriodFormState {
  customVehicleFields: string[];
  vehicleType: string;
  includeInitialDeficiencies: boolean;
  doNotIncludeFieldsCurrentlyOk: boolean;
}

interface Props {
  reportTitle: string;
  startDownloading: (
    data: DateFromToParams &
      CustomVehicleFieldsParams &
      VehicleTypeParams &
      IncludeInitialDeficienciesParams
  ) => Promise<{ taskResultId: string }>;
}

export function GenericDateVehicleTypeCustomVehicleFieldsReportView(
  props: Props
) {
  const { t } = useTranslation("backoffice");
  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();
  const { customVehicleFields, isLoadingCustomVehicleFields } =
    useAllCustomVehicleFieldsSupportedByFleetState("");

  return (
    <ReportFormLayout<FormState>
      reportTitle={props.reportTitle}
      startDownloading={async (data) =>
        props.startDownloading({
          ...formatDatePeriod(data),
          customVehicleFields: data.customVehicleFields || [],
          vehicleType: data.vehicleType || null,
          includeInitialDeficiencies: data.includeInitialDeficiencies || false,
          doNotIncludeFieldsCurrentlyOk:
            data.doNotIncludeFieldsCurrentlyOk || false,
        })
      }
      formInitialValues={
        isLoadingCustomVehicleFields || isLoadingVehicleTypes
          ? "loading"
          : {
              ...getDatePeriodFormInitialValues(),
              customVehicleFields: customVehicleFields.map((field) => field.id),
              vehicleType: "",
              includeInitialDeficiencies: false,
              doNotIncludeFieldsCurrentlyOk: false,
            }
      }
    >
      <DatePeriodFormItem />
      <VehicleTypeFormItem vehicleTypeOptions={vehicleTypeOptions} />
      <CustomVehicleFieldsSelectorFormItem />
      <Form.Item
        name="includeInitialDeficiencies"
        valuePropName="checked"
        style={{ margin: 0 }} // this is to decrease blank space between this and below checkbox
      >
        <Checkbox>
          {t("reportsView.fieldNames.includeInitialDeficiencies")}
        </Checkbox>
      </Form.Item>
      <Form.Item name="doNotIncludeFieldsCurrentlyOk" valuePropName="checked">
        <Checkbox>
          {t("reportsView.fieldNames.doNotIncludeFieldsCurrentlyOk")}
        </Checkbox>
      </Form.Item>
    </ReportFormLayout>
  );
}
