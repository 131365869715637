import { isRedirectionAllowed } from "@inspecto/common";
import { Alert, Modal, Typography } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import { QRScanner } from "src/components/QRScanner";
import { useReporting } from "src/reporting/useReporting";

const MarginedDiv = styled.div`
  margin: 10px -15px 0;
`;

interface Props {
  onClose: () => void;
}

export function ChooseVehicleViaQRModal(props: Props) {
  const { t } = useTranslation("protocolFiller");
  const [errorMessage, setErrorMessage] = useState("");
  const { logAnalyticsEvent } = useReporting();

  const replaceLocation = useCallback(
    (link) => {
      if (isRedirectionAllowed(link)) {
        setErrorMessage("");
        logAnalyticsEvent({
          eventType: "vehicleQRCodeScanned",
          payload: {},
        });
        window.location.replace(link);
      } else {
        setErrorMessage(t("qrCodes.invalidQRCode"));
      }
    },
    [logAnalyticsEvent, t]
  );

  const replaceLocationDebounced = useDebouncedCallback(replaceLocation, 100, {
    leading: true,
  });

  return (
    <Modal open onCancel={props.onClose} footer={null} style={{ top: 5 }}>
      <Typography.Text strong style={{ display: "block", paddingRight: 20 }}>
        {t("qrCodes.scanQRCodeToStartFillingOutAProtocol")}
      </Typography.Text>
      <MarginedDiv>
        <QRScanner onSuccessfulScan={replaceLocationDebounced} />
      </MarginedDiv>
      {errorMessage && (
        <MarginedDiv>
          <Alert type="error" message={errorMessage} />
        </MarginedDiv>
      )}
    </Modal>
  );
}
