import {
  ProtocolFillerLanguage,
  localeResources,
  defaultLanguage,
} from "@inspecto/common";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import i18n from "i18next";
import { produce } from "immer";
import { createContext, Dispatch, useEffect, useMemo, useReducer } from "react";
import { initReactI18next } from "react-i18next";

import { Action } from "src/utils";
import { isDevelopment } from "src/utils/isDevelopment";

import { getLanguageConfig } from "./";

type LanguageActions = Action<
  "setLanguage",
  { language: ProtocolFillerLanguage }
>;

interface LanguageContextValue {
  language: ProtocolFillerLanguage;
}

function reducer(
  state: LanguageContextValue,
  action: LanguageActions
): LanguageContextValue {
  return produce(state, (draft) => {
    switch (action.type) {
      case "setLanguage":
        draft.language = action.payload.language;
    }
  });
}

let initialValue: LanguageContextValue = {
  language: defaultLanguage,
};
export const languageContext = createContext<{
  value: LanguageContextValue;
  dispatch: Dispatch<LanguageActions>;
}>({
  value: initialValue,

  dispatch: () => {},
});

if (isDevelopment()) {
  window.i18n = i18n;
}

i18n.on("languageChanged", (lng) =>
  document.documentElement.setAttribute("lang", lng)
);

const { Provider } = languageContext;

i18n.use(initReactI18next).init({
  resources: localeResources,
  lng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export function LanguageProvider(props: { children: JSX.Element }) {
  const [languageContextValue, dispatch] = useReducer(reducer, initialValue);

  const languageConfig = useMemo(
    () => getLanguageConfig(languageContextValue.language),
    [languageContextValue.language]
  );
  useEffect(() => {
    i18n.changeLanguage(languageContextValue.language);
  }, [languageContextValue.language]);

  useEffect(() => {
    dayjs.locale(languageConfig.dayJSLocale);
  }, [languageConfig]);

  return (
    <Provider
      value={{
        value: languageContextValue,
        dispatch,
      }}
    >
      <ConfigProvider locale={languageConfig.antLocale}>
        {props.children}
      </ConfigProvider>
    </Provider>
  );
}
