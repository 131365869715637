import dayjs, { Dayjs } from "dayjs";
import { useCallback } from "react";

import { dayJsToISOTimeZero } from "src/utils";

import { DatePicker } from "..";

export type DateFieldValue = string | null;

export interface DateFieldProps {
  value?: DateFieldValue;
  onChange?: (value: DateFieldValue) => void;
  autoFocus?: boolean;
}

export function DateFieldComponent(props: DateFieldProps) {
  const handleChange = useCallback(
    (date: Dayjs | null) => props.onChange?.(dayJsToISOTimeZero(date)),
    [props]
  );

  return (
    <DatePicker
      autoFocus={props.autoFocus}
      onChange={handleChange}
      value={props.value ? dayjs.utc(props.value) : undefined}
    />
  );
}
