import { Input } from "antd";

import { InputWrapper } from "./InputWrapper";

interface Props {
  value?: string;
  onChange?: (newValue: string) => void;
  label?: string;
  isRequired?: boolean;
}

export function TextAreaInput(props: Props): JSX.Element {
  return (
    <InputWrapper label={props.label} isRequired={props.isRequired}>
      <Input.TextArea
        value={props.value}
        onChange={(event) => props.onChange?.(event.currentTarget.value)}
      />
    </InputWrapper>
  );
}
