import { Button, Modal, Result } from "antd";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useDevToolbar } from "src/devToolbar";

import { Position, watchCurrentPosition } from "../utils/position";

export interface PositionContext {
  bestPosition: Position | null;
  currentPosition: Position | null;
}

const positionContext = createContext<PositionContext>({
  bestPosition: null,
  currentPosition: null,
});
const { Provider } = positionContext;

function isGeolocationPositionError(
  something: unknown
): something is GeolocationPositionError {
  return (
    typeof something === "object" &&
    something !== null &&
    // @ts-ignore
    something.code &&
    // @ts-ignore
    something.PERMISSION_DENIED
  );
}

export function ProtocolFillerPositionProvider(props: { children: ReactNode }) {
  const { t } = useTranslation("protocolFiller");
  const [bestPosition, setBestPosition] = useState<Position | null>(null);
  const [currentPosition, setCurrentPosition] = useState<Position | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isErrorSkipped, setIsErrorSkipped] = useState(false);

  const { isDevToolbarEnabled, setDevToolbarLocationInformation } =
    useDevToolbar();

  const errorCallback: PositionErrorCallback = useCallback(
    (err: unknown) => {
      if (isGeolocationPositionError(err)) {
        switch (err.code) {
          case err.PERMISSION_DENIED:
            setError(t("positionProviderErrorScreen.permissionDeniedError"));
            break;
          default:
            setError(t("positionProviderErrorScreen.somethingWentWrongTitle"));
            break;
        }
      } else {
        throw err;
      }
    },
    [t]
  );

  useEffect(() => {
    return watchCurrentPosition(setCurrentPosition, errorCallback);
  }, [errorCallback]);

  useEffect(() => {
    setBestPosition((bestPosition) => {
      if (!currentPosition) {
        return bestPosition;
      }
      if (!bestPosition) {
        return currentPosition;
      }
      if (bestPosition.accuracy > currentPosition?.accuracy) {
        return currentPosition;
      }
      return bestPosition;
    });
  }, [currentPosition]);

  useEffect(() => {
    if (!isDevToolbarEnabled) {
      return;
    }
    setDevToolbarLocationInformation({
      bestPosition,
      currentPosition,
    });
  }, [
    isDevToolbarEnabled,
    setDevToolbarLocationInformation,
    bestPosition,
    currentPosition,
  ]);
  return (
    <Provider
      value={{
        bestPosition,
        currentPosition,
      }}
    >
      {/* Checking if it's running within Cypress to prevent issues with no access to location as we couldn't figure out permissions or mocking */}
      {(!window.Cypress || window.cypressUseLocation) &&
        error &&
        !isErrorSkipped && (
          <Modal
            onCancel={() => setIsErrorSkipped(true)}
            open
            style={{ top: 20 }}
            footer={
              <Button onClick={() => setIsErrorSkipped(true)}>
                {t("positionProviderErrorScreen.skip")}
              </Button>
            }
          >
            <Result style={{ padding: "0" }} status="403" title={error} />
          </Modal>
        )}
      {props.children}
    </Provider>
  );
}

export function useGeoPosition() {
  return useContext(positionContext);
}
