import { QrcodeOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { useState } from "react";
import styled from "styled-components";

import { ChooseVehicleViaQRModal } from "../views/ChooseVehicleViaQRModal";

const StyledQrcodeOutlinedIcon = styled(QrcodeOutlined)`
  font-size: 28px;
  position: relative;
  top: 1px;
`;

const StyledButtonWrapper = styled.div<{ marginBottom?: number }>`
  text-align: center;
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}px`};
`;

export function ChooseVehicleViaQRCodeButton(props: {
  marginBottom?: number;
  label: string;
}) {
  const [isQRScannerModalOpen, setIsQRScannerModalOpen] = useState(false);

  return (
    <>
      <StyledButtonWrapper marginBottom={props.marginBottom}>
        <Button
          type="dashed"
          shape="round"
          size="middle"
          style={{ whiteSpace: "normal", height: "auto" }}
          onClick={() => setIsQRScannerModalOpen(true)}
        >
          <Space>
            <StyledQrcodeOutlinedIcon />
            <div style={{ textAlign: "left" }}>{props.label}</div>
          </Space>
        </Button>
      </StyledButtonWrapper>
      {isQRScannerModalOpen && (
        <ChooseVehicleViaQRModal
          onClose={() => setIsQRScannerModalOpen(false)}
        />
      )}
    </>
  );
}
