import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import {
  FleetStateCustomVehicleFieldsListView,
  FleetStateCustomVehicleFieldValuesListView,
} from "./";

export function FleetStateRouter() {
  return (
    <Switch>
      <Route path={urls.backOffice.fleetState.customVehicleFields()} exact>
        <FleetStateCustomVehicleFieldsListView />
      </Route>
      <Route
        path={urls.backOffice.fleetState.customVehicleFieldsValues()}
        exact
      >
        <FleetStateCustomVehicleFieldValuesListView />
      </Route>
    </Switch>
  );
}
