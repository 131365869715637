import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export function HTTPCodeErrorPage(props: {
  errorCode: "404";
  redirectUrl: string;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const headerTexts: Record<typeof props["errorCode"], string> = {
    "404": t("cantFindYourPage"),
  };

  return (
    <Result
      status={props.errorCode}
      title={props.errorCode}
      subTitle={headerTexts[props.errorCode]}
      extra={
        <Button type="primary" onClick={() => history.push(props.redirectUrl)}>
          {t("mainPage")}
        </Button>
      }
    />
  );
}
