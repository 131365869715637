import { useCompany } from "@inspecto/common";
import { Checkbox, Form, Segmented } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ModalForm, ModalFormProps } from "src/components";
import { useReporting } from "src/reporting/useReporting";
import qrCodeIcon from "src/static/qrCodeIcon.svg";
import { downloadUri, getHaveFieldsUpdatedCallback } from "src/utils";

import { backOfficeApi, NumberOfQrCodesInRow } from "../api";
import { useCeleryTaskProgress, VehiclesViewState } from "../contexts";

const A4Page = styled.div`
  width: 210px;
  height: 297px;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0 6px 10px 0;
  margin: 30px auto 10px;
  border-radius: 2px;
  overflow: hidden;
`;

const A4PageItem = styled.div<{ percentSize: number }>`
  width: ${(props) => props.percentSize}%;
  height: 0;
  padding-bottom: ${(props) => props.percentSize}%;
  float: left;
  position: relative;
`;

const A4PageItemInner = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  display: flex;
  align-content: center;
  justify-content: center;
`;

interface Props extends Pick<ModalFormProps, "closeModal"> {
  vehiclesView: VehiclesViewState;
}

const qrCodeSizes = ["small", "medium", "large", "veryLarge"] as const;
type QrCodeSize = typeof qrCodeSizes[number];

const numberOfCodesInRowByQrCodeSize: Record<QrCodeSize, NumberOfQrCodesInRow> =
  {
    small: 5,
    medium: 4,
    large: 2,
    veryLarge: 1,
  };

const previewQrCodesNumber: Record<NumberOfQrCodesInRow, number> = {
  5: 28,
  4: 18,
  2: 4,
  1: 1,
};

interface FormState {
  itemsInRow: NumberOfQrCodesInRow;
  includeCompanyLogo: boolean;
}

export function DownloadVehicleQrCodesModal(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  const { handleTask } = useCeleryTaskProgress();
  const { logAnalyticsEvent } = useReporting();
  const company = useCompany();

  const initialState: FormState = {
    itemsInRow: 4,
    includeCompanyLogo: false,
  };

  return (
    <ModalForm<FormState>
      open
      initialValues={initialState}
      formTitle={t("vehiclesView.downloadQRCodes")}
      saveCallback={async (data) => {
        logAnalyticsEvent({
          eventType: "startedDownloadingQrCodesForVehicles",
          payload: {
            itemsInRow: data.itemsInRow,
          },
        });
        const result = await backOfficeApi.startDownloadVehiclesQrCodesTask(
          props.vehiclesView,
          data.itemsInRow,
          data.includeCompanyLogo
        );
        handleTask({
          id: result.taskResultId,
          onSuccess: (pdfUrl: string) => downloadUri(pdfUrl, "QR Codes.pdf"),
        });
      }}
      closeModal={props.closeModal}
      confirmButtonText={t("vehiclesView.downloadQRCodes")}
      cancelButtonText={t("cancel")}
    >
      <Form.Item
        label={t("vehicleQrCodes.selectSizeOfQrCodeImages")}
        name="itemsInRow"
      >
        <Segmented
          block
          size="small"
          options={qrCodeSizes.map((size) => ({
            value: numberOfCodesInRowByQrCodeSize[size],
            label: t(`vehicleQrCodes.size.${size}`),
          }))}
        />
      </Form.Item>

      <Form.Item
        shouldUpdate={getHaveFieldsUpdatedCallback("itemsInRow")}
        noStyle
      >
        {({ getFieldValue }) => (
          <A4Page>
            {Array(
              previewQrCodesNumber[
                getFieldValue("itemsInRow") as NumberOfQrCodesInRow
              ]
            )
              .fill("")
              .map(() => (
                <A4PageItem percentSize={100 / getFieldValue("itemsInRow")}>
                  <A4PageItemInner>
                    <img
                      alt="QR"
                      src={qrCodeIcon}
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </A4PageItemInner>
                </A4PageItem>
              ))}
          </A4Page>
        )}
      </Form.Item>

      {company.logo ? (
        <Form.Item
          valuePropName="checked"
          name="includeCompanyLogo"
          style={{ marginTop: 20, marginBottom: 0, textAlign: "center" }}
        >
          <Checkbox>{t("vehicleQrCodes.includeCompanyLogo")}</Checkbox>
        </Form.Item>
      ) : (
        <Form.Item hidden name="includeCompanyLogo" />
      )}
    </ModalForm>
  );
}
