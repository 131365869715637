import { MailOutlined } from "@ant-design/icons";
import { Company } from "@inspecto/common";
import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";

import { FullScreenCardLogoPage } from "src/components/FullScreenCardLogoPage";

export function CompanyIsNotActiveErrorPage(props: { company: Company }) {
  const { t } = useTranslation();
  return (
    <FullScreenCardLogoPage>
      <Result
        status="error"
        title={t("companyIsNotActive")}
        subTitle={t("yourCompanyHasBeenDeactivated", {
          companyName: props.company.name,
        })}
        extra={
          <Button
            href="mailto:biuro@getinspecto.com"
            type="primary"
            icon={<MailOutlined />}
          >
            {t("contact")}
          </Button>
        }
      />
    </FullScreenCardLogoPage>
  );
}
