import { useCallback } from "react";
import styled from "styled-components";

import {
  MultiChoiceOrChoiceFieldComponent,
  PhotosAndNoteComponent,
  MultiChoiceOrChoiceFieldComponentProps,
  ChoiceResponse,
  emptyFieldValueForVerificationByType,
} from "../";

const PhotosAndNoteWrapper = styled.div`
  margin-top: 20px;
`;

export type MultiChoiceOrChoiceWithPhotosAndNoteComponentProps =
  MultiChoiceOrChoiceFieldComponentProps;

export function MultiChoiceOrChoiceWithPhotosAndNoteComponent(
  props: MultiChoiceOrChoiceWithPhotosAndNoteComponentProps
) {
  const onChange = useCallback(
    (response: Partial<ChoiceResponse>) => {
      const existingResponse = props.value;
      const baseResponse =
        existingResponse || emptyFieldValueForVerificationByType.choice;
      props.onChange?.({
        ...baseResponse,
        ...response,
      });
    },
    [props]
  );

  return (
    <>
      <MultiChoiceOrChoiceFieldComponent {...props} onChange={onChange} />
      <PhotosAndNoteWrapper>
        <PhotosAndNoteComponent {...props} onChange={onChange} />
      </PhotosAndNoteWrapper>
    </>
  );
}
