import { Api, api } from "src/utils/api";

import { AuthenticationMethod, PasswordResetLink, User } from "./models";

export class AuthenticationApi {
  constructor(private api: Api) {}

  async protocolfillerLogin(loginPin: string, companyId: string) {
    return await this.api.post<{
      user: User;
    }>("/auth/user/protocolfiller-login/", {
      loginPin,
      company: companyId,
    });
  }

  async backofficeLogin(email: string, password: string, companyId: string) {
    return await this.api.post<{
      user: User;
    }>("/auth/user/backoffice-login/", {
      email,
      password,
      company: companyId,
    });
  }

  async getCurrentUser(): Promise<User | null> {
    const response = await this.api.get<{ user: User | null }>("/auth/user/");
    return response.user;
  }

  async logout(): Promise<void> {
    return await this.api.post<void>("/auth/user/logout/", {});
  }

  async getPasswordResetLink(passwordResetToken: string) {
    return await this.api.get<PasswordResetLink>(
      `/auth/password-reset-links/${passwordResetToken}/`
    );
  }

  async setPassword(
    passwordResetToken: string,
    password: string,
    confirmPassword: string
  ) {
    return await this.api.post(
      `/auth/password-reset-links/${passwordResetToken}/set-password/`,
      {
        password,
        confirmPassword,
      }
    );
  }

  async forgotPassword(email: string) {
    return await this.api.post(`/auth/password-reset-links/`, {
      email,
    });
  }

  async getAuthenticationMethods(companySubdomain: string) {
    return await this.api.get<{ type: AuthenticationMethod }[]>(
      "/auth/authentication-methods/",
      {
        subdomain: companySubdomain,
      }
    );
  }
}

export const authenticationApi = new AuthenticationApi(api);
