import { Image } from "antd";
import styled from "styled-components";

import emptyPhotoImage from "src/static/empty-photo.svg";
import { StorageUploadedImage } from "src/storage";

const photoWidth = 60;
const PhotoWrapper = styled.div`
  flex: 0 0 ${photoWidth}px;
  padding-right: 15px;
`;

interface Props {
  photo: StorageUploadedImage | null;
}

export function StorageUploadedPhoto(props: Props) {
  return (
    <PhotoWrapper>
      {props.photo ? (
        <span onClick={(event) => event.stopPropagation()}>
          <Image
            src={props.photo.thumbnailUrl}
            alt={props.photo.fileName}
            width={photoWidth}
            preview={{
              src: props.photo.fileUrl,
              maskClassName: "ins-image-preview",
            }}
          />
        </span>
      ) : (
        <Image src={emptyPhotoImage} preview={false} width={photoWidth} />
      )}
    </PhotoWrapper>
  );
}
