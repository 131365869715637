import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyBSkVFscr4xvyWeRjk4oYxXWgaYlwBIvYE",
  libraries: ["drawing"],
});

export async function embedGoogleMapsPosition(
  element: HTMLElement,
  latitude: string,
  longitude: string,
  accuracy: string
) {
  const lat = parseFloat(latitude);
  const lng = parseFloat(longitude);
  const acc = parseFloat(accuracy);
  const client = await loader.load();

  const map = new client.maps.Map(element, {
    center: {
      lat,
      lng,
    },
    zoom: 16,
    maxZoom: 19,
  });

  new google.maps.Marker({
    position: { lat, lng },
    map: map,
  });

  const circle = new google.maps.Circle({
    strokeColor: "#1273fc",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#1273fc",
    fillOpacity: 0.35,
    map,
    center: { lat, lng },
    radius: acc,
  });

  const bounds = circle.getBounds();
  if (bounds) {
    map.fitBounds(bounds);
    map.panToBounds(bounds);
  }
}
