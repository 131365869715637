import { Form, Radio, Space, Typography } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  Icon,
  FieldType,
  ClearRadioChoiceButton,
  FieldValueForVerificationByType,
  emptyFieldValueForVerificationByType,
} from "src/components";

import { RetrieveChoice, FieldVerification } from "../models";

export interface ChildrenComponentProps<TFieldType extends FieldType> {
  setResponse: (data: FieldValueForVerificationByType[TFieldType]) => void;
  value: FieldValueForVerificationByType[TFieldType] | undefined;
  autoFocus: boolean;
  type: TFieldType;
  choices: RetrieveChoice[];
}

export interface CustomFieldVerificationQuestionComponentProps<
  TFieldType extends FieldType,
  TFieldValue = FieldValueForVerificationByType[TFieldType]
> {
  fieldType: TFieldType;
  questionId: string;
  previousCustomFieldValue: TFieldValue | undefined;
  previousCustomFieldValueFormatted: string | JSX.Element;
  choices?: RetrieveChoice[];
  response: TFieldValue | undefined;
  customFieldVerificationResponse: FieldVerification | undefined;
  setResponse: (questionId: string, response: TFieldValue) => void;
  setCustomFieldVerificationResponse: (
    questionId: string,
    response: FieldVerification
  ) => void;
  renderComponentForIncorrect: (
    props: ChildrenComponentProps<TFieldType>
  ) => JSX.Element;
  renderComponentForCorrect?: (
    props: ChildrenComponentProps<TFieldType>
  ) => JSX.Element;
}

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledRadioButton = styled(Radio.Button)`
  width: 80px;
  text-align: center;
`;

const RadioGroupWrapper = styled.div`
  padding-top: 10px;
`;

const RenderedComponentWrapper = styled.div`
  margin-top: 20px;
`;

type RadioVerificationValue = FieldVerification;

export function CustomFieldVerificationQuestionComponent<
  TFieldType extends FieldType
>(props: CustomFieldVerificationQuestionComponentProps<TFieldType>) {
  const { t } = useTranslation("protocolFiller");
  const { setResponse: propsSetResponse } = props;

  const setResponse = useCallback(
    (response: FieldValueForVerificationByType[TFieldType] | undefined) => {
      if (typeof response !== "undefined") {
        propsSetResponse(props.questionId, response);
      }
    },
    [props.questionId, propsSetResponse]
  );

  const setFieldVerification = useCallback(
    (fieldVerification: FieldVerification) => {
      props.setCustomFieldVerificationResponse(
        props.questionId,
        fieldVerification
      );
    },
    [props]
  );

  const changeFieldVerification = (value: RadioVerificationValue) => {
    setFieldVerification(value);
    if (value === "correct") {
      setResponse(props.previousCustomFieldValue);
    } else {
      setResponse(emptyFieldValueForVerificationByType[props.fieldType]);
    }
  };

  return (
    <>
      <div>
        <Typography.Text
          style={{
            fontSize: 16,
          }}
        >
          {props.previousCustomFieldValueFormatted || (
            <Icon icon="minus" size={12} />
          )}
        </Typography.Text>
      </div>
      <RadioGroupWrapper>
        <Space>
          <Radio.Group
            buttonStyle="solid"
            value={props.customFieldVerificationResponse}
            onChange={(event) =>
              changeFieldVerification(
                event.target.value as RadioVerificationValue
              )
            }
          >
            <StyledRadioButton value="correct">
              <IconWrapper>
                <Icon
                  icon="check"
                  color={
                    props.customFieldVerificationResponse === "correct"
                      ? "white"
                      : "green"
                  }
                  aria-label="correct"
                />
              </IconWrapper>
            </StyledRadioButton>
            <StyledRadioButton value="incorrect">
              <IconWrapper>
                <Icon
                  icon="times"
                  color={
                    props.customFieldVerificationResponse === "incorrect"
                      ? "white"
                      : "red"
                  }
                  aria-label="incorrect"
                />
              </IconWrapper>
            </StyledRadioButton>
          </Radio.Group>
          {!!props.customFieldVerificationResponse && (
            <ClearRadioChoiceButton onChange={changeFieldVerification} />
          )}
        </Space>
      </RadioGroupWrapper>
      {props.customFieldVerificationResponse === "incorrect" && (
        <RenderedComponentWrapper>
          <Form layout="vertical">
            <Form.Item required label={t("newValue")}>
              {props.renderComponentForIncorrect({
                setResponse: setResponse,
                value: props.response,
                autoFocus: true,
                type: props.fieldType,
                choices: props.choices || [],
              })}
            </Form.Item>
          </Form>
        </RenderedComponentWrapper>
      )}
      {props.customFieldVerificationResponse === "correct" &&
        !!props.renderComponentForCorrect && (
          <RenderedComponentWrapper>
            {props.renderComponentForCorrect({
              setResponse: setResponse,
              value: props.response,
              autoFocus: true,
              type: props.fieldType,
              choices: props.choices || [],
            })}
          </RenderedComponentWrapper>
        )}
    </>
  );
}
