import { useCompany } from "@inspecto/common";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { MultiLanguageProvider, MultiLanguageTextInput } from "src/company";
import { MaxWidth700WithSaveButtonForm } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { DefaultLanguageRequiredFormItem } from "../../../components";
import {
  CreateUpdateCustomVehicleFieldsGroup,
  ListRetrieveCustomVehicleFieldsGroup,
} from "../../../models";

interface Props {
  existingCustomVehicleFieldsGroup: ListRetrieveCustomVehicleFieldsGroup | null;
  onSuccessfulSave?: () => void;
}

export function CustomVehicleFieldsGroupForm(props: Props) {
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const company = useCompany();

  return (
    <MaxWidth700WithSaveButtonForm<CreateUpdateCustomVehicleFieldsGroup, void>
      saveCallback={async (values) => {
        if (props.existingCustomVehicleFieldsGroup) {
          await backOfficeApi.updateCustomVehicleFieldsGroup(
            props.existingCustomVehicleFieldsGroup.id,
            values
          );
          props.onSuccessfulSave?.();
          message.success(t("settings.genericGroups.savedSuccessfully"));
        } else {
          const newCustomVehicleFieldsGroup =
            await backOfficeApi.createCustomVehicleFieldsGroup(values);

          history.replace(
            urls.backOffice.settings.editCustomVehicleFieldsGroup(
              newCustomVehicleFieldsGroup.id
            )
          );
          message.success(t("settings.genericGroups.savedSuccessfully"));
        }
      }}
      initialValues={props.existingCustomVehicleFieldsGroup || undefined}
    >
      <MultiLanguageProvider initialLanguage={company.language}>
        <DefaultLanguageRequiredFormItem
          requiredLanguage="company"
          maxLength={64}
          name="labelTranslations"
          label={t("settings.genericGroups.fields.label")}
        >
          <MultiLanguageTextInput />
        </DefaultLanguageRequiredFormItem>
      </MultiLanguageProvider>
    </MaxWidth700WithSaveButtonForm>
  );
}
