import { fetchAllPaginatedElements } from "@inspecto/common";
import { useCallback } from "react";

import { fullName } from "src/utils";

import { backOfficeApi } from "../api";
import { ListEmployee } from "../models";
import { useFetchOptions } from "./useFetchOptions";

interface UseEmployeeWithEmailOptions {
  employees: ListEmployee[];
  isLoadingEmployees: boolean;
  employeeManagerOptions: { label: string; value: string }[];
}

export function useEmployeesWithEmailOptions(
  mapper:
    | ((employee: ListEmployee) => { value: string; label: string })
    | null = null
): UseEmployeeWithEmailOptions {
  const defaultMapper = useCallback(
    (employee: ListEmployee) => ({
      value: employee.id,
      label: fullName(employee),
    }),
    []
  );

  const fetcher = useCallback(
    () =>
      fetchAllPaginatedElements((pageNumber) =>
        backOfficeApi.getAllEmployees({
          tableParams: {
            pageNumber,
          },
          searchTerm: "",
          searchIn: "name",
          status: "all",
          includeInactive: false,
          emailFilter: "has_email",
          userRoles: [],
        })
      ),
    []
  );

  const [employees, isLoadingEmployees, employeeManagerOptions] =
    useFetchOptions<ListEmployee>(fetcher, mapper || defaultMapper);

  return {
    employees,
    isLoadingEmployees,
    employeeManagerOptions,
  };
}
