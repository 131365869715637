export const allReports = [
  "numberOfProtocolsPerUser",
  "numberOfProtocolsPerVehicle",
  "numberOfOkAndNotOkProtocols",
  "numberOfNotOkVehicleFields",
  "protocolsCreationLocation",
  "listOfReportedDeficiencies",
  "vehicleStatesByDate",
] as const;

export type ReportType = typeof allReports[number];

export function getAllReports() {
  return allReports;
}
