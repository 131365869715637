export type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

export interface Draft {
  id: string | null;
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export function isSingleElementDefined(
  arr: string[] | undefined
): arr is [string] {
  return typeof arr !== "undefined" && arr.length === 1 && arr[0] !== undefined;
}
