import { ReportType } from "@inspecto/common";
import * as queryString from "querystring";

const vehicleCard = (vehicleId: string = ":vehicleId") =>
  `/back-office/vehicles/${vehicleId}`;

const vehicleCardCustomVehicleFieldsGroup = (
  vehicleId: string = ":vehicleId",
  customVehicleFieldsGroupId: string = ":customVehicleFieldsGroupId"
) =>
  `/back-office/vehicles/${vehicleId}/custom-vehicle-fields-group/${customVehicleFieldsGroupId}`;

export const urls = {
  landingPage: () => "/",
  passwordReset: (passwordResetToken: string = ":passwordResetToken") =>
    `/password-reset/${passwordResetToken}`, // This URL is static - should not be altered
  forgotPassword: () => "/forgot-password",

  backOffice: {
    main: () => "/back-office",
    vehicles: () => "/back-office/vehicles",
    protocols: (
      queryParams?: Partial<{
        selectedVehicle: { value: string; label: string };
        selectedEmployee: { value: string; label: string };
      }>
    ) => {
      const queryParamsStringified = queryString.stringify(
        Object.fromEntries(
          Object.entries(queryParams || {}).map(([key, value]) => [
            key,
            encodeURIComponent(JSON.stringify(value)),
          ])
        )
      );
      return `/back-office/protocols${
        queryParamsStringified ? "?" : ""
      }${queryParamsStringified}`;
    },
    createVehicle: () => "/back-office/vehicles/new",
    vehicleCard,
    vehicleCardCustomVehicleFieldsGroup,
    vehicleCardDamages: (vehicleId: string = ":vehicleId") =>
      `/back-office/vehicles/${vehicleId}/damages`,
    vehicleFieldWithoutGroupValueHistory: (
      vehicleId: string = ":vehicleId",
      customVehicleFieldId: string = ":customVehicleFieldId"
    ) => vehicleCard(vehicleId) + `/field-history/${customVehicleFieldId}`,
    vehicleFieldWithGroupValueHistory: (
      vehicleId: string = ":vehicleId",
      customVehicleFieldId: string = ":customVehicleFieldId",
      customVehicleFieldsGroupId: string = ":customVehicleFieldsGroupId"
    ) =>
      vehicleCardCustomVehicleFieldsGroup(
        vehicleId,
        customVehicleFieldsGroupId
      ) + `/field-history/${customVehicleFieldId}`,
    protocol: (protocolId: string = ":protocolId") =>
      `/back-office/protocols/${protocolId}`,
    compareProtocols: (
      firstProtocolId: string = ":firstProtocolId",
      secondProtocolId: string = ":secondProtocolId"
    ) =>
      `/back-office/protocols/compare-protocols/${firstProtocolId}/${secondProtocolId}`,

    employeeCharges: () => "/back-office/employee-charges",

    damages: {
      list: () => "/back-office/vehicle-damages",
      create: (selectedVehicle?: { value: string; label: string }) => {
        const queryParamsStringified = selectedVehicle
          ? queryString.stringify({ vehicle: JSON.stringify(selectedVehicle) })
          : "";
        return `/back-office/vehicle-damages/create${
          queryParamsStringified ? `?${queryParamsStringified}` : ""
        }`;
      },
      edit: (damageId: string = ":damageId") =>
        `/back-office/vehicle-damages/${damageId}`,
    },

    templateBuilder: () => "/back-office/template-builder",
    templateBuilderNew: () => `/back-office/template-builder/new`,
    templateBuilderEdit: (templateId: string = ":templateId") =>
      `/back-office/template-builder/${templateId}`,
    templateBuilderClone: (templateId: string = ":templateId") =>
      `/back-office/template-builder/clone/${templateId}`,
    employees: () => "/back-office/employees",
    createEmployee: () => "/back-office/employees/new",
    editEmployee: (employeeId: string = ":employeeId") =>
      `/back-office/employees/${employeeId}`,
    templates: () => "/back-office/templates",
    settings: {
      main: () => "/back-office/settings",
      customVehicleFieldsGroups: () =>
        "/back-office/settings/custom-vehicle-fields-groups",
      createCustomVehicleFieldsGroup: () =>
        "/back-office/settings/custom-vehicle-fields-groups/create",
      editCustomVehicleFieldsGroup: (
        customVehicleFieldsGroupId: string = ":customVehicleFieldsGroupId"
      ) =>
        `/back-office/settings/custom-vehicle-fields-groups/${customVehicleFieldsGroupId}`,
      templateTags: () => "/back-office/settings/template-tags",
      createTemplateTag: () => "/back-office/settings/template-tags/create",
      editTemplateTag: (templateTagId: string = ":templateTagId") =>
        `/back-office/settings/template-tags/${templateTagId}`,
      vehicleGroups: () => "/back-office/settings/vehicle-groups",
      createVehicleGroup: () => "/back-office/settings/vehicle-groups/create",
      editVehicleGroup: (vehicleGroupId: string = ":vehicleGroupId") =>
        `/back-office/settings/vehicle-groups/${vehicleGroupId}`,
      vehicleFieldsPresets: () =>
        "/back-office/settings/vehicle-fields-presets",
      createVehicleFieldsPreset: () =>
        "/back-office/settings/vehicle-fields-presets/create",
      editVehicleFieldsPreset: (
        vehicleFieldsPresetId: string = ":vehicleFieldsPresetId"
      ) =>
        `/back-office/settings/vehicle-fields-presets/${vehicleFieldsPresetId}`,
      companyDepartments: () => "/back-office/settings/company-departments",
      createCompanyDepartment: () =>
        "/back-office/settings/company-departments/create",
      editCompanyDepartment: (
        companyDepartmentId: string = ":companyDepartmentId"
      ) => `/back-office/settings/company-departments/${companyDepartmentId}`,
      assignCompanyDepartmentToVehicles: (
        companyDepartmentId: string = ":companyDepartmentId"
      ) =>
        `/back-office/settings/company-departments/${companyDepartmentId}/assign-to-vehicles/`,
      assignCompanyDepartmentToUsers: (
        companyDepartmentId: string = ":companyDepartmentId"
      ) =>
        `/back-office/settings/company-departments/${companyDepartmentId}/assign-to-users/`,
      customVehicleFields: () => `/back-office/settings/custom-vehicle-fields/`,
      createCustomVehicleField: () =>
        `/back-office/settings/custom-vehicle-fields/create`,
      editCustomVehicleField: (
        customVehicleFieldId: string = ":customVehicleFieldId"
      ) =>
        `/back-office/settings/custom-vehicle-fields/${customVehicleFieldId}`,
      cloneCustomVehicleField: (
        customVehicleFieldId: string = ":customVehicleFieldId"
      ) =>
        `/back-office/settings/custom-vehicle-fields/clone/${customVehicleFieldId}`,
      notifications: {
        baseView: () => "/back-office/settings/notifications",
        newDamages: () => "/back-office/settings/notifications/new-damages",
        newProtocols: () => "/back-office/settings/notifications/new-protocols",
      },
      linkTypes: {
        baseView: () => "/back-office/settings/link-types",
        create: () => "/back-office/settings/link-types/create",
        edit: (linkTypeId: string = ":linkTypeId") =>
          `/back-office/settings/link-types/${linkTypeId}`,
      },
    },

    fleetState: {
      customVehicleFields: () => "/back-office/fleet-state",
      customVehicleFieldsValues: (
        customVehicleFieldId: string = ":customVehicleFieldId"
      ) => `/back-office/fleet-state/${customVehicleFieldId}`,
    },

    reports: {
      main: () => "/back-office/reports",
      reportView: (reportType: ReportType) =>
        `/back-office/reports/${reportType}`,
    },

    dashboard: () => "/back-office/dashboard",
  },

  protocolFiller: {
    main: () => "/protocol-filler",
    summarySuccess: () => `/protocol-filler/summary/success`,
    qrCodeLogin: () => "/protocol-filler/qr-code-login", // This URL is static - should not be altered
    vehicleType: (vehicleTypeId: string = ":vehicleTypeId") =>
      `/protocol-filler/${vehicleTypeId}`,
    vehicleTypeAndVehicle: (
      vehicleTypeId: string = ":vehicleTypeId",
      vehicleId: string = ":vehicleId"
    ) => `/protocol-filler/${vehicleTypeId}/${vehicleId}/`,
    vehicleTypeAndVehicleAndTemplate: (
      vehicleTypeId: string = ":vehicleTypeId",
      vehicleId: string = ":vehicleId",
      templateId: string = ":templateId"
    ) => `/protocol-filler/${vehicleTypeId}/${vehicleId}/${templateId}/`,
    vehicleTypeAndVehicleAndTemplateAndPage: (
      vehicleTypeId: string = ":vehicleTypeId",
      vehicleId: string = ":vehicleId",
      templateId: string = ":templateId",
      pageId: string = ":pageId"
    ) =>
      `/protocol-filler/${vehicleTypeId}/${vehicleId}/${templateId}/${pageId}`,
    vehicleTypeAndVehicleAndTemplateSummary: (
      vehicleTypeId: string = ":vehicleTypeId",
      vehicleId: string = ":vehicleId",
      templateId: string = ":templateId"
    ) => `/protocol-filler/${vehicleTypeId}/${vehicleId}/${templateId}/summary`,
  },
};
