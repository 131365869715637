import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";

import { disableReloadPageConfirmation } from "src/utils";

interface Props {
  onRetryClick(): void;
}

export function NoConnectionResultPage(props: Props) {
  const { t } = useTranslation();

  return (
    <Result
      status="500"
      title={t("errors.noInternet.message")}
      subTitle={t("errors.noInternet.subtitle")}
      extra={
        <Button
          onClick={() => {
            disableReloadPageConfirmation();
            props.onRetryClick();
          }}
        >
          {t("retry")}
        </Button>
      }
    />
  );
}
