import { useTranslation } from "react-i18next";

import { StatusBadge, StatusBadgeProps } from "src/components";

import {
  CustomVehicleFieldValueStatus,
  labelTranslationKeyByCustomVehicleFieldStatus,
} from "../models";

interface Props {
  status: CustomVehicleFieldValueStatus;
}

export const colorByCustomVehicleFieldStatus: Record<
  CustomVehicleFieldValueStatus,
  StatusBadgeProps["color"]
> = {
  ok: "green",
  not_ok: "red",
  warning: "orange",
  none: "gray",
};

export function CustomVehicleFieldStatusBadge(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");

  return (
    <StatusBadge
      statusText={t(
        `fleetState.statuses.${
          labelTranslationKeyByCustomVehicleFieldStatus[props.status]
        }`
      )}
      color={colorByCustomVehicleFieldStatus[props.status]}
    />
  );
}
