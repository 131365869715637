import { Badge, Divider, Form, TableColumnType, Typography } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";

import { GlobalFormErrors } from "src/components/Form/GlobalFormErrors";
import { wrapCallback } from "src/utils";

import { AssignUnassignPayload } from "../api";
import { CommonViewState } from "../contexts";
import { BackOfficeLayout } from "../views/BackOfficeLayout";
import { ListView, ListViewProps } from "./ListView";

type ItemLikeObject = { id: string };

export type ColumnsAssignItemsListViewProps<TItem extends ItemLikeObject> =
  | TableColumnType<TItem>[]
  | ((selectedRows: TItem[]) => TableColumnType<TItem>[]);

export interface AssignItemsListViewProps<
  TItem extends ItemLikeObject,
  TViewName extends keyof CommonViewState
> extends Pick<
    ListViewProps<TItem, TViewName>,
    | "commonStateViewName"
    | "dataGetter"
    | "debouncedFilterProps"
    | "selectableRowRequirements"
  > {
  title?: string;
  filters?: JSX.Element;
  isItemAssigned: (item: TItem) => boolean;
  assignItems: (assignUnassignPayload: AssignUnassignPayload) => Promise<void>;
  columns?: ColumnsAssignItemsListViewProps<TItem>;
}

export function AssignItemsListView<
  TItem extends ItemLikeObject,
  TViewName extends keyof CommonViewState
>({
  columns = [],
  ...props
}: AssignItemsListViewProps<TItem, TViewName>): JSX.Element {
  const { t } = useTranslation("backoffice");
  const { t: translationT } = useTranslation();
  const [form] = Form.useForm();
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const [numberOfSelectionChangesMade, setNumberOfSelectionChangesMade] =
    useState<number>(0);

  const isItemAssignmentChanged = useCallback(
    (item: TItem, selectedRowsIds: string[]) => {
      const isItemSelected = selectedRowsIds.includes(item.id);
      return isItemSelected !== props.isItemAssigned(item);
    },
    [props]
  );

  const getNumberOfChanges = useCallback(
    (allRows: TItem[], selectedRows: TItem[]): number => {
      const selectedRowsIds = selectedRows.map((row) => row.id);
      return allRows.filter((row) =>
        isItemAssignmentChanged(row, selectedRowsIds)
      ).length;
    },
    [isItemAssignmentChanged]
  );

  const columnsWithChangeIndicator: (
    currentPage: TItem[],
    selectedRows: TItem[]
  ) => TableColumnType<TItem>[] = useCallback(
    (currentPage, selectedRows) => {
      const selectedRowsIds = selectedRows.map((row) => row.id);
      return [
        {
          title: "",
          width: 20,
          dataIndex: "",
          render: (value: TItem) => {
            return (
              isItemAssignmentChanged(value, selectedRowsIds) && (
                <Badge status="warning" />
              )
            );
          },
        },
        ...(typeof columns === "function" ? columns(selectedRows) : columns),
      ];
    },
    [columns, isItemAssignmentChanged]
  );

  return (
    <ListView
      isRowSelected={props.isItemAssigned}
      selectableRowRequirements={props.selectableRowRequirements}
      tableParamsChangeConfirmationMessage={
        numberOfSelectionChangesMade > 0
          ? translationT("quittingWithUnsavedChangesConfirmation")
          : undefined
      }
      selectableRowActions={(currentPage, selectedRows) => {
        const numberOfChangesMade = getNumberOfChanges(
          currentPage,
          selectedRows
        );
        setNumberOfSelectionChangesMade(numberOfChangesMade);
        return [
          {
            buttonLabel: t("assignItemsList.saveChanges"),
            badge: {
              count: numberOfChangesMade,
              status: "warning",
            },
            callback: async (selectedRows, notSelectedRows) => {
              try {
                await wrapCallback(
                  () =>
                    props.assignItems({
                      idsToAssign: selectedRows.map((row) => row.id),
                      idsToUnassign: notSelectedRows.map((row) => row.id),
                    }),
                  form,
                  setGlobalErrors,
                  () => {},
                  t("somethingWentWrong"),
                  translationT("formValidationErrors", { returnObjects: true })
                );
              } catch {}
              form.submit();
            },
            isCorrectNumberOfRows: () => numberOfChangesMade > 0,
            selectionErrorMessage: t("assignItemsList.youHaveNotMadeChanges"),
          },
        ];
      }}
      isCommonStateUpdateEnabled={false}
      dataGetter={props.dataGetter}
      columns={columnsWithChangeIndicator}
      debouncedFilterProps={props.debouncedFilterProps}
      filtersForm={form}
      commonStateViewName={props.commonStateViewName}
    >
      {({ listElement }) => (
        <>
          {props.title ? (
            <>
              <Divider />
              <Typography.Title level={4}>{props.title}</Typography.Title>
            </>
          ) : null}

          <BackOfficeLayout.Filters>{props.filters}</BackOfficeLayout.Filters>
          <GlobalFormErrors errors={globalErrors} />
          {numberOfSelectionChangesMade > 0 && (
            <Prompt
              message={translationT("quittingWithUnsavedChangesConfirmation")}
            />
          )}
          {listElement}
        </>
      )}
    </ListView>
  );
}
