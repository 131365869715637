import { Company, ProtocolFillerVehicleSelectionMethod } from "src/models";

export function getCompanyProtocolFillerSelectors(
  company: Company
): ("qrCode" | "plateNumber")[] {
  switch (company.protocolFillerVehicleSelectionMethod) {
    case ProtocolFillerVehicleSelectionMethod.PLATE_NUMBER_ONLY:
      return ["plateNumber"];
    case ProtocolFillerVehicleSelectionMethod.PLATE_NUMBER_OR_QR_CODE:
      return ["plateNumber", "qrCode"];
    case ProtocolFillerVehicleSelectionMethod.QR_CODE_ONLY:
      return ["qrCode"];
  }
}
