import { api, Api } from "src/utils";

interface NotificationSettings {
  usersToBeNotifiedAboutNewDamages: string[];
  newProtocolNotificationsSettings: {
    id: string | null;
    protocolStatusCondition: "onNotOk" | "always";
    template: string;
    user: string;
  }[];
}

export class NotificationsApi {
  constructor(private api: Api) {}

  async getNotificationSettings(): Promise<NotificationSettings> {
    return await this.api.get("/backoffice/notifications/");
  }

  async saveNotificationSettings(
    data: Partial<NotificationSettings>
  ): Promise<NotificationSettings> {
    return await this.api.patch("/backoffice/notifications/", data);
  }
}

export const notificationsApi = new NotificationsApi(api);
