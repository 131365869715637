import { Space, Switch } from "antd";
import styled from "styled-components";

interface Props {
  isChecked?: boolean;
  label: JSX.Element | string;
  onToggle?: (newValue: boolean) => void;
  disabled?: boolean;
}

const SwitchWrapper = styled(Space)`
  cursor: pointer;
`;

const LabelWrapper = styled.div`
  vertical-align: center;
  line-height: 24px;
`;

export function SwitchInput(props: Props) {
  return (
    <SwitchWrapper
      onClick={() => props.onToggle?.(!props.isChecked)}
      align="end"
    >
      <Switch disabled={props.disabled} checked={props.isChecked} />
      <LabelWrapper>{props.label}</LabelWrapper>
    </SwitchWrapper>
  );
}
