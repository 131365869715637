import { useCallback, useEffect, useMemo, useState } from "react";

type UseFetchOptions<T> = [
  T[],
  boolean,
  { label: string; value: string }[],
  () => void
];

export function useFetchOptions<T>(
  dataFetcher: () => Promise<T[]>,
  optionMapper: (element: T) => { label: string; value: string }
): UseFetchOptions<T> {
  const [allElements, setAllElements] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAndSetData = useCallback(() => {
    setIsLoading(true);
    dataFetcher().then((data) => {
      setAllElements(data);
      setIsLoading(false);
    });
  }, [dataFetcher]);

  useEffect(() => {
    fetchAndSetData();
  }, [fetchAndSetData]);

  const allOptions = useMemo<{ label: string; value: string }[]>(
    () => allElements.map(optionMapper),
    [optionMapper, allElements]
  );

  return [allElements, isLoading, allOptions, fetchAndSetData];
}
