import { useCallback } from "react";

import { NumberFieldComponent, NumberFieldValue } from "src/components";

import { useProtocolFillerContext } from "../contexts";

interface Props {
  questionId: string;
}

export function NumberQuestionComponent(props: Props) {
  const { setNumberResponse, responses } = useProtocolFillerContext();

  const { numberResponses } = responses;

  const setResponse = useCallback(
    (value: NumberFieldValue) => {
      setNumberResponse(props.questionId, value);
    },
    [setNumberResponse, props.questionId]
  );
  const value = numberResponses[props.questionId];

  return <NumberFieldComponent value={value} onChange={setResponse} />;
}
