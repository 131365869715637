import { maxBy } from "lodash";

import {
  CommonChoiceProperties,
  CommonRequirementType,
  commonRequirementTypePriorities,
} from "./models";

export type PhotosAndNoteRequirements = Pick<
  CommonChoiceProperties,
  "photosRequirement" | "noteRequirement"
>;

export interface PhotosAndNoteRequirementsWithId
  extends PhotosAndNoteRequirements {
  id: string;
}

export function getPhotosAndNoteRequirementsForChoices(
  choices: PhotosAndNoteRequirementsWithId[],
  selectedChoiceIds: string[]
): PhotosAndNoteRequirements {
  const requirements = choices.reduce(
    (prev, current) =>
      selectedChoiceIds.includes(current.id)
        ? {
            noteRequirement:
              maxBy(
                [prev.noteRequirement, current.noteRequirement],
                (requirement) =>
                  commonRequirementTypePriorities.indexOf(requirement)
              ) || prev.noteRequirement,
            photosRequirement:
              maxBy(
                [prev.photosRequirement, current.photosRequirement],
                (requirement) =>
                  commonRequirementTypePriorities.indexOf(requirement)
              ) || prev.photosRequirement,
          }
        : prev,
    {
      noteRequirement: CommonRequirementType.None,
      photosRequirement: CommonRequirementType.None,
    }
  );
  return {
    noteRequirement: requirements.noteRequirement,
    photosRequirement: requirements.photosRequirement,
  };
}
