import { ArrowRightOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Grid,
  Select,
  SelectProps,
  Switch,
  TableColumnType,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  MultiLineTag,
  RangePicker,
  SearchableAsyncSelect,
} from "src/components";
import { urls } from "src/urls";
import { fullName, fullNameReversed, getPlateNumber } from "src/utils";

import { backOfficeApi } from "../../api";
import {
  CustomVehicleFieldStatusBadge,
  FilterItem,
  FiltersRow,
  VehicleGroupsFilterItem,
} from "../../components";
import { ProtocolsViewState } from "../../contexts";
import { useProtocolTypes, useVehicleGroups } from "../../hooks";
import {
  Protocol,
  ListProtocol,
  SimpleVehicle,
  RetrieveCreateUpdateEmployee,
  ListEmployee,
} from "../../models";
import { BackOfficeListViewLayout } from "../BackOfficeListViewLayout";

export function ProtocolsListView() {
  const { t } = useTranslation("backoffice");
  const breakpoint = Grid.useBreakpoint();
  const separateLabelAndCreatedByNameBreakpoint = "xxl";
  const history = useHistory();
  const [filtersForm] = Form.useForm();
  const { vehicleGroups, vehicleGroupOptions } = useVehicleGroups();
  const { protocolTypeOptions: _protocolTypeOptions, isLoadingProtocolTypes } =
    useProtocolTypes();

  const protocolTypeOptions: SelectProps<string>["options"] = useMemo(
    () =>
      _protocolTypeOptions.map((protocolTypeOption) => ({
        label: (
          <div style={{ whiteSpace: "normal" }}>{protocolTypeOption.label}</div>
        ),
        value: protocolTypeOption.value,
      })),
    [_protocolTypeOptions]
  );

  const dataGetter = useMemo(
    () => async (protocolsView: ProtocolsViewState) => {
      const [dateFrom, dateTo] = protocolsView.datesFilter || [null, null];

      return await backOfficeApi.getProtocols({
        ...protocolsView,
        employeeId: protocolsView.selectedEmployee?.value || "",
        vehicleId: protocolsView.selectedVehicle?.value || "",
        dateAfter: dateFrom?.format("YYYY-MM-DD"),
        dateBefore: dateTo?.format("YYYY-MM-DD"),
      });
    },
    []
  );

  const columns = useMemo<TableColumnType<ListProtocol>[]>(
    () => [
      {
        title: breakpoint[separateLabelAndCreatedByNameBreakpoint]
          ? t("protocolsView.label")
          : `${t("protocolsView.label")} + ${t("protocolsView.createdByName")}`,
        width: 200,
        render: (protocol: ListProtocol) => (
          <>
            <div>
              <Typography.Text strong>
                {protocol.protocolObject.label}
              </Typography.Text>
            </div>
            {!breakpoint[separateLabelAndCreatedByNameBreakpoint] && (
              <div>{fullName(protocol.createdByObject)}</div>
            )}
          </>
        ),
      },
      {
        dataIndex: ["createdByObject"],
        title: t("protocolsView.createdByName"),
        width: 250,
        responsive: [separateLabelAndCreatedByNameBreakpoint],
        render: (createdBy: RetrieveCreateUpdateEmployee) =>
          fullName(createdBy),
      },
      {
        dataIndex: ["vehicleObject"],
        title: t("protocolsView.plateNumber"),
        width: "auto",
        render: (vehicle: ListProtocol["vehicleObject"]) => (
          <div>
            <div>{vehicle.plateNumber}</div>
            <div>
              {vehicleGroups
                .filter((vehicleGroup) =>
                  vehicle.vehicleGroups.includes(vehicleGroup.id)
                )
                .map((vehicleGroup) => (
                  <MultiLineTag key={vehicleGroup.id} style={{ marginTop: 4 }}>
                    {vehicleGroup.label}
                  </MultiLineTag>
                ))}
            </div>
          </div>
        ),
      },
      {
        dataIndex: ["protocolObject", "createdAt"],
        title: t("protocolsView.createdAt"),
        width: "150px",
        render: (date: string) => (
          <>{dayjs(date as string).format("DD.MM.YYYY HH:mm")}</>
        ),
      },
      {
        dataIndex: ["lastEdited"],
        title: t("protocolsView.lastEdited"),
        responsive: ["sm"],
        width: "170px",
        render: (date: string | null) => {
          if (date) {
            return <>{dayjs(date as string).format("DD.MM.YYYY HH:mm")}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        dataIndex: ["protocolObject", "status"],
        title: t("protocolsView.status"),
        align: "center",
        width: "120px",
        render: (value: Protocol["status"]) => (
          <CustomVehicleFieldStatusBadge status={value} />
        ),
      },
      {
        dataIndex: "protocolObject",
        align: "right",
        width: 140,
        render: (value: Protocol) => {
          return (
            <Button
              onClick={() => {
                history.push(urls.backOffice.protocol(value.id));
              }}
              size="small"
              icon={<ArrowRightOutlined />}
            >
              {t("tableActions.open")}
            </Button>
          );
        },
      },
    ],
    [breakpoint, t, vehicleGroups, history]
  );

  const searchVehicles = useCallback(
    async (query: string, includeArchived: boolean) => {
      const vehicles = await backOfficeApi.getSimpleVehiclesList({
        tableParams: {
          pageNumber: 1,
          sortByField: "",
        },
        plateNumber: query,
        includeArchived,
      });

      return vehicles.results;
    },
    []
  );

  const searchEmployees = useCallback(
    async (query: string, includeArchived: boolean) => {
      const employees = await backOfficeApi.getAllEmployees({
        tableParams: {
          pageNumber: 1,
        },
        searchTerm: query,
        searchIn: "name",
        status: "all",
        includeInactive: includeArchived,
        userRoles: [],
        emailFilter: "all",
      });
      return employees.results;
    },
    []
  );

  return (
    <BackOfficeListViewLayout
      dataGetter={dataGetter}
      columns={columns}
      commonStateViewName="protocolsView"
      pageTitle={t("protocolsView.pageTitle")}
      filtersForm={filtersForm}
      queryParamsFilters={["selectedVehicle", "selectedEmployee"]}
      selectableRowActions={[
        {
          isCorrectNumberOfRows: (selectedRowsCount) => selectedRowsCount === 2,
          selectionErrorMessage: t(
            "vehicleProtocols.selectTwoProtocolsFirstBeforeComparison"
          ),
          buttonLabel: t("vehicleProtocols.compareProtocolsButton"),
          callback: (selectedRows: ListProtocol[]) => {
            if (selectedRows[0] && selectedRows[1]) {
              history.push(
                urls.backOffice.compareProtocols(
                  selectedRows[0].id,
                  selectedRows[1].id
                )
              );
            }
          },
        },
      ]}
      filters={
        <>
          <FiltersRow>
            <FilterItem
              name="issuesOnly"
              valuePropName="checked"
              label={t("issuesOnly")}
            >
              <Switch />
            </FilterItem>
          </FiltersRow>
          <FiltersRow>
            <FilterItem name="protocolType" $inputWidth="medium">
              <Select
                allowClear
                options={protocolTypeOptions}
                loading={isLoadingProtocolTypes}
                placeholder={t("protocolsView.label")}
                style={{
                  width: 350,
                }}
              />
            </FilterItem>
            <FilterItem name="selectedEmployee" $inputWidth="medium">
              <SearchableAsyncSelect<ListEmployee>
                searchElements={searchEmployees}
                showArchivedCheckbox
                getLabel={fullNameReversed}
                valueKey="id"
                placeholder={t("protocolsView.createdByName")}
                noMargin
              />
            </FilterItem>
            <FilterItem name="selectedVehicle" $inputWidth="medium">
              <SearchableAsyncSelect<SimpleVehicle>
                searchElements={searchVehicles}
                showArchivedCheckbox
                getLabel={getPlateNumber}
                valueKey="id"
                placeholder={t("protocolsView.plateNumber")}
                noMargin
              />
            </FilterItem>
          </FiltersRow>
          <FiltersRow>
            <VehicleGroupsFilterItem options={vehicleGroupOptions} />
            <FilterItem name="datesFilter">
              <RangePicker allowClear />
            </FilterItem>
          </FiltersRow>
        </>
      }
    />
  );
}
