import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  FormInstance,
  FormProps as AntdFormProps,
  Modal,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { wrapCallback } from "src/utils/antdFormUtils";

import { FormErrorsAlert } from "./FormErrorsAlert";
import { GlobalFormErrors } from "./GlobalFormErrors";

export interface FormProps<RecordType, TSaveOutput>
  extends Pick<
    AntdFormProps<RecordType>,
    | "form"
    | "children"
    | "initialValues"
    | "autoComplete"
    | "onValuesChange"
    | "layout"
  > {
  saveCallback(record: RecordType): Promise<TSaveOutput>;
  onSuccessfulSave?(
    formValues: RecordType,
    savedRecord: TSaveOutput,
    onSuccessfulSaveContext?: boolean
  ): void;
  alignment?: "center";
  confirmationText?: string;
  onFieldsChange?: () => void;
  onIsSavingChange?(isSaving: boolean): void;
  isRetryableOnError?: boolean;
}

const ON_SUCCESSFUL_SAVE_CONTEXT_FORM_ITEM_NAME = "__onSuccessfulSaveContext";

export function submitFormWithOnSuccessfulSaveContext(
  form: FormInstance,
  context: boolean
) {
  form.setFields([
    {
      name: ON_SUCCESSFUL_SAVE_CONTEXT_FORM_ITEM_NAME,
      value: context,
    },
  ]);
  form.submit();
}

export function CommonForm<
  RecordType extends Record<string, any>,
  TSavedRecord = void
>({ layout = "vertical", ...props }: FormProps<RecordType, TSavedRecord>) {
  const [modal, modalContext] = Modal.useModal();
  const [isSaving, setIsSaving] = useState(false);
  const [globalErrors, setGlobalErrors] = useState<string[]>([]);
  const [isOfflineError, setIsOfflineError] = useState(false);

  const [form] = Form.useForm<RecordType>(props.form);
  const { t } = useTranslation();

  const { onIsSavingChange } = props;
  useEffect(() => {
    if (onIsSavingChange) {
      onIsSavingChange(isSaving);
    }
  }, [onIsSavingChange, isSaving]);

  return (
    <Form<RecordType>
      id="myForm"
      form={form}
      initialValues={props.initialValues}
      layout={layout}
      onFieldsChange={props.onFieldsChange}
      scrollToFirstError
      labelCol={
        props.alignment === "center"
          ? {
              style: {
                textAlign: "center",
              },
            }
          : undefined
      }
      style={props.alignment === "center" ? { textAlign: "center" } : undefined}
      onValuesChange={(changedValues, values) => {
        let fieldsToReset = new Set<string>();

        for (const [fieldName] of Object.entries(changedValues)) {
          fieldsToReset.add(fieldName);
        }

        if (fieldsToReset.size !== 0) {
          form.setFields(
            Array.from(fieldsToReset).map((fieldName) => ({
              name: fieldName,
              errors: [],
            }))
          );
        }

        if (props.onValuesChange) {
          props.onValuesChange(changedValues, values);
        }
      }}
      onFinish={async (values) => {
        if (props.confirmationText) {
          await new Promise((resolve, reject) => {
            modal.confirm({
              title: props.confirmationText,
              icon: <ExclamationCircleOutlined />,
              maskClosable: true,
              onCancel: () => reject(),
              async onOk() {
                resolve("");
              },
            });
          });
        }

        const bypassOnSuccessfulSave =
          values[ON_SUCCESSFUL_SAVE_CONTEXT_FORM_ITEM_NAME];
        delete values[ON_SUCCESSFUL_SAVE_CONTEXT_FORM_ITEM_NAME];

        form.setFields([
          {
            name: ON_SUCCESSFUL_SAVE_CONTEXT_FORM_ITEM_NAME,
            value: undefined,
          },
        ]);

        setIsSaving(true);
        try {
          const savedRecord = await wrapCallback(
            () => props.saveCallback(values),
            form,
            setGlobalErrors,
            setIsOfflineError,
            t("errors.somethingWentWrong"),
            t("formValidationErrors", { returnObjects: true })
          );
          if (props.onSuccessfulSave) {
            props.onSuccessfulSave(values, savedRecord, bypassOnSuccessfulSave);
          }
        } catch {}

        setIsSaving(false);
      }}
    >
      <FormErrorsAlert visible={isOfflineError}>
        {t("errors.noInternet.message")}
        {props.isRetryableOnError && (
          <Button type="link" onClick={() => form.submit()}>
            {t("retry")}
          </Button>
        )}
      </FormErrorsAlert>
      <GlobalFormErrors errors={globalErrors} />
      {/* Below hidden button makes submit work on Enter keydown */}
      <button type="submit" hidden style={{ display: "none" }} />
      <Form.Item name={ON_SUCCESSFUL_SAVE_CONTEXT_FORM_ITEM_NAME} hidden>
        <></>
      </Form.Item>
      {props.children}
      {modalContext}
    </Form>
  );
}
