import { InputWrapper } from "./InputWrapper";
import { SignaturePad } from "./SignaturePad";

interface Props {
  onChange: (newValue: File | null) => void;
  label?: string;
  "aria-label"?: string;
  isRequired?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  value: File | null;
}

export function SignatureInput(props: Props): JSX.Element {
  return (
    <InputWrapper label={props.label} isRequired={props.isRequired}>
      <SignaturePad
        onSignatureDataChange={(signatureData) => props.onChange(signatureData)}
        value={props.value}
        aria-label={props["aria-label"]}
      />
    </InputWrapper>
  );
}
