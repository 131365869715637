import { fetchAllPaginatedElements } from "@inspecto/common";
import { useEffect, useState } from "react";

import { StandardCustomVehicleFieldType } from "src/company";

import { backOfficeApi } from "../../../api";
import { ListCustomVehicleField } from "../../../models";

interface ListCustomVehicleFieldExcludingDamages
  extends ListCustomVehicleField {
  type: StandardCustomVehicleFieldType;
}

export function useAllCustomVehicleFieldsExcludingDamages(
  vehicleTypeId: string
) {
  const [isCustomVehicleFieldsLoading, setIsCustomVehicleFieldsLoading] =
    useState(true);
  const [customVehicleFields, setCustomVehicleFields] = useState<
    ListCustomVehicleFieldExcludingDamages[]
  >([]);

  useEffect(() => {
    (async () => {
      setIsCustomVehicleFieldsLoading(true);
      const customVehicleFields =
        await fetchAllPaginatedElements<ListCustomVehicleFieldExcludingDamages>(
          (pageNumber) =>
            backOfficeApi.getCustomVehicleFields({
              tableParams: { pageNumber, pageSize: 400 },
              vehicleTypes: [vehicleTypeId],
              excludeTypes: ["damages"],
            })
        );

      setCustomVehicleFields(customVehicleFields);
      setIsCustomVehicleFieldsLoading(false);
    })();
  }, [vehicleTypeId]);

  return {
    isCustomVehicleFieldsLoading,
    customVehicleFields,
  };
}
