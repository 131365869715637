import { PAGE_SIZE } from "@inspecto/common";
import {
  Empty,
  Image,
  Modal,
  ModalProps,
  Pagination,
  Skeleton,
  Tag,
  Timeline,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { backOfficeApi } from "../../api";
import { getDamageStatusOptions, tagColorByDamageStatus } from "../../common";
import { StorageUploadedPhoto } from "../../components";
import { UserDateTimelineItem } from "../../components/UserDateTimelineItem";
import { RetrieveDamageHistory } from "../../models";

const PhotoAndLabelWrapper = styled.div`
  width: 400px;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.whitePanelBorderColor};
  border-radius: 5px;
  padding: 15px;
`;

export interface DamageHistoryModalProps extends ModalProps {
  damageId: string;
  damageLabel: string;
}

export function DamageHistoryModal({
  damageId,
  ...props
}: DamageHistoryModalProps) {
  const { t } = useTranslation("backoffice");
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [damageHistory, setDamageHistory] = useState<RetrieveDamageHistory[]>(
    []
  );

  const loadData = useCallback(async () => {
    setIsLoading(true);
    const data = await backOfficeApi.getDamageHistory(damageId, {
      pageNumber: currentPage,
    });
    setDamageHistory(data.results);
    setTotal(data.count);
    setIsLoading(false);
  }, [currentPage, damageId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Modal
      {...props}
      title={t("damageHistory.title") + ": " + props.damageLabel}
      width={600}
      open
      okButtonProps={{ style: { display: "none" } }}
      cancelText={t("close")}
    >
      {isLoading ? (
        <Skeleton />
      ) : damageHistory.length ? (
        <>
          <Timeline>
            <Image.PreviewGroup>
              {damageHistory.map((history, index) => (
                <UserDateTimelineItem
                  marginBottom={10}
                  key={`${index}-${history.historyDate}`}
                  user={history.historyUser}
                  datetime={history.historyDate}
                >
                  <PhotoAndLabelWrapper>
                    <StorageUploadedPhoto photo={history.photo} />
                    <div>
                      <div style={{ marginBottom: 5 }}>
                        <Typography.Text strong>
                          {history.label}
                        </Typography.Text>
                      </div>
                      <Tag color={tagColorByDamageStatus[history.status]}>
                        {
                          getDamageStatusOptions(t).find(
                            (option) => option.value === history.status
                          )?.label
                        }
                      </Tag>
                    </div>
                  </PhotoAndLabelWrapper>
                </UserDateTimelineItem>
              ))}
            </Image.PreviewGroup>
          </Timeline>
          {total > PAGE_SIZE && (
            <Pagination
              showSizeChanger={false}
              current={currentPage}
              total={total}
              pageSize={PAGE_SIZE}
              onChange={setCurrentPage}
            />
          )}
        </>
      ) : (
        <Empty />
      )}
    </Modal>
  );
}
