import { SelectProps, TableColumnType } from "antd";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { backOfficeApi } from "../../../api";
import {
  AssignVehiclesListView,
  CompanyDepartmentSelectFilterItem,
} from "../../../components";
import { getAssignedItemTagRenderer } from "../../../components/AssignedItemsTagsRenderer";
import { useVehicleGroups } from "../../../hooks";
import {
  ListCompanyDepartment,
  RetrieveCompanyDepartment,
  SimpleVehicle,
} from "../../../models";
import {
  BackOfficeLayout,
  BreadcrumbInformation,
} from "../../BackOfficeLayout";
import { contentMinWidth } from "./common";

export function AssignCompanyDepartmentToVehiclesView(props: {
  companyDepartment: RetrieveCompanyDepartment;
  breadcrumbs: BreadcrumbInformation[];
  allCompanyDepartments: ListCompanyDepartment[];
  onSuccessfulSave: () => void;
}) {
  const { t } = useTranslation("backoffice");
  const { companyDepartmentId } = useParams<{ companyDepartmentId: string }>();
  const { vehicleGroupOptions } = useVehicleGroups();
  const allCompanyDepartmentsOptions: SelectProps["options"] = useMemo(
    () =>
      props.allCompanyDepartments.map((department) => ({
        label: department.name,
        value: department.id,
      })),
    [props.allCompanyDepartments]
  );

  const extraColumns: (
    selectedRows: SimpleVehicle[]
  ) => TableColumnType<SimpleVehicle>[] = useCallback(
    (selectedRows) => [
      {
        dataIndex: "companyDepartmentObject",
        title: t("settings.companyDepartments.singleItemLabel"),
        width: 400,
        render: getAssignedItemTagRenderer<
          SimpleVehicle,
          SimpleVehicle["companyDepartmentObject"]
        >(
          selectedRows,
          {
            id: companyDepartmentId,
            label: props.companyDepartment.name,
          },
          (companyDepartment) => companyDepartment.name
        ),
      },
    ],
    [companyDepartmentId, props.companyDepartment.name, t]
  );

  const isVehicleAssigned = useCallback(
    (vehicle) => vehicle.companyDepartmentObject.id === companyDepartmentId,
    [companyDepartmentId]
  );

  return (
    <div>
      <BackOfficeLayout
        contentMinWidth={contentMinWidth}
        breadcrumbs={props.breadcrumbs}
        pageTitle={t(
          "settings.companyDepartments.vehiclesAssignedToCompanyDepartment",
          {
            name: props.companyDepartment.name,
          }
        )}
      >
        <BackOfficeLayout.Content>
          <AssignVehiclesListView
            selectableRowRequirements={{
              deselectionDisallowedMessages: {
                title: t(
                  "settings.companyDepartments.vehicleUnassignmentDisallowed.title"
                ),
                info: t(
                  "settings.companyDepartments.vehicleUnassignmentDisallowed.info"
                ),
              },
              renderDisallowedRow: (row) => row.plateNumber,
              isRowSelectionRequired: (row) =>
                row.companyDepartmentObject.id === companyDepartmentId,
            }}
            header=""
            vehicleGroupOptions={vehicleGroupOptions}
            isVehicleAssigned={isVehicleAssigned}
            assignVehicles={async (payload) => {
              await backOfficeApi.assignCompanyDepartmentToVehicles(
                companyDepartmentId,
                payload
              );
              props.onSuccessfulSave();
            }}
            extraColumns={extraColumns}
            extraFilters={
              <CompanyDepartmentSelectFilterItem
                options={allCompanyDepartmentsOptions}
              />
            }
          />
        </BackOfficeLayout.Content>
      </BackOfficeLayout>
    </div>
  );
}
