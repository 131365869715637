import { useTranslation } from "react-i18next";

import { useAuthentication } from "src/authentication";
import { urls } from "src/urls";

import { BackOfficeLayout } from "../../BackOfficeLayout";
import { contentMinWidth } from "./common";
import { CompanyDepartmentForm } from "./CompanyDepartmentForm";

export function CreateCompanyDepartmentView() {
  const { t } = useTranslation("backoffice");
  const { reloadUserWithoutLoading } = useAuthentication();

  return (
    <BackOfficeLayout
      contentMinWidth={contentMinWidth}
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.companyDepartments.listLabel"),
          url: urls.backOffice.settings.companyDepartments(),
        },
      ]}
      pageTitle={t("settings.companyDepartments.singleItemLabel")}
    >
      <BackOfficeLayout.Content>
        <CompanyDepartmentForm
          existingCompanyDepartment={null}
          onSuccessfulSave={reloadUserWithoutLoading}
        />
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
