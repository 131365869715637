import axios from "axios";
import { isObject } from "lodash";
import { useEffect, useState } from "react";

import { sendErrorToSentryIfNotNetworkError } from "src/utils";

import { NEW_APP_VERSION_CHECK_INTERVAL } from "./config";

interface VersionResponse {
  fullHash: string;
}

export function useNewAppVersionAvailableHook(): {
  isNewAppVersionAvailable: boolean;
} {
  const [currentVersion, setCurrentVersion] = useState<string | "not-set">(
    "not-set"
  );
  const [isNewAppVersionAvailable, setIsNewAppVersionAvailable] =
    useState(false);

  useEffect(() => {
    async function checkIfNewVersionAvailable(): Promise<void> {
      if (isNewAppVersionAvailable) {
        return;
      }

      try {
        const { data } = await axios.get<unknown>("/version.json");
        if (!isVersionResponse(data)) {
          throw new Error(
            `Wrong version.json content: ${JSON.stringify(data)}`
          );
        }
        if (currentVersion === "not-set") {
          setCurrentVersion(data.fullHash);
        } else if (data.fullHash !== currentVersion) {
          setIsNewAppVersionAvailable(true);
        }
      } catch (e) {
        sendErrorToSentryIfNotNetworkError(
          "New App Version Available check failed",
          e
        );
      }
    }

    const interval = setInterval(
      checkIfNewVersionAvailable,
      NEW_APP_VERSION_CHECK_INTERVAL
    );
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [currentVersion, isNewAppVersionAvailable]);

  return { isNewAppVersionAvailable };
}

function isVersionResponse(data: unknown): data is VersionResponse {
  return isObject(data) && data.hasOwnProperty("fullHash");
}
