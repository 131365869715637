import { Select as AntdSelect, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { InputWrapper } from "../InputWrapper";
import { SelectProps } from "./SelectProps";

export function Select<T extends string = string>({
  options,
  ...props
}: SelectProps<T>) {
  const { t } = useTranslation("translation");
  const { isOptionDisabled, getDisabledOptionText, noMargin, ...restProps } =
    props;

  return (
    <InputWrapper
      label={props.label}
      isRequired={props.isRequired}
      helpTooltip={props.helpTooltip}
      noMargin={noMargin}
    >
      <AntdSelect<T>
        {...restProps}
        showArrow
        defaultValue={props.defaultValue || undefined}
        value={props.value || undefined}
        placeholder={props.placeholder || t("select")}
        notFoundContent={t("noOptions")}
        filterOption={!props.onSearch}
      >
        {options.map((option) => {
          const disabled = isOptionDisabled?.(option);
          return (
            <AntdSelect.Option
              value={option.value}
              disabled={disabled}
              key={option.value}
              label={option.label}
            >
              <Tooltip
                title={
                  disabled && (getDisabledOptionText?.(option.value) || "")
                }
              >
                <div>
                  <Typography.Text
                    disabled={disabled}
                    ellipsis={{ tooltip: true }}
                  >
                    {option.label}
                  </Typography.Text>
                </div>
              </Tooltip>
            </AntdSelect.Option>
          );
        })}
      </AntdSelect>
    </InputWrapper>
  );
}
