import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useCompany } from "@inspecto/common";
import { Col, Form, Input, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { useAuthentication } from "src/authentication";
import { ModalForm } from "src/components";
import { fullName } from "src/utils";

import { backOfficeApi, ScheduleFreeTrainingData } from "../api";

interface Props {
  closeModal(): void;
  onSuccess(): void;
}

export function ScheduleFreeTrainingModal(props: Props) {
  const { t } = useTranslation("backoffice");
  const { user } = useAuthentication();
  const company = useCompany();

  const initialValues: ScheduleFreeTrainingData = {
    phoneNumber: "",
  };

  if (!user) {
    return null;
  }

  return (
    <ModalForm<ScheduleFreeTrainingData>
      open
      saveCallback={handleSave}
      closeModal={props.closeModal}
      formTitle={t("scheduleFreeTraining.title")}
      cancelButtonText={t("cancel")}
      confirmButtonText={t("send")}
      initialValues={initialValues}
    >
      <Row gutter={[0, 5]} style={{ marginBottom: 20 }}>
        <Col xs={24}>
          <Space>
            <UserOutlined />
            <Typography.Text strong>{fullName(user)}</Typography.Text>
            <Typography.Text italic>{`<${user.email}>`}</Typography.Text>
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <HomeOutlined />
            <Typography.Text strong>{company.name}</Typography.Text>
          </Space>
        </Col>
      </Row>
      <Form.Item
        label={t("phoneNumber")}
        name="phoneNumber"
        rules={[{ required: true }]}
        style={{ maxWidth: 250 }}
      >
        <Input />
      </Form.Item>
    </ModalForm>
  );

  async function handleSave(values: ScheduleFreeTrainingData): Promise<void> {
    await backOfficeApi.scheduleFreeTraining(values);
    props.onSuccess();
  }
}
