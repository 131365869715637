import { useCallback, useEffect, useState } from "react";

import { backOfficeApi } from "../api";
import { TemplateTagsRequirementsByVehicleType } from "../models";

type UseTemplateTagsRequirementsByVehicleType = [
  TemplateTagsRequirementsByVehicleType | null,
  () => void
];

export function useTemplateTagsRequirementsByVehicleType(): UseTemplateTagsRequirementsByVehicleType {
  const [
    templateTagsRequirementsByVehicleType,
    setTemplateTagsRequirementsByVehicleType,
  ] = useState<TemplateTagsRequirementsByVehicleType | null>(null);

  const fetchTemplateTagsRequirementsByVehicleType = useCallback(async () => {
    const templateTagsRequirementsByVehicleType =
      await backOfficeApi.getTemplateTagsRequirementsByVehicleType();
    setTemplateTagsRequirementsByVehicleType(
      templateTagsRequirementsByVehicleType
    );
  }, []);

  useEffect(() => {
    fetchTemplateTagsRequirementsByVehicleType();
  }, [fetchTemplateTagsRequirementsByVehicleType]);

  return [
    templateTagsRequirementsByVehicleType,
    fetchTemplateTagsRequirementsByVehicleType,
  ];
}
