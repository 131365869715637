import { ApiError } from "@inspecto/common";
import { Alert, Button, Form, Input, Modal, Spin } from "antd";
import { useState } from "react";

interface Props {
  title: string;
  cancelButtonText: string;
  actionButtonText: string;
  destroyButtonText: string;
  unexpectedErrorMessage: string;
  unexpectedErrorMessageOnDestroy: string;
  performAction: (text: string) => Promise<void>;
  removeObject?: () => Promise<void>;
  closeModal: () => void;
  initialCommentText?: string;
}

export function AddEditDeleteCommentModal({
  initialCommentText = "",
  ...props
}: Props): JSX.Element {
  const [commentText, setCommentText] = useState<string>(initialCommentText);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      onCancel={props.closeModal}
      open
      title={props.title}
      footer={[
        ...(props.removeObject
          ? [
              <Button
                danger
                disabled={isLoading}
                key="destroy"
                onClick={getActionHandler(
                  props.removeObject,
                  props.unexpectedErrorMessageOnDestroy
                )}
              >
                {props.destroyButtonText}
              </Button>,
            ]
          : []),
        <Button key="back" onClick={props.closeModal}>
          {props.cancelButtonText}
        </Button>,
        <Button
          type="primary"
          disabled={isLoading}
          onClick={getActionHandler(
            props.performAction,
            props.unexpectedErrorMessage
          )}
        >
          {props.actionButtonText}
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        {!!error && (
          <Form.Item>
            <Alert message={error} type="error" />
          </Form.Item>
        )}

        <Input.TextArea
          rows={4}
          onChange={(e) => setCommentText(e.target.value)}
          value={commentText}
        />
      </Spin>
    </Modal>
  );

  function getActionHandler(
    performRequest: (text: string) => Promise<void>,
    unexpectedErrorMessage: string
  ): () => Promise<void> {
    return async () => {
      setIsLoading(true);
      setError("");
      try {
        await performRequest(commentText);
      } catch (e) {
        if (e instanceof ApiError) {
          setError(Object.values(e.body).join(" "));
        } else {
          setError(unexpectedErrorMessage);
        }
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      props.closeModal();
    };
  }
}
