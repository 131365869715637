import dayjs from "dayjs";

export interface DatePeriodFormState {
  datePeriod: [dayjs.Dayjs, dayjs.Dayjs];
}

const today = dayjs();
const thirtyDaysAgo = today.subtract(30, "day");

export const datePeriodFormInitialValues: DatePeriodFormState = {
  datePeriod: [thirtyDaysAgo, today],
};

export function getDatePeriodFormInitialValues() {
  return datePeriodFormInitialValues;
}

export function formatDatePeriod(data: DatePeriodFormState): {
  dateFrom: string;
  dateTo: string;
} {
  return {
    dateFrom: data.datePeriod[0].format("YYYY-MM-DD"),
    dateTo: data.datePeriod[1].format("YYYY-MM-DD"),
  };
}
