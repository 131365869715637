import { InfoCircleOutlined } from "@ant-design/icons";
import {
  useCompany,
  getCompanyProtocolFillerSelectors,
} from "@inspecto/common";
import { Alert, Button, Space } from "antd";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { useAuthentication } from "src/authentication";
import { OrDivider, Spinner, TopBarLayout } from "src/components";
import { myTheme } from "src/theme";
import { urls } from "src/urls";

import { ChooseVehicleViaQRCodeButton } from "../components/ChooseVehicleViaQRCodeButton";
import { protocolFillerTopBarLayoutMaxWidth } from "../components/PageLayout";
import { ProtocolResolutionComponent } from "../components/ProtocolResolutionComponent";
import { VehicleType } from "../models";

interface ProtocolSuccessfullySentScreenState {
  chooseVehicleViaQRCodeButtonLabel: string;
  orDividerText: string;
  title: string;
  logoutButtonText: string;
  youCanLogOutMessage: string;
  youHaveBeenAutomaticallyLoggedOut: string;
  vehicleTypes: VehicleType[];
}

interface ProtocolFillerSummaryPageManagerContext {
  setProtocolSuccessfullySentScreenState: (
    protocolSuccessfullySentScreenState: ProtocolSuccessfullySentScreenState
  ) => void;
}

const protocolFillerSummaryPageManagerContext =
  createContext<ProtocolFillerSummaryPageManagerContext>({
    setProtocolSuccessfullySentScreenState: () => null,
  });

export function ProtocolFillerSummaryPageManager(
  props: PropsWithChildren<{}>
): JSX.Element {
  const company = useCompany();
  const history = useHistory();
  const { user, logout, isLoggingOut } = useAuthentication();
  const selectors = useMemo(
    () => getCompanyProtocolFillerSelectors(company),
    [company]
  );
  const [
    protocolSuccessfullySentScreenState,
    setProtocolSuccessfullySentScreenState,
  ] = useState<null | ProtocolSuccessfullySentScreenState>(null);

  const isAutomaticLogoutEnabled =
    company.automaticallyLogOutAfterCreatingProtocol;

  const logoutUser = useCallback(async () => {
    if (!user) {
      return;
    }
    await logout();
  }, [logout, user]);

  useEffect(() => {
    if (isAutomaticLogoutEnabled && protocolSuccessfullySentScreenState) {
      logoutUser();
    }
  }, [
    isAutomaticLogoutEnabled,
    logoutUser,
    protocolSuccessfullySentScreenState,
  ]);

  return (
    <protocolFillerSummaryPageManagerContext.Provider
      value={{
        setProtocolSuccessfullySentScreenState,
      }}
    >
      {protocolSuccessfullySentScreenState ? (
        <TopBarLayout
          maxWidth={protocolFillerTopBarLayoutMaxWidth}
          onLogoClick={() => history.push(urls.landingPage())}
        >
          {isLoggingOut ? (
            <Spinner />
          ) : (
            <ProtocolResolutionComponent
              status="success"
              title={protocolSuccessfullySentScreenState.title}
              logoutButtonText={
                protocolSuccessfullySentScreenState.logoutButtonText
              }
              subTitle={
                <>
                  {!!user ? (
                    protocolSuccessfullySentScreenState.youCanLogOutMessage
                  ) : (
                    <Alert
                      style={{
                        display: "inline-block",
                        marginTop: 12,
                      }}
                      type="info"
                      message={
                        <Space>
                          <InfoCircleOutlined
                            style={{
                              fontSize: 16,
                              color: myTheme.colors.primary.primary,
                            }}
                          />
                          {
                            protocolSuccessfullySentScreenState.youHaveBeenAutomaticallyLoggedOut
                          }
                        </Space>
                      }
                    />
                  )}
                </>
              }
              extraContent={
                <>
                  {selectors.includes("qrCode") && (
                    <ChooseVehicleViaQRCodeButton
                      label={
                        protocolSuccessfullySentScreenState.chooseVehicleViaQRCodeButtonLabel
                      }
                    />
                  )}
                  {selectors.length > 1 &&
                    !!protocolSuccessfullySentScreenState.vehicleTypes
                      .length && (
                      <OrDivider
                        text={protocolSuccessfullySentScreenState.orDividerText}
                      />
                    )}
                  {selectors.includes("plateNumber") &&
                    protocolSuccessfullySentScreenState.vehicleTypes.map(
                      (vehicleType) => (
                        <Button
                          block
                          style={{ maxWidth: 300 }}
                          key={vehicleType.id}
                          onClick={() => {
                            setProtocolSuccessfullySentScreenState(null);
                            history.push(
                              urls.protocolFiller.vehicleType(vehicleType.id)
                            );
                          }}
                        >
                          {vehicleType.label}
                        </Button>
                      )
                    )}
                </>
              }
            />
          )}
        </TopBarLayout>
      ) : (
        props.children
      )}
    </protocolFillerSummaryPageManagerContext.Provider>
  );
}

export function useProtocolFillerSummaryPageManager(): ProtocolFillerSummaryPageManagerContext {
  return useContext(protocolFillerSummaryPageManagerContext);
}
