import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";

import { DeploymentInProgressModal } from "./DeploymentInProgressModal";
import { useDeploymentInfoHook } from "./useDeploymentInfoHook";

const context = createContext({
  isInDeployment: false,
  openModal: () => {},
});
const { Provider } = context;

export function DeploymentInfoProvider(props: PropsWithChildren<{}>) {
  const isInDeployment = useDeploymentInfoHook();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <Provider
      value={{
        isInDeployment,
        openModal,
      }}
    >
      {isModalOpen && isInDeployment && (
        <DeploymentInProgressModal onClose={closeModal} />
      )}
      {props.children}
    </Provider>
  );
}

export function useDeploymentInfo() {
  return useContext(context);
}
