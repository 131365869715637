import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Form, Modal, Select, Skeleton } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { urls } from "src/urls";

import {
  useVehicleFieldsPresets,
  useVehicleGroups,
  useVehicleTypes,
} from "../../hooks";
import { VehicleType } from "../../models";
import { BackOfficeLayout } from "../BackOfficeLayout";
import { CreateVehicleForm } from "./components";

export function CreateVehicleView() {
  const { t } = useTranslation("backoffice");
  const [vehicleTypeId, setVehicleTypeId] = useState<VehicleType["id"] | null>(
    null
  );
  const [modal, modalContext] = Modal.useModal();
  const { isLoadingVehicleGroups, vehicleGroupOptions } = useVehicleGroups();
  const { isLoadingVehicleFieldsPresets, vehicleFieldsPresetOptions } =
    useVehicleFieldsPresets();
  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();

  return (
    <BackOfficeLayout
      contentMinWidth={0}
      breadcrumbs={[
        {
          label: t("vehiclesView.pageTitle"),
          url: urls.backOffice.vehicles(),
        },
      ]}
      pageTitle={t("createVehicle")}
    >
      <BackOfficeLayout.Content>
        {isLoadingVehicleGroups ||
        isLoadingVehicleFieldsPresets ||
        isLoadingVehicleTypes ? (
          <Skeleton />
        ) : (
          <>
            <Form layout="vertical">
              <Form.Item
                label={t("vehicleType")}
                required
                htmlFor="vehicle-form-select-vehicle-type"
              >
                <Select<VehicleType["id"]>
                  id="vehicle-form-select-vehicle-type"
                  options={vehicleTypeOptions}
                  style={{ maxWidth: 300 }}
                  value={vehicleTypeId}
                  onChange={async (newValue) => {
                    if (vehicleTypeId) {
                      await new Promise((resolve, reject) => {
                        modal.confirm({
                          title: t(
                            "vehicleView.changeVehicleTypeConfirmation.title"
                          ),
                          content: t(
                            "vehicleView.changeVehicleTypeConfirmation.content"
                          ),
                          icon: <ExclamationCircleOutlined />,
                          maskClosable: true,
                          onCancel: () => reject(),
                          async onOk() {
                            setVehicleTypeId(newValue);
                            resolve("");
                          },
                        });
                      });
                    } else {
                      setVehicleTypeId(newValue);
                    }
                  }}
                />
              </Form.Item>
            </Form>
            {vehicleTypeId ? (
              <CreateVehicleForm
                key={vehicleTypeId}
                customFieldValues={{}}
                vehicleGroupsOptions={vehicleGroupOptions}
                vehicleFieldsPresetOptions={vehicleFieldsPresetOptions}
                vehicleTypeId={vehicleTypeId}
              />
            ) : (
              <Alert
                style={{ marginBottom: 24 }}
                message={t("vehicleView.selectVehicleTypeToSeeVehicleFields")}
                type="info"
              />
            )}
          </>
        )}
        {modalContext}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
