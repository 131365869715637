import { useCallback } from "react";

import { backOfficeApi } from "../api";
import { useFetchOptions } from "./useFetchOptions";

interface UseProtocolTypes {
  protocolTypes: string[];
  isLoadingProtocolTypes: boolean;
  protocolTypeOptions: { label: string; value: string }[];
}

export function useProtocolTypes(): UseProtocolTypes {
  const mapper = useCallback(
    (protocolType: string) => ({
      value: protocolType,
      label: protocolType,
    }),
    []
  );
  const fetcher = useCallback(() => backOfficeApi.getProtocolTypes(), []);

  const [protocolTypes, isLoadingProtocolTypes, protocolTypeOptions] =
    useFetchOptions<string>(fetcher, mapper);

  return {
    protocolTypes,
    isLoadingProtocolTypes,
    protocolTypeOptions,
  };
}
