import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { myTheme } from "src/theme";

export function DeploymentInProgressModal(props: { onClose: () => void }) {
  const { t } = useTranslation();

  return (
    <Modal
      open
      title={
        <Space size="middle">
          <LoadingOutlined style={{ fontSize: 20 }} />
          {t("deploymentInfo.title")}
        </Space>
      }
      onCancel={props.onClose}
      footer={[
        <Button type="primary" onClick={props.onClose}>
          {t("ok")}
        </Button>,
      ]}
    >
      <Space size="middle" style={{ alignItems: "start" }}>
        <InfoCircleOutlined
          style={{ fontSize: 26, color: myTheme.colors.primary.primary }}
        />
        <div>
          {t("deploymentInfo.content1")}{" "}
          <Typography.Text strong>
            {t("deploymentInfo.content2")}
          </Typography.Text>
          <div style={{ paddingTop: 10 }}>
            {t("deploymentInfo.thanksForUnderstanding")}
          </div>
        </div>
      </Space>
    </Modal>
  );
}
