import { ExpandOutlined, LinkOutlined, SaveOutlined } from "@ant-design/icons";
import { Col, Modal, ModalProps, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

import highQualityPhoto from "src/static/photo-quality-examples/high-quality.jpeg";
import standardQualityPhoto from "src/static/photo-quality-examples/standard-quality.jpeg";

const pulseAnimation = keyframes`
  0% {
      opacity: 1;
      transform: scale(1);
  }
  80% {
      opacity: 0;
      transform: scale(3);
  }
  100% {
      opacity: 0;
      transform: scale(6);
  }
`;

const PhotoFragmentPreview = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #000;
  border: 5px solid #fff;
`;

const CircleIndicator = styled.div`
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #fff;

  &:after {
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #fff;
    position: absolute;
    top: -5px;
    left: -5px;
    animation: ${pulseAnimation} 1.5s ease 0s infinite;
  }
`;

const BigPhotoImg = styled.img`
  display: block;
  width: 100%;
  border-radius: 10px;
`;

const PhotoContainer = styled.div`
  position: relative;
`;

function Column(props: {
  title: string;
  photoUrl: string;
  size: string;
  resolution: string;
  imageWidth: number;
}) {
  const { t } = useTranslation("backoffice");
  return (
    <>
      <Typography.Title level={4}>{props.title}</Typography.Title>
      <p>
        <Space>
          <SaveOutlined />
          <Typography.Text strong>{props.size}</Typography.Text>
        </Space>
      </p>
      <p>
        <Space>
          <ExpandOutlined />
          <Typography.Text strong>{props.resolution}</Typography.Text>
        </Space>
      </p>
      <p>
        <Space>
          <LinkOutlined />
          <Typography.Text strong>
            <a href={props.photoUrl} target="_blank" rel="noreferrer">
              {t("openPhotoInNewWindow")}
            </a>
          </Typography.Text>
        </Space>
      </p>

      <PhotoContainer>
        <BigPhotoImg src={props.photoUrl} />
        <CircleIndicator
          style={{
            left: "64%",
            top: "45%",
          }}
        />
        <PhotoFragmentPreview
          style={{
            backgroundImage: `url("${props.photoUrl}")`,
            backgroundPosition: "-3584px -1820px",
            backgroundSize: "5333px 4000px",
          }}
        />
      </PhotoContainer>
    </>
  );
}

export function ComparePhotosQualityModal(props: Pick<ModalProps, "onCancel">) {
  const { t } = useTranslation("backoffice");
  return (
    <Modal
      onCancel={props.onCancel}
      open
      width={1200}
      footer={null}
      style={{
        minWidth: 900,
      }}
    >
      <Row gutter={[25, 25]}>
        <Col span={12}>
          <Column
            imageWidth={750}
            title={t("builder.imageQuality.options.standard")}
            photoUrl={standardQualityPhoto}
            size="108 kB"
            resolution="≤ 1000px"
          />
        </Col>
        <Col span={12}>
          <Column
            imageWidth={4000}
            title={t("builder.imageQuality.options.highQuality")}
            photoUrl={highQualityPhoto}
            size="747 kB"
            resolution="≤ 4000px"
          />
        </Col>
      </Row>
    </Modal>
  );
}
