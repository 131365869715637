import {
  useCompany,
  BackofficeLanguage,
  ProtocolFillerLanguage,
} from "@inspecto/common";
import { Input, Tabs } from "antd";
import { get, isEmpty } from "lodash";
import { ChangeEvent, useCallback, useContext } from "react";
import styled from "styled-components";

import { flagHeight, FlagImage, Icon } from "src/components";
import {
  TranslatedField,
  getLanguageConfig,
  emptyTranslatedField,
} from "src/locales";
import { myTheme } from "src/theme";

import { multiLanguageContext } from "../../MultiLanguageContext";

const TabPaneContent = styled.div`
  width: 48px;
  line-height: ${flagHeight};
`;

const LanguageName = styled.span`
  text-transform: uppercase;
  margin-left: 10px;
  position: relative;
`;

const checkmarkSize = 12;

const GreenCircle = styled.div`
  background-color: ${myTheme.colors.status.ok};
  color: #fff;
  display: flex;
  width: ${checkmarkSize}px;
  height: ${checkmarkSize}px;
  border-radius: 100%;
  line-height: ${checkmarkSize}px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2px;
  right: -${checkmarkSize + 4}px;
`;

type Value = TranslatedField;

export interface InternalMultiLanguageTextInputProps {
  value?: Value;
  onChange?: (value: Value) => void;
  variant: "textInput" | "textarea";
}

export function InternalMultiLanguageTextInput({
  value = emptyTranslatedField,
  ...props
}: InternalMultiLanguageTextInputProps): JSX.Element | null {
  const { currentLanguage, initialLanguage, setCurrentLanguage } =
    useContext(multiLanguageContext);
  const { availableLanguages } = useCompany();
  const availableLanguagesSorted = (
    availableLanguages as ProtocolFillerLanguage[]
  ).sort((a, b) => (a < b ? -1 : 1));

  const getOnChange = useCallback(
    (language: ProtocolFillerLanguage) =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (props.onChange) {
          props.onChange({
            ...value,
            [language]: event.target.value,
          });
        }
      },
    [props, value]
  );

  if (availableLanguages.length === 0) {
    return null;
  }

  const Component = props.variant === "textInput" ? Input : Input.TextArea;

  return availableLanguages.length > 1 ? (
    <Tabs
      defaultActiveKey={initialLanguage}
      activeKey={currentLanguage}
      onChange={(newTabKey) =>
        setCurrentLanguage(newTabKey as BackofficeLanguage)
      }
      size="small"
      type="card"
      tabBarStyle={{
        marginBottom: 4,
      }}
      items={availableLanguagesSorted.map((language) => ({
        label: (
          <TabPaneContent>
            <FlagImage
              alt={language}
              src={getLanguageConfig(language).countryFlag}
            />
            <LanguageName>
              {language}
              {!isEmpty(value[language]) ? (
                <GreenCircle data-testid={`${language}-filled-out`}>
                  <Icon icon="check" size={8} />
                </GreenCircle>
              ) : null}
            </LanguageName>
          </TabPaneContent>
        ),
        key: language,
        children: (
          <Component
            value={get(value, language)}
            onChange={getOnChange(language)}
          />
        ),
      }))}
    />
  ) : availableLanguages[0] ? (
    <Component
      value={get(value, availableLanguages[0])}
      onChange={getOnChange(availableLanguages[0])}
    />
  ) : null;
}
