export const INSPECTO_HELP_EMAIL_ADDRESS = "biuro@getinspecto.com" as const;
export const INSPECTO_LANDING_PAGE_URL = "https://getinspecto.com/" as const;
export const INSPECTO_LANDING_PAGE_TITLE =
  "Aplikacja Inspecto do kontroli stanu i wyposażenia floty." as const;
export const ALLOWED_REDIRECTION_DOMAINS = [
  "getinspecto.com",
  "localhost",
] as const;

export function isRedirectionAllowed(link: string): boolean {
  let url;
  try {
    url = new URL(link);
  } catch (e) {
    return false;
  }

  for (const allowedDomain of ALLOWED_REDIRECTION_DOMAINS) {
    if (url.host.includes(allowedDomain)) {
      return true;
    }
  }
  return false;
}
