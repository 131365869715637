import { useCallback } from "react";

import { backOfficeApi } from "../api";
import { useFetchOptions } from "./useFetchOptions";

interface UseCompanyFooters {
  isLoadingCompanyFooters: boolean;
  companyFooterOptions: { label: string; value: string }[];
}

export function useCompanyFooters(): UseCompanyFooters {
  const fetcher = useCallback(() => backOfficeApi.getAllCompanyFooters(), []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isLoadingCompanyFooters, companyFooterOptions] = useFetchOptions(
    fetcher,
    useCallback(
      (companyFooter) => ({
        value: companyFooter.id,
        label: companyFooter.name,
      }),
      []
    )
  );

  return {
    isLoadingCompanyFooters,
    companyFooterOptions,
  };
}
