import { Button as AntdButton } from "antd";
import { ReactNode } from "react";

interface ButtonComponentProps {}

type ButtonProps = {
  children: ReactNode;
  variant?: "primary" | "ghost" | "text";
  disabled?: boolean;
  hover?: boolean;
  isLoading?: boolean;
} & ({ type: "button" | "submit" } | { onClick(): void });

export function Button(props: ButtonProps & ButtonComponentProps) {
  return (
    <AntdButton
      onClick={(event) => {
        if ("onClick" in props && props.onClick) {
          event.preventDefault();
          props.onClick();
        }
      }}
      loading={props.isLoading}
      type={props.variant || "primary"}
      htmlType={"type" in props ? props.type : undefined}
      disabled={props.disabled}
    >
      {props.children}
    </AntdButton>
  );
}
