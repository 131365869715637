import { fetchAllPaginatedElements } from "@inspecto/common";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { customFieldTypesWithoutFleetStateSupport } from "src/company";

import { backOfficeApi } from "../api";
import { ListCustomVehicleField } from "../models";

interface UseCustomVehicleFields {
  customVehicleFields: ListCustomVehicleField[];
  isLoadingCustomVehicleFields: boolean;
}

export function useAllCustomVehicleFieldsSupportedByFleetState(
  vehicleType: string
): UseCustomVehicleFields {
  const { t } = useTranslation("backoffice");
  const [isLoadingCustomVehicleFields, setIsLoadingCustomVehicleFields] =
    useState(true);
  const [customVehicleFields, setCustomVehicleFields] = useState<
    ListCustomVehicleField[]
  >([]);

  useEffect(() => {
    (async () => {
      setIsLoadingCustomVehicleFields(true);

      try {
        const customVehicleFields =
          await fetchAllPaginatedElements<ListCustomVehicleField>(
            (pageNumber) =>
              backOfficeApi.getCustomVehicleFields({
                tableParams: {
                  pageNumber,
                },
                vehicleTypes: !!vehicleType.length ? [vehicleType] : [],
                excludeTypes: customFieldTypesWithoutFleetStateSupport,
              })
          );

        setCustomVehicleFields(customVehicleFields);
      } catch (e) {
        message.error(t("somethingWentWrong"));
      } finally {
        setIsLoadingCustomVehicleFields(false);
      }
    })();
  }, [vehicleType, t]);

  return {
    customVehicleFields,
    isLoadingCustomVehicleFields,
  };
}
