import { Form, Space, Table, TableColumnType } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FormItemValue } from "../../../components";
import { ExpandableSection } from "../../../components/ExpandableSection";

interface Props {
  protocolTypeOptions: { label: string; value: string }[];
  isLoadingProtocolTypes: boolean;
}

export function ExpandableProtocolTypesSelectorFormItem(props: Props) {
  const { t } = useTranslation("backoffice");

  const columns = useMemo<TableColumnType<{ value: string; label: string }>[]>(
    () => [
      {
        title: t("reportsView.fieldNames.selectAll"),
        key: "label",
        render: (value: { value: string; label: string }) => (
          <Space>{value.label}</Space>
        ),
      },
    ],
    [t]
  );
  return (
    <ExpandableSection>
      {() => (
        <Form.Item
          name="protocolTypes"
          label={t("reportsView.fieldNames.protocolTypes")}
        >
          <FormItemValue<string[]>>
            {({ value, onChange }) => (
              <Table
                loading={props.isLoadingProtocolTypes}
                size="small"
                pagination={false}
                rowKey="value"
                dataSource={props.protocolTypeOptions}
                columns={columns}
                rowSelection={{
                  onChange: (selectedRowKeys, selectedRows) =>
                    onChange?.(selectedRows.map((row) => row.value)),
                  selectedRowKeys: value,
                }}
              />
            )}
          </FormItemValue>
        </Form.Item>
      )}
    </ExpandableSection>
  );
}
