import { Alert, Checkbox, Form } from "antd";
import { useTranslation } from "react-i18next";

import { getHaveFieldsUpdatedCallback } from "src/utils";

import { backOfficeApi } from "../../api";
import { useVehicleTypes } from "../../hooks";
import {
  DatePeriodFormState,
  formatDatePeriod,
  getDatePeriodFormInitialValues,
} from "./common";
import {
  DatePeriodFormItem,
  ReportFormLayout,
  CustomVehicleFieldsSelector,
} from "./components";
import { VehicleTypeFormItem } from "./components/VehicleTypeFormItem";

interface VehicleStateByDateReportViewFormState extends DatePeriodFormState {
  customVehicleFields: string[];
  vehicleType: string;
  showOnlyChanges: boolean;
}

export function VehicleStateByDateReportView() {
  const { t } = useTranslation("backoffice");
  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();

  return (
    <ReportFormLayout<VehicleStateByDateReportViewFormState>
      reportTitle={t("reportsView.reports.vehicleStatesByDate")}
      startDownloading={async (data) =>
        backOfficeApi.reports.startDownloadVehicleStatesByDate({
          ...formatDatePeriod(data),
          customVehicleFields: data.customVehicleFields,
          vehicleType: data.vehicleType,
          showOnlyChanges: data.showOnlyChanges,
        })
      }
      formInitialValues={
        isLoadingVehicleTypes
          ? "loading"
          : {
              ...getDatePeriodFormInitialValues(),
              vehicleType: "",
              customVehicleFields: [],
              showOnlyChanges: false,
            }
      }
    >
      <Alert
        type="info"
        message={t("reportsView.notes.groupedByMinute")}
        style={{ marginBottom: 20 }}
      />

      <DatePeriodFormItem />
      <VehicleTypeFormItem vehicleTypeOptions={vehicleTypeOptions} />

      <Form.Item name="showOnlyChanges" valuePropName="checked">
        <Checkbox>{t("reportsView.fieldNames.showOnlyChanges")}</Checkbox>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={getHaveFieldsUpdatedCallback("vehicleType")}
      >
        {({ getFieldValue }) => (
          <Form.Item
            name="customVehicleFields"
            label={t("reportsView.fieldNames.customVehicleFields")}
            rules={[{ required: true }]}
          >
            <CustomVehicleFieldsSelector
              vehicleType={getFieldValue("vehicleType")}
              removeStickyOffsetHeader={false}
            />
          </Form.Item>
        )}
      </Form.Item>
    </ReportFormLayout>
  );
}
