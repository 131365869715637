import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Spinner } from "src/components";

import { backOfficeApi } from "../../api";
import { VehicleFieldHistoryModal } from "../../components";
import { RetrieveCustomVehicleField } from "../../models";

export function VehicleFieldValueHistoryModalView() {
  const history = useHistory();
  const { vehicleId, customVehicleFieldId } =
    useParams<{ vehicleId: string; customVehicleFieldId: string }>();
  const [customVehicleField, setCustomVehicleField] =
    useState<RetrieveCustomVehicleField | null>(null);

  useEffect(() => {
    (async () => {
      const customVehicleField = await backOfficeApi.getCustomVehicleField(
        customVehicleFieldId
      );
      setCustomVehicleField(customVehicleField);
    })();

    return () => setCustomVehicleField(null);
  }, [customVehicleFieldId]);

  return !customVehicleField ? (
    <Spinner />
  ) : customVehicleField.type === "damages" ? (
    <div>
      The history is not supported for the Damages Custom Vehicle Field.
    </div>
  ) : (
    <VehicleFieldHistoryModal
      vehicleId={vehicleId}
      vehicleFieldId={customVehicleFieldId}
      vehicleFieldType={customVehicleField.type}
      vehicleFieldLabel={customVehicleField.label}
      onCancel={() => history.goBack()}
    />
  );
}
