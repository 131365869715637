import styled from "styled-components";

import { listElementBoxShadow } from "../../../css";

export const PageLevelElement = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  border: 1px solid ${(props) => props.theme.colors.outline};
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 5px;
  ${listElementBoxShadow};
  margin-bottom: 20px;
`;
