import { PoweroffOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { User } from "src/authentication";
import { fullName } from "src/utils";

import { TopBarLayout } from "./TopBarLayout";
import { UserAvatar } from "./UserAvatar";

const NoWrapEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export function LandingPageTopBarLayout(
  props: PropsWithChildren<{ user: User; onLogoutClick: () => void }>
) {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { t } = useTranslation("translation");

  return (
    <TopBarLayout
      onLogoClick={() => null}
      maxWidth="100%"
      topRightContent={
        <Popover
          open={isUserMenuOpen}
          onOpenChange={setIsUserMenuOpen}
          trigger="click"
          placement="bottomRight"
          overlayInnerStyle={{
            width: 230,
            margin: 0,
          }}
          title={
            <NoWrapEllipsis style={{ padding: "5px 0" }}>{`${fullName(
              props.user
            )}`}</NoWrapEllipsis>
          }
          content={
            <div>
              <Button
                onClick={props.onLogoutClick}
                block
                ghost
                type="primary"
                size="small"
                icon={<PoweroffOutlined />}
              >
                {t("logout")}
              </Button>
            </div>
          }
        >
          <UserAvatar
            data-testid="logout-menu"
            user={props.user}
            style={{
              boxSizing: "content-box",
              border: "2px solid #fff",
              cursor: "pointer",
            }}
          />
        </Popover>
      }
    >
      {props.children}
    </TopBarLayout>
  );
}
