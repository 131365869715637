import {
  useCompany,
  getCompanyProtocolFillerSelectors,
} from "@inspecto/common";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  OrDivider,
  RadioGroupOfTypeButton,
  RadioGroupOfTypeButtonItem,
} from "src/components";
import { urls } from "src/urls";

import { WhiteCard } from "../components";
import { ChooseVehicleViaQRCodeButton } from "../components/ChooseVehicleViaQRCodeButton";
import { PageLayout } from "../components/PageLayout";
import { protocolFillerLocalStorageContext } from "../contexts";

interface Props {
  vehicleTypes: RadioGroupOfTypeButtonItem<string>[];
}

export function ChooseVehicleInitialScreen(props: Props) {
  const { t } = useTranslation("protocolFiller");
  const history = useHistory();

  const company = useCompany();

  const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState("");
  const [wasSubmittedWithMissingData, setWasSubmittedWithMissingData] =
    useState(false);
  const { clearStorage } = useContext(protocolFillerLocalStorageContext);

  useEffect(() => {
    clearStorage();
  }, [clearStorage]);

  useEffect(() => {
    setWasSubmittedWithMissingData(false);
  }, [selectedVehicleTypeId]);

  const selectors = useMemo(() => {
    return getCompanyProtocolFillerSelectors(company);
  }, [company]);

  return (
    <PageLayout
      onNextClick={
        !!selectedVehicleTypeId
          ? () =>
              history.push(
                urls.protocolFiller.vehicleType(selectedVehicleTypeId)
              )
          : () => setWasSubmittedWithMissingData(true)
      }
    >
      {selectors.includes("qrCode") && (
        <WhiteCard
          innerKey="qrCodes"
          title={t("qrCodes.title")}
          hasAsterisk
          errors={
            wasSubmittedWithMissingData
              ? [t("questionValidationErrors.requiredQuestion")]
              : []
          }
        >
          <ChooseVehicleViaQRCodeButton
            marginBottom={15}
            label={t("qrCodes.chooseVehicle")}
          />
        </WhiteCard>
      )}

      {selectors.length > 1 && <OrDivider />}
      {selectors.includes("plateNumber") && (
        <WhiteCard
          title={t("chooseVehicleType")}
          innerKey="vehicleType"
          hasAsterisk
          errors={
            wasSubmittedWithMissingData
              ? [t("questionValidationErrors.requiredQuestion")]
              : []
          }
        >
          <RadioGroupOfTypeButton
            value={selectedVehicleTypeId}
            onChange={(value) =>
              setSelectedVehicleTypeId(value == null ? "" : value)
            }
            items={props.vehicleTypes}
          />
        </WhiteCard>
      )}
    </PageLayout>
  );
}
