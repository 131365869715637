import { Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { AssignCustomVehicleFieldsListView } from "../../../components/AssignCustomVehicleFieldsListView";
import { ListRetrieveCustomVehicleFieldsGroup } from "../../../models";
import { BackOfficeLayout } from "../../BackOfficeLayout";
import { CustomVehicleFieldsGroupForm } from "./CustomVehicleFieldsGroupForm";

export function EditCustomVehicleFieldsGroupView() {
  const { t } = useTranslation("backoffice");
  const { customVehicleFieldsGroupId } =
    useParams<{ customVehicleFieldsGroupId: string }>();

  const [
    existingCustomVehicleFieldsGroup,
    setExistingCustomVehicleFieldsGroup,
  ] = useState<ListRetrieveCustomVehicleFieldsGroup | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    backOfficeApi
      .getCustomVehicleFieldsGroup(customVehicleFieldsGroupId)
      .then((customVehicleFieldsGroup) => {
        setExistingCustomVehicleFieldsGroup(customVehicleFieldsGroup);

        setIsLoading(false);
      });
  }, [customVehicleFieldsGroupId]);

  const isCustomVehicleFieldAssigned = useCallback(
    (customVehicleField) =>
      customVehicleField.customVehicleFieldsGroupObject?.id ===
      customVehicleFieldsGroupId,
    [customVehicleFieldsGroupId]
  );

  return (
    <BackOfficeLayout
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.customVehicleFieldsGroups.label"),
          url: urls.backOffice.settings.customVehicleFieldsGroups(),
        },
      ]}
      pageTitle={t("settings.customVehicleFieldsGroups.editingLabel")}
      contentMinWidth={1200}
      headerDescription={
        <div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <CustomVehicleFieldsGroupForm
              existingCustomVehicleFieldsGroup={
                existingCustomVehicleFieldsGroup
              }
            />
          )}
        </div>
      }
    >
      <BackOfficeLayout.Content>
        {existingCustomVehicleFieldsGroup ? (
          <AssignCustomVehicleFieldsListView
            itemBeingAssigned={existingCustomVehicleFieldsGroup}
            isCustomVehicleFieldAssigned={isCustomVehicleFieldAssigned}
            assignCustomVehicleFields={async (payload) =>
              backOfficeApi.assignCustomVehicleFieldsGroupToCustomVehicleFields(
                customVehicleFieldsGroupId,
                payload
              )
            }
          />
        ) : (
          <Skeleton />
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
