import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Form, Space, TableColumnType, Tag, Typography } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { RemoveButton } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { CommonViewState } from "../../../contexts";
import {
  ListVehicleFieldIsOkOverwrite,
  ListVehicleFieldsPreset,
} from "../../../models";
import { BackOfficeListViewLayout } from "../../BackOfficeListViewLayout";

export function VehicleFieldsPresetsListView() {
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const [filtersForm] = Form.useForm();

  const dataGetter = useCallback(
    ({
      tableParams: { pageNumber },
    }: CommonViewState["vehicleFieldsPresetsView"]) =>
      backOfficeApi.getVehicleFieldsPresets(pageNumber),
    []
  );

  const columns: TableColumnType<ListVehicleFieldsPreset>[] = [
    {
      title: t("settings.vehicleFieldsPresets.fields.label"),
      dataIndex: "label",
      width: 300,
    },
    {
      title: t("settings.vehicleFieldsPresets.fields.overwrites"),
      dataIndex: "vehicleFieldIsOkOverwrites",
      render: (value: ListVehicleFieldIsOkOverwrite[]) => (
        <div>
          {value.map((item) => (
            <Tag key={item.customVehicleField.id}>
              <Typography.Text
                style={{
                  maxWidth: 250,
                }}
                ellipsis={{ tooltip: true }}
              >
                {item.customVehicleField.label}
              </Typography.Text>
            </Tag>
          ))}
        </div>
      ),
    },
    {
      key: "action",
      align: "right",
      width: "200px",
      render: (value) => (
        <Space size="small">
          <Button
            size="small"
            onClick={() => {
              history.push(
                urls.backOffice.settings.editVehicleFieldsPreset(value.id)
              );
            }}
            icon={<ArrowRightOutlined />}
          >
            {t("tableActions.edit")}
          </Button>
          <RemoveButton
            onRemoveConfirm={async () => {
              await backOfficeApi.removeVehicleFieldsPreset(value.id);
              filtersForm.submit();
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <BackOfficeListViewLayout
      commonStateViewName="vehicleFieldsPresetsView"
      filtersForm={filtersForm}
      dataGetter={dataGetter}
      columns={columns}
      breadcrumbs={[{ label: t("settings.label") }]}
      pageTitle={t("settings.vehicleFieldsPresets.label")}
      createPageUrl={urls.backOffice.settings.createVehicleFieldsPreset()}
    />
  );
}
