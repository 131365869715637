import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Form, Space, TableColumnType, Tag, Typography } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { customFieldTypesWithoutFleetStateSupport } from "src/company";
import { MultiLineTag } from "src/components";
import { urls } from "src/urls";

import { AvatarFieldType } from "../../components";
import {
  FleetStateCustomVehicleFieldValue,
  ListCustomVehicleField,
} from "../../models";
import { BaseCustomVehicleFieldsListView } from "../BaseCustomVehicleFieldsListView";

function CountTag(props: {
  color: "red" | "orange" | "green";
  value: number;
}): JSX.Element {
  return props.value === 0 ? (
    <>-</>
  ) : (
    <Tag color={props.color}>{props.value}</Tag>
  );
}

export function FleetStateCustomVehicleFieldsListView() {
  const [form] = Form.useForm();
  const { t } = useTranslation("backoffice");
  const history = useHistory();

  const columns = useMemo<TableColumnType<ListCustomVehicleField>[]>(
    () => [
      {
        width: "auto",
        title: t("settings.customVehicleFields.label"),
        dataIndex: "label",
        render: (label, customVehicleField) => (
          <>
            <AvatarFieldType
              vehicleTypes={customVehicleField.vehicleTypes.map(
                (vehicleType) => ({
                  value: vehicleType.id,
                  label: vehicleType.label,
                })
              )}
              questionType={customVehicleField.type}
              label={label}
            />
          </>
        ),
      },
      {
        dataIndex: "customVehicleFieldsGroupObject",
        width: 200,
        title: t("settings.customVehicleFieldsGroups.editingLabel"),
        render: (
          customVehicleFieldsGroupObject: ListCustomVehicleField["customVehicleFieldsGroupObject"]
        ) => {
          return customVehicleFieldsGroupObject ? (
            <MultiLineTag key={customVehicleFieldsGroupObject.id}>
              <Typography.Text
                style={{
                  maxWidth: 170,
                }}
                ellipsis={{ tooltip: true }}
              >
                {customVehicleFieldsGroupObject.label}
              </Typography.Text>
            </MultiLineTag>
          ) : (
            "-"
          );
        },
      },
      {
        width: 120,
        dataIndex: "notOkStatusCount",
        title: t("fleetState.statuses.notOk"),
        render: (value) => <CountTag color="red" value={value} />,
      },
      {
        width: 120,
        dataIndex: "warningStatusCount",
        title: t("fleetState.statuses.warning"),
        render: (value) => <CountTag color="orange" value={value} />,
      },
      {
        width: 120,
        dataIndex: "okStatusCount",
        title: t("fleetState.statuses.ok"),
        render: (value) => <CountTag color="green" value={value} />,
      },
      {
        key: "action",
        width: 140,
        align: "right",
        render: (value: FleetStateCustomVehicleFieldValue) => {
          return (
            <Space size="small">
              <Button
                size="small"
                onClick={() => {
                  history.push(
                    urls.backOffice.fleetState.customVehicleFieldsValues(
                      value.id
                    )
                  );
                }}
                icon={<ArrowRightOutlined />}
              >
                {t("tableActions.open")}
              </Button>
            </Space>
          );
        },
      },
    ],
    [history, t]
  );

  return (
    <BaseCustomVehicleFieldsListView
      isVehicleGroupsFilterVisible
      dataGetterExtraFilters={{
        excludeTypes: customFieldTypesWithoutFleetStateSupport,
      }}
      columns={columns}
      commonStateViewName="fleetStateCustomVehicleFieldsListView"
      filtersForm={form}
      sortedTableProps={[
        "customVehicleFieldsGroupObject",
        "warningStatusCount",
        "notOkStatusCount",
        "okStatusCount",
        "label",
      ]}
      pageTitle={t("fleetState.pageTitle")}
    />
  );
}
