import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Form, Space, TableColumnType } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { RemoveButton } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { CommonViewState } from "../../../contexts";
import { ListCompanyDepartment } from "../../../models";
import { BackOfficeListViewLayout } from "../../BackOfficeListViewLayout";

export function CompanyDepartmentsListView() {
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const [filtersForm] = Form.useForm();

  const dataGetter = useCallback(
    ({ tableParams }: CommonViewState["companyDepartmentsListView"]) =>
      backOfficeApi.getCompanyDepartments(tableParams),
    []
  );

  const columns: TableColumnType<ListCompanyDepartment>[] = [
    {
      title: t("settings.companyDepartments.columns.name"),
      dataIndex: "name",
    },
    {
      title: t("settings.companyDepartments.columns.assignedVehiclesCount"),
      dataIndex: "assignedVehiclesCount",
      width: 200,
    },
    {
      title: t("settings.companyDepartments.columns.assignedUsersCount"),
      dataIndex: "assignedUsersCount",
      width: 200,
    },
    {
      key: "action",
      align: "right",
      width: "200px",
      render: (value) => (
        <Space size="small">
          <Button
            size="small"
            onClick={() => {
              history.push(
                urls.backOffice.settings.editCompanyDepartment(value.id)
              );
            }}
            icon={<ArrowRightOutlined />}
          >
            {t("tableActions.edit")}
          </Button>
          <RemoveButton
            onRemoveConfirm={async () => {
              await backOfficeApi.removeCompanyDepartment(value.id);
              filtersForm.submit();
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <BackOfficeListViewLayout
      commonStateViewName="companyDepartmentsListView"
      filtersForm={filtersForm}
      dataGetter={dataGetter}
      columns={columns}
      breadcrumbs={[{ label: t("settings.label") }]}
      pageTitle={t("settings.companyDepartments.listLabel")}
      createPageUrl={urls.backOffice.settings.createCompanyDepartment()}
    />
  );
}
