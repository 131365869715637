import { Input, Skeleton, Switch, TableColumnType, Tag } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  AssignUnassignPayload,
  backOfficeApi,
  SimpleVehicleListFilters,
} from "../api";
import { useVehicleTypes } from "../hooks";
import { SimpleVehicle, SimpleVehicleVehicleGroup } from "../models";
import { getAssignedItemsTagsRenderer } from "./AssignedItemsTagsRenderer";
import { AssignItemsListView } from "./AssignItemsListView";
import { VehicleGroupsFilterItem } from "./filterItems";
import {
  FilterItem,
  FiltersRow,
  FilterTabsRow,
  SelectableRowRequirements,
} from "./ListView";

export interface AssignVehiclesListViewProps {
  header?: string;
  itemBeingAssigned?: {
    id: string;
    label: string;
  };
  vehicleGroupOptions: { label: string; value: string }[];
  extraFilters?: JSX.Element;
  isVehicleAssigned: (vehicle: SimpleVehicle) => boolean;
  assignVehicles: (
    assignUnassignPayload: AssignUnassignPayload
  ) => Promise<void>;
  extraColumns?: (
    selectedRows: SimpleVehicle[]
  ) => TableColumnType<SimpleVehicle>[];
  selectableRowRequirements?: SelectableRowRequirements<SimpleVehicle>;
}

export function AssignVehiclesListView({
  extraColumns = () => [],
  ...props
}: AssignVehiclesListViewProps): JSX.Element {
  const { t } = useTranslation("backoffice");

  const columns = useCallback(
    (selectedRows: SimpleVehicle[]) => [
      {
        title: t("plateNumber"),
        width: "200px",
        render: (vehicle: SimpleVehicle) => (
          <>
            {!vehicle.isActive && (
              <Tag color="red">{t("employeesView.deletedUserTag")}</Tag>
            )}
            {vehicle.plateNumber}
          </>
        ),
      },
      {
        dataIndex: "vehicleGroups",
        title: t("vehiclesView.vehicleGroups"),
        render: getAssignedItemsTagsRenderer<
          SimpleVehicle,
          SimpleVehicleVehicleGroup
        >(
          selectedRows,
          props.itemBeingAssigned || null,
          (vehicleGroup) => vehicleGroup.label
        ),
      },
      ...extraColumns(selectedRows),
    ],
    [extraColumns, props.itemBeingAssigned, t]
  );

  const dataGetter = useCallback((viewFilters: SimpleVehicleListFilters) => {
    return backOfficeApi.getSimpleVehiclesList(viewFilters);
  }, []);

  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();

  if (isLoadingVehicleTypes) {
    return <Skeleton />;
  }

  return (
    <AssignItemsListView
      title={
        typeof props.header === "undefined"
          ? t("assignVehiclesList.title")
          : props.header
      }
      selectableRowRequirements={props.selectableRowRequirements}
      commonStateViewName="assignVehiclesListView"
      debouncedFilterProps={["plateNumber"]}
      dataGetter={dataGetter}
      isItemAssigned={props.isVehicleAssigned}
      assignItems={props.assignVehicles}
      filters={
        <>
          <FiltersRow>
            <FilterItem
              label={t("includeArchivedItems")}
              valuePropName="checked"
              name="includeArchived"
            >
              <Switch />
            </FilterItem>
          </FiltersRow>
          <FiltersRow>
            <FilterItem name="plateNumber" $inputWidth="medium">
              <Input.Search placeholder={t("search")} />
            </FilterItem>
            <VehicleGroupsFilterItem options={props.vehicleGroupOptions} />
            {props.extraFilters}
          </FiltersRow>
          <FilterTabsRow
            name="selectedVehicleType"
            options={vehicleTypeOptions}
          />
        </>
      }
      columns={columns}
    />
  );
}
