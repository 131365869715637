import {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";

import { base64StringToFile } from "src/utils";

const inspectoMessageEventTypes = [
  "REQUEST_IMAGE_CAPTURE",
  "DEBUG_MESSAGE",
] as const;

interface BaseInspectoMessageEvent {
  type: typeof inspectoMessageEventTypes[number];
}

interface RequestImageCaptureInspectoMessageEvent
  extends BaseInspectoMessageEvent {
  type: "REQUEST_IMAGE_CAPTURE";
  allowSelectingImageFromPhoneGallery: boolean;
  isMultiImageMode: boolean;
  useExternalCameraApplication: boolean;
  translations: {
    selectImageSource: {
      header: string;
      options: {
        camera: string;
        browse: string;
        cancel: string;
      };
    };
  };
}

interface DebugMessageInspectoMessageEvent extends BaseInspectoMessageEvent {
  type: "DEBUG_MESSAGE";
  message: string;
}

type InspectoMessageEvent =
  | RequestImageCaptureInspectoMessageEvent
  | DebugMessageInspectoMessageEvent;

const webViewContext = createContext<{
  isInWebView: boolean;
  sendDebugMessage: (message: string) => void;
  requestImageCapture: (
    callback: (file: File) => void,
    allowSelectingImageFromPhoneGallery: boolean,
    useExternalCameraApplication: boolean,
    isMultiImageMode: boolean
  ) => void;
  isPending: boolean;
}>({
  isInWebView: false,
  sendDebugMessage: () => {},
  requestImageCapture: () => {},
  isPending: false,
});

const { Provider } = webViewContext;

export function WebViewProvider(props: { children: JSX.Element }) {
  const { t } = useTranslation("protocolFiller");
  const [imageCaptureCallback, setImageCaptureCallback] = useState<
    ((file: File) => void) | null
  >(null);

  const webView = window.ReactNativeWebView;

  const sendMessage = useCallback(
    <E extends InspectoMessageEvent>(event: E) => {
      if (webView) {
        webView.postMessage(JSON.stringify(event));
      }
    },
    [webView]
  );

  const handleMessage = useCallback(
    async (event) => {
      try {
        const message = JSON.parse(event.data);
        switch (message.type) {
          case "IMAGE_CAPTURED":
            if (imageCaptureCallback) {
              const file = base64StringToFile(message.data, message.fileName);
              imageCaptureCallback(file);
              if (!message.multiImageModeSupported) {
                setImageCaptureCallback(null);
              }
            }
            break;
          case "IMAGE_CAPTURE_CANCELED":
            setImageCaptureCallback(null);
            break;
        }
      } catch (e) {}
    },
    [imageCaptureCallback]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage, true);
    return () => {
      window.removeEventListener("message", handleMessage, true);
    };
  }, [handleMessage]);
  return (
    <Provider
      value={{
        isInWebView: !!webView,
        sendDebugMessage: useCallback(
          (message: string) => {
            sendMessage({
              type: "DEBUG_MESSAGE",
              message,
            });
          },
          [sendMessage]
        ),
        requestImageCapture: useCallback(
          (
            callback: (file: File) => void,
            allowSelectingImageFromPhoneGallery: boolean,
            useExternalCameraApplication: boolean,
            isMultiImageMode: boolean
          ) => {
            if (!imageCaptureCallback) {
              setImageCaptureCallback(() => callback);
              sendMessage({
                type: "REQUEST_IMAGE_CAPTURE",
                allowSelectingImageFromPhoneGallery,
                useExternalCameraApplication,
                isMultiImageMode,
                translations: t("webView", {
                  returnObjects: true,
                }),
              });
            }
          },
          [imageCaptureCallback, sendMessage, t]
        ),
        isPending: !!imageCaptureCallback,
      }}
    >
      {props.children}
    </Provider>
  );
}

export function useWebView() {
  return useContext(webViewContext);
}
