import { Tag } from "antd";

export function getAssignedItemsTagsRenderer<
  TRow extends { id: string },
  TItem extends { id: string }
>(
  selectedRows: TRow[],
  itemBeingAssigned: {
    id: string;
    label: string;
  } | null,
  getItemLabel: (item: TItem) => string
) {
  return (items: TItem[], row: TRow) => {
    const isRowSelected = selectedRows
      .map((selectedRow) => selectedRow.id)
      .includes(row.id);
    const isThisItemAssigned = items.find(
      (item) => item.id === itemBeingAssigned?.id
    );

    return (
      <>
        {items
          .filter((item) => {
            if (item.id === itemBeingAssigned?.id) {
              return isRowSelected;
            }
            return true;
          })
          .map((item) => (
            <Tag color={item.id === itemBeingAssigned?.id ? "blue" : "default"}>
              {getItemLabel(item)}
            </Tag>
          ))}
        {!!itemBeingAssigned && isRowSelected && !isThisItemAssigned ? (
          <Tag color="orange">{itemBeingAssigned?.label}</Tag>
        ) : null}
      </>
    );
  };
}

export function getAssignedItemTagRenderer<
  TRow extends { id: string },
  TItem extends { id: string }
>(
  selectedRows: TRow[],
  itemBeingAssigned: {
    id: TItem["id"];
    label: string;
  },
  getItemLabel: (item: TItem) => string
) {
  return (item: TItem | null, row: TRow) => {
    const isRowSelected = selectedRows
      .map((selectedRow) => selectedRow.id)
      .includes(row.id);
    const isThisItemBeingAssigned = item && item.id === itemBeingAssigned?.id;

    if (isRowSelected) {
      return (
        <Tag color={isThisItemBeingAssigned ? "blue" : "orange"}>
          {itemBeingAssigned.label}
        </Tag>
      );
    }

    if (item) {
      if (item.id === itemBeingAssigned.id) {
        return null;
      }
      return <Tag color="default">{getItemLabel(item)}</Tag>;
    }

    return null;
  };
}
