import { ArrowRightOutlined } from "@ant-design/icons";
import { getAllReports } from "@inspecto/common";
import { useCompany } from "@inspecto/common";
import { Button, Space, TableColumnType, Tooltip, Typography } from "antd";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { BasicTable } from "src/components";
import { urls } from "src/urls";

import { LockedFeatureIcon } from "../../components";
import { BackOfficeLayout } from "../BackOfficeLayout";

interface Report {
  title: string;
  key: string;
  url: string;
  isAvailable: boolean;
}

export function ReportsListView() {
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const { availableReports } = useCompany();

  const columns = useMemo<TableColumnType<Report>[]>(
    () => [
      {
        title: t("reportsView.title"),
        render: (item) => (
          <Typography.Text type={item.isAvailable ? undefined : "secondary"}>
            {item.isAvailable ? (
              item.title
            ) : (
              <Tooltip title={t("reportsView.contactUsToUnlockAccess")}>
                <Space>
                  <LockedFeatureIcon />
                  {item.title}
                </Space>
              </Tooltip>
            )}
          </Typography.Text>
        ),
      },
      {
        width: 140,
        align: "right",
        render: (item) => (
          <Tooltip
            title={t("reportsView.contactUsToUnlockAccess")}
            open={item.isAvailable ? false : undefined}
          >
            <Button
              size="small"
              icon={<ArrowRightOutlined />}
              disabled={!item.isAvailable}
              onClick={() => {
                history.push(item.url);
              }}
            >
              {t("tableActions.open")}
            </Button>
          </Tooltip>
        ),
      },
    ],
    [history, t]
  );

  const allReports = getAllReports();

  const dataSource = useMemo(
    () =>
      orderBy(
        allReports.map((report) => ({
          title: t(`reportsView.reports.${report}`),
          key: report,
          url: urls.backOffice.reports.reportView(report),
          isAvailable: availableReports.includes(report),
        })),
        "isAvailable",
        "desc"
      ),
    [allReports, availableReports, t]
  );

  return (
    <BackOfficeLayout
      pageTitle={t("reportsView.pageTitle")}
      contentMinWidth={0}
    >
      <BackOfficeLayout.Content>
        <BasicTable<Report>
          columns={columns}
          pagination={false}
          dataSource={dataSource}
        />
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
