import dayjs, { Dayjs } from "dayjs";
import { useCallback } from "react";

import { DateMonthPicker } from "..";

export type DateMonthFieldValue = string | null;

export interface DateMonthFieldProps {
  value?: DateMonthFieldValue;
  onChange?: (value: DateMonthFieldValue) => void;
  autoFocus?: boolean;
}

export function DateMonthFieldComponent(props: DateMonthFieldProps) {
  const setResponse = useCallback(
    (dateMonth: Dayjs | null) => {
      const formattedDateMonthAsString = dateMonth
        ? dateMonth.format("YYYY-MM-01")
        : null;
      props.onChange?.(formattedDateMonthAsString);
    },
    [props]
  );

  return (
    <DateMonthPicker
      autoFocus={props.autoFocus}
      onChange={setResponse}
      value={props.value ? dayjs.tz(props.value) : undefined}
    />
  );
}
