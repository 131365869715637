import { TableColumnType } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import produce from "immer";

export type DataIndex<Item> = TableColumnType<Item>["dataIndex"];

export function getStringDataIndex<Item>(dataIndex: DataIndex<Item>): string {
  if (!dataIndex) {
    return "";
  }
  if (Array.isArray(dataIndex)) {
    return dataIndex
      .map((item: string | number) => {
        if (typeof item === "number") {
          return item.toString();
        } else {
          return item;
        }
      })
      .join("_");
  }
  return dataIndex as string;
}

export function antdOrderToOrderedDataIndexString(
  antdOrder: SortOrder | undefined,
  dataIndexString: string
): string {
  switch (antdOrder) {
    case "descend":
      return `-${dataIndexString}`;
    case "ascend":
      return dataIndexString;
    case undefined:
    case null:
      return "";
  }
}

export function orderedDataIndexStringToAntdOrder(
  orderedDataIndexString: string,
  dataIndexStringToCompare: string
): SortOrder {
  if (!dataIndexStringToCompare || !orderedDataIndexString) {
    return null;
  }

  if (orderedDataIndexString.includes(dataIndexStringToCompare)) {
    return orderedDataIndexString.startsWith("-") ? "descend" : "ascend";
  }
  return null;
}

export function modifyColumnsWithOrdering<Item extends { id: string }>(
  columns: TableColumnType<Item>[],
  sortableColumns: string[],
  currentSort: string
): TableColumnType<Item>[] {
  return columns.map((column) => {
    return produce(column, (draft) => {
      const dataIndexString = getStringDataIndex(column.dataIndex);
      if (dataIndexString && sortableColumns?.includes(dataIndexString)) {
        draft.sorter = true;
        draft.sortDirections = ["ascend", "descend", "ascend"];
        draft.sortOrder = orderedDataIndexStringToAntdOrder(
          currentSort,
          dataIndexString
        );
      }
    });
  });
}
