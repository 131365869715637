import { CloseOutlined } from "@ant-design/icons";
import { PAGE_SIZE } from "@inspecto/common";
import { Button, List, ListProps, Radio, RadioGroupProps, Space } from "antd";
import { isString } from "lodash";
import { PaginationProps } from "rc-pagination";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

type PossibleValue = number | string;

export interface RadioGroupOfTypeButtonItem<TValue extends PossibleValue> {
  value: TValue;
  label: JSX.Element | string;
}

interface Props<TValue extends PossibleValue>
  extends Pick<RadioGroupProps, "value">,
    Pick<ListProps<RadioGroupOfTypeButtonItem<TValue>>, "pagination"> {
  mode?: "vertical" | "verticalWithOptionalHorizontal" | "verticalSkeleton";
  onChange?: (
    value: RadioGroupOfTypeButtonItem<TValue>["value"] | null
  ) => void;
  items: RadioGroupOfTypeButtonItem<TValue>[];
  pageNumber?: PaginationProps["current"];
  onPageNumberChange?: PaginationProps["onChange"];
  total?: PaginationProps["total"];
  showClearSelectionButton?: boolean;
  verticalListWidth?: number;
  height?: number;
}

const spacing = 8;

const Center = styled.div<{ width: string }>`
  text-align: center;
  width: ${(props) => props.width};
`;

const StyledRadioButton = styled(Radio.Button)`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 12px 16px;
  line-height: 18px;
`;

function RadioGroup<TValue>({
  width = "100%",
  ...props
}: PropsWithChildren<{
  onChange?: (value: TValue) => void;
  value: TValue;
  style?: RadioGroupProps["style"];
  width?: "100%" | number;
}>): JSX.Element {
  return (
    <Radio.Group
      size="middle"
      buttonStyle="solid"
      value={props.value}
      onChange={(event) => props.onChange?.(event.target.value)}
      style={{ ...props.style, width }}
    >
      {props.children}
    </Radio.Group>
  );
}

export function ClearRadioChoiceButton(props: {
  onChange: (value: null) => void;
}): JSX.Element {
  const { t } = useTranslation("translation");
  return (
    <Button
      size="small"
      type="dashed"
      shape="round"
      onClick={() => props.onChange(null)}
      icon={<CloseOutlined />}
    >
      {t("clearRadioChoice")}
    </Button>
  );
}

export function RadioGroupOfTypeButton<TValue extends PossibleValue>({
  mode = "vertical",
  ...props
}: Props<TValue>): JSX.Element {
  const clearSelectionButtonElement = props.showClearSelectionButton &&
    !!props.value &&
    props.onChange && <ClearRadioChoiceButton onChange={props.onChange} />;

  return mode === "verticalSkeleton" ? (
    <>
      {Array.from(Array(5).keys()).map((_, index) => (
        <Skeleton
          height={40}
          key={index}
          style={{
            marginBottom: spacing,
          }}
        />
      ))}
    </>
  ) : mode === "verticalWithOptionalHorizontal" &&
    props.items.length === 2 &&
    getChoicesLabelsCombinedLength(props.items) < 15 ? (
    <Space>
      <RadioGroup
        value={props.value}
        onChange={props.onChange}
        style={{ whiteSpace: "nowrap" }}
      >
        {props.items.map((item) => (
          <Radio.Button key={item.value} value={item.value}>
            {item.label}
          </Radio.Button>
        ))}
      </RadioGroup>
      {clearSelectionButtonElement}
    </Space>
  ) : (
    <>
      <RadioGroup
        value={props.value}
        onChange={props.onChange}
        width={props.verticalListWidth ? props.verticalListWidth : undefined}
      >
        <List<RadioGroupOfTypeButtonItem<TValue>>
          grid={{
            gutter: 0,
            column: 1,
          }}
          style={
            props.height
              ? {
                  height: props.height,
                  overflowY: "scroll",
                }
              : undefined
          }
          split={false}
          dataSource={props.items}
          renderItem={(item) => (
            <List.Item style={{ marginBottom: spacing }}>
              <StyledRadioButton value={item.value}>
                {item.label}
              </StyledRadioButton>
            </List.Item>
          )}
          pagination={
            props.pageNumber &&
            props.onPageNumberChange &&
            props.total &&
            props.total > PAGE_SIZE
              ? {
                  current: props.pageNumber,
                  onChange: props.onPageNumberChange,
                  total: props.total,
                  hideOnSinglePage: true,
                  pageSize: PAGE_SIZE,
                  showSizeChanger: false,
                  size: "default",
                  showLessItems: true,
                }
              : false
          }
        />
      </RadioGroup>
      <Center
        width={
          props.verticalListWidth ? `${props.verticalListWidth}px` : "auto"
        }
      >
        {clearSelectionButtonElement}
      </Center>
    </>
  );

  function getChoicesLabelsCombinedLength(
    choices: RadioGroupOfTypeButtonItem<TValue>[]
  ): number {
    return choices.reduce(
      (prev, curr) => prev + (isString(curr.label) ? curr.label.length : 0),
      0
    );
  }
}
