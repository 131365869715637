import { useCallback } from "react";

import { backOfficeApi } from "../api";
import { ListRetrieveTemplateTag } from "../models";
import { useFetchOptions } from "./useFetchOptions";

export interface UseTemplateTags {
  templateTags: ListRetrieveTemplateTag[];
  isLoadingTemplateTags: boolean;
  templateTagOptions: { label: string; value: ListRetrieveTemplateTag["id"] }[];
  refresh: () => void;
}

export function useTemplateTags(): UseTemplateTags {
  const mapper = useCallback(
    (templateTag) => ({
      value: templateTag.id,
      label: templateTag.label,
    }),
    []
  );
  const fetcher = useCallback(async () => {
    const templateTags = await backOfficeApi.getTemplateTags();
    return templateTags.results;
  }, []);

  const [templateTags, isLoadingTemplateTags, templateTagOptions, refresh] =
    useFetchOptions<ListRetrieveTemplateTag>(fetcher, mapper);

  return {
    isLoadingTemplateTags,
    templateTagOptions,
    templateTags,
    refresh,
  };
}
