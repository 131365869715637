import { message, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { RetrieveCustomVehicleField } from "../../models";
import {
  CustomVehicleFieldForm,
  useCustomVehicleFieldFormSubmit,
} from "../../views/settings/vehicleFields";

export interface ModalCustomVehicleFieldFormProps {
  onCancel: () => void;
  onSuccessfulSave: (
    customVehicleField: RetrieveCustomVehicleField | null
  ) => void;
  existingCustomVehicleFieldId?: string;
  isCloning: boolean;
}

export function ModalCustomVehicleFieldForm(
  props: ModalCustomVehicleFieldFormProps
) {
  const { t } = useTranslation("backoffice");
  const { setFormSubmitter, setIsSaving, pageTitle, saveButtonProps } =
    useCustomVehicleFieldFormSubmit(!!props.existingCustomVehicleFieldId);

  return (
    <Modal
      open
      width={700}
      onCancel={props.onCancel}
      title={pageTitle}
      cancelText={t("cancel")}
      okText={saveButtonProps?.children}
      okButtonProps={saveButtonProps}
    >
      <CustomVehicleFieldForm
        customVehicleFieldId={props.existingCustomVehicleFieldId}
        setFormSubmitter={setFormSubmitter}
        onIsSavingChange={setIsSaving}
        onSuccessfulSave={(savedRecord) => {
          message.success(t("settings.customVehicleFields.savedSuccessfully"));
          props.onSuccessfulSave(savedRecord || null);
        }}
        isCloning={props.isCloning}
      />
    </Modal>
  );
}
