import { RadioGroupOfTypeButton } from "../RadioGroupOfTypeButton";

export type ChoiceFieldValue = string | null; // selected choice ID

interface Choice {
  value: string;
  label: string;
}

export interface ChoiceFieldComponentProps {
  value?: ChoiceFieldValue;
  onChange?: (value: ChoiceFieldValue) => void;
  choices: Choice[];
  verticalListWidth?: number;
}

export function ChoiceFieldComponent(props: ChoiceFieldComponentProps) {
  return (
    <RadioGroupOfTypeButton
      showClearSelectionButton
      mode="verticalWithOptionalHorizontal"
      items={props.choices.map((choice) => ({
        ...choice,
        id: choice.value,
      }))}
      value={props.value}
      onChange={(value) => props.onChange?.(value)}
      verticalListWidth={props.verticalListWidth}
    />
  );
}
