import { Button, Empty } from "antd";
import produce from "immer";
import { ReactNode, useRef } from "react";
import { Droppable } from "react-beautiful-dnd";
import * as uuid from "uuid";

import { DarkBgListContainer } from "src/components";
import { getTranslatedField, TranslatedField } from "src/locales";

import { Page, Question } from "../../../models";

type PageType = "pages" | "signaturePages";

interface Props {
  pages?: Page[];
  onChange?: (newValue: Page[]) => void;
  pageType: PageType;
  addPageTitle: string;
  renderPage: (
    page: Page,
    allPages: Page[],
    index: number,
    actions: {
      removePage: (pageId: string) => void;
      saveQuestion: (pageId: string, question: Question) => void;
      addQuestions: (pageId: string, questions: Question[]) => void;
      insertQuestion: (
        pageId: string,
        question: Question,
        index: number
      ) => void;
      removeQuestion: (pageId: string, questionId: string) => void;
      insertPage: (page: Page, index: number) => void;
      savePageTitle: (pageId: string, title: TranslatedField) => void;
    }
  ) => ReactNode;
}

export function PageListWrapper(props: {
  children: ReactNode;
  onCreate: () => void;
  addPageTitle: string;
  pageType: PageType;
}) {
  const createPageRef = useRef<HTMLDivElement>(null);

  return (
    <DarkBgListContainer>
      <Droppable droppableId={props.pageType} type={props.pageType}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {props.children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Button
        type="primary"
        block
        ref={createPageRef}
        data-test={`create-page-${props.pageType}`}
        onClick={() => {
          props.onCreate();
          window.setTimeout(() => {
            createPageRef.current?.scrollIntoView({
              block: "nearest",
            });
          }, 10);
        }}
      >
        {props.addPageTitle}
      </Button>
    </DarkBgListContainer>
  );
}

export function PagesList(props: Props) {
  const insertPage = (page: Page, index: number) => {
    if (!props.onChange || !props.pages) {
      return;
    }
    props.onChange(
      produce(props.pages, (draft) => {
        draft.splice(index, 0, page);
      })
    );
  };
  const removePage = (pageFrontendId: string) => {
    if (!props.onChange || !props.pages) {
      return;
    }
    props.onChange(
      props.pages.filter((element) => element.frontendId !== pageFrontendId)
    );
  };
  const savePageTitle = (pageId: string, title: TranslatedField) => {
    if (!props.onChange || !props.pages) {
      return;
    }
    props.onChange(
      produce(props.pages, (draft) => {
        const page = draft.find((page) => page.frontendId === pageId);
        if (!page) {
          throw new Error("Could not find page during create/update");
        }
        page.title = title;
      })
    );
  };
  const removeQuestion = (pageId: string, questionId: string) => {
    if (!props.onChange || !props.pages) {
      return;
    }
    props.onChange(
      produce(props.pages, (draft) => {
        if (!draft) {
          return;
        }
        const page = draft.find((page) => page.frontendId === pageId);
        if (!page) {
          throw new Error("Could not find page during create/update");
        }
        page.questions = page.questions.filter(
          (question) => question.frontendId !== questionId
        );
      })
    );
  };
  const insertQuestion = (
    pageId: string,
    questionObject: Question,
    index: number
  ) => {
    if (!props.onChange || !props.pages) {
      return;
    }
    props.onChange(
      produce(props.pages, (draft) => {
        if (!draft) {
          return;
        }
        const page = draft.find((page) => page.frontendId === pageId);
        if (!page) {
          throw new Error("Could not find page during create/update");
        }
        page.questions.splice(index, 0, questionObject);
      })
    );
  };
  const saveQuestion = (pageId: string, questionObject: Question) => {
    if (!props.onChange || !props.pages) {
      return;
    }
    props.onChange(
      produce(props.pages, (draft) => {
        if (!draft) {
          return;
        }
        const page = draft.find((page) => page.frontendId === pageId);
        if (!page) {
          throw new Error("Could not find page during create/update");
        }
        const questionIndex = page.questions.findIndex(
          (question) => question.frontendId === questionObject.frontendId
        );
        if (questionIndex === -1) {
          page.questions.push(questionObject);
        } else {
          page.questions[questionIndex] = questionObject;
        }
      })
    );
  };
  const addQuestions = (pageId: string, questionObjects: Question[]) => {
    if (!props.onChange || !props.pages) {
      return;
    }
    props.onChange(
      produce(props.pages, (draft) => {
        if (!draft) {
          return;
        }
        const page = draft.find((page) => page.frontendId === pageId);
        if (!page) {
          throw new Error("Could not find page");
        }
        page.questions = page.questions.concat(questionObjects);
      })
    );
  };

  return (
    <>
      <PageListWrapper
        addPageTitle={props.addPageTitle}
        pageType={props.pageType}
        onCreate={() => {
          if (props.onChange) {
            props.onChange([
              ...(props.pages || []),
              {
                frontendId: uuid.v4(),
                questions: [],
                id: null,
                title: getTranslatedField(""),
              },
            ]);
          }
        }}
      >
        {props.pages && !props.pages.length && <Empty description={false} />}
        {props.pages &&
          props.pages.map(
            (page, index) =>
              props.pages &&
              props.renderPage(page, props.pages, index, {
                removePage,
                saveQuestion,
                addQuestions,
                removeQuestion,
                insertPage,
                insertQuestion,
                savePageTitle,
              })
          )}
      </PageListWrapper>
    </>
  );
}
