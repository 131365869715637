import * as Sentry from "@sentry/react";

export function sendErrorToSentryIfNotNetworkError(
  sentryEventMessage: string,
  e: any
): void {
  if (
    typeof e.message === "string" &&
    (e.message.includes("Network") ||
      e.message.includes("timeout") ||
      e.message.includes("Request failed with status code 504") ||
      e.message.includes("Request failed with status code 502") ||
      e.message.includes("Request aborted"))
  ) {
    // assume it's a Network Error and do nothing
    return;
  }

  Sentry.captureMessage(sentryEventMessage, {
    level: Sentry.Severity.Error,
    extra: {
      body: e.body ? JSON.stringify(e.body) : null,
      message: e.message,
      error: JSON.stringify(e),
    },
  });
}
