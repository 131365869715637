import { useTranslation } from "react-i18next";

import { backOfficeApi } from "../../api";
import { formatDatePeriod, getDatePeriodFormInitialValues } from "./common";
import { DatePeriodFormItem, ReportFormLayout } from "./components";

export function ProtocolsCreationLocationReportView() {
  const { t } = useTranslation("backoffice");

  return (
    <ReportFormLayout
      reportTitle={t("reportsView.reports.protocolsCreationLocation")}
      startDownloading={async (data) =>
        backOfficeApi.reports.startDownloadProtocolsCreationLocation(
          formatDatePeriod(data)
        )
      }
      formInitialValues={getDatePeriodFormInitialValues()}
    >
      <DatePeriodFormItem />
    </ReportFormLayout>
  );
}
