import React from "react";
import Lightbox, { LightboxProps, Slide } from "yet-another-react-lightbox";

interface Props
  extends Pick<LightboxProps, "close">,
    Pick<Slide, "src" | "alt"> {}

export function SingleImagePreview(props: Props) {
  return (
    <Lightbox
      open
      close={props.close}
      slides={[
        {
          src: props.src,
          alt: props.alt,
        },
      ]}
      render={{
        buttonPrev: () => null,
        buttonNext: () => null,
      }}
      controller={{
        closeOnBackdropClick: true,
      }}
    />
  );
}
