import Compressor from "compressorjs";

const compressionPresets = {
  highQuality: {
    maxWidth: 4000,
    maxHeight: 4000,
    quality: 0.4,
  },
  standard: {
    maxWidth: 1000,
    maxHeight: 1000,
    quality: 0.6,
  },
} as const;

export type CompressionPreset = keyof typeof compressionPresets;

export function compressFile(
  file: File | Blob,
  compressionPreset: CompressionPreset
): Promise<File> {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      checkOrientation: false,
      convertSize: 1,
      ...compressionPresets[compressionPreset],
      success(file: File) {
        resolve(file);
      },
      error(error: Error) {
        reject(error);
      },
    });
  });
}
