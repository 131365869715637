import { BackofficeLanguage } from "@inspecto/common";
import { Alert, Form, Skeleton } from "antd";
import produce from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as uuid from "uuid";

import { customFieldTypes } from "src/company";
import { MaxWidth700FormWrapper, RadioGroupOfTypeButton } from "src/components";
import { emptyTranslatedField } from "src/locales";

import { backOfficeApi } from "../../../api";
import {
  GivenTypeCustomVehicleFieldForm,
  GivenTypeCustomVehicleFieldFormProps,
  QuestionTypeWithAvatar,
} from "../../../components";
import { AvatarFieldType } from "../../../components";
import { CreateUpdateCustomVehicleField } from "../../../models";

const StyledRadioGroupOfTypeButtonWrapper = styled.div`
  max-width: 500px;
`;

interface Props {
  customVehicleFieldId?: string;
  setFormSubmitter: (submitter: (() => void) | null) => void;
  onSuccessfulSave: GivenTypeCustomVehicleFieldFormProps["onSuccessfulSave"];
  onIsSavingChange(isSaving: boolean): void;
  isCloning: boolean;
}

function cloneField(
  field: CreateUpdateCustomVehicleField,
  clonedFieldSuffix: string
): CreateUpdateCustomVehicleField {
  return produce(field, (draft) => {
    draft.id = null;
    draft.choices = draft.choices.map((choice) => ({
      ...choice,
      id: null,
    }));

    draft.label += ` (${clonedFieldSuffix})`;
    for (const language in draft.labelTranslations) {
      if (draft.labelTranslations[language as BackofficeLanguage]) {
        draft.labelTranslations[
          language as BackofficeLanguage
        ] += ` (${clonedFieldSuffix})`;
      }
    }
  });
}

export function CustomVehicleFieldForm(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFieldType, setSelectedFieldType] = useState<
    CreateUpdateCustomVehicleField["type"] | null
  >(null);
  const [existingCustomVehicleField, setExistingCustomVehicleField] =
    useState<CreateUpdateCustomVehicleField | null>(null);

  useEffect(() => {
    (async () => {
      if (!props.customVehicleFieldId) {
        setIsLoading(false);
        return;
      }
      const customVehicleField = await backOfficeApi.getCustomVehicleField(
        props.customVehicleFieldId
      );

      let mappedFrontendVehicleField: CreateUpdateCustomVehicleField = {
        ...customVehicleField,
        choices: customVehicleField.choices.map((choice) => ({
          ...choice,
          frontendId: uuid.v4(),
        })),
        customVehicleFieldsGroup:
          customVehicleField.customVehicleFieldsGroupObject?.id || null,
      };
      if (props.isCloning) {
        mappedFrontendVehicleField = cloneField(
          mappedFrontendVehicleField,
          t("copyOf")
        );
      }
      setExistingCustomVehicleField(mappedFrontendVehicleField);
      setSelectedFieldType(customVehicleField.type);
      setIsLoading(false);
    })();
  }, [t, props.customVehicleFieldId, props.isCloning]);

  const isEditingExistingObject = !!existingCustomVehicleField?.id;
  const isEditingExistingObjectOrCloning = !!existingCustomVehicleField;

  const fieldTypeOptions = useMemo<
    { label: JSX.Element; value: CreateUpdateCustomVehicleField["type"] }[]
  >(
    () =>
      customFieldTypes.map((fieldType) => ({
        label: (
          <AvatarFieldType
            questionType={fieldType}
            label={t(`fieldType.${fieldType}`)}
          />
        ),
        value: fieldType,
      })),
    [t]
  );

  return isLoading ? (
    <Skeleton />
  ) : (
    <MaxWidth700FormWrapper>
      {isEditingExistingObject ? (
        <Alert
          type="info"
          message={t("settings.customVehicleFields.affectedAreasAlert")}
          style={{ marginBottom: 20 }}
        />
      ) : null}
      <Form layout="vertical">
        <Form.Item
          label={t("settings.customVehicleFields.fields.type")}
          rules={[{ required: true }]}
          required
          htmlFor="custom-vehicle-field-select-field-type"
        >
          {selectedFieldType ? (
            <QuestionTypeWithAvatar
              questionType={selectedFieldType}
              label={t(`fieldType.${selectedFieldType}`)}
              onChangeButtonClick={
                isEditingExistingObjectOrCloning
                  ? undefined
                  : () => setSelectedFieldType(null)
              }
            />
          ) : (
            <StyledRadioGroupOfTypeButtonWrapper>
              <RadioGroupOfTypeButton
                mode="vertical"
                total={fieldTypeOptions.length}
                items={fieldTypeOptions}
                onChange={setSelectedFieldType}
                value={selectedFieldType}
              />
            </StyledRadioGroupOfTypeButtonWrapper>
          )}
        </Form.Item>
      </Form>
      {!!selectedFieldType && (
        <GivenTypeCustomVehicleFieldForm
          initialFormValues={
            existingCustomVehicleField || {
              id: null,
              labelTranslations: emptyTranslatedField,
              choices: [],
              label: "",
              condition: null,
              type: selectedFieldType,
              vehicleTypes: [],
              customVehicleFieldsGroup: null,
              damagesRequiredFields: null,
              suggestedValues: [],
            }
          }
          key={selectedFieldType}
          setFormSubmitter={props.setFormSubmitter}
          onIsSavingChange={props.onIsSavingChange}
          onSuccessfulSave={props.onSuccessfulSave}
        />
      )}
    </MaxWidth700FormWrapper>
  );
}

export function useCustomVehicleFieldFormSubmit(
  isEditingExistingObject: boolean
) {
  const { t } = useTranslation("backoffice");
  const [formSubmitter, _setFormSubmitter] = useState<(() => void) | null>(
    null
  );
  const setFormSubmitter: (submitter: (() => void) | null) => void =
    useCallback((submitter) => _setFormSubmitter(() => submitter), []);
  const [isSaving, setIsSaving] = useState(false);

  const pageTitle = isEditingExistingObject
    ? t("settings.customVehicleFields.editingLabel")
    : t("settings.customVehicleFields.createFieldLabel");

  const saveButtonProps:
    | {
        children: string;
        type: "primary";
        loading: boolean;
        onClick: () => void | null;
      }
    | undefined = useMemo(
    () =>
      formSubmitter
        ? {
            children: isEditingExistingObject ? t("save") : t("add"),
            type: "primary",
            loading: isSaving,
            onClick: formSubmitter,
          }
        : undefined,
    [formSubmitter, isEditingExistingObject, isSaving, t]
  );

  return {
    setFormSubmitter,
    setIsSaving,
    pageTitle,
    saveButtonProps,
  };
}
