import { Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { getAssignedItemsTagsRenderer } from "../../../components/AssignedItemsTagsRenderer";
import { AssignTemplatesListView } from "../../../components/AssignTemplatesListView";
import { useTemplateTags } from "../../../hooks/useTemplateTags";
import {
  ListRetrieveTemplateTag,
  ListTemplate,
  ListTemplateTag,
} from "../../../models";
import { BackOfficeLayout } from "../../BackOfficeLayout";
import { TemplateTagForm } from "./TemplateTagForm";

export function EditTemplateTagView() {
  const { t } = useTranslation("backoffice");
  const { templateTagId } = useParams<{ templateTagId: string }>();

  const [existingTemplateTag, setExistingTemplateTag] =
    useState<ListRetrieveTemplateTag | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { templateTagOptions } = useTemplateTags();

  const isTemplateAssigned: (item: ListTemplate) => boolean = useCallback(
    (template) =>
      template.templateTags.map((tag) => tag.id).includes(templateTagId),
    [templateTagId]
  );

  useEffect(() => {
    setIsLoading(true);
    backOfficeApi.getTemplateTag(templateTagId).then((templateTag) => {
      setExistingTemplateTag(templateTag);

      setIsLoading(false);
    });
  }, [templateTagId]);

  const extraColumns = useCallback(
    (selectedRows: ListTemplate[]) => [
      {
        dataIndex: "templateTags",
        title: t("settings.templateTags.label"),
        render: getAssignedItemsTagsRenderer<ListTemplate, ListTemplateTag>(
          selectedRows,
          { id: templateTagId, label: existingTemplateTag?.label || "" },
          (templateTag) => templateTag.label
        ),
      },
    ],
    [existingTemplateTag?.label, t, templateTagId]
  );

  return (
    <BackOfficeLayout
      breadcrumbs={[
        { label: t("settings.label") },
        {
          label: t("settings.templateTags.label"),
          url: urls.backOffice.settings.templateTags(),
        },
      ]}
      pageTitle={t("settings.templateTags.editingLabel")}
      contentMinWidth={1200}
      headerDescription={
        <div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <TemplateTagForm existingTemplateTag={existingTemplateTag} />
          )}
        </div>
      }
    >
      <BackOfficeLayout.Content>
        <AssignTemplatesListView
          templateTagOptions={templateTagOptions}
          isTemplateAssigned={isTemplateAssigned}
          assignTemplates={async (payload) =>
            backOfficeApi.assignTemplateTagToTemplates(templateTagId, payload)
          }
          extraColumns={extraColumns}
        />
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
