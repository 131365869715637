import { createContext, useContext } from "react";

import { PositionContext } from "src/protocolFiller/components/ProtocolFillerPositionProvider";

export interface DevToolbarContext {
  setDevToolbarLocationInformation: (
    geoLocationInformation: Pick<
      PositionContext,
      "bestPosition" | "currentPosition"
    >
  ) => void;
  setDevToolbarLocalStorageSize: (localStorageSize: number) => void;
  isDevToolbarEnabled: boolean;
}

const devToolbarContext = createContext<DevToolbarContext>({
  setDevToolbarLocationInformation: () => {},
  setDevToolbarLocalStorageSize: () => {},
  isDevToolbarEnabled: false,
});

export const { Provider } = devToolbarContext;

export function useDevToolbar() {
  return useContext(devToolbarContext);
}
