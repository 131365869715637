import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { CreateEditDamageView } from "./CreateEditDamageView";
import { DamagesListView } from "./DamagesListView";

export function DamagesRouter() {
  return (
    <Switch>
      <Route path={urls.backOffice.damages.list()} exact>
        <DamagesListView />
      </Route>
      <Route path={urls.backOffice.damages.create()} exact>
        <CreateEditDamageView isEditing={false} />
      </Route>
      <Route path={urls.backOffice.damages.edit()}>
        <CreateEditDamageView isEditing={true} />
      </Route>
    </Switch>
  );
}
