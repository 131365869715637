import { NoConnectionError } from "@inspecto/common";
import * as Sentry from "@sentry/react";
import { Alert, Button, Checkbox } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import { DropdownMenuDivider } from "../DropdownMenuDivider";
import { Select } from "./Select";
import { SelectProps } from "./SelectProps";

interface Props<T>
  extends Omit<SelectProps<string>, "options" | "onChange" | "value"> {
  searchElements(searchQuery: string, isArchived: boolean): Promise<T[]>;
  getLabel: (item: T) => string;
  valueKey: keyof T;
  onChange?(option: { value: string; label: string } | null): void;
  value?: { value: string; label: string } | null;

  showArchivedCheckbox?: boolean;
}

export function SearchableAsyncSelect<T>(props: Props<T>) {
  const { t } = useTranslation();
  const [isSearching, setIsSearching] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{
    message: string;
    subtitle: string;
  } | null>(null);
  const [includeArchived, setIncludeArchived] = useState(false);

  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [searchQuery, setSearchQuery] = useState("");

  const [searchTerm] = useDebounce(searchQuery, 300);

  const {
    getLabel,
    valueKey,
    searchElements,
    onChange,
    value,
    showArchivedCheckbox,
    ...restProps
  } = props;

  const runSearch = useCallback(async () => {
    setIsSearching(true);

    try {
      const response = await searchElements(searchTerm, includeArchived);
      setOptions(
        response.map((element) => ({
          label: getLabel(element) as unknown as string,
          value: element[valueKey] as unknown as string,
        }))
      );
      setErrorMessage(null);
    } catch (e) {
      if (e instanceof NoConnectionError) {
        setErrorMessage({
          message: t("errors.noInternet.message"),
          subtitle: t("errors.noInternet.subtitle"),
        });
      } else {
        setErrorMessage({
          message: t("errors.somethingWentWrong"),
          subtitle: t("errors.pleaseTryAgainOrContactAdministrator"),
        });
        Sentry.captureException(e);
      }
    } finally {
      setIsSearching(false);
    }
  }, [t, searchElements, searchTerm, getLabel, valueKey, includeArchived]);

  useEffect(() => {
    runSearch();
  }, [runSearch]);

  const optionsWithSelection = useMemo(() => {
    const newOptions = [];

    if (
      value &&
      !~options.findIndex((el) => el.value === value.value) &&
      !searchTerm.length
    ) {
      newOptions.push(value);
    }

    newOptions.push(...options);
    return newOptions;
  }, [options, searchTerm.length, value]);

  return (
    <Select<string>
      allowClear
      showSearch
      value={value ? value.value : undefined}
      options={optionsWithSelection}
      loading={isSearching}
      onSearch={setSearchQuery}
      dropdownRender={(menu) =>
        errorMessage ? (
          <div style={{ padding: "5px 18px" }}>
            <Alert
              type="error"
              message={errorMessage.message}
              description={errorMessage.subtitle}
              action={
                <Button
                  size="small"
                  type="primary"
                  onClick={() => runSearch()}
                  style={{ height: 24, padding: "0 7px", borderRadius: 2 }} // Revert to default AntD Small default
                >
                  {t("retry")}
                </Button>
              }
            />
          </div>
        ) : (
          <>
            {showArchivedCheckbox ? (
              <DropdownMenuDivider
                topContent={
                  <Checkbox
                    checked={includeArchived}
                    onChange={(e) => setIncludeArchived(e.target.checked)}
                  >
                    {t("includeDeletedElements")}
                  </Checkbox>
                }
              >
                {menu}
              </DropdownMenuDivider>
            ) : (
              menu
            )}
          </>
        )
      }
      onClear={() => {
        onChange?.(null);
      }}
      onChange={(value) => {
        const selectedOption = options.find((option) => option.value === value);
        if (!selectedOption) {
          return;
        }
        onChange?.({ value, label: selectedOption.label });
        setSearchQuery("");
      }}
      {...restProps}
    />
  );
}
