import * as uuid from "uuid";

export type Base64 = string | ArrayBuffer | null;

export async function convertFileToBase64(
  file: File | Blob
): Promise<string | ArrayBuffer | null> {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export async function convertFilesToBase64s(
  files: (File | null)[]
): Promise<Base64[]> {
  const convertOperations = files.map(async (file) => {
    if (file) {
      return await convertFileToBase64(file);
    }
    return null;
  });
  return await Promise.all(convertOperations);
}

export async function convertBase64ToFile(
  base64: Base64,
  mimeType: string
): Promise<File | null> {
  if (typeof base64 === "string") {
    return await fetch(base64)
      .then((res) => res.blob())
      .then((blob) => new File([blob], uuid.v4(), { type: mimeType }));
  }

  return null;
}

export async function convertBase64sToFiles(
  base64s: Base64[],
  mimeType: string
): Promise<(File | null)[]> {
  const convertOperations = base64s.map(async (base64) =>
    convertBase64ToFile(base64, mimeType)
  );
  return await Promise.all(convertOperations);
}
