import { Card, Select, Space } from "antd";
import { produce } from "immer";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CustomVehicleFieldType } from "src/company";

import {
  ConditionName,
  CustomVehicleFieldCondition,
  CustomVehicleFieldConditionArgument,
  CustomVehicleFieldConditionOption,
  CustomVehicleFieldConditionOptionArgument,
} from "../../models";
import { ConditionInput } from "./ConditionInput";

interface CustomVehicleFieldConditionOptionArgumentWithValue
  extends CustomVehicleFieldConditionOptionArgument {
  value: CustomVehicleFieldConditionArgument;
}

interface CustomVehicleFieldConditionOptionWithValues
  extends CustomVehicleFieldConditionOption {
  arguments: CustomVehicleFieldConditionOptionArgumentWithValue[];
}

export function ConditionBuilder({
  fieldType,
  onChange,
  value,
  conditions,
  ...props
}: {
  customVehicleFieldId: string | null;
  fieldType: CustomVehicleFieldType | null;
  value?: CustomVehicleFieldCondition | null;
  onChange?: (newValue: CustomVehicleFieldCondition | null) => void;
  conditions: CustomVehicleFieldConditionOption[];
  disabled?: boolean;
}) {
  const { t } = useTranslation("backoffice");

  const conditionChoicesOptions = useMemo(
    () =>
      fieldType
        ? conditions.filter((condition) =>
            condition.allowedFieldTypes.includes(fieldType)
          )
        : [],
    [fieldType, conditions]
  );

  const selectedConditionWithValues:
    | CustomVehicleFieldConditionOptionWithValues
    | undefined = useMemo(() => {
    const foundCondition = conditions.find((condition) =>
      value ? condition.value === value.name : condition.value === null
    );
    return foundCondition
      ? {
          ...foundCondition,
          arguments: foundCondition.arguments.map((argument, index) => {
            const valueToAssign =
              value && value.arguments.length >= index
                ? value.arguments[index]
                : null;
            return {
              ...argument,
              value:
                typeof valueToAssign === "undefined" ? null : valueToAssign,
            };
          }),
        }
      : undefined;
  }, [value, conditions]);

  return (
    <Card>
      <Space direction="vertical" size="middle">
        <Select
          size="small"
          disabled={props.disabled}
          value={value ? value.name : null}
          onSelect={(selectedConditionName: ConditionName | null) => {
            const foundCondition = conditions.find(
              (condition) => condition.name === selectedConditionName
            );

            onChange?.(
              foundCondition
                ? {
                    name: foundCondition.name,
                    arguments: foundCondition.arguments.map(() => null),
                  }
                : null
            );
          }}
          options={conditionChoicesOptions.map((condition) => ({
            label: t(`conditions.names.${condition.name}`),
            value: condition.value,
          }))}
          style={{ width: 350 }}
        />
        <Space>
          {value &&
            selectedConditionWithValues &&
            selectedConditionWithValues.arguments.map(
              (selectedConditionWithValue, index) => (
                <ConditionInput
                  customVehicleFieldId={props.customVehicleFieldId}
                  input={selectedConditionWithValue}
                  disabled={props.disabled}
                  key={index}
                  value={selectedConditionWithValue.value}
                  onChange={(newValue) => {
                    onChange?.(
                      produce(value, (draft) => {
                        draft.arguments[index] = newValue;
                      })
                    );
                  }}
                />
              )
            )}
        </Space>
      </Space>
    </Card>
  );
}
