import { Currency, useCompany } from "@inspecto/common";
import { Form, Input, InputNumber, Select } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { SearchableAsyncSelect } from "src/components";
import { ModalForm } from "src/components/Form/ModalForm";
import { fullName, fullNameReversed } from "src/utils";

import { backOfficeApi, ChargedEmployeePayload } from "../api";
import { ChargedEmployee, ChargedEmployeeStatus } from "../models";
import { ChargedEmployeeStatusSelect } from "./ChargedEmployeeStatusSelect";

type InitialValues =
  | Pick<ChargedEmployee, "comment" | "amount" | "status" | "employeeObject">
  | Pick<ChargedEmployee, "comment">
  | undefined;

export interface ChargeEmployeeModalProps {
  mode: "add" | "edit";
  title: string;
  cancelButtonText: string;
  confirmButtonText: string;
  unexpectedErrorMessage: string;
  performAction: (
    employee?: string,
    amount?: number,
    status?: ChargedEmployeeStatus,
    comment?: string,
    currency?: Currency
  ) => Promise<void>;
  closeModal: () => void;
  initialValues: InitialValues | undefined;
}

export function ChargeEmployeeModal(
  props: ChargeEmployeeModalProps
): JSX.Element {
  const { t } = useTranslation("backoffice");
  const labelByProperty: Record<
    keyof Omit<
      ChargedEmployeePayload,
      "damage" | "response" | "historicalCustomVehicleFieldValue" | "currency"
    >,
    string
  > = {
    amount: t("employeeCharges.chargeEmployeeAmountLabel"),
    employee: t("employeeCharges.chargeEmployeeSelectLabel"),
    comment: t("employeeCharges.chargeEmployeeCommentLabel"),
    status: t("employeeCharges.chargeEmployeeStatusLabel"),
  };
  const company = useCompany();

  const searchEmployees = useCallback(async (query, includeArchived) => {
    const employees = await backOfficeApi.getAllEmployees({
      tableParams: {
        pageNumber: 1,
      },
      searchTerm: query,
      searchIn: "name",
      status: "all",
      includeInactive: includeArchived,
      userRoles: [],
      emailFilter: "all",
    });
    return employees.results;
  }, []);
  const [form] = Form.useForm();

  const isEditingDisabled =
    !props.initialValues || !("status" in props.initialValues)
      ? false
      : props.initialValues.status !== ChargedEmployeeStatus.UNPAID;

  return (
    <ModalForm<{
      amount: number;
      employee: { label: string; value: string };
      comment: string;
      status: ChargedEmployeeStatus;
      currency: Currency;
    }>
      initialValues={
        props.initialValues && "employeeObject" in props.initialValues
          ? {
              ...props.initialValues,
              employee: {
                value: props.initialValues.employeeObject.id,
                label: fullName(props.initialValues.employeeObject),
              },
            }
          : {
              ...props.initialValues,
              currency:
                company.availableCurrencies.length === 1
                  ? company.availableCurrencies[0]
                  : undefined,
            }
      }
      saveCallback={async (values) => {
        await props.performAction(
          values.employee.value,
          values.amount,
          values.status,
          values.comment,
          values.currency
        );
      }}
      form={form}
      closeModal={props.closeModal}
      open
      cancelButtonText={props.cancelButtonText}
      confirmButtonText={props.confirmButtonText}
      formTitle={props.title}
    >
      <Form.Item
        name="employee"
        label={labelByProperty.employee}
        rules={[{ required: true }]}
      >
        <SearchableAsyncSelect
          disabled={isEditingDisabled}
          placeholder={t("employeeCharges.startTypingToSearch")}
          notFoundContent={t("employeeCharges.noMatchingEmployeeFound")}
          showArchivedCheckbox
          searchElements={searchEmployees}
          getLabel={fullNameReversed}
          valueKey="id"
        />
      </Form.Item>
      <Form.Item
        name="amount"
        label={labelByProperty.amount}
        rules={[{ required: true }]}
      >
        <InputNumber
          disabled={isEditingDisabled}
          style={{ width: "100%" }}
          addonAfter={
            <Form.Item name="currency" noStyle rules={[{ required: true }]}>
              <Select style={{ width: 100 }}>
                {company.availableCurrencies.map((currency) => (
                  <Select.Option value={currency}>{currency}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
        />
      </Form.Item>
      <Form.Item name="comment" label={labelByProperty.comment}>
        <Input autoComplete="off" disabled={isEditingDisabled} />
      </Form.Item>
      <Form.Item name="status" label={labelByProperty.status}>
        <ChargedEmployeeStatusSelect />
      </Form.Item>
    </ModalForm>
  );
}
