import { useTranslation } from "react-i18next";

import { backOfficeApi } from "../../api";
import { GenericDateVehicleTypeCustomVehicleFieldsReportView } from "./GenericDateVehicleTypeCustomVehicleFieldsReportView";

export function NumberOfNotOkVehicleFieldsReportView() {
  const { t } = useTranslation("backoffice");

  return (
    <GenericDateVehicleTypeCustomVehicleFieldsReportView
      reportTitle={t("reportsView.reports.numberOfNotOkVehicleFields")}
      startDownloading={async (data) =>
        backOfficeApi.reports.startDownloadNumberOfNotOkVehicleFields(data)
      }
    />
  );
}
