export function FormItemValue<TValue>(props: {
  value?: TValue;
  onChange?: (value: TValue) => void;
  children: (props: {
    value?: TValue;
    onChange?: (value: TValue) => void;
  }) => JSX.Element;
}): JSX.Element {
  return props.children({ value: props.value, onChange: props.onChange });
}
