import { Button, Col, Row, Space, Tooltip, TooltipProps } from "antd";
import { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as uuid from "uuid";

import { Icon, ImmediateImageUploadersContextProvider } from "src/components";
import { emptyTranslatedField } from "src/locales";
import { allRegularQuestionTypes } from "src/protocolFiller/models";

import {
  ModalCustomVehicleFieldForm,
  ModalCustomVehicleFieldFormProps,
} from "../../../components";
import { Page, Question } from "../../../models";
import { defaultIsRequired } from "../defaults";
import { useTemplateContext } from "../TemplateEditorView";
import {
  canCreateNewQuestionInPage,
  canSelectQuestionType,
  cloneQuestion,
} from "../utils";
import {
  CustomVehicleFieldSelectorModal,
  CustomVehicleFieldSelectorModalProps,
} from "./CustomVehicleFieldSelectorModal";
import { QuestionComponent } from "./QuestionComponent";
import { QuestionEditModal } from "./QuestionEditModal";
import {
  QuestionTypeSelectorModal,
  QuestionTypeSelectorModalProps,
} from "./QuestionTypeSelectorModal";

const QuestionListComponent = styled.div`
  min-height: 10px;
`;

const QuestionsHeader = styled.div`
  font-size: 20px;
  margin-bottom: 5px;
`;

interface Props {
  page: Page;
  allPages: Page[];
  saveQuestion(pageFrontendId: string, question: Question): void;
  addQuestions(pageFrontendId: string, questions: Question[]): void;
  removeQuestion: (pageFrontendId: string, questionFrontendId: string) => void;
  insertQuestion(
    pageFrontendId: string,
    question: Question,
    index: number
  ): void;
}

export function QuestionsList(props: Props) {
  const { refreshCustomVehicleFields } = useTemplateContext();
  const [questionEditModalState, setQuestionEditModalState] =
    useState<null | Question>(null);
  const [questionTypeSelectorModalState, setQuestionTypeSelectorModalState] =
    useState<null | Pick<
      QuestionTypeSelectorModalProps,
      "disableMotion" | "question"
    >>(null);
  const [
    customVehicleFieldSelectorModalState,
    setCustomVehicleFieldSelectorModalState,
  ] = useState<null | Pick<
    CustomVehicleFieldSelectorModalProps,
    "disableMotion" | "question"
  >>(null);
  const { t } = useTranslation("backoffice");
  const [
    modalCustomVehicleFieldFormState,
    setModalCustomVehicleFieldFormState,
  ] = useState<null | Pick<
    ModalCustomVehicleFieldFormProps,
    "onSuccessfulSave" | "existingCustomVehicleFieldId" | "isCloning"
  >>(null);

  const canCreateNewQuestion = canCreateNewQuestionInPage(props.page);
  const tooltipTitle: TooltipProps["title"] =
    !canCreateNewQuestion &&
    t("builder.choiceQuestionCreationInfo.cantCreateNewQuestionInPage");

  return (
    <div style={{ margin: 10 }}>
      <QuestionsHeader>{t("builder.questions")}:</QuestionsHeader>
      <Droppable
        isDropDisabled={!canCreateNewQuestion}
        droppableId={props.page.frontendId}
        type="QUESTION"
      >
        {(provided) => (
          <QuestionListComponent
            {...provided.droppableProps}
            ref={provided.innerRef}
            data-test="question-list"
          >
            {props.page.questions.map((question, index) => (
              <QuestionComponent
                question={question}
                key={question.frontendId}
                index={index}
                page={props.page}
                allPages={props.allPages}
                onEditQuestionClick={() => setQuestionEditModalState(question)}
                onCloneQuestionClick={() => {
                  const clonedQuestion = cloneQuestion(question, t("copyOf"));
                  if (clonedQuestion.questionType === "custom-field-update") {
                    setCustomVehicleFieldSelectorModalState({
                      question: clonedQuestion,
                    });
                  } else {
                    setQuestionEditModalState(clonedQuestion);
                  }
                }}
                removeQuestion={props.removeQuestion}
                insertQuestion={props.insertQuestion}
                canDuplicateQuestion={canCreateNewQuestion}
              />
            ))}
            {provided.placeholder}
          </QuestionListComponent>
        )}
      </Droppable>
      <div>
        <Row gutter={[8, 0]}>
          <Col xs={12}>
            <Tooltip title={tooltipTitle}>
              <Button
                type="primary"
                block
                size="small"
                disabled={!canCreateNewQuestion}
                data-test="create-custom-field-question"
                onClick={() =>
                  canCreateNewQuestion &&
                  setCustomVehicleFieldSelectorModalState({
                    question: {
                      id: null,
                      frontendId: uuid.v4(),
                      questionType: "custom-field-update",
                      label: emptyTranslatedField,
                      description: emptyTranslatedField,
                      descriptionImage: null,
                      isRequired: defaultIsRequired,
                      customVehicleFieldId: null,
                      isCustomVehicleFieldVerification: false,
                    },
                  })
                }
              >
                <Space size="small">
                  <Icon icon="carSide" />
                  {t("builder.createCustomVehicleFieldQuestionButton")}
                </Space>
              </Button>
            </Tooltip>
          </Col>
          <Col xs={12}>
            <Tooltip title={tooltipTitle}>
              <Button
                type="primary"
                ghost
                block
                size="small"
                disabled={!canCreateNewQuestion}
                data-test="create-question"
                onClick={() =>
                  canCreateNewQuestion &&
                  setQuestionTypeSelectorModalState({
                    question: {
                      id: null,
                      frontendId: uuid.v4(),
                      questionType: "text",
                      label: emptyTranslatedField,
                      description: emptyTranslatedField,
                      isRequired: defaultIsRequired,
                      descriptionImage: null,
                    },
                  })
                }
              >
                <Space size="small">
                  <Icon icon="plusCircle" />
                  {t("builder.createRegularQuestionButton")}
                </Space>
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </div>

      {!!questionEditModalState && (
        <ImmediateImageUploadersContextProvider>
          <QuestionEditModal
            closeModal={() => setQuestionEditModalState(null)}
            saveQuestion={async (question) =>
              props.saveQuestion(props.page.frontendId, question)
            }
            onChangeCustomVehicleFieldClick={(question) => {
              setQuestionEditModalState(null);
              setCustomVehicleFieldSelectorModalState({
                question,
                disableMotion: true,
              });
            }}
            onChangeQuestionTypeClick={(question) => {
              setQuestionEditModalState(null);
              setQuestionTypeSelectorModalState({
                question,
                disableMotion: true,
              });
            }}
            question={questionEditModalState}
          />
        </ImmediateImageUploadersContextProvider>
      )}
      {!!questionTypeSelectorModalState && (
        <QuestionTypeSelectorModal
          notAllowedQuestionTypes={allRegularQuestionTypes.filter(
            (questionType) =>
              !canSelectQuestionType(
                questionType,
                questionTypeSelectorModalState.question,
                props.page
              )
          )}
          closeModal={() => setQuestionTypeSelectorModalState(null)}
          onNextClick={(question) => setQuestionEditModalState(question)}
          {...questionTypeSelectorModalState}
        />
      )}

      {!!customVehicleFieldSelectorModalState && (
        <CustomVehicleFieldSelectorModal
          onEditCustomVehicleFieldClick={(existingCustomVehicleFieldId) =>
            setModalCustomVehicleFieldFormState({
              onSuccessfulSave: () => null,
              existingCustomVehicleFieldId: existingCustomVehicleFieldId,
              isCloning: false,
            })
          }
          onCloneCustomVehicleFieldClick={(
            existingCustomVehicleFieldId,
            onSuccessfulSave
          ) =>
            setModalCustomVehicleFieldFormState({
              onSuccessfulSave: (customVehicleFieldOrNull) =>
                customVehicleFieldOrNull && onSuccessfulSave
                  ? onSuccessfulSave(customVehicleFieldOrNull)
                  : null,
              existingCustomVehicleFieldId: existingCustomVehicleFieldId,
              isCloning: true,
            })
          }
          onCreateCustomVehicleFieldClick={(onSuccessfulSave) =>
            setModalCustomVehicleFieldFormState({
              onSuccessfulSave: (customVehicleFieldOrNull) =>
                customVehicleFieldOrNull && onSuccessfulSave
                  ? onSuccessfulSave(customVehicleFieldOrNull)
                  : null,
              existingCustomVehicleFieldId: undefined,
              isCloning: false,
            })
          }
          closeModal={() => setCustomVehicleFieldSelectorModalState(null)}
          onNextClick={(question) => setQuestionEditModalState(question)}
          onAddMultiple={(questions) => {
            props.addQuestions(props.page.frontendId, questions);
            setCustomVehicleFieldSelectorModalState(null);
          }}
          {...customVehicleFieldSelectorModalState}
        />
      )}
      {!!modalCustomVehicleFieldFormState && (
        <ModalCustomVehicleFieldForm
          existingCustomVehicleFieldId={
            modalCustomVehicleFieldFormState.existingCustomVehicleFieldId
          }
          onCancel={() => setModalCustomVehicleFieldFormState(null)}
          onSuccessfulSave={(customVehicleField) => {
            refreshCustomVehicleFields();
            modalCustomVehicleFieldFormState.onSuccessfulSave(
              customVehicleField
            );
            setModalCustomVehicleFieldFormState(null);
          }}
          isCloning={modalCustomVehicleFieldFormState.isCloning}
        />
      )}
    </div>
  );
}
