import { useEffect } from "react";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { SingleImagePreview } from "src/components";

interface Props {
  closeImage: () => void;
  src: string;
}

export function QuestionDescriptionImage(props: Props): JSX.Element | null {
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block(() => {
      props.closeImage();
      return false; // Prevent navigation
    });

    return () => {
      unblock();
    };
  }, [history, props]);

  return <SingleImagePreview close={props.closeImage} src={props.src} />;
}
