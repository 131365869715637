import { useCompany } from "@inspecto/common";
import { Form, Select, Skeleton, Space, Tag, Typography } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import * as React from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import {
  isFieldTypeSupportsCondition,
  MultiLanguageProvider,
  MultiLanguageTextInput,
} from "src/company";
import { CommonForm, DropdownMenuDivider, MultiSelect } from "src/components";
import { urls } from "src/urls";
import {
  allowOnlyIntegersKeyboardEventHandler,
  getHaveFieldsUpdatedCallback,
} from "src/utils";

import { backOfficeApi } from "../../api";
import {
  useCustomVehicleFieldsGroups,
  useVehicleTypes,
  useCustomVehicleFieldConditions,
} from "../../hooks";
import {
  CreateUpdateCustomVehicleField,
  RetrieveCustomVehicleField,
} from "../../models";
import { ConditionBuilder } from "../conditions";
import { DefaultLanguageRequiredFormItem } from "../DefaultLanguageRequiredFormItem";
import { DraggableChoicesListFormItem } from "../DraggableChoicesListFormItem";
import { FormItemValue } from "../FormItemValue";
import { SelectAddLink } from "../SelectAddLink";

export type CustomVehicleFieldFormState = Omit<
  CreateUpdateCustomVehicleField,
  "id" | "customVehicleFieldsGroup"
> & {
  customVehicleFieldsGroup?:
    | CreateUpdateCustomVehicleField["customVehicleFieldsGroup"]
    | undefined;
};

export interface GivenTypeCustomVehicleFieldFormProps {
  initialFormValues: CreateUpdateCustomVehicleField;
  setFormSubmitter: (submitter: (() => void) | null) => void;
  onSuccessfulSave(savedRecord: RetrieveCustomVehicleField | void): void;
  onIsSavingChange(isSaving: boolean): void;
}

export function GivenTypeCustomVehicleFieldForm(
  props: GivenTypeCustomVehicleFieldFormProps
) {
  const [form] = Form.useForm();
  const { t } = useTranslation("backoffice");
  const { t: tTranslation } = useTranslation("translation");
  const { t: tProtocolFiller } = useTranslation("protocolFiller");

  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();
  const {
    isLoadingCustomVehicleFieldsGroups,
    customVehicleFieldsGroupOptions,
  } = useCustomVehicleFieldsGroups();
  const { isLoadingConditions, conditions } =
    useCustomVehicleFieldConditions(t);
  const company = useCompany();

  const reorderChoices = useMemo(
    () => DraggableChoicesListFormItem.getOnChoicesReorderHandler(form),
    [form]
  );

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      switch (result.type) {
        case "CHOICE":
          reorderChoices(result.source.index, result.destination.index);
          break;
      }
    },

    [reorderChoices]
  );

  const setFormSubmitter = props.setFormSubmitter;

  useEffect(() => {
    setFormSubmitter(() => form.submit());
    return () => setFormSubmitter(null);
  }, [form, setFormSubmitter]);

  if (
    isLoadingVehicleTypes ||
    isLoadingConditions ||
    isLoadingCustomVehicleFieldsGroups
  ) {
    return <Skeleton />;
  }

  return (
    <CommonForm<CustomVehicleFieldFormState, RetrieveCustomVehicleField | void>
      onIsSavingChange={props.onIsSavingChange}
      form={form}
      saveCallback={async (values) => {
        const valuesToSend = {
          ...values,
          customVehicleFieldsGroup: values.customVehicleFieldsGroup || null,
        };

        if (props.initialFormValues?.id) {
          await backOfficeApi.updateCustomVehicleField(
            props.initialFormValues.id,
            valuesToSend
          );
        } else {
          return await backOfficeApi.createCustomVehicleField(valuesToSend);
        }
      }}
      onSuccessfulSave={(_, savedRecord) => props.onSuccessfulSave(savedRecord)}
      initialValues={props.initialFormValues}
    >
      <MultiLanguageProvider initialLanguage={company.protocolFillerLanguage}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Form.Item hidden name="type" />
          <Form.Item
            name="vehicleTypes"
            label={t("settings.customVehicleFields.vehicleTypes")}
            rules={[{ required: true }]}
          >
            <MultiSelect noMargin options={vehicleTypeOptions} />
          </Form.Item>
          <DefaultLanguageRequiredFormItem
            name="labelTranslations"
            label={t("settings.customVehicleFields.fields.label")}
            maxLength={256}
          >
            <MultiLanguageTextInput />
          </DefaultLanguageRequiredFormItem>
          {props.initialFormValues.type !== "damages" && (
            <Form.Item
              name="customVehicleFieldsGroup"
              label={t("settings.customVehicleFieldsGroups.editingLabel")}
            >
              <Select
                placeholder="-"
                allowClear
                options={customVehicleFieldsGroupOptions}
                style={{ maxWidth: 300 }}
                dropdownRender={(menu) => (
                  <SelectAddLink
                    addUrl={urls.backOffice.settings.createCustomVehicleFieldsGroup()}
                  >
                    {menu}
                  </SelectAddLink>
                )}
              />
            </Form.Item>
          )}
          {props.initialFormValues.type === "choice" ? (
            <DraggableChoicesListFormItem
              getFieldValue={form.getFieldValue}
              type="statusSelector"
            />
          ) : props.initialFormValues.type === "damages" ? (
            <Form.Item
              name="damagesRequiredFields"
              label={t(
                "settings.customVehicleFields.fields.requiredFieldsOnDamages"
              )}
              rules={[{ required: true }]}
            >
              <Select
                style={{ maxWidth: 300 }}
                options={[
                  {
                    label: tProtocolFiller("damages.fieldLabels.photo"),
                    value: "photo",
                  },
                  {
                    label: tProtocolFiller("damages.fieldLabels.label"),
                    value: "label",
                  },
                  {
                    label: `${tProtocolFiller(
                      "damages.fieldLabels.photo"
                    )} + ${tProtocolFiller("damages.fieldLabels.label")}`,
                    value: "photo_and_label",
                  },
                ]}
              />
            </Form.Item>
          ) : !!props.initialFormValues.type &&
            isFieldTypeSupportsCondition(props.initialFormValues.type) ? (
            <Form.Item
              label={t("conditions.valueIsOkWhen")}
              shouldUpdate={getHaveFieldsUpdatedCallback("type")}
              required
            >
              {({ getFieldValue }) => (
                <Form.Item name="condition" noStyle>
                  <ConditionBuilder
                    customVehicleFieldId={props.initialFormValues.id}
                    fieldType={getFieldValue("type")}
                    conditions={conditions}
                  />
                </Form.Item>
              )}
            </Form.Item>
          ) : null}
          {props.initialFormValues.type === "date" && (
            <Form.Item
              name="suggestedValues"
              label={t("settings.customVehicleFields.fields.suggestedValues")}
            >
              <FormItemValue<string[]>>
                {({ value, onChange }) => (
                  <Space>
                    <span>{tTranslation("todayPlus")}</span>
                    <Select
                      allowClear
                      style={{ width: 400 }}
                      mode="tags"
                      value={value}
                      onChange={onChange}
                      placeholder={t(
                        "settings.customVehicleFields.enterNumberOfDaysAndHitEnter"
                      )}
                      onInputKeyDown={allowOnlyIntegersKeyboardEventHandler}
                      tagRender={(tagProps) => (
                        <Tag {...tagProps}>
                          {tTranslation("numberOfDays", {
                            numberOfDays: tagProps.value,
                          })}
                        </Tag>
                      )}
                      dropdownRender={(menu) => (
                        <DropdownMenuDivider
                          topContent={
                            <Typography.Text type="secondary">
                              {t(
                                "settings.customVehicleFields.enterNumberOfDaysAndHitEnter"
                              )}
                            </Typography.Text>
                          }
                        >
                          {menu}
                        </DropdownMenuDivider>
                      )}
                    />
                  </Space>
                )}
              </FormItemValue>
            </Form.Item>
          )}
        </DragDropContext>
      </MultiLanguageProvider>
    </CommonForm>
  );
}
