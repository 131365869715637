import { Divider, Form, message, Skeleton } from "antd";
import { omit } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useAuthentication } from "src/authentication";
import { CommonForm, emptyFieldValueByType } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import {
  CustomFieldValuesFormState,
  CustomVehicleFieldsFormItems,
} from "../../../components";
import {
  VehicleObject,
  VehicleType,
  RetrieveVehicleCustomVehicleField,
  CreateUpdateVehicle,
} from "../../../models";
import { useAllCustomVehicleFieldsExcludingDamages } from "../hooks/useAllCustomVehicleFieldsExcludingDamages";
import {
  StandardVehicleFormItems,
  StandardVehicleFormItemsProps,
} from "./StandardVehicleFormItems";
import { StickySaveButtons } from "./StickySaveButtons";

interface FormState extends CustomFieldValuesFormState, CreateUpdateVehicle {}

interface Props {
  vehicleTypeId: VehicleType["id"];
  customFieldValues: Record<string, RetrieveVehicleCustomVehicleField>;
  vehicleGroupsOptions: StandardVehicleFormItemsProps["vehicleGroupsOptions"];
  vehicleFieldsPresetOptions: StandardVehicleFormItemsProps["vehicleFieldsPresetOptions"];
}

export function CreateVehicleForm(props: Props) {
  const { t } = useTranslation("backoffice");
  const { user } = useAuthentication();
  const history = useHistory();
  const { customVehicleFields, isCustomVehicleFieldsLoading } =
    useAllCustomVehicleFieldsExcludingDamages(props.vehicleTypeId);
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  const emptyInitialCustomVehicleFields: FormState["customFieldValues"] =
    customVehicleFields.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: {
          value: emptyFieldValueByType[curr.type],
          ...(curr.type === "choice" ? { note: "", photos: [] } : {}),
        },
      };
    }, {});

  return isCustomVehicleFieldsLoading || !user ? (
    <Skeleton />
  ) : (
    <CommonForm<FormState, VehicleObject>
      form={form}
      onIsSavingChange={setIsSaving}
      saveCallback={async (values) => {
        const customFieldValues = values["customFieldValues"];
        const valuesWithoutCustomFields = omit(values, "customFieldValues");

        const vehicleObject = await backOfficeApi.createVehicle(
          valuesWithoutCustomFields
        );
        if (customFieldValues) {
          await backOfficeApi.updateVehicleCustomFields(
            vehicleObject.id,
            customFieldValues
          );
        }

        return vehicleObject;
      }}
      onSuccessfulSave={(
        formValues,
        savedRecord: VehicleObject,
        shouldRedirect: boolean | undefined
      ) => {
        if (shouldRedirect) {
          history.replace(urls.backOffice.vehicles());
        } else {
          history.replace(urls.backOffice.vehicleCard(savedRecord.id));
        }

        message.success(t("vehicleSuccessfullySaved"));
      }}
      initialValues={{
        plateNumber: "",
        isActive: true,
        vehicleGroups: [],
        customFieldValues: emptyInitialCustomVehicleFields,
        vehicleType: props.vehicleTypeId,
        companyDepartment:
          user.allowedCompanyDepartments.length === 1
            ? user.allowedCompanyDepartments[0]!.id
            : undefined,
      }}
    >
      <Form.Item name="vehicleType" hidden />
      <StandardVehicleFormItems
        vehicleGroupsOptions={props.vehicleGroupsOptions}
        vehicleFieldsPresetOptions={props.vehicleFieldsPresetOptions}
      />

      {t("vehicleView.customVehicleFields")}
      <Divider style={{ marginTop: 12 }} />
      <CustomVehicleFieldsFormItems
        vehicleId={undefined}
        displayMode="creatingVehicle"
        customVehicleFieldValues={props.customFieldValues}
        customVehicleFields={customVehicleFields}
        displayOnlyNotOk={false}
      />

      <StickySaveButtons isSaving={isSaving} form={form} />
    </CommonForm>
  );
}
