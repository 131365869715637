import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Popconfirm, Radio, Space } from "antd";
import produce from "immer";
import { FormInstance } from "rc-field-form/es/interface";
import { useRef } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as uuid from "uuid";

import { MultiLanguageTextInput } from "src/company";
import {
  CommonRequirementType,
  Asterisk,
  Icon,
  CreateChoiceFieldChoice,
} from "src/components";
import { emptyTranslatedField } from "src/locales";

import { DefaultLanguageRequiredFormItem } from "./DefaultLanguageRequiredFormItem";
import { FormItemValue } from "./FormItemValue";
import { NotNoneVehicleFieldStatusSelect } from "./NotNoneVehicleFieldStatusSelect";

const ChoiceWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.outline};
  background: ${(props) => props.theme.colors.background};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 3px;
`;

const ChoiceBottomBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: end;
`;

interface Props {
  getFieldValue: FormInstance["getFieldValue"];
  type: "isRequiredSwitch" | "statusSelector";
}

const CHOICES_FORM_ITEM_NAME = "choices";

export function DraggableChoicesListFormItem(props: Props) {
  const { t } = useTranslation("backoffice");
  const { t: tTranslation } = useTranslation("translation");
  const addChoiceButton = useRef<HTMLDivElement | null>(null);

  return (
    <Droppable droppableId="choices" type="CHOICE">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <Form.Item label={t("draggableChoicesList.label")}>
            <Form.List
              name={CHOICES_FORM_ITEM_NAME}
              rules={[
                {
                  validator: (rule, value) => {
                    if (!value || value.length < 1) {
                      return Promise.reject(
                        t("draggableChoicesList.youNeedAtLeastOneChoice")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => {
                    let frontendId = props.getFieldValue([
                      CHOICES_FORM_ITEM_NAME,
                      field.name,
                      "frontendId",
                    ]);
                    return (
                      <Draggable
                        draggableId={frontendId}
                        index={index}
                        key={frontendId}
                      >
                        {(provided1) => {
                          const optionLabel = t(
                            "draggableChoicesList.choiceLabel",
                            {
                              number: index + 1,
                            }
                          );
                          return (
                            <ChoiceWrapper
                              {...provided1.draggableProps}
                              {...provided1.dragHandleProps}
                              ref={provided1.innerRef}
                            >
                              <DefaultLanguageRequiredFormItem
                                label={optionLabel}
                                name={[field.name, "labelTranslations"]}
                                maxLength={128}
                                style={{ marginBottom: 14 }}
                              >
                                <MultiLanguageTextInput />
                              </DefaultLanguageRequiredFormItem>
                              <ChoiceBottomBar>
                                <div
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  <Space direction="vertical">
                                    <div style={{ textAlign: "left" }}>
                                      {props.type === "statusSelector" ? (
                                        <Form.Item
                                          rules={[
                                            {
                                              required: true,
                                              message: `${t(
                                                "settings.customVehicleFields.choiceSetsStatus"
                                              )}: ${tTranslation(
                                                "thisFieldIsRequired"
                                              )}`,
                                            },
                                          ]}
                                          name={[field.name, "setsStatus"]}
                                          noStyle
                                        >
                                          <FormItemValue>
                                            {({ value, onChange }) => (
                                              <Space>
                                                <span>
                                                  <Asterisk />
                                                  {`${t(
                                                    "settings.customVehicleFields.choiceSetsStatus"
                                                  )}:`}
                                                </span>
                                                <NotNoneVehicleFieldStatusSelect
                                                  onChange={onChange}
                                                  value={value}
                                                />
                                              </Space>
                                            )}
                                          </FormItemValue>
                                        </Form.Item>
                                      ) : (
                                        <Form.Item
                                          name={[
                                            field.name,
                                            "isPositiveOrRequired",
                                          ]}
                                          style={{ marginBottom: 0 }}
                                          valuePropName="checked"
                                        >
                                          <Checkbox>
                                            {t(
                                              "builder.choiceFields.isRequired"
                                            )}
                                          </Checkbox>
                                        </Form.Item>
                                      )}
                                    </div>

                                    <div
                                      data-testid={`note-requirement-form-item-${
                                        index + 1
                                      }`}
                                    >
                                      <Form.Item
                                        name={[field.name, "noteRequirement"]}
                                        noStyle
                                      >
                                        <FormItemValue>
                                          {({ value, onChange }) => (
                                            <Space>
                                              <span>
                                                <Asterisk />
                                                {`${t(
                                                  "draggableChoicesList.noteRequirement.label"
                                                )}:`}
                                              </span>
                                              <Radio.Group
                                                size="small"
                                                options={Object.values(
                                                  CommonRequirementType
                                                ).map((value) => ({
                                                  label: t(
                                                    `draggableChoicesList.noteRequirement.values.${value}`
                                                  ),
                                                  value,
                                                }))}
                                                onChange={onChange}
                                                value={value}
                                                optionType="button"
                                              />
                                            </Space>
                                          )}
                                        </FormItemValue>
                                      </Form.Item>
                                    </div>
                                    <div
                                      data-testid={`photos-requirement-form-item-${
                                        index + 1
                                      }`}
                                    >
                                      <Form.Item
                                        name={[field.name, "photosRequirement"]}
                                        noStyle
                                      >
                                        <FormItemValue>
                                          {({ value, onChange }) => (
                                            <Space>
                                              <span>
                                                <Asterisk />
                                                {`${t(
                                                  "draggableChoicesList.photosRequirement.label"
                                                )}:`}
                                              </span>
                                              <Radio.Group
                                                size="small"
                                                options={Object.values(
                                                  CommonRequirementType
                                                ).map((value) => ({
                                                  label: t(
                                                    `draggableChoicesList.photosRequirement.values.${value}`
                                                  ),
                                                  value,
                                                }))}
                                                onChange={onChange}
                                                value={value}
                                                optionType="button"
                                              />
                                            </Space>
                                          )}
                                        </FormItemValue>
                                      </Form.Item>
                                    </div>
                                  </Space>
                                </div>

                                <Popconfirm
                                  title={t(
                                    "draggableChoicesList.deleteChoiceConfirmation"
                                  )}
                                  onConfirm={() => remove(index)}
                                >
                                  <Button
                                    type="text"
                                    danger
                                    icon={<Icon icon="trashAlt" />}
                                    title={`${t(
                                      "tableActions.delete"
                                    )} - ${optionLabel}`}
                                  />
                                </Popconfirm>
                              </ChoiceBottomBar>
                            </ChoiceWrapper>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                  <Button
                    block
                    type="primary"
                    ref={addChoiceButton}
                    data-test="add-choice"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      const initialValues: Omit<
                        CreateChoiceFieldChoice,
                        "setsStatus"
                      > & {
                        setsStatus?: CreateChoiceFieldChoice["setsStatus"];
                      } = {
                        frontendId: uuid.v4(),
                        id: null,
                        label: "",
                        labelTranslations: emptyTranslatedField,
                        setsStatus: undefined,
                        noteRequirement: CommonRequirementType.None,
                        photosRequirement: CommonRequirementType.None,
                      };

                      add(initialValues);
                    }}
                  >
                    {t("draggableChoicesList.addNewChoiceButton")}
                  </Button>

                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>
          </Form.Item>
        </div>
      )}
    </Droppable>
  );
}

function getOnChoicesReorderHandler(form: FormInstance) {
  return (sourceIndex: number, destinationIndex: number) => {
    const currentChoices: {}[] = form.getFieldValue(CHOICES_FORM_ITEM_NAME);
    form.setFieldsValue({
      choices: produce(currentChoices, (draft) => {
        const movingChoice = draft[sourceIndex];
        if (!movingChoice) {
          return;
        }
        draft.splice(sourceIndex, 1);
        draft.splice(destinationIndex, 0, movingChoice);
      }),
    });
  };
}

DraggableChoicesListFormItem.getOnChoicesReorderHandler =
  getOnChoicesReorderHandler;
