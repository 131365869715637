import csBackoffice from "src/locales/backoffice/cs.json";
import deBackoffice from "src/locales/backoffice/de.json";
import enBackoffice from "src/locales/backoffice/en.json";
import esBackoffice from "src/locales/backoffice/es.json";
import frBackoffice from "src/locales/backoffice/fr.json";
import huBackoffice from "src/locales/backoffice/hu.json";
import plBackoffice from "src/locales/backoffice/pl.json";
import plGenericBackoffice from "src/locales/backoffice/plgener.json";
import roBackoffice from "src/locales/backoffice/ro.json";
import trBackoffice from "src/locales/backoffice/tr.json";
import ukBackoffice from "src/locales/backoffice/uk.json";
import bgProtocolFiller from "src/locales/protocolFiller/bg.json";
import bsProtocolFiller from "src/locales/protocolFiller/bs.json";
import csProtocolFiller from "src/locales/protocolFiller/cs.json";
import deProtocolFiller from "src/locales/protocolFiller/de.json";
import elProtocolFiller from "src/locales/protocolFiller/el.json";
import enProtocolFiller from "src/locales/protocolFiller/en.json";
import esProtocolFiller from "src/locales/protocolFiller/es.json";
import fiProtocolFiller from "src/locales/protocolFiller/fi.json";
import frProtocolFiller from "src/locales/protocolFiller/fr.json";
import huProtocolFiller from "src/locales/protocolFiller/hu.json";
import hyProtocolFiller from "src/locales/protocolFiller/hy.json";
import itProtocolFiller from "src/locales/protocolFiller/it.json";
import kaProtocolFiller from "src/locales/protocolFiller/ka.json";
import ltProtocolFiller from "src/locales/protocolFiller/lt.json";
import plProtocolFiller from "src/locales/protocolFiller/pl.json";
import plGenericProtocolFiller from "src/locales/protocolFiller/plgener.json";
import roProtocolFiller from "src/locales/protocolFiller/ro.json";
import ruProtocolFiller from "src/locales/protocolFiller/ru.json";
import skProtocolFiller from "src/locales/protocolFiller/sk.json";
import slProtocolFiller from "src/locales/protocolFiller/sl.json";
import srProtocolFiller from "src/locales/protocolFiller/sr.json";
import svProtocolFiller from "src/locales/protocolFiller/sv.json";
import trProtocolFiller from "src/locales/protocolFiller/tr.json";
import ukProtocolFiller from "src/locales/protocolFiller/uk.json";
import uzProtocolFiller from "src/locales/protocolFiller/uz.json";
import bgTranslation from "src/locales/translation/bg.json";
import bsTranslation from "src/locales/translation/bs.json";
import csTranslation from "src/locales/translation/cs.json";
import deTranslation from "src/locales/translation/de.json";
import elTranslation from "src/locales/translation/el.json";
import enTranslation from "src/locales/translation/en.json";
import esTranslation from "src/locales/translation/es.json";
import fiTranslation from "src/locales/translation/fi.json";
import frTranslation from "src/locales/translation/fr.json";
import huTranslation from "src/locales/translation/hu.json";
import hyTranslation from "src/locales/translation/hy.json";
import itTranslation from "src/locales/translation/it.json";
import kaTranslation from "src/locales/translation/ka.json";
import ltTranslation from "src/locales/translation/lt.json";
import plTranslation from "src/locales/translation/pl.json";
import plGenericTranslation from "src/locales/translation/plgener.json";
import roTranslation from "src/locales/translation/ro.json";
import ruTranslation from "src/locales/translation/ru.json";
import skTranslation from "src/locales/translation/sk.json";
import slTranslation from "src/locales/translation/sl.json";
import srTranslation from "src/locales/translation/sr.json";
import svTranslation from "src/locales/translation/sv.json";
import trTranslation from "src/locales/translation/tr.json";
import ukTranslation from "src/locales/translation/uk.json";
import uzTranslation from "src/locales/translation/uz.json";
import { stripTags } from "src/privateUtils";

export const allBackofficeLanguages = [
  "pl",
  "plgener",
  "en",
  "ro",
  "tr",
  "de",
  "cs",
  "es",
  "fr",
  "hu",
  "uk",
] as const;
export const defaultLanguage = "pl";

export const allProtocolFillerLanguages = [
  ...allBackofficeLanguages,
  "ru",
  "it",
  "sr",
  "sk",
  "sl",
  "el",
  "bs",
  "lt",
  "bg",
  "uz",
  "ka",
  "hy",
  "fi",
  "sv",
] as const;
export type BackofficeLanguage = typeof allBackofficeLanguages[number];
export type ProtocolFillerLanguage = typeof allProtocolFillerLanguages[number];

const englishResource = {
  protocolFiller: enProtocolFiller,
  backoffice: enBackoffice,
  translation: enTranslation,
};

export const localeResources: Record<
  ProtocolFillerLanguage,
  typeof englishResource
> = {
  en: englishResource,
  pl: {
    protocolFiller: plProtocolFiller,
    backoffice: plBackoffice,
    translation: plTranslation,
  },
  plgener: {
    protocolFiller: plGenericProtocolFiller,
    backoffice: plGenericBackoffice,
    translation: plGenericTranslation,
  },
  tr: {
    protocolFiller: stripTags(trProtocolFiller, "[TR]"),
    backoffice: stripTags(trBackoffice, "[TR]"),
    translation: stripTags(trTranslation, "[TR]"),
  },
  ro: {
    protocolFiller: stripTags(roProtocolFiller, "[RO]"),
    backoffice: stripTags(roBackoffice, "[RO]"),
    translation: stripTags(roTranslation, "[RO]"),
  },
  de: {
    backoffice: stripTags(deBackoffice, "[DE]"),
    protocolFiller: stripTags(deProtocolFiller, "[DE]"),
    translation: stripTags(deTranslation, "[DE]"),
  },
  cs: {
    backoffice: stripTags(csBackoffice, "[CS]"),
    protocolFiller: stripTags(csProtocolFiller, "[CS]"),
    translation: stripTags(csTranslation, "[CS]"),
  },
  es: {
    backoffice: stripTags(esBackoffice, "[ES]"),
    protocolFiller: stripTags(esProtocolFiller, "[ES]"),
    translation: stripTags(esTranslation, "[ES]"),
  },
  fr: {
    backoffice: stripTags(frBackoffice, "[FR]"),
    protocolFiller: stripTags(frProtocolFiller, "[FR]"),
    translation: stripTags(frTranslation, "[FR]"),
  },
  hu: {
    backoffice: stripTags(huBackoffice, "[HU]"),
    protocolFiller: stripTags(huProtocolFiller, "[HU]"),
    translation: stripTags(huTranslation, "[HU]"),
  },
  uk: {
    backoffice: stripTags(ukBackoffice, "[UK]"),
    protocolFiller: stripTags(ukProtocolFiller, "[UK]"),
    translation: stripTags(ukTranslation, "[UK]"),
  },
  fi: {
    ...englishResource,
    protocolFiller: stripTags(fiProtocolFiller, "[FI]"),
    translation: stripTags(fiTranslation, "[FI]"),
  },
  ru: {
    ...englishResource,
    protocolFiller: stripTags(ruProtocolFiller, "[RU]"),
    translation: stripTags(ruTranslation, "[RU]"),
  },
  it: {
    ...englishResource,
    protocolFiller: stripTags(itProtocolFiller, "[IT]"),
    translation: stripTags(itTranslation, "[IT]"),
  },
  sr: {
    ...englishResource,
    protocolFiller: stripTags(srProtocolFiller, "[SR]"),
    translation: stripTags(srTranslation, "[SR]"),
  },
  sk: {
    ...englishResource,
    protocolFiller: stripTags(skProtocolFiller, "[SK]"),
    translation: stripTags(skTranslation, "[SK]"),
  },
  sl: {
    ...englishResource,
    protocolFiller: stripTags(slProtocolFiller, "[SL]"),
    translation: stripTags(slTranslation, "[SL]"),
  },
  el: {
    ...englishResource,
    protocolFiller: stripTags(elProtocolFiller, "[EL]"),
    translation: stripTags(elTranslation, "[EL]"),
  },
  bs: {
    ...englishResource,
    protocolFiller: stripTags(bsProtocolFiller, "[BS]"),
    translation: stripTags(bsTranslation, "[BS]"),
  },
  lt: {
    ...englishResource,
    protocolFiller: stripTags(ltProtocolFiller, "[LT]"),
    translation: stripTags(ltTranslation, "[LT]"),
  },
  bg: {
    ...englishResource,
    protocolFiller: stripTags(bgProtocolFiller, "[BG]"),
    translation: stripTags(bgTranslation, "[BG]"),
  },
  ka: {
    ...englishResource,
    protocolFiller: stripTags(kaProtocolFiller, "[KA]"),
    translation: stripTags(kaTranslation, "[KA]"),
  },
  uz: {
    ...englishResource,
    protocolFiller: stripTags(uzProtocolFiller, "[UZ]"),
    translation: stripTags(uzTranslation, "[UZ]"),
  },
  hy: {
    ...englishResource,
    protocolFiller: stripTags(hyProtocolFiller, "[HY]"),
    translation: stripTags(hyTranslation, "[HY]"),
  },
  sv: {
    ...englishResource,
    protocolFiller: stripTags(svProtocolFiller, "[SV]"),
    translation: stripTags(svTranslation, "[SV]"),
  },
};
