import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useQueryParams } from "./useQueryParams";

export function useValueFromQueryParams(parameterName: string) {
  const history = useHistory();
  const queryParams = useQueryParams();

  const queryParamValue = queryParams.get(parameterName) || "";
  useEffect(() => {
    if (queryParamValue) {
      queryParams.delete(parameterName);
      const joinedQueryParams = queryParams.toString();
      const newUrl =
        history.location.pathname +
        (joinedQueryParams ? `?${queryParams.toString()}` : "");
      window.history.replaceState("", "", newUrl);
    }
  }, [queryParamValue, history, queryParams, parameterName]);
  return queryParamValue;
}
