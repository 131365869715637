import {
  FileExcelTwoTone,
  FileImageTwoTone,
  FileTwoTone,
  FilePdfTwoTone,
  FilePptTwoTone,
  FileTextTwoTone,
  FileWordTwoTone,
  FileZipTwoTone,
} from "@ant-design/icons";

const normalizeExtension = (extension: string): string => {
  return extension.toLowerCase();
};

const fileIconMap = new Map<string, typeof FileTwoTone>([
  [".xls", FileExcelTwoTone],
  [".xlsx", FileExcelTwoTone],
  [".csv", FileExcelTwoTone],
  [".png", FileImageTwoTone],
  [".jpg", FileImageTwoTone],
  [".jpeg", FileImageTwoTone],
  [".gif", FileImageTwoTone],
  [".bmp", FileImageTwoTone],
  [".tiff", FileImageTwoTone],
  [".pdf", FilePdfTwoTone],
  [".doc", FileWordTwoTone],
  [".docx", FileWordTwoTone],
  [".ppt", FilePptTwoTone],
  [".pptx", FilePptTwoTone],
  [".zip", FileZipTwoTone],
  [".rar", FileZipTwoTone],
  [".7z", FileZipTwoTone],
  [".txt", FileTextTwoTone],
]);

const iconColorMap = new Map<typeof FileTwoTone, string>([
  [FileExcelTwoTone, "#4dbf00"],
  [FileImageTwoTone, "#0095ff"],
  [FilePdfTwoTone, "#D93025"],
  [FileWordTwoTone, "#0058d6"],
  [FilePptTwoTone, "#D24726"],
  [FileZipTwoTone, "#ffad00"],
  [FileTextTwoTone, "#bcbcbc"],
  [FileTwoTone, "#36c1da"],
]);

interface Props {
  extension: string;
  size: number;
}

export function FileTypeIcon(props: Props) {
  const normalizedExt = normalizeExtension(props.extension);
  const FileIconComponent = fileIconMap.get(normalizedExt) || FileTwoTone;
  return (
    <FileIconComponent
      twoToneColor={iconColorMap.get(FileIconComponent) || undefined}
      style={{
        fontSize: props.size,
      }}
    />
  );
}
