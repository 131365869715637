import { ApiError, NoConnectionError } from "@inspecto/common";
import * as Sentry from "@sentry/react";
import { Spin, Result, Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, Redirect, useParams } from "react-router-dom";

import { useReporting } from "src/reporting/useReporting";
import { urls } from "src/urls";

import { commonResultProps } from "../components/common";
import { PageLayout } from "../components/PageLayout";
import { ProtocolResolutionComponent } from "../components/ProtocolResolutionComponent";
import {
  protocolFillerLocalStorageContext,
  useProtocolFillerContext,
} from "../contexts";

export function ProtocolCreator() {
  const { t } = useTranslation("protocolFiller");
  const { t: translationT } = useTranslation();
  const { vehicleId, templateId } =
    useParams<{ vehicleId: string; templateId: string }>();

  const { createProtocol, sendingStatus, resetSendingState } =
    useProtocolFillerContext();

  const { clearStorage } = useContext(protocolFillerLocalStorageContext);
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    subtitle: "",
  });
  const { logAnalyticsEvent } = useReporting();

  useEffect(() => {
    clearStorage();
  }, [clearStorage]);

  useEffect(() => {
    (async () => {
      if (sendingStatus !== "toSend") {
        return;
      }

      try {
        await createProtocol(templateId, vehicleId);
        logAnalyticsEvent({
          eventType: "finishedFillingProtocol",
          payload: {
            resolution: "success",
          },
        });
      } catch (e) {
        if (e instanceof NoConnectionError) {
          setErrorMessage({
            message: translationT("errors.noInternet.message"),
            subtitle: translationT("errors.noInternet.subtitle"),
          });
        } else {
          if (e instanceof ApiError) {
            Sentry.captureMessage("Protocol sending failed", {
              level: Sentry.Severity.Error,
              extra: {
                response: JSON.stringify(e.body),
                status: e.statusCode,
              },
            });
          } else {
            Sentry.captureException(e);
          }
          setErrorMessage({
            message: t("protocolCreationFailed"),
            subtitle: translationT(
              "errors.pleaseTryAgainOrContactAdministrator"
            ),
          });
        }
        logAnalyticsEvent({
          eventType: "finishedFillingProtocol",
          payload: {
            resolution: "failed",
          },
        });
      }
    })();
  }, [
    templateId,
    vehicleId,
    sendingStatus,
    createProtocol,
    t,
    translationT,
    logAnalyticsEvent,
  ]);

  return (
    <PageLayout progress="finish">
      {sendingStatus === "sending" || sendingStatus === "toSend" ? (
        <>
          <Result
            {...commonResultProps}
            icon={<Spin size="large" />}
            title={t("pleaseWaitMessage")}
            subTitle={t("creatingProtocolMessage")}
          />
          <Prompt
            message={translationT("quittingWithUnsavedChangesConfirmation")}
          />
        </>
      ) : sendingStatus === "failed" ? (
        <ProtocolResolutionComponent
          status="error"
          logoutButtonText={t("logout")}
          extraContent={
            <Button
              key="retry"
              onClick={() => {
                logAnalyticsEvent({
                  eventType: "retriedSendingProtocol",
                  payload: {},
                });
                resetSendingState();
              }}
            >
              {t("retry")}
            </Button>
          }
          title={errorMessage.message}
          subTitle={errorMessage.subtitle}
        />
      ) : (
        <Redirect to={urls.protocolFiller.main()} />
      )}
    </PageLayout>
  );
}
