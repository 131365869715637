import { CommonApi } from "@inspecto/common";
import axios, { AxiosRequestConfig } from "axios";

import { downloadObject } from "./downloadObject";
import { isDevelopment } from "./isDevelopment";

export async function waiter(): Promise<void> {
  if (
    !process.env.JEST_WORKER_ID &&
    isDevelopment() &&
    "delayRequests" in localStorage
  ) {
    await new Promise((resolve) => window.setTimeout(resolve, 500));
  }
}

export class Api extends CommonApi {
  public inspectoModule: "backoffice" | "protocol_filler" = "backoffice";

  async beforeEachRequest() {
    await waiter();
  }

  async download(
    url: string,
    queryParams?: object,
    overwrittenFileName?: string,
    removeBaseURL?: boolean
  ) {
    const response = await this.makeRequest<Blob>({
      method: "get",
      url,
      params: queryParams,
      responseType: "blob",
      ...(removeBaseURL ? { baseURL: "/" } : {}),
    });
    let fileName = overwrittenFileName;
    if (!fileName) {
      const [, receivedFileName] =
        response.headers["content-disposition"].split("filename=");
      fileName = receivedFileName as string;
    }

    downloadObject(response.data, fileName);
  }
}

export const defaultAxiosRequestConfig: AxiosRequestConfig & {
  baseURL: NonNullable<AxiosRequestConfig["baseURL"]>;
  xsrfHeaderName: NonNullable<AxiosRequestConfig["xsrfHeaderName"]>;
  xsrfCookieName: NonNullable<AxiosRequestConfig["xsrfCookieName"]>;
} = {
  baseURL: "/api",
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
};

export const api = new Api(axios.create(defaultAxiosRequestConfig));
