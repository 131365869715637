import { MenuOutlined } from "@ant-design/icons";
import { List, Modal, Space, Typography } from "antd";
import { produce } from "immer";
import { sortBy } from "lodash";
import { useCallback, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DragStart,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledListItem = styled(List.Item)<{ isDragged: boolean }>`
  background-color: #fff;

  ${(props) =>
    props.isDragged ? `box-shadow: rgb(0 0 0 / 10%) 0 2px 4px 0;` : ""}
`;

export interface ReorderListItem {
  id: string;
  label: string;
  description?: string;
}

type GroupId = string;

type ItemsByGroupId = Record<GroupId, ReorderListItem[]>;

export interface ReorderListGroup {
  order: number;
  groupHeader: string;
  groupId: GroupId;
  items: ReorderListItem[];
}

interface Props {
  groups: Record<GroupId, ReorderListGroup>;
  closeModal(): void;
  onSuccess(): void;
  save(items: ReorderListItem[]): Promise<void>;
  modalTitle: string;
}

export function ReorderListItemsModal(props: Props) {
  const { t } = useTranslation("backoffice");
  const [isSaving, setIsSaving] = useState(false);
  const [draggedGroupId, setDraggedGroupId] = useState<string | null>(null);
  const [itemsByGroupId, setItemsByGroupId] = useState<ItemsByGroupId>(
    Object.values(props.groups).reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.groupId]: currentValue.items,
      }),
      {}
    )
  );

  const orderedGroups = sortBy(Object.values(props.groups), "order");

  const onDragStart = useCallback((initial: DragStart) => {
    setDraggedGroupId(initial.source.droppableId);
  }, []);

  const onDragEnd = useCallback((result: DropResult) => {
    setDraggedGroupId(null);
    const { destination, source } = result;

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    setItemsByGroupId((itemsByGroupId) =>
      produce(itemsByGroupId, (draftItemsByGroupId) => {
        const items = draftItemsByGroupId[source.droppableId];
        if (!items) {
          return;
        }

        const movingItem = items[source.index];
        if (!movingItem) {
          return;
        }

        items.splice(source.index, 1);
        items.splice(destination.index, 0, movingItem);
      })
    );
  }, []);

  return (
    <Modal
      open
      onCancel={props.closeModal}
      onOk={async () => {
        setIsSaving(true);
        await props.save(
          orderedGroups
            .map((group) => itemsByGroupId[group.groupId])
            .filter((items): items is ReorderListItem[] => !!items)
            .flat()
        );
        setIsSaving(false);
        props.onSuccess();
        props.closeModal();
      }}
      confirmLoading={isSaving}
      cancelText={t("cancel")}
      okText={t("save")}
      title={props.modalTitle}
    >
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        {orderedGroups.map((group) => {
          const groupId = group.groupId;
          const items = itemsByGroupId[groupId] || [];
          const isDropDisabled = !!draggedGroupId && draggedGroupId !== groupId;
          return (
            <div>
              <Droppable droppableId={groupId} isDropDisabled={isDropDisabled}>
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <List
                      size="small"
                      bordered
                      style={{
                        opacity: isDropDisabled ? 0.4 : 1,
                        marginBottom: 10,
                      }}
                      header={
                        <Typography.Text strong>
                          {props.groups[groupId]?.groupHeader || ""}
                        </Typography.Text>
                      }
                    >
                      {items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot1) => (
                            <StyledListItem
                              isDragged={snapshot1.isDragging}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Space size="middle">
                                <MenuOutlined />
                                <div>
                                  <div>{item.label}</div>
                                  {item.description && (
                                    <div>
                                      <Typography.Text
                                        type="secondary"
                                        style={{ fontSize: 12 }}
                                      >
                                        {item.description}
                                      </Typography.Text>
                                    </div>
                                  )}
                                </div>
                              </Space>
                            </StyledListItem>
                          )}
                        </Draggable>
                      ))}
                      <div style={{ borderBottom: "1px" }}>
                        {provided.placeholder}
                      </div>
                    </List>
                  </div>
                )}
              </Droppable>
            </div>
          );
        })}
      </DragDropContext>
    </Modal>
  );
}
