// CAUTION!
// When changing below values, also update backend config!
const _numberFieldValueDecimalPlaces = 4;
const _numberFieldValueMaxDigits = 15;
const _numberFieldValueMaxDigitsBeforeDecimal =
  _numberFieldValueMaxDigits - _numberFieldValueDecimalPlaces;

export const numberFieldValueConfig = {
  decimalPlaces: _numberFieldValueDecimalPlaces,
  maxDigitsBeforeDecimal: _numberFieldValueMaxDigitsBeforeDecimal,
};
