import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { CompareProtocolsView } from "./CompareProtocolsView";
import { ProtocolsListView } from "./ProtocolsListView";
import { ProtocolView } from "./ProtocolView";

export function ProtocolsRouter() {
  return (
    <Switch>
      <Route path={urls.backOffice.protocols()} exact>
        <ProtocolsListView />
      </Route>
      <Route path={urls.backOffice.compareProtocols()} exact>
        <CompareProtocolsView />
      </Route>
      <Route path={urls.backOffice.protocol()} exact>
        <ProtocolView />
      </Route>
    </Switch>
  );
}
