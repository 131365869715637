import * as fullStory from "@fullstory/browser";
import { useCompany } from "@inspecto/common";
import * as Sentry from "@sentry/react";
import { createContext, ReactNode, useMemo } from "react";

import { User } from "src/authentication";

import { AnalyticsEvent } from "./AnalyticsEvent";

interface ReportingContext {
  setCurrentUser(user: User | null): void;
  initialize(): void;
  logAnalyticsEvent(event: AnalyticsEvent): void;
  stop(): void;
}

export const reportingContext = createContext<ReportingContext>({
  setCurrentUser(_: User) {},
  initialize() {},
  logAnalyticsEvent(_) {},
  stop() {},
});

const { Provider } = reportingContext;

export function ReportingProvider(props: { children: ReactNode }) {
  const company = useCompany();

  const callbacks = useMemo<ReportingContext>(
    () => ({
      setCurrentUser(user: User | null) {
        if (!user) {
          fullStory.anonymize();
          Sentry.configureScope((scope) => scope.setUser(null));
          return;
        }
        const displayUserName = `${user.firstName} ${user.lastName} [${company.name} - ${company.subdomain}]`;
        let customUserVars = {
          email: user.email || undefined,
          displayName: displayUserName,
          companySubdomain: `${company.subdomain}`,
          companyId: `${company.id}`,
          companyName: `${company.name}`,
        };
        fullStory.identify(user.id, customUserVars);
        Sentry.setUser({
          id: user.id,
          ...customUserVars,
          username: displayUserName,
        });
        Sentry.setTag("company", company.subdomain);
      },
      initialize() {
        fullStory.restart();
      },
      stop() {
        fullStory.shutdown();
      },
      logAnalyticsEvent(event: AnalyticsEvent) {
        fullStory.event(event.eventType, event.payload);
      },
    }),
    [company]
  );

  return <Provider value={callbacks}>{props.children}</Provider>;
}
