import { useCallback } from "react";

import { TextFieldComponent } from "src/components";

import { useProtocolFillerContext } from "../contexts";

interface Props {
  questionId: string;
}

export function TextQuestionComponent(props: Props) {
  const { setTextResponse, responses } = useProtocolFillerContext();

  const { textResponses } = responses;

  const setResponse = useCallback(
    (response: string) => {
      setTextResponse(props.questionId, response);
    },
    [props.questionId, setTextResponse]
  );

  return (
    <TextFieldComponent
      value={textResponses[props.questionId]}
      onChange={(event) => setResponse(event.currentTarget.value)}
    />
  );
}
