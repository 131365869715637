import { Company } from "src/models";

import { CommonApi } from "../Api";

export class CompanyApi {
  constructor(private api: CommonApi) {}

  async getBySubdomain(subdomain: string): Promise<Company> {
    return await this.api.get<Company>(`/companies/${subdomain}/`);
  }
}
